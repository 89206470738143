export const CONNECTION_STATUS = {
   PENDING_TO:"Request sent",
   PENDING_FROM:"Request received",
   ACCEPT_TO:"Connected",
   ACCEPT_FROM:"Connected",
   DECLINE_FROM:"Declined",
   DECLINE_TO:"Declined",
   EXCHANGE_PENDING_TO:"Xchange request sent",
   EXCHANGE_PENDING_FROM:"Xchange Request received",
   EXCHANGE_ACCEPT_TO:"Xchange Pending",
   EXCHANGE_ACCEPT_FROM:"Xchange Pending",
   PAID_FROM:"Xchange Active",
   PAID_TO:"Xchange Active",
   EXCHANGE_DECLINE_FROM:"Xchange Declined",
   EXCHANGE_DECLINE_TO:"Xchange Declined",
}
