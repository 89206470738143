import React, { FC, memo } from 'react';
import moment from 'moment';
import InfoBubble from '../common/InfoBubble';
import { RoleLabel, RoleCode } from '../../store/Users/constants'
import { Button, UserIcon } from '../common';
import { defaultProfileValue } from '../UserProfile/utils';
import "./styles.scss";

interface Props {
	profile: any;
	image: string;
	roleCode: RoleCode;
	openProfileModal: () => void;
}

const AccountSettingsCard: FC<Props> = ({
	profile,
	image,
	roleCode,
	openProfileModal
}) => {

	const formatDate = (date: string) => moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY');

	return (
		<div className="dash-acc">
			<div className="box box-bottom-margin">
				<div className="dash-top">
					<div className="top-inner">
						<div className="padding-com" id="account-setting">
							<div className="row">
								<div className="col-5 col-sm-3">
									<div className="user-icon-container">
										<UserIcon profile_image_url={image} />
									</div>
								</div>
								<div className="col-sm-9 text-light user-profile-info">
									<div className="user-name">{profile.name}</div>
									<div className="user-role">{roleCode === RoleCode.GUEST ? RoleLabel.GUEST : RoleLabel.HOST}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-md-12 padding-com">
					<div className="dash-basic">
						<div className="row">
							<div className="col-md-6 col-sm-6">
								<dt className="dashboard-info__value">
									Preferred name
									<InfoBubble tooltip="Please select a name that is not identifiable. EG: Your first name only, an abbreviation of your name or your nickname." />
								</dt>
								<dd>
									{profile.preferred_name ? profile.preferred_name : profile.first_name}
								</dd>
							</div>
							<div className="col-md-6 col-sm-6">
								<dt className="dashboard-info__value">
									Date of birth
									<InfoBubble tooltip="This must be exactly as it appears on the photo iD you use for your Digital iD verification. If not, you won’t be verified and therefore cannot connect with other users." />
								</dt>
								<dd>{profile.dob ? formatDate(profile.dob) : defaultProfileValue}</dd>
							</div>
						</div>
						<div className="row">
							<div className="col-md-6 col-sm-6">
								<dt>Phone number</dt>
								<dd>{profile.phone_no}</dd>
							</div>
							<div className="col-md-6 col-sm-6">
								<dt>Postcode</dt>
								<dd>{profile.post_code}</dd>
							</div>
						</div>
						<div className="row">
							<div className="col-md-12 col-sm-12">
								<dt>Email</dt>
								<dd>{profile.email}</dd>
							</div>
						</div>
						<div className="row">
							<div className="col-md-6 col-sm-6">
								<dt className="dashboard-info__value">
									Available
									<InfoBubble tooltip="Keep this updated so more people will reach out to you." />
								</dt>
								<dd>{profile.exchange_date ? formatDate(profile.exchange_date) : defaultProfileValue}</dd>
							</div>
							<div className="col-md-6 col-sm-6">
								<dt>Duration of rental
								<InfoBubble tooltip="Please select the length of time you plan to house share." />
								</dt>
								<dd>{profile.exchange_period || defaultProfileValue}</dd>
							</div>
						</div>
						<Button href="/account" className="float-right">Edit</Button>
						<div className="clearfix" />
						<div className="row">
							<div className="col-sm-12 mt-3">
								<div className="account-info-note">
									Your date of birth, email &amp; phone number are private and will
									not be displayed publicly or shared with other users.
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default memo(AccountSettingsCard);
