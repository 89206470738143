import { createUseStyles } from 'react-jss';

export const useSearchPageStyles = createUseStyles({
  resultsContainer: {
    width: '100%',
    maxWidth: 1280,
    marginLeft: 'auto',
    marginRight: 'auto',
    overflow: 'hidden',
    padding: 16
  },
  row: {
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: -8,
    marginRight: -8
  },
  grid: {
    boxSizing: 'border-box',
    paddingLeft: 8,
    paddingRight: 8,
    paddingBottom: 32,
    display: 'flex',
    flex: '1 1 100%',
    maxWidth: '100%',
    '@media (min-width: 380px)': {
      flex: '1 1 50%',
      maxWidth: '50%'
    },
    '@media (min-width: 768px)': {
      flex: '1 1 33.333%',
      maxWidth: '33.333%'
    },
    '@media (min-width: 1024px)': {
      flex: '1 1 25%',
      maxWidth: '25%'
    }
  },
  actionDanger: {
    backgroundColor: '#ef7f49',
    color: '#fff',
    borderRadius: 30,
    fontSize: 14,
    fontWeight: 600,
    padding: '8px 16px',
    border: 0,
    fontFamily: 'Montserrat, sans-serif'
  },
  actionSuccess: {
    backgroundColor: '#ef7f49',
    color: '#fff',
    borderRadius: 30,
    fontSize: 14,
    cursor: 'pointer',
    fontWeight: 600,
    padding: '8px 16px',
    border: 0,
    fontFamily: 'Montserrat, sans-serif',
  },
  resultsTotal: {
    fontSize: 18,
    fontWeight: 300,
    paddingTop: 16,
    marginBottom: 24
  },
  topPaginationContainer: {
    'div&': {
      '@media (min-width: 768px)': {
        paddingBottom: 24,
        paddingTop: 0
      }
    }
  },
  resultsNotFound: {
    fontFamily: 'Montserrat, sans-serif',
    fontSize: 20,
    fontWeight: 300,
    paddingTop: 16,
    marginBottom: 32
  }
});

export const useSearchPageFilterBarStyles = createUseStyles({
  filterContainer: {
    backgroundColor: '#444'
  },
  formWrapper: {
    maxWidth: 1280,
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: 16
  }
});

export const useSearchPagePaginationStyles = createUseStyles({
  paginationContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    fontFamily: 'Montserrat, sans-serif',
    paddingTop: 16,
    paddingBottom: 16,
    '@media (min-width: 768px)': {
      paddingTop: 48,
      paddingBottom: 48
    }
  },
  paginationItemsContainer: {
    display: 'flex',
    flex: '1 1 auto',
    justifyContent: 'center'
  },
  paginationButtonWrapper: {
    width: 30,
    '@media (min-width: 768px)': {
      width: 120
    }
  },
  paginationButton: {
    border: '1px solid #ef7f49',
    backgroundColor: 'white',
    color: '#ef7f49',
    width: '100%',
    height: 30,
    borderRadius: 5,
    fontWeight: 500,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#ef7f49',
      color: 'white'
    },
    '& span': {
      display: 'none',
      '@media (min-width: 768px)': {
        display: 'inline'
      }
    },
    '@media (min-width: 768px)': {
      height: 40
    }
  },
  paginationItemWrapper: {
    paddingLeft: 4,
    paddingRight: 4,
    '@media (min-width: 768px)': {
      paddingLeft: 8,
      paddingRight: 8
    }
  },
  paginationItem: {
    border: '1px solid #999999',
    color: '#999999',
    cursor: 'pointer',
    borderRadius: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 30,
    height: 30,
    '@media (min-width: 768px)': {
      width: 40,
      height: 40
    }
  },
  paginationItemActive: {
    color: 'white',
    backgroundColor: '#ef7f49'
  }
});
