export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';

export function ProcessModal(actionType, modalType, modalProps) {
  return {
    type: actionType,
    modalType: modalType,
    modalProps: modalProps
  };
}
