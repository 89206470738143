export enum LocationType {
	MAJOR_URBAN_LOCALITY = 'Major urban locality',
	SUBURB = 'Suburb',
	RURAL_LOCALITY = 'Rural locality',
	DELIVERY_AREA = 'Delivery Area',
	URBAN_LOCALITY = 'Urban locality',
	STATE = 'State'
}

export interface Location {
	id: number,
	display: string,
	source: {
		subtype: LocationType,
		name: string,
		state: string,
		postcode?: string
	}
	
}
