import React, { Component } from 'react';
import { connect } from 'react-redux';
import { HIDE_MODAL, ProcessModal } from '../../actions/Modal';
import { XchangeForm } from '../xchange-form';
import { createExchange } from '../../actions/Xchange';
import moment from 'moment';
import { fetchConnectionList } from '../../actions/Connection';

class XchangeModal extends Component {
  handleCloseModal = () => {
    const { dispatch } = this.props;
    const modalProps = { isOpened: false };
    dispatch(ProcessModal(HIDE_MODAL, null, modalProps));
  };

  handleSubmit = async data => {
    const {
      connection,
      connectionDetail,
      currentUser,
      createExchange
    } = this.props;

    const userId =
      currentUser.user_id == connectionDetail.from_user_id
        ? connectionDetail.to_user_id
        : connectionDetail.from_user_id;

    const exchangeData = {
      ...data,
      endDate:
        data.xchangeType === 'short-term'
          ? moment(data.startDate).add(7, 'days')
          : data.endDate,
      connectionId: connection.connection_id,
      userId,
      token: currentUser.token
    };

    createExchange(exchangeData);
  };

  componentDidUpdate() {
    const {
      xchange,
      fetchConnectionList,
      currentUser: { user_id, token }
    } = this.props;
    const { isCreatedSuccess } = xchange;

    if (isCreatedSuccess) {
      fetchConnectionList({
        token,
        page: 1,
        limit: 20,
        type: '',
        search: ''
      });

      this.handleCloseModal();
    }
  }

  render() {
    const { isOpened, xchange } = this.props;
    const { isCreatingExchange } = xchange;

    return (
      <React.Fragment>
        {isCreatingExchange && <div className="page-loading" />}
        <div
          className={`modal fade ${
            isOpened ? 'show default-modal__shown' : ''
          }`}
        >
          <div className="modal-dialog modal-dialog-centered modal-lg visible-xs">
            <div className="modal-content">
              <div className="modal-body default-modal__body">
                <button
                  type="button"
                  className="close default-modal__close"
                  data-dismiss="modal"
                  onClick={this.handleCloseModal}
                >
                  &times;
                </button>
                <h2 className="default-modal__heading">Xchange Type</h2>
                <XchangeForm onSubmit={this.handleSubmit} />
              </div>
            </div>
          </div>
        </div>
        {isOpened && <div class="modal-backdrop fade show" />}
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  dispatch,
  createExchange: data => dispatch(createExchange(data)),
  fetchConnectionList: params => dispatch(fetchConnectionList(params))
});

const mapStateToProps = ({ xchange }) => ({
  xchange
});

const EnhancedXchangeModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(XchangeModal);

export { EnhancedXchangeModal as XchangeModal };
