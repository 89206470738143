import React from 'react';
import filter from 'lodash/filter';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import axios from 'axios';
import URL from '../../constants/URL';

export class SuburbField extends React.Component {
  constructor(props) {
	  console.log(props);
    super(props);

    this.state = {
      suburbs: [],
      inputValue: this.props.value
        ? this.getSuggestionLabel(this.props.value)
        : ''
    };
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.value) === JSON.stringify(this.props.value)) {
      return;
    }

    this.setState({
      inputValue: this.props.value
        ? this.getSuggestionLabel(this.props.value)
        : ''
    });
  }

  get inputProps() {
    const { placeholder, name } = this.props;
    const { inputValue } = this.state;

    return {
      placeholder,
      value: inputValue,
      name,
      onChange: this.handleInputChange
    };
  }

  handleInputChange = (event, { newValue }) => {
    this.setState({
      inputValue: newValue
    });

    const { onChange, name } = this.props;

    if (newValue === '') {
      onChange({ target: { name, value: undefined } });
    }
  };

  handleSuggestionSelected = (event, { suggestion }) => {
    const { onChange, name } = this.props;

    onChange({ target: { name, value: suggestion } });
  };

  handleSuggestionsFetchRequestedContext = (fetchURL) => {
	  return ({ value }) => {
		  if (!value) this.setState({ suburbs: [] });

		  if (value.length < 3) return;

		  try {
			  axios.get(`${fetchURL}?query=${value}`)
				  .then(({data}) => {
					  const locationList = data.postcodes || data.cities
					  const suburbs = filter(locationList, (data) => data.type !== 'State');
					  this.setState({ suburbs });
				  });
		  } catch (error) {
			  this.setState({ suburbs: [] });
		  }
	  };
  }

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  getSuggestionLabel = suburb => `${suburb.locality || ''} ${suburb.post_code || ''}`;

  renderSuggestion = (suggestion, { query }) => {
    const suggestionLabel = this.getSuggestionLabel(suggestion);
    const matches = match(suggestionLabel, query);
    const parts = parse(suggestionLabel, matches);

    return (
      <div className="autosuggest__item">
        {parts.map(part => (
          <span
            key={part.text}
            style={{ fontWeight: part.highlight ? 500 : 400 }}
          >
            {part.text}
          </span>
        ))}
      </div>
    );
  };

  renderSuggestionsContainer = ({ containerProps, children }) => (
    <div className="autosuggest__container" {...containerProps}>
      {children}
    </div>
  );

  renderInput = ({ className, name, ...other }) => {
    const { label, error, displayLabelIfEmpty, classes } = this.props;
    const { input: inputStyle = 'form-control', inputWrapper } = classes || {};

    return (
      <div className={inputWrapper}>
        {(label || displayLabelIfEmpty) && (
          <label htmlFor={name} className="control-label">
            {label} &nbsp;
          </label>
        )}
        <input name={name} className={inputStyle} {...other} />
        {error && (
          <div className="has-error">
            <span className="help-block">{error}</span>
          </div>
        )}
      </div>
    );
  };

  render() {
    const { suburbs } = this.state;

    return (
      <Autosuggest
        suggestions={suburbs}
        onSuggestionsFetchRequested={this.handleSuggestionsFetchRequestedContext( this.props.includeSuburbs ? URL.GET_SUBURB_SUGGESTIONS : URL.GET_CITY_SUGGESTIONS)}
        onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
        getSuggestionValue={this.getSuggestionLabel}
        onSuggestionSelected={this.handleSuggestionSelected}
        renderSuggestion={this.renderSuggestion}
        inputProps={this.inputProps}
        renderInputComponent={this.renderInput}
        renderSuggestionsContainer={this.renderSuggestionsContainer}
      />
    );
  }
}
