import {ACTION_CONTINUE, ACTION_EXIT} from "../../utils/common";
import React, {FC} from "react";
import RemoteUpdateError from './RemoteUpdateError';

type SubmitButtonsProps={
	setButtonType: Function,
	isValid: boolean
}
const FormSubmitButtons: FC<SubmitButtonsProps>=({setButtonType, isValid})=>{
	return (
		<div className="abt-btn float-right">
			<button className="button btn-orange" type="submit" onClick={()=>{setButtonType(ACTION_EXIT)}}>Save &amp; exit</button>
			<button className="button btn-teal" type="submit"  onClick={()=>{setButtonType(ACTION_CONTINUE)}}>Save &amp; continue</button>
		</div>)
};
export default FormSubmitButtons;
