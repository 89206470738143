import React, { FC, memo, MouseEvent as ME } from 'react';
import { Link } from 'react-router-dom';
// @ts-ignore
import { button as rvButton } from 'react-validation';
import './Button.scss';

interface Props {
	color?: 'orange' | 'teal' | 'grey' | 'blue';
	anchor?: boolean;
	href?: string;
	type?: 'link_ext' | 'button' | 'submit';
	className?: string;
	size?: '' | 'medium' | 'large';
	onClick?: (event?: ME) => void;
	disabled?: boolean;
	hasErrors?: boolean;
}
/**
 * @ButtonComponent generates a component of Button UI for
 * HTML Button, HTML anchor, react-router-dom & npm/react-validation-button
 *
 * To generate the required Button UI with HTML Button,
 * - add children as button label
 * - all the props are optional
 * - @anchor & @href prop is restricted
 *
 * - @example: <Button {...optionalProps}>Text</Button>
 *
 * To generate the required Button UI with react-router-dom/Link,
 * - add children as button label
 * - @href props is mandatory, rest of the props are optional
 * - @anchor prop is restricted
 *
 * - @example: <Button href="#" {...optionalProps}>Text</Button>
 *
 * To generate the required Button UI with HTML anchor,
 * - add children as button label
 * - @href & @anchor props are mandatory, rest of the props are optional
 * - @example: <Button anchor href="#" {...optionalProps}>Text</Button>
 *
 */

const Button: FC<Props> = memo(
	({ color, className, href, children, disabled, anchor, ...rest }) => {
		const style = `button btn-${color} ${className} ${
			rest.size && `button-${rest.size}`
		}`;

		let btnProps: any = {};
		if (rest.hasErrors || disabled) btnProps.disabled = true;

		if (href) {
			if (disabled) {
				return (
					<span className={`disabled ${style}`} {...rest}>
						{children}
					</span>
				);
			} else if (rest.type === 'link_ext') {
				return (
					<a className={style} href={href} {...rest}>
						{children}
					</a>
				);
			} else {
				return (
					<Link className={style} to={href} {...rest}>
						{children}
					</Link>
				);
			}
		}

		return (
			<button className={style} {...rest} {...btnProps}>
				{children}
			</button>
		);
	}
);

Button.defaultProps = {
	color: 'orange',
	className: '',
	size: '',
	onClick: (event?: ME) => {}
};

const RVButton = rvButton(Button);

export { Button, RVButton };
