import React, { Component } from 'react';
import axios from 'axios';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import { required } from '../../utils/FormValidators.jsx';
import URL from '../../constants/URL';
import FRONTEND_RESPONSE_MESSAGE from '../../constants/FRONTEND_RESPONSE_MESSAGE';
import {
  injectStripe,
  Elements,
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement
} from 'react-stripe-elements';
import { Button, RVButton } from '../../../components/common';

class CardForm extends Component {
  state = {
    requesting: false,
    cardError: '',
    cardSuccess: '',
    cardName: '',
    couponCheck: false,
    coupon: ''
  };

  handleInputChange = e => {
    e.preventDefault();
    this.setState({ ...this.state, [e.target.name]: e.target.value });
  };

  toggleChange = () => {
    if (!this.state.couponCheck) {
      this.setState({
        couponCheck: !this.state.couponCheck
      });
    } else {
      this.setState({
        couponCheck: !this.state.couponCheck,
        coupon: ''
      });
    }
  };

  handlePaymentRequest = e => {
    e.preventDefault();
    const { token } = this.props.login;
    this.setState({ ...this.state, requesting: true });
    this.props.stripe.createToken().then(stripeResponse => {
      if (stripeResponse.error) {
        this.setState({
          ...this.state,
          requesting: false,
          cardError: stripeResponse.error.message
        });
      } else {
        const request = axios({
          method: 'post',
          url: URL.PAYMENT_API,
          data: {
            user_token: token,
            card_token: stripeResponse.token.id,
            invoice_id: this.props.invoiceId,
            exchange_id: this.props.xchangeId,
            coupon: this.state.coupon
          }
        });
        request.then(result => {
          if (result.data.status === 'success') {
            this.props.onFetchConnectionList({
              token: this.props.login.token,
              page: 1,
              limit: 20,
              type: '',
              search: ''
            });
            this.props.fetchConnectionDetailComponent(this.props.connections);
            this.props.onFetchCustomerCardDetail(token);
            this.props.onFetchInvoices(token);
            this.props.onFetchStripeInvoices(token);
            this.props.getNotifyFromCard();
            this.setState({
              ...this.state,
              cardSuccess: result.data.msg,
              cardError: '',
              requesting: false
            });
          } else {
            this.setState({
              ...this.state,
              requesting: false,
              cardError: result.data.msg,
              cardSuccess: ''
            });
          }
        });
      }
    });
  };

  render() {
    return (
      <div>
        {this.state.requesting && <div className="page-loading" />}
        {!this.state.cardSuccess ? (
          <Form>
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <label className="control-label">Card Number</label>
                  <CardNumberElement className="card-element" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-5 col-12">
                <div className="form-group">
                  <label className="control-label">Expiry</label>
                  <CardExpiryElement className="card-element" />
                </div>
              </div>
              <div className="col-md-5 col-12">
                <div className="form-group">
                  <label className="control-label">Security Code</label>
                  <CardCVCElement className="card-element" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="form-check">
                  <input
                    name="couponCheck"
                    checked={this.state.couponCheck}
                    onChange={this.toggleChange}
                    className="form-check-input"
                    type="checkbox"
                  />
                  <label className="form-check-label" htmlFor="experience-5">
                    Coupon
                  </label>
                </div>
                {this.state.couponCheck && (
                  <div className="form-group">
                    <Input
                      type="coupon"
                      validations={[required]}
                      className="form-control"
                      style={{ height: '42px' }}
                      name="coupon"
                      value={this.state.coupon}
                      onChange={this.handleInputChange}
                      placeholder="Please enter your coupon"
                    />
                  </div>
                )}
              </div>
            </div>
            {this.state.cardError && (
              <div className="alert-danger" role="alert">
                <strong>Error: </strong>
                {this.state.cardError}
              </div>
            )}
            {this.props.payment.cards && this.props.payment.cards.length > 0 && (
              <Button
                anchor
                href="#"
                onClick={this.props.useExistingCard}
                color="teal"
              >
                Use existing card
              </Button>
            )}
            <div className="modal-footer">
              <RVButton
                type="submit"
                onClick={this.handlePaymentRequest}
                className="float-right"
                data-toggle="modal"
              >
                Submit
              </RVButton>
            </div>
          </Form>
        ) : (
          <div>
            <p>{FRONTEND_RESPONSE_MESSAGE.PAYMENT.SUCCESS_RESPONSE}</p>
            <div className="modal-footer">
              <Button
                onClick={this.close}
                type="button"
                color="teal"
                className="float-right"
              >
                Close
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const InjectedCardForm = injectStripe(CardForm);

const CardForXchangeCompletion = props => (
  <div className="Checkout">
    <div>
      <Elements>
        <InjectedCardForm {...props} />
      </Elements>
    </div>
  </div>
);

export default CardForXchangeCompletion;
