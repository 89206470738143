import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import URL from '../../constants/URL';
import { viewProfile } from '../../actions/Profile';
import { ProcessModal } from '../../actions/Modal';
import { getBadges } from '../../actions/Badges';
import { HIDE_MODAL } from '../../actions/Modal';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
// import Button from 'react-validation/build/button';
import { Button } from '../../../components/common';

class PoliceCheckModal extends Component {

  constructor(props){
    super(props);
    this.state={
      requesting:false,
      fileInputError:'',
      documentCV:'',
      fileName:''
    }
  }


  close = () => {
    const modalProps={showModal:false}
        this.props.dispatch(ProcessModal(HIDE_MODAL,null,modalProps));
    }

    handleCVChange = (e) => {
      e.preventDefault();
      let files;
      if(e.dataTransfer){
        files = e.dataTransfer.files;
      } else if (e.target) {
        files=e.target.files
      }
      if(files){
        let reader = new FileReader();
        reader.fileName= files.name
        reader.readAsDataURL(files[0])
        reader.onload = (result) => {
          this.setState({...this.state, documentCV:reader.result,fileName:files[0].name})
        }
      }
    }

    b64toBlob(b64Data, contentType, sliceSize) {
      contentType = contentType || '';
      sliceSize = sliceSize || 512;

      var byteCharacters = atob(b64Data);
      var byteArrays = [];

      for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }

      var blob = new Blob(byteArrays, {type: contentType});
      return blob;
    }

handlePoliceCheckSubmit = (e) => {
  e.preventDefault();
  if(this.state.documentCV != null){
    var form = new FormData();
    var documentCV=this.state.documentCV;
    // Split the base64 string in data and contentType
    var block = documentCV.split(";");
    // Get the content type
    var contentType = block[0].split(":")[1];// In this case "image/gif"
    // get the real base64 content of the file
    var realData = block[1].split(",")[1];// In this case "iVBORw0KGg...."
    var CV =this.b64toBlob(realData, contentType);
    form.append("token", this.props.login.token);
    form.append("cv", CV , this.state.fileName);

    const request =axios({
      method:"post",
      url:URL.POLICE_CHECK_API,
      data:form,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    request.then((result) => {
      if(result.data.status=="success"){
        this.props.onGetBadges(this.props.login);
        this.props.onViewProfile(this.props.login.user_id,this.props.login.token)
        this.close();
        this.setState({...this.state,othersrc:'',requesting:false})
      }

    });
  }else{
    this.setState({...this.state,fileInputError:"Please select the document"})
  }
}

  render(){
    return(
      <div>
        <div className="overlay modal-backdrop modal-dialog-centered">
          <div className="col-md-6 offset-md-3">
            <div>
            </div>
            <div className="modal-content padding-com">
              {this.state.requesting && (
                <div className="page-loading"></div>
              )}
              <div>
                <div className="modal-header">
                  <button type="button" className="close" onClick={this.close} data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true"><i className="fa fa-close"></i></span>
                  </button>
                </div>

                <div className="modal-body">
                  <div>
                    <div className="top-title">Police Check</div>
                    <Form>
                      <div>
                        <div >
                          <input type="file" className="form-control"
                            style={{display: 'none'}}
                            onChange={this.handleCVChange}
                            accept="image/gif, image/jpeg, application/pdf"
                            ref="cvUpload"
                          />
                          </div>
                          <p>
                            If you don’t have a Police Check, we’ve made it easy for you to apply for one here
                            <a style={{color:"#EF7F49"}} href="https://ww1.cvcheck.com/ref/436e9aa0-4ee7-4a9a-ac96-1610695693a5" target="_blank"> via our partner CV Check</a>.
                            If there are no issues, you’ll have your Police Check within 24 hours. If you already have one, upload your Police Check below
                          </p>
                          <div>
                          {(this.state.fileName && this.state.documentCV) && (
                            this.state.fileName
                          )}
                        </div>
                            <span><i className="fa fa-plus" data-toggle="modal" onClick={(refs)=> this.refs.cvUpload.click() }>
                            </i>Upload your file</span>
                          </div>
                          </Form>
                        </div>

                      </div>
                          <div className="modal-footer">
                                <div>

                                    <Button type="submit" disabled={!this.state.fileName && !this.state.documentCV} onClick={this.handlePoliceCheckSubmit}>Save</Button>

                                  <Button type="button" color="grey" onClick={this.close}>Cancel</Button>
                                    </div>

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
    )
  }
}
const mapDispatchToProps = (dispatch) => {
  return{
    onGetBadges:(user)=>dispatch(getBadges(user)),
    onClose:(actionType,modalType,modalProps)=>dispatch(ProcessModal(actionType,modalType,modalProps)),
    onViewProfile:(user_id,token) => dispatch(viewProfile(user_id,token)),
    dispatch
  }
}
const mapStateToProps = (store) => {
  return {
    login: store.login.user,
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(PoliceCheckModal)
