import React, {useEffect, useState, memo} from 'react';
import AboutEditHost from "./components/AboutEditHost";
import LifeStyleHost from "./components/LifeStyleHost";
import Priorities from "./components/Priorities";
import PracticalInclusions from "./components/PracticalInclusions";
import Photos from "./components/Photos";
import Personality from "./components/Personality";
import {editProfileService} from "./utils/editProfileService";
import EditProfileStateProvider from "./context/EditProfileStateProvider";
import AjaxLoader from "./components/helpers/AjaxLoader";


const HostEdit=({token, user_id, role_code})=>{

	const [data,setData]=useState(null);
	useEffect(  ()=>{
		async function fetchData() {
			if(data!==null)
				return;
			const remoteData=await editProfileService.COMMON.getDataFromServer(user_id,token)
			const res=await remoteData.data;
			setData(res);
		}
		fetchData();
	},[user_id, token, data])

	if(data===null)
		return <></>;

	return (
		<EditProfileStateProvider data={data}>
			<div className="trx-abt-section container trx-com-wrap">
				<AjaxLoader/>
				<div className="tab-content">

					<div role="tabpanel" className="tab-pane active ">
						<div className="trx-abt-wrap">

							<div id="profileAccordion" role="tablist" aria-multiselectable="true">

								<AboutEditHost user={{user_id, token}}/>
								<LifeStyleHost user={{user_id, token}}/>
								<Priorities user={{user_id, token}}/>
								<Personality user={{user_id, token, role_code}}/>
								<PracticalInclusions user={{user_id, token}}/>
								<Photos user={{user_id, token}} profileType={role_code}/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</EditProfileStateProvider>
	);
}

export default memo(HostEdit);
