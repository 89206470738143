import React, { FC, memo } from 'react';
import { Link } from 'react-router-dom';
import InfoBubble from '../common/InfoBubble';
import { Button } from '../common';
import { RoleLabel, RoleCode } from '../../store/Users/constants';

interface Props {
	myBadges: { badge_code: string, verification_status: string }[];
    roleCode: RoleCode
}

const ProfileBadges: FC<Props> = (props) => {
    let myBadges:any[] = [];
    if (props.myBadges) {
        myBadges = props.myBadges.map((myBadges, index) => {
          if (
            myBadges.badge_code === 'DIGITAL_ID' ||
            (myBadges.badge_code === 'POLICE_CHECK' &&
              myBadges.verification_status === 'Police Check Verified')
          ) {
            return (
              <span className={myBadges.badge_code.toLowerCase()} key={index} />
            );
          }
        });
      }
    return (
        <div className="box box-bottom-margin">
            <div className="digi-id padding-com clearfix">
            <dt className="block-title dashboard-info__value">
                Verification badge
                <InfoBubble tooltip="Once you have completed your Digital iD verification process, your Digital iD badge will show up here and on your public profile as well." />
            </dt>
            {myBadges.length ? (
                <div className="badges-wrap">
                {myBadges}
                </div>
            ) : (
                <p className="verify-empty">
                All users need to be verified, find out
                how.
                </p>
            )}
            <Button className="float-right" href="/dashboard/badges">Verification</Button>
            </div>
        </div>
	);
};

export default memo(ProfileBadges);
