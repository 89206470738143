import React from 'react';
import { SignUpStep } from './SignUpStep';
import { object, string, date } from 'yup';
import { Form, Field } from 'formik';
import { TextField, SelectField, SuburbField } from 'js/components/forms';
import moment from 'moment';
import { Button } from 'components/common';

const Step2Fields = {
  DOB: 'dob',
  GENDER: 'gender',
  SUBURB: 'suburb',
  FOUNDOUT: 'foundout',
  OTHER_FOUNDOUT: 'otherFoundout',
  OTHER_GENDER: 'otherGender'
};

const step2Validation = object({
  [Step2Fields.DOB]: date()
    .typeError('Please enter a valid date of birth')
    .required('Please enter your date of birth')
    .max(
      moment().subtract(18, 'years'),
      'You must be at least 18 years old to register'
    ),
  [Step2Fields.GENDER]: string().when('otherGender', {
    is: (value) => !value,
    then: string().required('Please select your gender')
  }),
  [Step2Fields.SUBURB]: object().required('Please select a suburb'),
  [Step2Fields.FOUNDOUT]: string().required('Please select a medium'),
  [Step2Fields.OTHER_GENDER]: string()
});

export const step2InitialData = {
  dob: '',
  gender: '',
  suburb: undefined,
  otherGender: '',
  foundout: '',
  otherFoundout: ''
};

const foundOutOptions = [
  { value: 'Facebook', label: 'Facebook' },
  { value: 'Instagram', label: 'Instagram' },
  { value: 'Podcast', label: 'Podcast' },
  { value: 'Blog', label: 'Blog article' },
  { value: 'Google Search', label: 'Google Search' },
  { value: 'Linkedin', label: 'Linkedin' },
  { value: 'Media', label: 'Media' },
  { value: 'Postcard', label: 'Postcard/Mail'},
  { value: 'Referral', label: 'Referral from a friend' },
  { value: 'Other', label: 'Other' }
];

export class SignUpStep2 extends React.Component {
  render() {
    const { onSubmit, onPrevious, data } = this.props;
	const suburbLabel = data.role === 'HOUSEHOLD' ? 'Suburb' : 'What city would you like to live in/near?';
	const suburbPlaceHolder = data.role === 'HOUSEHOLD' ? 'Please enter your suburb' : 'Please enter your desired location';

    return (
      <SignUpStep
        onSubmit={onSubmit}
        data={data}
        validation={step2Validation}
        renderForm={({ errors, touched, values, handleChange, handleBlur }) => (
          <Form>
            <div className="card-wrapper bg-light">
              <div className="row">
                <div className="col-md-12">
                  <TextField
                    type="date"
                    label="Date of birth"
                    name={Step2Fields.DOB}
                    placeholder="DD/MM/YYYY"
                    smallText="***We need your correct DOB for our Digital iD verification process."
                    fieldClassName="w-50"
                    error={errors.dob && touched.dob ? errors.dob : null}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <label className="control-label">Gender</label>
                </div>
              </div>
              <div className="row row__gender">
                <div className="col-md-2">
                  <div className="form-group">
                    <label className="form-check">
                      <div>
                        <Field
                          className="form-check-input"
                          name={Step2Fields.GENDER}
                          type="radio"
                          value="Male"
                          checked={values.gender === 'Male'}
                        />
                      </div>
                      <span className="form-check-label">Male</span>
                    </label>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <label className="form-check">
                      <div>
                        <Field
                          className="form-check-input"
                          name={Step2Fields.GENDER}
                          type="radio"
                          value="Female"
                          checked={values.gender === 'Female'}
                        />
                      </div>
                      <span className="form-check-label">Female</span>
                    </label>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="form-group">
                    <div className="field_gender__other">
                      <span>Or</span>
                      <TextField
                        name={Step2Fields.OTHER_GENDER}
                        placeholder="How do you identify?"
                        displayLabelIfEmpty={false}
                        error={
                          errors.otherGender && touched.otherGender
                            ? errors.otherGender
                            : null
                        }
                      />
                    </div>
                  </div>
                </div>
                {errors.gender && touched.gender && (
                  <div className="col-md-12">
                    <div className="has-error">
                      <span className="help-block">{errors.gender}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className="row">
                <div className="col-md-6 form-group">
                  <SuburbField
                    label={suburbLabel}
                    name="suburb"
                    placeholder={suburbPlaceHolder}
					includeSuburbs={data.role === 'HOUSEHOLD'}
                    value={values.suburb}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      errors.suburb && touched.suburb ? errors.suburb : null
                    }
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <label className="control-label">
                    How did you find out about us?
                  </label>
                </div>
                <div className="col-md-6">
                  <SelectField
                    displayLabelIfEmpty={false}
                    name={Step2Fields.FOUNDOUT}
                    placeholder="Select"
                    options={foundOutOptions}
                    error={
                      errors.foundout && touched.foundout
                        ? errors.foundout
                        : null
                    }
                  />
                </div>
                {values.foundout === 'Other' && (
                  <div className="col-md-6">
                    <TextField
                      placeholder="Please enter other medium here"
                      name={Step2Fields.OTHER_FOUNDOUT}
                      displayLabelIfEmpty={false}
                      error={
                        errors.otherFoundout && touched.otherFoundout
                          ? errors.otherFoundout
                          : null
                      }
                    />
                  </div>
                )}
              </div>
              <div className="signup__controls">
                <Button
                  type="button"
                  color="grey"
                  onClick={onPrevious}
                >
                  Previous
                </Button>
                <Button
                  type="submit"
                  color="teal"
                >
                  Next
                </Button>
              </div>
            </div>
          </Form>
        )}
      />
    );
  }
}
