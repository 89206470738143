import React from 'react';
import { Button } from 'components/common';

export const SignUpRole = {
    GUEST: 'HOUSEMATE',
    HOST: 'HOUSEHOLD',
    INTERNATIONAL_USER: 'INTERNATIONAL_USER',
    NOT_READY_TO_COMMIT_USER: 'NOT_READY_TO_COMMIT_USER'
};

export const SignUpRoleSelect = ({role, onRoleSelect}) => (
    <React.Fragment>
        <Button
            color="blue"
            type="button"
            size="large"
            onClick={() => onRoleSelect(SignUpRole.HOST)}
        >
            Household
        </Button>
        <Button
            type="button"
            color="teal"
            size="large"
            onClick={() => onRoleSelect(SignUpRole.GUEST)}
        >
            Housemate
        </Button>
        <div className="international-wrap">
            <p>Are you from <span className="international-text">outside of Australia</span>? Please {' '}
                <a href="#"
                    onClick={() => onRoleSelect(SignUpRole.INTERNATIONAL_USER)}>
                    click here
                </a>
            </p>
            <p><span className="international-text">Not ready yet</span>?&nbsp;
                <a href="#"
                    onClick={() => onRoleSelect(SignUpRole.NOT_READY_TO_COMMIT_USER)}>
                    Join
                </a>&nbsp;our mailing list to stay updated.
            </p>
        </div>
    </React.Fragment>
);
