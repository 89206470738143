import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import URL from '../../constants/URL';
import { COUNTRIES } from '../../constants/COUNTRIES';
import { ProcessModal } from '../../actions/Modal';
import { HIDE_MODAL } from '../../actions/Modal';
import Form from 'react-validation/build/form';
import Select from 'react-validation/build/select';
import Input from 'react-validation/build/input';
// import Button from 'react-validation/build/button';
import { required, email } from '../../utils/FormValidators.jsx';
import { Button, RVButton } from '../../../components/common';
import { RoleLabel } from '../../../store/Users/constants';

class ProspectiveUserModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requesting: false,
      success: props.success || false,
      error: '',
      countries: COUNTRIES,
      fields: {
        firstName: '',
        email: '',
        country: '',
        preference: '',
        findUs: '',
        type: props.type
      }
    };
  }

  close = () => {
    const modalProps = { showModal: false };
    this.props.dispatch(ProcessModal(HIDE_MODAL, null, modalProps));
    this.props.history.push('/register/');
  };

  handleInputChange = e => {
    e.preventDefault();
    this.setState({
      ...this.state,
      fields: { ...this.state.fields, [e.target.name]: e.target.value }
    });
  };

  submitProspectiveUser = e => {
    e.preventDefault();
    this.setState({ ...this.state, requesting: true });
    const request = axios({
      method: 'post',
      url: URL.PROSPECTIVE_USER_API,
      data: {
        first_name: this.state.fields.firstName,
        email: this.state.fields.email,
        country: this.state.fields.country,
        guest_host_preference: this.state.fields.preference,
        find_about_us: this.state.fields.findUs,
        type: this.state.fields.type
      }
    });
    request.then(result => {
      if (result.data.status === 'success') {
        this.setState({ ...this.state, success: true, requesting: false });
        this.props.history.push({
          pathname: '/register/' + this.props.match.params.role + '/thankyou',
          state: { success: true }
        });
      } else {
        this.setState({
          ...this.state,
          error: result.data.msg,
          requesting: false
        });
      }
    });
  };

  getCountryListDisplayContent() {
    let countryListTemp = COUNTRIES.slice();
    if (this.props.type === 'INTERNATIONAL_USER') {
      let index = countryListTemp.indexOf('Australia');
      countryListTemp.splice(index, 1);
    }
    return countryListTemp.map((country, index) => (
      <option key={index} name={country}>
        {country}
      </option>
    ));
  }

  getInterestedInBeingContent() {
    return this.props.type === 'NOT_READY_TO_COMMIT_USER' ? (
      <Select
        name="preference"
        validations={[required]}
        className="form-control"
        onChange={this.handleInputChange}
      >
        <option value="">Select</option>
        <option value="HOST">Household</option>
        <option value="GUEST">Housemate</option>
        <option value="KEPT IN THE LOOP">Keep me in the loop</option>
      </Select>
    ) : (
      <Select
        name="preference"
        validations={[required]}
        className="form-control"
        onChange={this.handleInputChange}
      >
        <option value="">Select</option>
        <option value="HOST">Household</option>
        <option value="GUEST">Housemate</option>
      </Select>
    );
  }

  formDisplayContent() {
    return (
      <Form className="form-signup">
        <div>
          <div className="row">
            {this.state.fields.type === 'INTERNATIONAL_USER' ? (
              <div className="col-md-12 model-heading-subtext">
                Although we're not in your neck of the woods yet, we’ve got big
                plans! To make sure we don't miss you, pop in your details below
                and we'll contact you as soon as we launch in your country.
              </div>
            ) : (
              <div className="col-md-12 model-heading-subtext">
                If you're not ready to jump right in yet, that's okay. Pop in
                your details below and we'll keep you updated every now and then
                via our email newsletter.
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label
                  htmlFor="first-name "
                  className="control-label pull-left"
                >
                  First name
                </label>
                <Input
                  type="first-name"
                  className="form-control"
                  name="firstName"
                  placeholder="First Name"
                  validations={[required]}
                  value={this.state.firstName}
                  onBlur={this.handleInputChange}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="email" className="control-label pull-left">
                  Email address
                </label>
                <Input
                  type="email"
                  className="form-control"
                  name="email"
                  placeholder="Email"
                  validations={[required, email]}
                  value={this.state.email}
                  onBlur={this.handleInputChange}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="email" className="control-label pull-left">
                  I'm interested in being:
                </label>
                {this.getInterestedInBeingContent()}
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="email" className="control-label pull-left">
                  Country
                </label>
                <Select
                  validations={[required]}
                  name="country"
                  className="form-control"
                  onChange={this.handleInputChange}
                >
                  <option value="">Select</option>
                  {this.getCountryListDisplayContent()}
                </Select>
              </div>
            </div>
          </div>
          
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="email" className="control-label pull-left">
                  How did you find out about us?
                </label>
                <Input
                  type="find-us"
                  className="form-control"
                  name="findUs"
                  validations={[required]}
                  placeholder="eg: Google, blog article, referral"
                  value={this.state.findUs}
                  onBlur={this.handleInputChange}
                />
              </div>
            </div>
          </div>
          {this.state.error && (
            <div className="alert-danger" role="alert">
              <strong>Error: </strong>
              {this.state.error}
            </div>
          )}
        </div>

        <div className="modal-footer">
          <div>
            <RVButton
              type="submit"
              className="modal__btn__submit"
              onClick={this.submitProspectiveUser}
            >
              Submit
            </RVButton>
            <Button
              type="button"
              color="grey"
              className="modal__btn__cancel"
              onClick={this.close}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Form>
    );
  }

  successMessageDisplayContent() {
    const message =
      this.state.fields.type === 'INTERNATIONAL_USER' ? (
        <div className="modal-body">
          <div className="textAlignLeft">
            <p>
              Thanks for connecting with us. While we’re not in your area just
              yet, we hope you will stay connected with our journey via our
              social media platforms.
            </p>
            <p>
              In the meantime, we are always on the look out for ambassadors to
              help us reach out to {RoleLabel.HOSTS} and {RoleLabel.GUESTS} on a country and regional
              level.
            </p>
            <p>
              If you would like to apply to become an ambassador of The Room
              Xchange and learn about the benefits, please{' '}
              <a
                style={{ color: '#EF7F49', cursor: 'pointer' }}
                target="_blank"
                href="/contact"
              >
                contact us{' '}
              </a>
              .
            </p>
            <p>We’re looking forward to Xchanging near you soon.</p>
          </div>
        </div>
      ) : (
        <div className="modal-body">
          <div>
            <p>
              Thanks for connecting with us. We'll be sending you our news and
              update email every now and then. In the meantime, if you have any
              questions, please{' '}
              <a
                style={{ color: '#EF7F49', cursor: 'pointer' }}
                target="_blank"
                href="/contact"
              >
                contact
              </a>{' '}
              our Customer Care Team directly or you can book a call via our
              customer care online calendar{' '}
              <a href="https://calendly.com/customer_care_the_room_xchange">
                here
              </a>
              .
            </p>
          </div>
        </div>
      );

    return message;
  }

  render() {
    return (
      <div className="international-user-modal">
        <div className="overlay modal-backdrop modal-dialog-centered">
          <div className="col-md-6 offset-md-3">
            <div />
            <div className="modal-content padding-com">
              {this.state.requesting && <div className="page-loading" />}
              <div className="modal-close">
                <button
                  type="button"
                  className="close"
                  onClick={this.close}
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">
                    <i className="fa fa-close" />
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div className="modal-header">
                  {this.state.fields.type === 'INTERNATIONAL_USER' ? (
                    <div className="top-title" style={{ color: '#EF7F49' }}>
                      Outside Australia?
                    </div>
                  ) : (
                    <div className="top-title" style={{ color: '#EF7F49' }}>
                      Not ready yet?
                    </div>
                  )}
                </div>
                {this.state.success
                  ? this.successMessageDisplayContent()
                  : this.formDisplayContent()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  };
};

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(ProspectiveUserModal)
);
