import React, {FC, useContext, useEffect, useState} from 'react';
import InfoBubble from "../../../components/common/InfoBubble";
import { useFormik } from 'formik';
import '../styles/profile_edit.scss';
import {
	getAccordionDefaultConfig,
	hasError,
	manageAccordion,
	postActionSuccess,
	setAjaxLoader,
	showAjaxError, includeTestId,
} from "../utils/common";
import {languages, homeTypes, cityDistances, numberOfPeopleInHouse, exchangePeriods, rentOffset, yesOrNo} from "../utils/formData";
import AboutHostSchema from "../schemas/AboutHostSchema";
import {editProfileService} from "../utils/editProfileService";
import {UserProps} from "../types/editProfileTypes";
import FormError from "./helpers/FormError";
import CharCount from "./helpers/CharCount";
import RemoteUpdateError from "./helpers/RemoteUpdateError";
import FormSubmitButtons from "./helpers/FormSubmitButtons";
import EditProfileContext from "../context/EditProfileContext";



const AboutEditHost: FC<UserProps>=({user})=>{


	const {accordionConfig, update, data}=useContext(EditProfileContext);
	let about=editProfileService.HOST.getAboutSection({...data});
	const [submissionError, setSubmissionError] = useState('');
	const [buttonType, setButtonType]=useState('');

	useEffect(()=>{
		editProfileService.ABOUT.mapValues(about);
	},[about]);


	const {values, errors, touched, handleBlur, handleChange, handleSubmit, isValid,validateForm} = useFormik({
		initialValues: about as any,
		validationSchema: AboutHostSchema,
		onSubmit: (values, formikHelpers) => {
			const params={...values};
			setAjaxLoader(true,update);

			editProfileService.HOST.updateAboutSection(params,user).then(result=>{
				const {data}=result;
				if(data.status==='success')
					postActionSuccess(buttonType, update, 'lifestyleCollapsed', user.user_id, user.token);
				else if(data.msg!==undefined)
					setSubmissionError(data.msg);
				else
					setSubmissionError('Some error occurred. Please try again later');

				setAjaxLoader(false,update);
			}).catch(res=>{
				showAjaxError(res,setSubmissionError,update);
			});
		}
	});

	useEffect(() => {
		(() => validateForm())();
	}, []);

	const errorMessages = () => {
		if (submissionError) {
			return submissionError
		} else if (!isValid) {
			return "There are some issues you need to resolve before saving"
		} else {
			return '';
		}
	}

	return (
		<div className="card">
			<div className="card-header" role="tab" id="aboutHeading">
				<a className={accordionConfig.aboutCollapsed ? 'collapsed' : ''} href="#abt-basic" aria-expanded={!accordionConfig.aboutCollapsed} onClick={()=>{ manageAccordion({...getAccordionDefaultConfig(), aboutCollapsed: !accordionConfig.aboutCollapsed}, update) }}>
					<h2 className="mb-0">
						<div className="card-header__left">
							<i className={isValid ? 'fa fa-check icon-rounded' : 'fa fa-close icon-rounded'}/>
							About
							<InfoBubble tooltip="The first three questions in this section are your chance to tell potential housemates who you are. Make sure you answer them clearly, check for typos and misspellings before you publish." />
						</div>
					</h2>
				</a>
			</div>

			<div id="abt-basic" className={`collapse ${accordionConfig.aboutCollapsed?'':'show'} `} role="tabpanel" aria-labelledby="aboutHeading" data-parent="#profileAccordion">
				<form className="form-horizontal form-signup section-about" role="form" onSubmit={handleSubmit} noValidate={true}>
					<div className="card-body">
						<div className="row form-mod">
							<div className="col-md-12">
								<div className="form-group">
									<label htmlFor="self_description" className="control-label">Profile snapshots</label>
									<InfoBubble tooltip={`Write a sentence that best describes you. This is first thing that users will read about you on the search results page.`}/>
									<textarea id="self_description" name="self_description" className={`form-control ${hasError(errors, touched,'self_description')?'error-field':''}`} placeholder={`For example: We're a fun loving professional couple looking for a housemate to help with living costs and some help around the house.`} onChange={handleChange} onBlur={handleBlur} value={values.self_description} maxLength={80}/>
									<div className="row mt-1">
										<div className="col-sm-10 col-12"><FormError errors={errors} fieldName={'self_description'} touched={touched}/></div>
										<div className="col-sm-2"><CharCount dangerLimit={70} field={values.self_description} maxLimit={80} fieldName={'self_description'}/></div>
									</div>
								</div>
							</div>
						</div>
						<div className="row form-mod">
							<div className="col-md-12">
								<div className="form-group">
									<label htmlFor="aboutInfoHost" {...includeTestId('host-about-label')} className="control-label">About</label>
									<InfoBubble tooltip="This section is about highlighting the unique personality of your household. Include any quirks you have and things of interest that will attract a housemate who is interested in living your way." />
									<br />
									<textarea id="aboutInfoHost" maxLength={500} name="additional_info" onBlur={handleBlur} className={`form-control ${hasError(errors, touched,'additional_info')?'error-field':''}`} placeholder="For example: We've never house shared before so we're new to this. We're looking for someone who will fit in with our vegetarian lifestyle. You'd be an independent person who will respect our privacy as we will yours. We would like to find someone who is similar in personality and shares the same values so we feel like we're coming home to a friend."  onChange={handleChange} value={values.additional_info}/>
									<div className="row mt-1">
										<div className="col-sm-10 col-12"><FormError errors={errors} fieldName={'additional_info'} touched={touched}/></div>
										<div className="col-sm-2"><CharCount dangerLimit={450} field={values.additional_info} maxLimit={500} fieldName={'additional_info'}/></div>
									</div>
								</div>
							</div>
						</div>

						<div className="row form-mod">
							<div className="col-md-12">
								<div className="form-group">
									<label htmlFor="offerInfoHost" className="control-label" {...includeTestId('host-offer-label')}>Offer</label>
									<InfoBubble tooltip="Is it a rental arrangement or a rent offset? If it’s a rent offset, what would you like your housemate to do in return for the reduction in rent? Also mention what the housemate will have in their room such as furnishings, bathroom etc. You could also highlight some features of the location you live in such as shops, beach, or cafes." />
									<br />
									<textarea id="offerInfoHost" maxLength={500} name="offering_to_guest" className={`form-control ${hasError(errors, touched,'offering_to_guest')?'error-field':''}`} placeholder="For example: We would be happy to negotiate a rent offset to help out with some home organising and to sell some items we have around the house. We also like the idea of having a house sitter to look after our dog when we go on holidays and would be happy to do a rent offset at those times." onChange={handleChange} onBlur={handleBlur} value={values.offering_to_guest}/>
									<div className="row mt-1">
										<div className="col-sm-10 col-12"><FormError errors={errors} fieldName={'offering_to_guest'} touched={touched}/></div>
										<div className="col-sm-2"><CharCount dangerLimit={450} field={values.offering_to_guest} maxLimit={500} fieldName={'offering_to_guest'}/></div>
									</div>
								</div>
							</div>
						</div>

						<div className="row form-mod">
							<div className="col-lg-6 col-md-6 col-sm-6 col-12">
								<div className="form-group" {...includeTestId('first_language')}>
									<label htmlFor="first-language"  className="control-label">First language</label>
									<select  className={`form-control ${hasError(errors, touched,'first_language')?'error-field':''}`} name="first_language" onChange={handleChange} value={values.first_language} onBlur={handleBlur}>
										<option value="">Select</option>
										{languages.map((language, index) => <option key={index} value={language}>{language}</option>)}
									</select>
									<FormError errors={errors} fieldName={'first_language'} touched={touched} containerClass={'mt-1'}/>
								</div>
							</div>

							<div className="col-lg-6 col-md-6 col-sm-6 col-12">
								<div className="form-group" {...includeTestId('other-language-radio')}>
									<label htmlFor="other-language" className="control-label">
										Do you speak any other languages?
										<InfoBubble tooltip="Some people like to learn a language, so if you speak multiple languages, list them here as it may be of interest to a user. If you speak multiple languages, please separate them with a comma." />
									</label>

									{yesOrNo.map((item, key) => (
										<div className="form-check" key={`other-language-${item}`}>
											<input name="yesOtherLanguage" className="form-check-input" id={`yesOtherLanguage-${item}`} type="radio" checked={item===values.yesOtherLanguage} onChange={handleChange} value={item}/>
											<label className="form-check-label" htmlFor={`yesOtherLanguage-${item}`}>
												{item}
											</label>
										</div>
									))}

									{values.yesOtherLanguage==='Yes'&&<>
										<input  className={`form-control ${hasError(errors, touched,'yesOtherLanguage')?'error-field':''}`} {...includeTestId('other-language-name')} name="other_language" type="text" placeholder="Please enter other language" onChange={handleChange} onBlur={handleBlur} value={values.other_language}/>
										<FormError errors={errors} fieldName={'other_language'} touched={touched} containerClass={'mt-1'}/>
									</>}

								</div>
							</div>
						</div>
						<div className="row form-mod">
							<div className="col-lg-6 col-md-6 col-sm-6 col-12">
								<div className="form-group" {...includeTestId('gender_preference')}>
									<label htmlFor="gender" className="control-label">Gender preference</label>
									<select name="gender_preference" id="gender" onChange={handleChange} value={values.gender_preference} onBlur={handleBlur} className={`form-control ${hasError(errors, touched,'gender_preference')?'error-field':''}`}>
										<option value="">Select</option>
										<option value="Female">Female</option>
										<option value="Male">Male</option>
										<option value="I don't mind">I don't mind</option>
									</select>
									<FormError errors={errors} fieldName={'gender_preference'} touched={touched} containerClass={'mt-1'}/>
								</div>
							</div>
							<div className="col-lg-6 col-md-6 col-sm-6 col-12">
								<div className="form-group" {...includeTestId('lgbtqia-friendly')}>
									<label htmlFor="LGBTQI friendly" className="control-label">Are you a LGBTQIA+ friendly household?
										<InfoBubble tooltip="We want to help you find your people and feel at home. Therefore, if you are not comfortable living with people in the LGBTQIA+ community OR if it's important that you do live in a home that is supportive of LGBTQIA+ people, then please say so. Your preference will be visible in your profile. (LGBTQIA+ stands for people who identify as Lesbian, Gay, Bisexual, Transgender, Queer, Intersex and/or Asexual)." />
									</label>
									<select name="lgbtqi_friendly" id="LGBTQI friendly" onChange={handleChange} value={values.lgbtqi_friendly} onBlur={handleBlur} className={`form-control ${hasError(errors, touched,'lgbtqi_friendly')?'error-field':''}`}>
										<option value="">Select</option>
										{yesOrNo.map((type, index) => <option key={index} value={type}>{type}</option>)}
									</select>
									<FormError errors={errors} fieldName={'lgbtqi_friendly'} touched={touched} containerClass={'mt-1'}/>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-lg-6 col-md-6 col-sm-6 col-12">
								<div className="form-group" {...includeTestId('home-type')}>
									<label htmlFor="type-home" className="control-label">What type of home do you have?</label>
									<select id="type-home" name="home_type" onChange={handleChange} value={values.home_type} onBlur={handleBlur} className={`form-control ${hasError(errors, touched,'home_type')?'error-field':''}`}>
										<option value="">Select</option>
										{homeTypes.map((type, index) => <option key={index} value={type}>{type}</option>)}
									</select>
									<FormError errors={errors} fieldName={'home_type'} touched={touched} containerClass={'mt-1'}/>
								</div>
							</div>
							<div className="col-lg-6 col-md-6 col-sm-6 col-12">
								<div className="form-group" {...includeTestId('distance-from-city')}>
									<label htmlFor="distance_from_city" className="control-label">How many kilometres are you from the city?</label>
									<select id="distance_from_city" name="distance_from_city" onChange={handleChange} value={values.distance_from_city} onBlur={handleBlur} className={`form-control ${hasError(errors, touched,'distance_from_city')?'error-field':''}`}>
										<option value="">Select</option>
										{cityDistances.map((distance, index) => <option key={index} value={distance}>{distance}</option>)}
									</select>
									<FormError errors={errors} fieldName={'distance_from_city'} touched={touched} containerClass={'mt-1'}/>
								</div>
							</div>
						</div>

						<div className="row form-mod">
							<div className="col-lg-6 col-md-6 col-sm-6 col-12">
								<div className="form-group" {...includeTestId('people-count')}>
									<label htmlFor="peoplelive " className="control-label">How many people live in the house?</label>
									<select id="peoplelive" name="no_of_people_in_house" onChange={handleChange} value={values.no_of_people_in_house} className={`form-control ${hasError(errors, touched,'no_of_people_in_house')?'error-field':''}`} onBlur={handleBlur}>
										<option value="">Select</option>
										{numberOfPeopleInHouse.map((people, index) => <option key={index} value={people}>{people}</option>)}
									</select>
									<FormError errors={errors} fieldName={'no_of_people_in_house'} touched={touched} containerClass={'mt-1'}/>
								</div>
							</div>
							<div className="col-lg-6 col-md-6 col-sm-6 col-12">
								<div className="form-group" {...includeTestId('age-group')}>
									<label htmlFor="peopleage " className="control-label">
										What ages are the people in the house?
										<InfoBubble tooltip="This is important as some users will prefer to live with people their own age." />
									</label>
									<input id="peopleage" name="age_group" type="text" onChange={handleChange} value={values.age_group} onBlur={handleBlur} className={`form-control ${hasError(errors, touched,'age_group')?'error-field':''}`} placeholder="eg. 5, 10, 35 years"
									/>
									<FormError errors={errors} fieldName={'age_group'} touched={touched} containerClass={'mt-1'}/>
								</div>
							</div>
						</div>
						<div className="row form-mod">
							<div className="col-lg-6 col-md-6 col-sm-6 col-12">
								<div className="form-group trx-about-host-date-picker"  {...includeTestId('house-sharing-date')}>
									<label htmlFor="xchangebegin" className="control-label">
										When would you like to start house sharing?
										<InfoBubble tooltip="Make sure keep this updated if your circumstances change." />
									</label>

									<input type="date" name="exchange_date" min={new Date().toISOString().split('T')[0]}  onChange={handleChange} value={values.exchange_date} className={`form-control ${hasError(errors, touched,'exchange_date')?'error-field':''}`} placeholder="Select Date" onBlur={handleBlur}/>
									<FormError errors={errors} fieldName={'exchange_date'} touched={touched} containerClass={'mt-1'}/>
								</div>
							</div>
							<div className="col-lg-6 col-md-6 col-sm-6 col-12">
								<div className="form-group"  {...includeTestId('house-sharing-duration')}>
									<label htmlFor="exchange_period" className="control-label">
										How long would you like to house share?
										<InfoBubble tooltip="Make sure you keep this updated in your circumstances change." />
									</label>
									<select id="exchange_period" name="exchange_period" onChange={handleChange} value={values.exchange_period} className={`form-control ${hasError(errors, touched,'exchange_period')?'error-field':''}`} onBlur={handleBlur}>
										<option value="">Select</option>
										{exchangePeriods.map((period, index) => <option key={index} value={period}>{period}</option>)}
									</select>
									<FormError errors={errors} fieldName={'exchange_period'} touched={touched} containerClass={'mt-1'}/>
								</div>
							</div>
						</div>

						<div className="row form-mod">
							<div className="col-lg-6 col-md-6 col-sm-6 col-12">
								<div className="form-group" {...includeTestId('rent-offset')}>
									<label htmlFor="rent" className="control-label">Rent/Rent offset</label>
									<InfoBubble tooltip="Select which option you prefer." />
									{rentOffset.map((item, key) => (
										<div className="form-check" key={`rent-offset-${item}`}>
											<input name="rent_offset" className="form-check-input" id={`rent-offset-id-${item}`} type="radio" checked={item===values.rent_offset} onChange={handleChange} value={item}/>
											<label className="form-check-label" htmlFor={`rent-offset-id-${item}`}>
												{item}
											</label>
										</div>
									))}
								</div>
								{values.rent_offset === 'Rent offset' &&
									<div className="form-group" {...includeTestId('hours-of-help')}>
										<label htmlFor="Hours Of help " className="control-label">Hours of help</label>
										<InfoBubble
											tooltip="List how many hours of help you’d like to offset your rent."/>
										<input id="hours_of_help" name="hours_of_help" type="text" onChange={handleChange} value={values.hours_of_help} className={`form-control ${hasError(errors, touched, 'hours_of_help') ? 'error-field' : ''}`} onBlur={handleBlur} placeholder="eg. 4, 6, 8"/>
										<FormError errors={errors} fieldName={'hours_of_help'} touched={touched} containerClass={'mt-1'}/>
									</div>
								}

							</div>
							<div className="col-lg-6 col-md-6 col-sm-6 col-12">
								<div className="form-group" {...includeTestId('room-value')}>
									<label htmlFor="room_value" className="control-label">
										Room value (per week)
									</label>
									<InfoBubble tooltip="Add the amount but DO NOT add the $ sign" />
									<input id="room_value" name="room_value" type="text" onChange={handleChange} value={values.room_value} className={`form-control ${hasError(errors, touched,'room_value')?'error-field':''}`} onBlur={handleBlur} placeholder="eg. 250"/>
									<FormError errors={errors} fieldName={'room_value'} touched={touched} containerClass={'mt-1'}/>
								</div>
								<div className="form-group" {...includeTestId('utilities-included')}>
									<label htmlFor="room_value" className="control-label">
										Does this include utilities?
									</label>
									{yesOrNo.map((item, key) => (
										<div className="form-check" key={`room-value-${item}`}>
											<input name="room_value_all_inclusive" className="form-check-input" id={`room-value-inclusive-${item}`} type="radio" checked={values.room_value_all_inclusive===item} onChange={handleChange} value={item}
											/>
											<label className="form-check-label" htmlFor={`room-value-inclusive-${item}`}>{item}</label>
										</div>
									))}
								</div>
							</div>
						</div>
						<RemoteUpdateError submissionError={errorMessages()}/>
					</div>
					<FormSubmitButtons setButtonType={setButtonType} isValid={isValid}/>
				</form>
			</div>
		</div>
	);
}

export default AboutEditHost;
