import {ACCORDION_ACTION, SET_LOADER, UPDATE_GLOBAL_STATE} from "./actions";
import {AccordionConfigType, RemoteResponse} from "../types/editProfileTypes";

export type ReducerPayload = {
	accordionConfig: AccordionConfigType,
	data: RemoteResponse,
	loader?: boolean
};

export type ReducerActionType = {
    payload: ReducerPayload;
    type: string;
};
const EditProfileReducer = (state: any, action: any) => {
    let currentState = { ...state };
    switch (action.type) {
		case ACCORDION_ACTION:
			currentState.accordionConfig=action.payload.accordionConfig
			return currentState;
		case UPDATE_GLOBAL_STATE:
			currentState.accordionConfig=action.payload.accordionConfig
			return currentState;
		case SET_LOADER:
			currentState.loader=action.payload.loader
			return currentState;
        default:
            return state;
    }
};

export default EditProfileReducer;
