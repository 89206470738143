import * as Yup from 'yup';





const HostLifeStyleSchema=Yup.object().shape({

	food_preference: Yup.string().required('Please select your food preference').max(80),
	rent_offset: Yup.string(),
	other_food_preference: Yup
		.string()
		.when("food_preference", {
			is: 'other',
			then: Yup.string().required("Please specify the food preference")
		}),
	help_tasks: Yup.array().when('rent_offset',{
		is: 'Rent offset',
		then: Yup.array().min(1, 'Please select at least one help task')
	}),
	other_help_tasks: Yup.string().when('help_tasks',{
		is: (help_tasks)=>{
			return (help_tasks.includes('Other'))
		},
		then: Yup.string().required('Please enter the other household task')
	}).nullable(true),
	activities: Yup.array().min(1, "Please select at least one activity"),
	smoking_preference: Yup.string().required('Please select your smoking preference'),
	comfortable_with_pet: Yup.string().required('Please select information about pets'),
	pets: Yup.string().when('comfortable_with_pet',{
		is: '1',
		then: Yup.string().required('Please what type of pet you have')
	}),
	other_pets: Yup.string().when('pets',{
		is: 'other',
		then: Yup.string().required('Please enter the name of you pet you have')
	})


});

export default HostLifeStyleSchema;
