import React, { FC, memo } from 'react';
import { RoleCode } from 'store/Users/constants';

interface ProfileNameProps {
	role: string;
	name: string;
	userId: string | number;
	anchor: boolean;
}

const ProfileName: FC<ProfileNameProps> = ({ role, name, userId, anchor }) => {
	return anchor ? (
		<a
			href={`/backend/theroomexchange/api/${
				role === RoleCode.HOST ? 'hostuser' : 'users'
			}/detail/${userId}`}
		>
			{name}
		</a>
	) : (
		<span>{name}</span>
	);
};

export default memo(ProfileName);
