import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchExchangePreferences } from '../actions/HostPreferences';
import { getBadges } from '../actions/Badges';
import UserProfile from '../../components/UserProfile';

class ProfileView extends Component {
	constructor(props) {
		super(props);
		this.state = {
			photoIndex: 0,
			isOpen: false,
			myBadges: []
		};
	}

	componentDidMount() {
		document.title = 'Public Profile | The Room Xchange';
		if (this.props.preferences === null) {
			this.props.onFetchExchangePreferences(this.props.login);
		}
	}
	render() {
		const preferences = this.props.preferences
			? this.props.preferences.data
			: null;
		return (
			<div className="tab-content trx-abt-section container trx-com-wrap">
				<div role="tabpanel" className="tab-pane active">
					<UserProfile
						preferences={preferences}
						role={this.props.login.role_code}
						context="self"
						connectionStatus={'NOT CONNECTED'}
						isLoggedIn={this.props.login && true}
					/>
				</div>
				{/*tab-panel*/}
			</div>
		);
	}
}
const mapStateToProps = (store) => {
	return {
		login: store.login.user,
		preferences: store.preferences.preferenceData,
		myBadges: store.badges.myBadges
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		onFetchExchangePreferences: (user) => {
			dispatch(fetchExchangePreferences(user));
		},
		onGetBadges: (user) => dispatch(getBadges(user))
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(ProfileView);
