import React, { Component } from 'react';
import { connect } from 'react-redux';
import { login, logout } from '../actions/Login';
import { default as LoginForm } from '../components/layouts/Login';
import Header from '../components/layouts/Header';
import Footer from '../../components/Footer';

class Login extends Component {
  componentDidMount() {
    document.title = 'Login | The Room Xchange';

    window.scrollTo(0, 0);
  }

	hideBackdropModal() {
		const modalBackdrop = window.document.getElementsByClassName(
			'modal-backdrop'
		);

		if (modalBackdrop.length > 0) {
			modalBackdrop[0].style.display = 'none';
		}
	}


  componentWillMount() {
    if (this.props.login.user != null) {
      this.props.history.push('/');
    }
	this.hideBackdropModal()
  }

  componentWillUpdate(props) {
    if (props.signup.info !== null) {
      this.props.history.push('/register');
    }

    if (props.login.user !== null) {
      this.props.history.push('/');
    }
  }

  render() {
    return (
      <React.Fragment>
        <Header
          login={this.props.login}
          onLogout={this.props.onLogout}
          onLogin={this.props.onLogin}
          origin="app"
        />
        <div className="login-page com-mod header-mod">
          <div className="col-md-4 offset-md-4">
            <div className="login-card">
              <LoginForm
                onLogin={this.props.onLogin}
                login={this.props.login}
              />
            </div>
          </div>
        </div>
        <Footer user={this.props.login.user} />
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  onLogin: (postData, info) => dispatch(login(postData, info)),
  onLogout: () => dispatch(logout())
});

const mapStateToProps = ({ login, signup }) => ({
  login,
  signup
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
