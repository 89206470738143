import React from 'react';
import Header from '../Header';
import Footer from '../../../../components/Footer';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { login, logout } from '../../../actions/Login';
import { usePublicLayoutStyles } from './styles';

export const PublicLayout = ({ children }) => {
  const loginStore = useSelector(({ login }) => login, shallowEqual);
  const dispatch = useDispatch();

  const onLogin = (postData, info) => dispatch(login(postData, info));
  const onLogout = () => dispatch(logout());

  const { pageContainer } = usePublicLayoutStyles();

  return (
    <React.Fragment>
      <Header
        login={loginStore}
        onLogout={onLogout}
        onLogin={onLogin}
        origin="app"
      />
      <div className={pageContainer}>{children}</div>
      <Footer />
    </React.Fragment>
  );
};
