import axios from 'axios';
import URL from '../constants/URL';

export const REQUEST_FETCH_CUSTOMER_CARD_DETAIL="REQUEST_FETCH_CUSTOMER_CARD_DETAIL";
export const FETCH_CUSTOMER_CARD_DETAIL_FAILURE="FETCH_CUSTOMER_CARD_DETAIL_FAILURE";
export const FETCH_CUSTOMER_CARD_DETAIL ="FETCH_CUSTOMER_CARD_DETAIL";
export const FETCH_INVOICES = "FETCH_INVOICES";
export const FETCH_STRIPE_INVOICES = "FETCH_STRIPE_INVOICES_APINVOICES";


export const fetchInvoices = (token) => {
   return dispatch => {
     const request = axios({
       method:'get',
       url:URL.FETCH_INVOICES_API(token),
     });
     request.then((result) => {
       if(result.data.status ==="success"){
         dispatch({
           type:FETCH_INVOICES,
           payload:result.data.data
         })
       }
     });
   }
}

export const fetchStripeInvoices = (token) => {
   return dispatch => {
     const request = axios({
       method:'get',
       url:URL.FETCH_STRIPE_INVOICES_API(token),
     });
     request.then((result) => {
       if(result.data.status ==="success"){
         dispatch({
           type:FETCH_STRIPE_INVOICES,
           payload:result.data.invoice.data
         })
       }
     });
   }
}

export const fetchCustomerCardDetailRequest = () => {
  return{
    type:REQUEST_FETCH_CUSTOMER_CARD_DETAIL,
  }
}

export const fetchCustomerCardDetailFailure = (err) => {
  return{
    type:FETCH_CUSTOMER_CARD_DETAIL_FAILURE,
    payload:err
  }
}

export const fetchCustomerCardDetail = (token) => {
   return dispatch => {
      dispatch(fetchCustomerCardDetailRequest())
      const request = axios({
        method:'get',
        url:URL.FETCH_CUSTOMER_CARD_DETAIL_API(token),
      });
      request.then( (result)=> {
        if(result.data.status === "success"){
           dispatch({
             type:FETCH_CUSTOMER_CARD_DETAIL,
             payload:result.data.data
           })
        }else{
          dispatch(fetchCustomerCardDetailFailure(result.data.msg))
        }
      }).catch((err)=>{
        dispatch(fetchCustomerCardDetailFailure(err))
      })
   }
}
