import React, { useContext} from "react";
import EditProfileContext from "../../context/EditProfileContext";



const AjaxLoader=()=>{

	const {loader}=useContext(EditProfileContext);
	return (
		<>
			{loader && <div className="page-loading" />}
		</>
	)
}

export default AjaxLoader;
