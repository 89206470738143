import React, {Component} from 'react';

class NotificationBubble extends Component {
  constructor(props){
    super(props);
    this.state = {
      unseenConnections: this.getUnseenNotifications( props.userId, props.connection.connections )
    }
  }

  componentWillReceiveProps(nextProps){
      this.setState({
        ...this.state,
        unseenConnections: this.getUnseenNotifications( nextProps.userId, nextProps.connection.connections )
      })
  }

  getUnseenNotifications(user_id,connections){
    return connections.filter((connection,index) => {
      if(user_id==connection.from_user_id && connection.from_user_seen_status === 'Unseen'){
        return true;
      } else if(user_id==connection.to_user_id && connection.to_user_seen_status === 'Unseen'){
        return true;
      }
      return false;
    },user_id);
  }

  render(){
   return (
     this.state.unseenConnections.length ? (
       <span className="notifCount">
         {this.state.unseenConnections.length}
       </span>
     ):(
       <span className="notifCount empty"></span>
     )
   )
  }
}

export default NotificationBubble;
