import React, { useState } from 'react';
import { SearchContainer } from '../../components/search-container';
import { RoleCode } from 'store/Users/constants';
import { PublicLayout } from '../../components/layouts/public-layout';
import { searchPublicUsers } from '../../actions/Search';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { usePublicSearchPageStyles } from './styles';
import { PublicSearchModal } from '../../components/modals/public-search-modal';
import { useHistory } from 'react-router-dom';

const getPosition = () =>
  new Promise((res, rej) => {
    navigator.geolocation.getCurrentPosition(res, rej);
  });

export const PublicSearchPage = () => {
  const [isLoading, handleLoading] = useState(false);
  const [broswerGeolocation, setBrowserGeolocation] = useState(undefined);
  const { actionSuccess } = usePublicSearchPageStyles();
  const { login: loginData } = useSelector(
    ({ login }) => ({
      login,
    }),
    shallowEqual
  );
  const history = useHistory();

  React.useEffect(() => {
    if (!loginData || !loginData.user) {
      return;
    }
    history.push(
      `/search?mode=${loginData.user.role_code === RoleCode.HOST ? 'guests': 'hosts'}`
    );
  }, [loginData]);

  const handleRequireUserGeolocation = async () => {
    try {
      const {
        coords: { latitude, longitude },
      } = await getPosition();

      setBrowserGeolocation({
        latitude,
        longitude,
      });
    } catch (exception) {
      console.log(exception);
    }
  };

  const dispatch = useDispatch();

  React.useEffect(() => {
    handleRequireUserGeolocation();
  }, []);

  const handleSearch = searchParams => {
    handleLoading(true);
    dispatch(searchPublicUsers(searchParams)).then(() => {
      handleLoading(false);
    }).catch(() => {
      handleLoading(false);
    });
  };

	const handleViewProfile = React.useCallback(({ user_type, id }) => {
		history.push(`/browse/${user_type.toLowerCase()}s/${id}`);
	}, []);

	const userCardAction = React.useMemo(
    () => ({
      label: 'View profile',
      onClick: handleViewProfile,
      className: actionSuccess,
    }),
    []
  );

  return (
    <PublicLayout>
      <div className="mt-5">
        <SearchContainer
          isLoading={isLoading}
          browserGeolocation={broswerGeolocation}
          onSearch={handleSearch}
          shouldShowRoleFilter
          cardAction={userCardAction}
        />
        {(!loginData || !loginData.user) && <PublicSearchModal />}
      </div>
    </PublicLayout>
  );
};
