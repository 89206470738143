import React from 'react';
import Logo from 'assets/images/trx-logo.png';

export const Copyright = () => (
	<div className="copyright">
		<div className="container">
			<div className="row">
				<div className="col-lg-6 col-md-12 col-sm-12">
					<a href="/">
						<img className="img-fluid" alt="" src={Logo} />
					</a>
				</div>
				<div className="col-lg-6 col-md-12 col-sm-12 text-right disclaimer">
					<ul className="nav">
						<li className="nav-item">
							<a className="nav-link" href="/privacy">
								Privacy Policy
							</a>
						</li>
						<li className="nav-item">
							<a className="nav-link" href="/marketplace">
								Marketplace T&amp;Cs
							</a>
						</li>
						<li className="nav-item">
							<a className="nav-link" href="/terms">
								Terms of Use
							</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
);
