import * as Yup from 'yup';


const PracticalInclusionsSchema=Yup.object().shape({

	guest_room_features: Yup.array(),
	bathroom: Yup.string().required('Please select the type of bathroom').max(500),
	home_feature: Yup.array().min(1, "Please select at least one home feature"),
	other_home_feature: Yup
		.string().nullable(true)

		.when("home_feature", {
			is: (val) => val?.includes("Other"),
			then: Yup.string().required("Please enter the other home feature")
		}),
	parking_available: Yup.string(),
	nearby_public_transport: Yup.string()


});




export default PracticalInclusionsSchema;
