import includes from 'lodash/includes';
import { RoleCode } from '../../store/Users/constants';

export const Permissions = {
  DASHBOARD: [],
  ACCOUNT_SETTING: ['ACTIVE'],
  BADGES: ['ACTIVE'],
  LOCATION_MAP: ['ACTIVE'],
  PROFILE_VIEW: ['ACTIVE'],
  PROFILE_EDIT: ['ACTIVE'],
  SEARCH: ['ACTIVE'],
  BROWSE_HOST: ['ACTIVE', RoleCode.GUEST],
  BROWSE_GUEST: ['ACTIVE', RoleCode.HOST],
  BROWSE_USER_DETAIL: ['ACTIVE', 'VERIFIED', 'COMPLETED'],
  MESSAGES: ['ACTIVE', 'VERIFIED', 'COMPLETED'],
  XCHANGES: ['ACTIVE', 'VERIFIED', 'COMPLETED']
};

export const checkPermission = (componentType, userPermissions) => {
  const requiredPermissions = Permissions[componentType];
  return requiredPermissions.every(element =>
    includes(userPermissions, element)
  );
};
