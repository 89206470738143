import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import toLower from 'lodash/toLower';
import { withRouter } from 'react-router-dom';
import URL from '../../constants/URL';
import { ProcessModal } from '../../actions/Modal';
import { SHOW_MODAL, HIDE_MODAL } from '../../actions/Modal';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import Select from 'react-validation/build/select';
import Textarea from 'react-validation/build/textarea';
import { required } from '../../utils/FormValidators.jsx';
import Button from 'react-validation/build/button';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { RoleLabel } from '../../../store/Users/constants';

class ConnectModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requesting: false,
      isSuccess: false,
      errorMessage: '',
      showDateError: true,
      fields: {
        startXchange: '',
        endXchange: '',
        message: '',
        userId: this.props.userId,
        token: this.props.login.token
      }
    };
  }

  close = () => {
    const modalProps = { showModal: false };
    this.props.dispatch(ProcessModal(HIDE_MODAL, null, modalProps));
  };

  inputChangeHandler = e => {
    e.preventDefault();
    this.setState({
      ...this.state,
      fields: { ...this.state.fields, [e.target.name]: e.target.value }
    });
  };
  makeConnectionHandler = e => {
    this.setState({ ...this.state, requesting: true });
    const request = axios({
      method: 'post',
      url: URL.CONNECTION_API,
      data: {
        token: this.state.fields.token,
        exchange_start_date: moment().format('YYYY-MM-DD'),
        exchange_period: '1 week',
        message: this.state.fields.message,
        user_id: this.state.fields.userId
      }
    });

    request.then(result => {
      if (result.data.status === 'success') {
        this.setState({ ...this.state, isSuccess: true, requesting: false });
        this.props.onRequestCompleted();
      } else {
        this.setState({
          ...this.state,
          errorMessage: result.data.msg,
          requesting: false,
          isSuccess: false
        });
      }
    });
  };

  render() {
    const { name, role } = this.props;

    const remainingChar = (wordLimit, type) => {
      if (type === 'connectMessage') {
        return wordLimit - this.state.fields.message.length;
      }
    };

    return (
      <div>
        <div className="overlay modal-backdrop connect-modal modal-dialog-centered">
          <div className="col-md-5 mx-auto">
            {this.state.requesting && <div className="page-loading" />}
            <div className="modal-content padding-com">
              <div className="modal-header border-bottom-0">
                <button
                  type="button"
                  className="close"
                  onClick={this.close}
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">
                    <i className="fa fa-close" />
                  </span>
                </button>
              </div>
              <Form className=" form-horizontal form-signup" autoComplete="off">
                <div className="modal-body">
                  {!this.state.isSuccess ? (
                    <div>
                      <h3 className="text-center">Connect with {name}</h3>
                      <p className="text-center">
                        Once you send a message the potential {role === 'guest' ? toLower(RoleLabel.GUEST) : toLower(RoleLabel.HOST)} will accept or decline your request.
                         Tell them a little about what you're looking for and why you'd like to connect with them.
                      </p>
                      <div className="form-wrap">
                        <div className="row form-mod">
                          <div className="col-md-12">
                            <div className="form-group">
                              <div className="form-title">Message</div>
                              <Textarea
                                validations={[required]}
                                maxLength="250"
                                name="message"
                                onChange={this.inputChangeHandler}
                                className="form-control"
                                placeholder="250 characters limit"
                              />
                              <span>
                                {remainingChar('250', 'connectMessage') < 10 ? (
                                  <div style={{ color: 'red' }}>
                                    {remainingChar('250', 'connectMessage')}/250
                                  </div>
                                ) : (
                                  <div>
                                    {remainingChar('250', 'connectMessage')}/250
                                  </div>
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <h3 className="text-center">
                        You have just requested to connect with{' '}
                        {this.props.name}
                      </h3>
                      <p className="text-center">
                        {this.props.name} will be notified of your connection
                        request. If they think you’re a good match, they’ll
                        reply to your request which will enable you to have a
                        conversation via our private messaging system.
                      </p>
                    </div>
                  )}
                  <div>
                    {this.state.errorMessage && (
                      <div className="alert-danger" role="alert">
                        <strong>Error : </strong>
                        {this.state.errorMessage}
                      </div>
                    )}
                  </div>
                </div>
                <div className="modal-footer">
                  {this.state.isSuccess ? (
                    <button
                      onClick={this.close}
                      type="button"
                      className="btn btn-green btn-x-small text-white"
                    >
                      Close
                    </button>
                  ) : (
                    <Button
                      onClick={this.makeConnectionHandler}
                      type="button"
                      className="btn btn-green text-white model-connect-connect-btn"
                    >
                      Connect
                    </Button>
                  )}
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  dispatch
});

const mapStateToProps = store => ({
  login: store.login.user
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ConnectModal));
