import * as React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import AppRoutes from './routes';
import { Provider } from 'react-redux';
import { isAuthenticated } from './utils/LocalStorage';
import { loginRestore } from './actions/Login';
import Store from './Store';

export class App extends React.Component {

  async componentDidMount() {
    const localStorageAuthentication = isAuthenticated();

    if (localStorageAuthentication) {
      Store.dispatch(loginRestore(localStorageAuthentication));
    }
  }

  render() {
    return (
      <Provider store={Store}>
        <GoogleReCaptchaProvider
          reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY}
        >
		   <BrowserRouter basename={'/app'}>
			  <AppRoutes />
			</BrowserRouter>
        </GoogleReCaptchaProvider>
      </Provider>
    );
  }
}
