export default {
  THE_ROOM_XCHANGE_MAIL: 'admin@theroomxchange.com',

  LOGIN: {
    ACCOUNT_NOT_VERIFIED:
      'To be able to login, Please verify your phone number first.',
    IS_DISABLED: 'This account has been suspended. Please contact us at',
    IS_INVALID_EMAIL_OR_PASSWORD: 'Invalid username or password.'
  },

  LOST_PASSWORD: {
    EMAIL_SUBMIT_ERROR_RESPONSE: 'Email address doesnot exists.',
    EMAIL_SUBMIT_SUCCESS_RESPONSE:
      'We have sent the details and reset password link in the email address you provided.'
  },

  PAYMENT: {
    SUCCESS_RESPONSE: 'Thank you for your payment.',
    COST_PER_MONTH: '$60 per month.'
  },

  VALIDATION_INPUT: {
    IS_REQUIRED_FIELD: 'This field is required.'
  },

  RECAPTCHA_FIELD_ERROR:
    'Oops. Looks like there is some information missing. Please check the form for any errors and re-submit.',

  VARIFY_EMAIL: {
    TOKEN_ERROR:
      'It looks like your verification link has expired or has been already used. To request a new one, please enter your email address in above field.'
  },

  VALIDATION_DOB: {
    IS_EMPTY: 'This is a required field.',
    PATTERN_NOT_MATCH: 'Please use DD/MM/YYYY format.',
    MIN_DATE: 'Sorry, you need to be at least 18 to join.'
  },

  VALIDATION_XCHANGE_DATE: {
    IS_EMPTY: 'This is a required field.',
    PATTERN_NOT_MATCH: 'Please use DD/MM/YYYY format.',
    MIN_DATE: 'Xchange start date cannot be in past.'
  },

  VALIDATION_RADIO: {
    IS_REQUIRED: 'This field is required.'
  },

  VALIDATION_CHECKBOX: {
    AGE: 'You must be over 18 years old.',
    POLICY: 'You must accept this policy.',
    AGREEMENT: 'Please confirm your details are correct.',
    IS_REQUIRED: 'This field is required.',
    IS_REQUIRED_LENGTH: maxValue =>
      `This field requires exactly ${maxValue} values.`
  },

  VALIDATION_EMAIL: email => `${email} is not a valid email.`,
  VALIDATION_MOBILE_PHONE: 'Invalid AUS phone number.',
  VALIDATION_POSTCODE:
    'Please type in the POSTCODE and CITY you are interested in, then click SEARCH.',
  VALIDATION_POSTCODE_FOR_REGISTRATION_PAGE:
    'Enter your postcode, then select the city from the dropdown list',
  VALIDATION_MIN_LENGTH: (name, minLength) =>
    `${name} must be at least ${minLength} characters long.`,
  VALIDATION_MAX_LENGTH: maxLength =>
    `The value exceeded ${maxLength} characters.`,
  VALIDATION_PASSWORD:
    'Minimum eight characters, at least one letter, one number and one special character.',
  VALIDATION_CONFIRM_PASSWORD: 'Passwords do not match.',
  VALIDATION_MIN_XCHANGE_START_DATE: 'Xchange start date cannot be in past.',
  VALIDATION_END_XCHANGE_DATE: 'The Xchange must be at least 1 month.',
  MESSAGE_LIST_ABUSE_MESSAGE:
    'At The Room Xchange we promote diversity & kindness. Inappropriate behaviour has no place here. If you feel uncomfortable or intimidated by any of the conversations you\'re having, <a href="/contact" target="_blank" style="color: rgb(239, 127, 73);">contact us</a> and we will investigate the matter further.',
  PUBLIC_PROFILE_EDIT_TITLE:
    "The questions are mostly multiple choice and you can save and continue as you go along. Once you're finished and you have your Digital iD verification, you can start reaching out to "
};
