import { receiveSearchResultsAction } from '../actions/Search';
import { handleActions } from 'redux-actions';

const defaultState = {
  browseData: [],
  page: 0,
  location: '',
  loadMore: false
};

export const searchStore = handleActions(
  {
    [receiveSearchResultsAction]: (state, { payload: { users, total, meta } }) => ({
      users,
      total,
      meta
    })
  },
  defaultState
);
