import { relative } from 'path';
import { createUseStyles } from 'react-jss';

export const userCardStyles = createUseStyles({
  card: {
    flex: 1,
    fontFamily: 'Montserrat, sans-serif',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
    overflow: 'hidden',
    borderRadius: '1em',
  },
  cardImageContainer: {
    width: '100%',
    height: 220
  },
  cardImage: {
    display: 'block',
    objectFit: 'cover',
    height: '100%',
    width: '100%',
    borderRadius: '1em 1em 0 0',
    border: '1px solid rgba(0, 0, 0, 0.05)',
    borderBottom: 0
  },
  cardContent: {
    padding: 20,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderRadius: '0 0 1em 1em',
    border: '2px solid rgba(0, 0, 0, 0.05)',
    borderTop: 0
  },
  cardContentHeading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  cardTitle: {
    '&': {
      color: '#1a1a1a',
      fontSize: 16,
      fontWeight: 600,
      marginBottom: 0,
      textTransform: 'uppercase',
      display: 'inline-block',
      width: 'auto',
      fontFamily: 'Montserrat, sans-serif !important',
    },
    
  },
  cardBudget: {
    fontSize: 17,
    fontWeight: 500,
    '& i.fa-envelope-open': {
      marginRight: 10,
      color: '#ef7f49'
    },
    '& i.fa-usd': {
      color: '#Fff',
      marginRight: 10,
      padding: '5px 8px',
      background: '#ef7f49',
      borderRadius: 11,
      fontSize: 12,
    },
  },
  cardSubtitle: {
    '&': {
      color: 'rgba(26, 26, 26, 0.3)',
      fontSize: 12,
      fontWeight: 500,
      marginBottom: 8,
      fontFamily: 'Montserrat, sans-serif',
    },
    '& i.fa': {
      marginRight: 5
    }
  },
  cardDescription: {
    '&': {
      color: '#1a1a1a',
      fontSize: 14,
      fontWeight: 300,
      marginBottom: 8,
      lineHeight: 'normal',
    }
  },
  cardActionWrapper: {
    paddingTop: 16,
    textTransform: 'none',
    position: 'relative',
    '& a': {
      fontSize: 12,
      textTransform: 'none',
    },
    '& a:hover': {
      color: '#FFFFFF'
    },
    '& .user-badges': {
      display: 'inline-block',
      position: 'absolute',
      top: 24,
      right: 0
    }
  },
  rentOffset: {
    color: '#FFF',
    bottom: 2,
    display: 'inline-block',
    padding: '0 7px',
    position: 'relative',
    fontSize: 10,
    borderRadius: 24,
    backgroundColor: '#4d9cd3',
    lineHeight: '15px',
    fontWeight: 500
  },
  rentBudget: {
    marginLeft: 8
  }
});
