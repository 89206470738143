import React, { useEffect, useState, FC } from 'react';
import axios from 'axios';
import get from 'lodash/get';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { Location, LocationType } from '../../store/Locations/constants';
import { states } from './configs'

const { REACT_APP_API_URL } = process.env;

interface Props {
	address?: any,
	locationChangeCallback: (location: Location|null) => void,
	enterKeyCallback: () => void,
	placeholder?: string,
	suburbsOnly?: boolean
}

const getLocation = (address: any) => {
	if(!address) return null;
	const {state, suburb, post_code} = address;
	return {
			id: 0,
			display: "",
			source: {
				subtype: post_code ? LocationType.SUBURB : LocationType.STATE,
				name: suburb,
				state,
				postcode: `${post_code}`
			}
		}
	}


const getAddress = (address: any) => {
	if(!address) return '';
	const {state, suburb, post_code} = address;
	return post_code ? `${suburb}, ${state} ${post_code}` : get(states, `${state}`, '');
}


export const LocationAutoComplete: FC<Props> = props => {
	const [suggestions, setSuggestions] = useState<Location[]>([]);
	const [userInput, setUserInput] = useState<string>('');
	const [selectedLocation, setSelectedLocation] = useState<Location|null>(null);
	const [isError, setError] = useState<boolean>(false);
	
	useEffect(() => {
		props.locationChangeCallback(selectedLocation);
	},[selectedLocation, props.locationChangeCallback]);

	useEffect(() => {
		const address = getAddress(props.address);
		const location = getLocation(props.address);
		if(address) setUserInput(address);
		if(location) {
			props.locationChangeCallback(location);
		}
	}, [setUserInput, props.locationChangeCallback, props.address])
	
	const fetchSuggestions = async (input: any) => {
		if (input.value.length < 2) return;
		
		let url = `${REACT_APP_API_URL}/v1/public/locations/suggest?query=${input.value}${props.suburbsOnly ? '' : '&states=1'}`;
		await axios.get(url)
			.then(response => {
				setSuggestions(response.data.data);
			}, error => setSuggestions([]));
	}

	const renderSuggestion = (suggestion: Location, { query }: { query: string }) => {
		const suggestionLabel = suggestion.display;
		const matches = match(suggestionLabel, query);
		const parts = parse(suggestionLabel, matches);

		return (
			<div className="autosuggest__item">
				{parts.map(part => (
					<span key={part.text} className={part.highlight ? 'highlight' : ''}>
						{part.text}
         			</span>
				))}
			</div>
		);
	}

	const onChange = (event: React.ChangeEvent, { newValue }: { newValue: string }) => {
		setUserInput(newValue);
	}

	const onKeyPress = (event: React.KeyboardEvent) => {
		/* If a location is selected and the user presses enter
		 * fire a callback to the parent to notify they can trigger
		 * the search functionality */
		if (event.key === 'Enter' && selectedLocation) {
			props.enterKeyCallback();
		}
	}

	const getSuggestionValue = (location: Location) => location.display;
	const clearSuggestions = () => setSuggestions([]);

	const suggestionSelected = (event: React.FormEvent<HTMLInputElement>, payload: any) => {
		setError(false);
		setSelectedLocation(payload.suggestion);
	}

	const onBlur = () => {
		if (selectedLocation === null && userInput !== '') {
			setError(true);
		} else if (selectedLocation && selectedLocation.display !== userInput) {
			/* If the input text field has been modified and no longer matches the
			 * display value of the selected location error out and remove the
			 * current location
			 */
			setError(true);
			setSelectedLocation(null);
		} else {
			setError(false);
		}
	}

	const autosuggestInputProps = {
		placeholder: props.placeholder ? props.placeholder : 'Search by state, suburb or postcode',
		value: userInput,
		className: 'location-autosuggest' + (isError ? ' error' : ''),
		onChange: onChange,
		onKeyPress: onKeyPress,
		onBlur: onBlur
	}

	return (
		<Autosuggest
			suggestions={suggestions}
			onSuggestionsFetchRequested={fetchSuggestions}
			onSuggestionsClearRequested={clearSuggestions}
			onSuggestionSelected={suggestionSelected}
			getSuggestionValue={getSuggestionValue}
			renderSuggestion={renderSuggestion}
			inputProps={autosuggestInputProps}
		/>
	);
}
export default LocationAutoComplete;

