import React, { FC, memo, useEffect, useState, createRef } from 'react';
import map from 'lodash/map';
import get from 'lodash/get';
import last from 'lodash/last';
import size from 'lodash/size';
import MessageBox from './MessageBox';
import { getRecipientInfoFromDetails } from '../api';
import { UserMessagesProps } from '../types';

const UserMessages: FC<UserMessagesProps> = ({
	messages,
	msgDetails,
	userId
}) => {
	const [messagesLoaded, handleMessagesLoaded] = useState(false);
	const [isBottomScrolled, handleBottomScrolled] = useState(false);
	const messagesRef = createRef<HTMLDivElement>();


	useEffect(() => {
		if(!messagesLoaded || isBottomScrolled){
			const ref = messagesRef.current;
			if(ref) ref.scrollTop = ref.scrollHeight - ref.clientHeight;
			if(!messagesLoaded && size(messages) > 0) handleMessagesLoaded(true);
		}
	}, [get(last(messages), 'created_at')]);

	const handleScroll = () => {
		const ref = messagesRef.current;
		if(ref){
			const isBottomScrolled = ref.scrollTop === ref.scrollHeight - ref.clientHeight
			handleBottomScrolled(isBottomScrolled);
		}
	}

	const { photo } = msgDetails ? getRecipientInfoFromDetails(userId, msgDetails) : {photo : ''};
	return (
		<div className="user-messages" ref={messagesRef} onScroll={handleScroll}>
			{map(messages, ({ text, created_at, created_by }, i: number) => {
				return (
					<MessageBox
						key={created_at}
						nextMessageUser={get(
							messages,
							`[${i + 1}].created_by`,
							0
						)}
						prevMessageUser={get(
							messages,
							`[${i - 1}].created_by`,
							0
						)}
						userPhoto={photo}
						created_at={created_at}
						created_by={created_by}
						userId={userId}
						text={text}
					/>
				);
			})}
		</div>
	);
};


export default memo(UserMessages);
