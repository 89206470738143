import React, { Component } from 'react';
import { connect } from 'react-redux';
import pick from "lodash/pick";
import Pusher from 'pusher-js';
import Header from '../../js/components/layouts/Header';
import Footer from '../../components/Footer';
import { fetchConnectionList } from '../../js/actions/Connection';
import { login, logout } from '../../js/actions/Login';
import { pusherMessageReceived } from '../../js/actions/Connection';
import { fetchInvoices } from '../../js/actions/Payment';
import URL from '../../js/constants/URL';

const Layout = (Content: any) => {
    const pusherEventName = 'TheRoomExchange\\Api\\Controllers\\MessageSent';
    class AuthenticatedRoutes extends Component <any, any> {
    constructor(props:any) {
        super(props);
        let socket = new Pusher('f497fc7aa4b3decc00ef', {
            cluster: 'ap1',
            authEndpoint: URL.PUSHER_AUTH_API,
            encrypted: true,
            auth: {
                params: { token: props.login.user.token }
            }
        });
        this.state = {
            socket: socket,
            channel: socket.subscribe(
                'private-notification_' + props.login.user.user_id
            )
        };
    }

    componentDidMount() {
        var self = this;
        this.state.channel.bind(pusherEventName, function(data: any) {
        if (data.type === 'MESSAGE') {
            self.props.onFetchConnectionList({
                token: self.props.login.user.token,
                page: 1,
                limit: 20,
                search: '',
                type: ''
            });
            self.props.onPusherReceived(
                data.message,
                self.props.login.user.user_id
            );
        } else {
            if (data.type === 'EXCHANGE_STATUS_UPDATE') {
                self.props.onFetchInvoices(self.props.login.user.token);
            }
            self.props.onFetchConnectionList({
                token: self.props.login.user.token,
                page: 1,
                limit: 20,
                search: '',
                type: ''
            });
        }
        });
    }

    componentWillUnmount() {
        this.state.channel.unbind(pusherEventName);
        this.state.socket.unsubscribe(
        'private-notification_' + this.props.login.user.user_id
        );
    }

    render() {
        const headerProps = ['login', 'onLogout', 'onLogin', 'profile', 'permission', 'myBadges', 'connection'];
        return (
            <div className="authenticated">
                <Header
                {...pick(this.props, headerProps)}
                />
                <Content {...this.props} />
                <Footer user={this.props.login.user} />
            </div>
        );
    }
    }

    const mapDispatchToProps = (dispatch:any) => {
        return {
            onLogout: () => dispatch(logout()),
            onLogin: (postData:any, info:any) => dispatch(login(postData, info)),
            onFetchConnectionList: (params:any) => dispatch(fetchConnectionList(params)),
            onPusherReceived: (message:any, user_id:any) =>
            dispatch(pusherMessageReceived(message, user_id)),
            onFetchInvoices: (token:any) => dispatch(fetchInvoices(token)),
        };
    };

    const mapStateToProps = (store:any) => {
        return {
            ...pick(store, ['login', 'signup', 'connection', 'payment']),
            profile: store.profile.data,
            permission: store.profile.userPermissions,
            myBadges: store.badges.myBadges,
        };
    };

    return connect(
        mapStateToProps,
        mapDispatchToProps
    )(AuthenticatedRoutes);
}

export default Layout;
