import React from 'react';
import { Field } from 'formik';
import clsx from 'clsx';
import { useSelectFieldStyles } from './styles';

export const SelectField = ({
  label,
  name,
  placeholder,
  options,
  error,
  displayLabelIfEmpty = true,
  className = 'form-control',
  wrapperClassName = 'form-group'
}) => {
  const { emptySelect } = useSelectFieldStyles();

  return (
    <div className={wrapperClassName}>
      {(label || displayLabelIfEmpty) && (
        <label htmlFor="found-out" className="control-label">
          {label} &nbsp;
        </label>
      )}
      <Field name={name} component="select">
        {({ field }) => (
          <select
            className={clsx(className, { [emptySelect]: !field.value })}
            {...field}
          >
            {placeholder && (
              <option key={placeholder} value="">
                {placeholder}
              </option>
            )}
            {options.map(({ value, label }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </select>
        )}
      </Field>
      {error && (
        <div className="has-error">
          <span className="help-block">{error}</span>
        </div>
      )}
    </div>
  );
};
