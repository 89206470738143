import React, { Component } from 'react';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import get from 'lodash/get';
import map from 'lodash/map';
import includes from 'lodash/includes';
import moment from 'moment';
import axios from 'axios';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import URL from '../constants/URL';
import { withRouter } from 'react-router-dom';
import { viewProfile } from '../actions/Profile';
import ChangePassword from './ChangePassword';
import { logout } from '../actions/Login';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import Select from 'react-validation/build/select';
import { required, email, mobilePhone } from '../utils/FormValidators.jsx';
import { fetchExchangePreferences } from '../actions/HostPreferences';
import ModalRoot from '../components/modals/ModalRoot';
import { ProcessModal, SHOW_MODAL } from '../actions/Modal';
import { ModalTypes } from '../constants/ModalTypes';
import Card from '../components/payments/Card';
import EditCard from '../components/payments/EditCard';
import { fetchCustomerCardDetail } from '../actions/Payment';
import { fetchInvoices } from '../actions/Payment';
import { fetchStripeInvoices } from '../actions/Payment';
import { fetchConnectionList } from '../actions/Connection';
import momentTimeZone from 'moment-timezone';
import 'react-datepicker/dist/react-datepicker.css';
import { SuburbField } from '../components/forms';
import InfoBubble from "../../components/common/InfoBubble";
import { RVButton, Button } from "../../components/common";
import { RoleCode, VerificationBadge } from '../../store/Users/constants';
import { optPeriod } from '../../components/XchangeSearch/configs'

/**
 * @todos
 * - integrate formik & yup to verify & submit the form.
 * - component is too big so split this implementation to multiple manageable components
 *
*/

class Account extends Component {
  constructor(props) {
    super(props);
    const { profile, userprofile } = props;
    this.state = {
      cardDetails: '',
      editCard: false,
      deleteCard: false,
      successMessage: '',
      errorMesssage: '',
      addNewCard: false,
      cardError: '',
      requireOutstandingData: '',
      requesting: false,
      fields: {
        firstName: get(profile, 'first_name', ''),
        lastName: get(profile, 'last_name', ''),
        role: get(profile, 'user_type', ''),
        preferredName: get(profile, 'preferred_name', ''),
        email: get(profile, 'email', ''),
        phone: get(userprofile, 'phone_no', ''),
        badges: get(profile, 'badges', []),
        smsNotificationEnabled: get(userprofile, 'sms_notification_enabled', 1),
        isPublicSearch: get(profile, 'is_public_search', true),
        group_id: get(profile, 'group_id', null),
        exchangeDate: get(profile, 'ready_date', ''),
        exchangePeriod: get(profile, 'xchange_period', ''),
        suburb: get(profile, 'location', ''),
        postCode: get(profile, 'location.postcode', false) ? [get(profile, 'location.postcode')] : [],
        dob: moment(userprofile.dob, 'YYYY-MM-DD').isValid()
            ? moment(userprofile.dob, 'YYYY-MM-DD').format('DD/MM/YYYY')
            : '',
      },
      showPostCodeError: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.displaySuccessMessage = this.displaySuccessMessage.bind(this);
    this.displayErrorMessage = this.displayErrorMessage.bind(this);
  }

  handleSuburbFormat = (data) => {
    return {
      suburb: get(data, 'locality', ''),
      postcode: get(data, 'post_code', ''),
      state: get(data, 'state', ''),
      city: "",
    }
  }


  allowEditField = (fieldName) => {
    return !(this.props.isDigitalVerified && includes(['firstName', 'lastName', 'dob'], fieldName));
  }

  handleInputChange(event) {
    const { name, value } = event.target;
    const val = name === 'suburb' ? this.handleSuburbFormat(value) : value;
    const data = {
      fields: { ...this.state.fields, [name]: val },
    }
    if(this.allowEditField(name))
      this.setState(data);
  }

  handleRadio = (event) => {
    this.setState({
      fields: {
        ...this.state.fields,
        [event.target.name]: JSON.parse(event.target.value),
      },
    });
  };

  componentDidMount() {
    document.title = 'Account Settings | The Room Xchange';
    if (this.props.payment.cards === null) {
      this.props.onFetchCustomerCardDetail(this.props.login.token);
    }

    if (this.props.payment.invoices === null) {
      this.props.onFetchInvoices(this.props.login.token);
    }

    if (this.props.payment.stripeInvoices === null) {
      this.props.onFetchStripeInvoices(this.props.login.token);
    }

    if (this.props.profile === null) {
      this.props.onViewProfile(
        this.props.login.user_id,
        this.props.login.token
      );
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.profile !== this.props.profile) {
      const { profile } = nextProps;
      this.setState({
        ...this.state,
        fields: {
          ...this.state.fields,
          firstName: get(profile, 'first_name', ''),
          lastName: get(profile, 'last_name', ''),
          role: get(profile, 'user_type', ''),
          email: get(profile, 'email', ''),
          badges: get(profile, 'badges', []),
          postCode: get(profile, 'location', false)
            ? [profile.location]
            : [],
        },
      });
    }
    if (nextProps.userprofile !== this.props.userprofile) {
      const { userprofile } = nextProps;
      this.setState({
        ...this.state,
        fields: {
          ...this.state.fields,
          dob:
          get(userprofile, 'dob', false) &&
            moment(userprofile.dob, 'YYYY-MM-DD').isValid()
              ? moment(userprofile.dob, 'YYYY-MM-DD').format('DD/MM/YYYY')
              : '',
          phone: get(userprofile, 'phone_no', ''),
        },
      });
    }
  }

  handleTypeAhead = (value) => {
    this.setState({
      ...this.state,
      fields: {
        ...this.state.fields,
        postCode: value,
      },
      showPostCodeError: true,
    });
  };

  addNewCard = (e) => {
    const addNewCard = this.state.addNewCard;
    this.setState({ ...this.state, addNewCard: !addNewCard });
  };

  editCard = (cardDetails, e) => {
    e.preventDefault();
    const editCard = this.state.editCard;
    this.setState({
      ...this.state,
      editCard: !editCard,
      cardDetails: cardDetails,
    });
  };

  editProfileSubmitHandler = async (e) => {
    e.preventDefault();
    const { user_id, token } = this.props.login;
    const { fields } = this.state;
    this.setState({ requesting: true });
    const payload = {
      first_name: fields.firstName,
      last_name: fields.lastName,
      preferred_name: fields.preferredName,
      email: fields.email,
      dob: fields.dob
        ? moment(this.state.fields.dob, 'DD/MM/YYYY').format('YYYY-MM-DD')
        : '',
      phone_no: fields.phone,
      post_code: fields.suburb && fields.suburb.postcode,
      suburb: fields.suburb && fields.suburb.suburb,
      city: fields.suburb && fields.suburb.city,
      state: fields.suburb && fields.suburb.state,
      sms_notification_enabled: fields.smsNotificationEnabled,
      is_public_search: fields.isPublicSearch,
      exchange_period: fields.exchangePeriod,
      exchange_date: fields.exchangeDate,
      token,
    };
    const result = await axios.put(URL.EDIT_PROFILE(user_id), payload);
    this.setState({ requesting: false });

    if (result.data.status === 'success') {
      this.props.onViewProfile(user_id, token);
      this.props.onFetchExchangePreferences(this.props.login);
      this.displaySuccessMessage(result.data.msg);
    } else {
      this.displayErrorMessage(result.data.msg);
    }
  };

  displaySuccessMessage(message) {
    this.setState({
      successMessage: message,
    });

    setTimeout(
      function () {
        this.setState({ successMessage: '' });
      }.bind(this),
      5000
    );
  }

  displayErrorMessage(message) {
    this.setState({
      errorMesssage: message,
    });

    setTimeout(
      function () {
        this.setState({ errorMesssage: '' });
      }.bind(this),
      5000
    );
  }

  openDeactivateModal = (e) => {
    e.preventDefault();
    this.props.onDeactivate(SHOW_MODAL, ModalTypes.DEACTIVATE_MODAL, null);
  };

  openPreviousInvoicesModal = (stripeInvoices, e) => {
    e.preventDefault();
    const modalProps = {
      stripeInvoices: stripeInvoices,
    };
    this.props.onPreviousInvoices(
      SHOW_MODAL,
      ModalTypes.PREVIOUSINVOICES_MODAL,
      modalProps
    );
  };

  openPaymentSubmitModal = (e) => {
    e.preventDefault();
    const modalProps = {
      payment: this.props.payment,
      requireOutstandingData: this.state.requireOutstandingData,
      onFetchCustomerCardDetail: this.props.onFetchCustomerCardDetail,
      onFetchConnectionList: this.props.onFetchConnectionList,
      onFetchInvoices: this.props.onFetchInvoices,
      onFetchStripeInvoices: this.props.onFetchStripeInvoices,
    };
    this.props.onPaymentSubmit(
      SHOW_MODAL,
      ModalTypes.PAYMENTSUBMIT_MODAL,
      modalProps
    );
  };

  openCardDeleteModal = (cardId, e) => {
    e.preventDefault();
    const modalProps = {
      cardId: cardId,
      onFetchCustomerCardDetail: this.props.onFetchCustomerCardDetail,
    };
    this.props.onPaymentSubmit(
      SHOW_MODAL,
      ModalTypes.CARD_DELETE_MODAL,
      modalProps
    );
  };

  getDateField() {
    return (
      <DatePicker
        selected={
          moment(this.state.fields.dob, 'DD/MM/YYYY').isValid()
            ? moment(this.state.fields.dob, 'DD/MM/YYYY')
            : null
        }
        dateFormat="DD/MM/YYYY"
        onChange={(dob) => {
          this.handleInputChange({ target: { name: 'dob', value: moment(dob).format('DD/MM/YYYY') } })
        }}
        minDate={moment().subtract(100, 'years')}
        maxDate={moment().subtract(18, 'years')}
        showMonthDropdown
        showYearDropdown
        placeholderText="Date of Birth"
      />
    )
  }

  render() {

    const getField = (attr='', defaultVal='') => get(this.state.fields, attr, defaultVal);

    const fetchStripeInvoices = this.props.payment.stripeInvoices ? (
      this.props.payment.stripeInvoices
        .map((stripeInvoices, index) => {
          if (stripeInvoices.total == 0) {
            //Hide the invoice with $0 bill
            return;
          }
          const date = momentTimeZone(stripeInvoices.date * 1000)
            .tz('Australia/Sydney')
            .format('DD/MM/YYYY');
          const amountPaid = stripeInvoices.amount_paid / 100;
          return (
            <li key={index}>
              <a
                href={URL.DOWNLOAD_INVOICE_API(
                  this.props.login.token,
                  stripeInvoices.id
                )}
                className="download-inv"
                target="_blank"
                download={`invoice ${stripeInvoices.id}`}
              >
                <span />
              </a>
              <span>
                Xchange Invoice {date} - ${amountPaid}
              </span>
            </li>
          );
        })
        .slice(0, 3)
    ) : (
      <p>You have no previous invoices</p>
    );

    const fetchOutstandingData = this.props.payment.invoices
      ? this.props.payment.invoices.filter((invoice) => {
          return invoice.status === 'OUTSTANDING';
        })
      : [];

    const fetchPaidData = this.props.payment.invoices
      ? this.props.payment.invoices.filter((invoice) => {
          return invoice.status === 'PAID';
        })
      : [];

    const renderOutstandingXchange =
      fetchOutstandingData.length > 0 ? (
        fetchOutstandingData.map((outstanding, index) => {
          const requireOutstandingData =
            outstanding.id +
            ' ' +
            outstanding.amount +
            ' ' +
            outstanding.exchange_id;
          return (
            <option value={requireOutstandingData} key={index}>
              Xchange Invoice #{outstanding.id}-{outstanding.first_name}{' '}
              {outstanding.last_name}
            </option>
          );
        })
      ) : (
        <p>You have no outstanding Xchanges</p>
      );

    const renderRecentPaidXchange =
      fetchPaidData.length > 0 ? (
        fetchPaidData.map((outstanding, index) => {
          if (index > 3) {
            return;
          }
          return (
            <li key={index}>
              <span>
                Xchange Invoice {outstanding.id} - {outstanding.first_name}{' '}
                {outstanding.last_name}
              </span>
            </li>
          );
        })
      ) : (
        <p>You have no previous invoices</p>
      );

    const renderPaidXchange =
      fetchPaidData.length > 0 ? (
        fetchPaidData.map((outstanding) => {
          return (
            <li>
              <span>
                Xchange Invoice {outstanding.id} - {outstanding.first_name}{' '}
                {outstanding.last_name}
              </span>
            </li>
          );
        })
      ) : (
        <p>You have no previous invoices</p>
      );

    const fetchDefaultCardData = this.props.payment.cards
      ? this.props.payment.cards.filter((card) => {
          return card.type === 'default';
        })
      : [];

    const fetchOptionalCardData = this.props.payment.cards
      ? this.props.payment.cards.filter((card) => {
          return card.type === 'other';
        })
      : [];

    const renderDefaultCard =
      fetchDefaultCardData.length > 0
        ? fetchDefaultCardData.map((defaultCard, index) => {
            const expYear = defaultCard.exp_year;
            const last2DigitYear = expYear.toString().substring(2);
            return (
              <div className="card" key={index}>
                <div className="input">
                  <div className={defaultCard.brand.toLowerCase()} />
                  <span>
                    {defaultCard.brand} **** {defaultCard.last4} Expiry date{' '}
                    {defaultCard.exp_month}/{last2DigitYear}
                  </span>
                  <a href="#">
                    <span
                      onClick={this.openCardDeleteModal.bind(
                        this,
                        defaultCard.card_id
                      )}
                    >
                      Remove
                    </span>
                  </a>
                </div>
              </div>
            );
          })
        : null;

    const renderOptionalCard =
      fetchOptionalCardData.length > 0
        ? fetchOptionalCardData.map((optionalCard, index) => {
            const expYear = optionalCard.exp_year;
            const last2DigitYear = expYear.toString().substring(2);
            return (
              <div
                className="card"
                style={{ marginBottom: '10px' }}
                key={index}
              >
                <div className="input">
                  <div
                    className={optionalCard.brand
                      .replace(/\s/g, '')
                      .toLowerCase()}
                  />
                  <span>
                    {optionalCard.brand} **** {optionalCard.last4} Expiry date{' '}
                    {optionalCard.exp_month}/{last2DigitYear}
                  </span>
                  <a href="#">
                    <span
                      onClick={this.openCardDeleteModal.bind(
                        this,
                        optionalCard.card_id
                      )}
                    >
                      {' '}
                      Remove
                    </span>
                  </a>
                </div>
              </div>
            );
          })
        : null;

    return (
      <div className="accountPage container trx-com-wrap">
      <div className="tab-content">
        <div role="tabpanel" className="tab-pane active " id="profile">
          <div className="trx-acc-wrap">
            <div className="row">
              <div className="col-md-12">
                {this.props.profile !== null ? (
                  <div>
                    {(this.state.requesting ||
                      this.props.payment.requesting) && (
                      <div className="page-loading" />
                    )}
                    <div className="box box-bottom-margin padding-com">
                    <div className="top-title">
                      Update your account details here
                    </div>
                    <Form
                      className="acc-form form-signup"
                      onSubmit={this.editProfileSubmitHandler}
                    >
                      <div className="acc-top-form com-mod">
                        <div className="row">
                          <div className="col-md-4 col-sm-6 col-12">
                            <div className="form-group">
                              <div>
                                <label className="control-label required">
                                  First name <i className="required" />
                                </label>
                                <InfoBubble
                                    tooltip="Make sure your first and last name are as they appear on your drivers licence or passport as it will be used for your verification and Digital iD"
                                />
                              </div>
                              <Input
                                type="firstname"
                                name="firstName"
                                validations={[required]}
                                value={getField('firstName')}
                                onChange={this.handleInputChange}
                                className="form-control"
                                placeholder="John"
                                readOnly={!this.allowEditField('firstName')}
                              />
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-6 col-12">
                            <div className="form-group">
                              <label className="control-label required">
                                Last name <i className="required" />
                              </label>
                              <Input
                                type="lastname"
                                name="lastName"
                                validations={[required]}
                                value={getField('lastName')}
                                onChange={this.handleInputChange}
                                className="form-control"
                                placeholder="Smith"
                                readOnly={!this.allowEditField('lastName')}
                              />
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-6 col-12">
                            <div className="form-group">
                              <div>
                                <label className="control-label">
                                  Preferred name
                                </label>
                                <InfoBubble
                                  tooltip={"This is the name that the public will see on your profile. We highly recommend that you do not add your full name here. "
                                    + (this.state.fields.role === RoleCode.GUEST ? "Your first name or a nickname will be fine"
                                      : "You and/or your partners first names are fine. Do not add children's or additional household members names here")}
                                />
                              </div>
                              <Input
                                type="preferredName"
                                name="preferredName"
                                value={getField('preferredName')}
                                onChange={this.handleInputChange}
                                className="form-control"
                                placeholder="John Smith"
                                readOnly={!this.allowEditField('preferredName')}
                              />
                            </div>
                          </div>
                        </div>
                        {/*row*/}

                        <div className="row">
                          <div className="col-md-4 col-sm-6 col-12">
                            <div className={`form-group trx-about-host-date-picker ${!this.allowEditField('dob') && 'dob-readonly'}`}>
                              <div>
                                <label className="control-label required">
                                  Date of birth <i className="required" />
                                </label>
                                <InfoBubble
                                    tooltip="Make sure you DOB is exactly as it should be as it will be used for your verification and Digital iD"
                                />
                              </div>
                              {this.getDateField()}
                              <Input
                                type="hidden"
                                id="dob-hidden"
                                name="dob-hidden"
                                disable={!this.allowEditField('dob')}
                                value={
                                  moment(
                                    getField('dob'),
                                    'DD/MM/YYYY'
                                  ).isValid()
                                    ? moment(
                                      getField('dob'),
                                        'DD/MM/YYYY'
                                      ).format('DD/MM/YYYY')
                                    : ''
                                }
                                validations={[required]}
                              />
                              {!getField('dob', false) && (
                                <div className="has-error">
                                  <span className="help-block">
                                    This field is required.
                                  </span>
                                </div>
                              )}
                            </div>
                            {/*form-group*/}
                          </div>
                          {/*col-4*/}
                          <div className="col-md-4 col-sm-6 col-12">
                            <div className="form-group">
                              <label className="control-label">
                                Phone number <i className="required" />
                              </label>
                              <Input
                                type="phone-no"
                                name="phone"
                                validations={[required, mobilePhone]}
                                value={getField('phone')}
                                onChange={this.handleInputChange}
                                className="form-control"
                                placeholder="0403 667 131"
                              />
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-6 col-12">
                            <div className="form-group">
                              <label className="control-label required">
                                Email address <i className="required" />
                              </label>
                              <Input
                                type="email"
                                name="email"
                                validations={[required, email]}
                                value={getField('email')}
                                onChange={this.handleInputChange}
                                className="form-control"
                                placeholder="email@domain.com"
                              />
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-6 col-12">
                            <div className="form-group">
                              <SuburbField
                                label={getField('role') === 'HOUSEHOLD' ? 'Suburb' : 'What city to you want to live in/near?'}
                                name="suburb"
                                placeholder={getField('role') === 'HOUSEHOLD' ? 'Please enter your suburb' : 'Please enter your desired location'}
                                value={{
                                  locality: getField('suburb.suburb'),
                                  post_code: getField('suburb.postcode')
                                }}
                                onChange={this.handleInputChange}
                                error={getField('suburb') ? '' : 'This field is required'}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6 col-sm-6 col-12">
                            <div className="form-group">
                              <label
                                htmlFor="Xchange"
                                className="control-label required"
                              >
                                When would you like your house share to begin? <i className="required" />
                              </label>
                              <Input
                                type="date"
                                validations={[required]}
                                name="exchangeDate"
                                value={getField('exchangeDate')}
                                onChange={this.handleInputChange}
                                className="form-control"
                                id="Xchange"
                                placeholder="eg. 12/08/2018"
                              />
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-6 col-12">
                            <div className="form-group">
                              <label
                                htmlFor="long-Xchange"
                                className="control-label"
                              >
                                How long would you like to house share? <i className="required" />
                              </label>
                              <Select
                                className="form-control"
                                value={getField('exchangePeriod')}
                                onChange={this.handleInputChange}
                                placeholder="eg 1-3 months"
                                name="exchangePeriod"
                                validations={[required]}
                                >
                                <option value="">Select</option>
                                {optPeriod.map(({label, value}) => (
                                    <option key={value} value={value}>{label}</option>
                                ))}
                              </Select>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6 col-sm-6 col-12">
                            <div>
                              <label className="control-label">
                                Enable SMS notifications
                              </label>
                              <InfoBubble
                                  tooltip="You must have this selected to 'yes' if you want to receive text messages when someone has reached out to you. If you select 'no' you will ALWAYS have to log in and check your messages in your profile. This means you will miss out on opportunities as they come through."
                              />
                            </div>
                            <div className="form-group sms-notification__options">
                              <label>
                                <input
                                  type="radio"
                                  name="smsNotificationEnabled"
                                  onChange={this.handleRadio}
                                  value="1"
                                  checked={`${this.state.fields.smsNotificationEnabled}` === '1'}
                                />
                                <span>Yes</span>
                              </label>
                              <label>
                                <input
                                  type="radio"
                                  name="smsNotificationEnabled"
                                  onChange={this.handleRadio}
                                  value="0"
                                  checked={`${this.state.fields.smsNotificationEnabled}` === '0'}
                                />
                                <span>No</span>
                              </label>
                            </div>
                          </div>
                          {this.state.fields.group_id !== null && (
                          <div className="col-md-6 col-sm-6 col-12">
                            <div>
                              <label className="control-label">
                                Enable public pearch
                              </label>
                              <div className="form-group sms-notification__options">
                                <label>
                                  <input
                                    type="radio"
                                    name="isPublicSearch"
                                    onChange={this.handleRadio}
                                    value={true}
                                    checked={getField('isPublicSearch', false)}
                                  />
                                  <span>Yes</span>
                                </label>
                                <label>
                                  <input
                                    type="radio"
                                    name="isPublicSearch"
                                    onChange={this.handleRadio}
                                    value={false}
                                    checked={!getField('isPublicSearch', false)}
                                  />
                                  <span>No</span>
                                </label>
                              </div>
                            </div>
                          </div>
                          )}

                        </div>
                        {this.state.successMessage && (
                          <div
                            className="alert alert-success"
                            role="alert"
                          >
                            <strong>Success: </strong>
                            {this.state.successMessage}
                          </div>
                        )}
                        {this.state.errorMesssage &&
                          !this.state.successMessage && (
                            <div className="alert-danger" role="alert">
                              <strong>Error: </strong>
                              {this.state.errorMesssage}
                            </div>
                          )}
                        <RVButton
                          type="submit"
                          className="float-right"
                        >
                          Submit
                        </RVButton>
                      </div>
                    </Form>
                    </div>
                    <div className="box box-bottom-margin padding-com">
                    <div className="pass-update">
                      <div className="top-title">Update password</div>
                      <ChangePassword />
                    </div>
                    </div>
                    <div className="box box-bottom-margin padding-com">
                      <div className="deactive-acc">
                        <div className="top-title mb-3">
                          Deactivate account
                          <InfoBubble
                              tooltip="If you wish to deactivate your account, click the button and follow the process. It will remove your account from public view but you will not lose all your information. Then if you decide to come back at any time in the future you simply login and reactivate your account."
                          />
                        </div>
                        <p className="mb-3">
                          Are you sure you want to deactivate your
                          account?
                        </p>
                        <Button onClick={this.openDeactivateModal}>Deactivate</Button>
                      </div>
                      </div>
                    <div className="payment d-none">
                      <div className="row">
                        <div className="col-md-7 col-12">
                          <div className='box box-bottom-margin padding-com'>
                          <div className="payment-lf">
                            <Form className="form-signup">
                              <div className="top-title">
                                Pay by Credit Card
                              </div>
                              {fetchOutstandingData.length < 1 ? (
                                <p>You have no outstanding Xchanges</p>
                              ) : (
                                <div className="row">
                                  <div className="col-12">
                                    <div className="form-group">
                                      <label className="control-label">
                                        Outstanding Xchanges
                                      </label>
                                      <div className="row">
                                        <div className="col-md-10">
                                          <Select
                                            value={
                                              this.state
                                                .requireOutstandingData
                                            }
                                            name="requireOutstandingData"
                                            onChange={(e) =>
                                              this.setState({
                                                ...this.state,
                                                requireOutstandingData:
                                                  e.target.value,
                                              })
                                            }
                                            className="form-control"
                                            validations={[required]}
                                          >
                                            <option value="">
                                              -Select outstanding
                                              invoice-
                                            </option>
                                            {renderOutstandingXchange}
                                          </Select>
                                        </div>
                                        <div className="col-md-2 nopadding">
                                          <RVButton
                                            type="submit"
                                            data-toggle="modal"
                                            onClick={this.openPaymentSubmitModal}
                                          >
                                            Pay
                                          </RVButton>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}

                              <div className="top-title">
                                Credit/Debit Cards
                              </div>
                              {!this.state.editCard && (
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="form-group">
                                      {fetchDefaultCardData.length >
                                        0 && (
                                        <label className="control-label">
                                          Current Payment Card
                                        </label>
                                      )}
                                      {renderDefaultCard}
                                    </div>
                                  </div>
                                </div>
                              )}
                              {!this.state.editCard && (
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="form-group">
                                      {fetchOptionalCardData.length >
                                        0 && (
                                        <label className="control-label">
                                          Other Registered Cards
                                        </label>
                                      )}
                                      {renderOptionalCard}
                                    </div>
                                  </div>
                                </div>
                              )}

                              {!this.state.editCard && (
                                <p className="add-new">
                                  Add New Card{' '}
                                  <i
                                    className="fa fa-plus icon-rounded-grey"
                                    onClick={this.addNewCard}
                                  />
                                </p>
                              )}

                              {this.state.addNewCard && (
                                <Card
                                  login={this.props.login}
                                  onFetchCustomerCardDetail={
                                    this.props.onFetchCustomerCardDetail
                                  }
                                  addNewCardSuccess={this.addNewCard}
                                />
                              )}
                              {this.state.editCard && (
                                <EditCard
                                  login={this.props.login}
                                  editCard={this.editCard}
                                  cardDetails={this.state.cardDetails}
                                  onFetchCustomerPaymentDetail={
                                    this.props
                                      .onFetchCustomerPaymentDetail
                                  }
                                  addNewCardSuccess={this.addNewCard}
                                />
                              )}
                            </Form>
                          </div>
                          </div>
                        </div>
                        <div className="col-md-5">
                          <div className="payment-rt">
                            <div className="box box-bottom-margin padding-com">
                            <div className="pre-invoice">
                              <div className="top-title prev-title">
                                Previous Invoices
                              </div>
                              <ul>{fetchStripeInvoices}</ul>
                              {this.props.payment.stripeInvoices &&
                                this.props.payment.stripeInvoices.length > 4 && (
                                  <div className="text-center">
                                    <a
                                      href=""
                                      className="text-org view-invoice"
                                      data-toggle="modal"
                                      onClick={this.openPreviousInvoicesModal.bind(
                                        this,
                                        this.props.payment
                                          .stripeInvoices
                                      )}
                                    >
                                      VIEW ALL
                                    </a>
                                  </div>
                                )}
                            </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <p>Loading account...</p>
                )}
              </div>
            </div>
          </div>
        </div>
        {/*tabpanel*/}
      </div>
      {/*tab-content*/}
      <ModalRoot />
    </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  onViewProfile: (user_id, token) => dispatch(viewProfile(user_id, token)),
  onFetchExchangePreferences: (user) => {
    dispatch(fetchExchangePreferences(user));
  },
  onLogout: () => dispatch(logout()),
  onDeactivate: (actionType, modalType, modalProps) =>
    dispatch(ProcessModal(actionType, modalType, modalProps)),
  onFetchCustomerCardDetail: (token) =>
    dispatch(fetchCustomerCardDetail(token)),
  onFetchInvoices: (token) => dispatch(fetchInvoices(token)),
  onFetchStripeInvoices: (token) => dispatch(fetchStripeInvoices(token)),
  onPreviousInvoices: (actionType, modalType, modalProps) =>
    dispatch(ProcessModal(actionType, modalType, modalProps)),
  onFetchConnectionList: (token) => dispatch(fetchConnectionList(token)),
  onPaymentSubmit: (actionType, modalType, modalProps) =>
    dispatch(ProcessModal(actionType, modalType, modalProps)),
});

const mapStateToProps = (store) => {
  const { login, profile, payment } = store;
  const badges = map(profile.profile.badges, 'badge_code');
  return {
    login: login.user,
    profile: profile.data,
    userprofile: profile.profile,
    isDigitalVerified: includes(badges, VerificationBadge.DIGITAL_ID),
    payment,

  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Account));
