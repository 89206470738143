import * as Yup from 'yup';


const AboutHostSchema=Yup.object().shape({

	self_description: Yup.string().required('Please enter your profile snapshot').max(80),
	additional_info: Yup.string().required('Please enter some information about yourself').max(500),
	offering_to_guest: Yup.string().required('Please specify the details of the offer').max(500),
	first_language: Yup.string().required('Please select your first spoken language'),
	yesOtherLanguage: Yup.string(),
	other_language: Yup
		.string()
		.when("yesOtherLanguage", {
			is: 'Yes',
			then: Yup.string().required("Please enter the other language that you speak")
		}),
	gender_preference: Yup.string().required('Please select gender preference'),
	lgbtqi_friendly: Yup.string().required('Please select if you are a LGBTQIA+ friendly household'),
	home_type: Yup.string().required('Please select your home type'),
	distance_from_city: Yup.string().required('Please select your distance from city'),
	no_of_people_in_house: Yup.string().required('Please select number of people living in your house'),
	age_group: Yup.string().required('Please enter the ages of people living in the house'),
	exchange_date: Yup.string(),
	exchange_period: Yup.string().required('Please select house sharing duration'),
	rent_offset: Yup.string(),
	hours_of_help: Yup
		.string()
		.when("rent_offset", {
			is: 'Rent offset',
			then: Yup.string().required("Please enter the number of hours of help")
		}),
	room_value: Yup.number().min(1,'Room value must be greater than 0').max(999,'Room value must be less than 1000').required('Please enter the room value per week'),
	room_value_all_inclusive: Yup.string(),


});

export default AboutHostSchema;
