import React from 'react';
import { Field } from 'formik';
import clsx from 'clsx';

export const TextField = ({
  label,
  name,
  placeholder,
  error,
  isDob = false,
  displayLabelIfEmpty = true,
  className,
  icon,
  smallText,
  fieldClassName,
  ...rest
}) => (
  <div className={clsx(className, 'form-group')}>
    {(label || displayLabelIfEmpty) && (
      <label
        htmlFor={name}
        className={`control-label text-field__label ${
          label ? '' : 'empty-control-label'
        }`}
      >
        {label}&nbsp; {smallText && <small className="small-text pl-1">{smallText}</small>} {icon}
      </label>
    )}
    <Field
      {...rest}
      className={"form-control " + (fieldClassName != null? fieldClassName : '')}
      name={name}
      placeholder={placeholder}
    />
    {error && (
      <div className="has-error">
        <span className="help-block">{error}</span>
      </div>
    )}
  </div>
);
