import React, { Component } from 'react';
import axios from 'axios';
import { required } from '../../utils/FormValidators.jsx';
import { button } from 'react-validation';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import URL from '../../constants/URL';
import {
  injectStripe,
  Elements,
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement
} from 'react-stripe-elements';
import { RVButton as Button } from '../../../components/common';

class CardForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requesting: false,
      cardError: '',
      cardName: ''
    };
  }

  handleInputChange = e => {
    e.preventDefault();
    this.setState({ ...this.state, cardName: e.target.value });
  };

  handlePaymentRequest = e => {
    e.preventDefault();
    const { token } = this.props.login;
    this.setState({ ...this.state, requesting: true });
    this.props.stripe
      .createToken({ name: this.state.cardName })
      .then(stripeResponse => {
        if (stripeResponse.error) {
          this.setState({
            ...this.state,
            requesting: false,
            cardError: stripeResponse.error.message
          });
        } else {
          const request = axios({
            method: 'post',
            url: URL.CREATE_CUSTOMER_CARD_API,
            data: {
              user_token: token,
              card_token: stripeResponse.token.id
            }
          });
          request.then(result => {
            if (result.data.status === 'success') {
              this.props.addNewCardSuccess();
              this.props.onFetchCustomerCardDetail(token);
              this.setState({ ...this.state, requesting: false });
            } else {
              this.setState({
                ...this.state,
                requesting: false,
                cardError: result.data.msg
              });
            }
          });
        }
      });
  };

  render() {
    return (
      <div>
        {this.state.requesting && <div className="page-loading"></div>}
        <Form>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label className="control-label">Name on card</label>
                <Input
                  type="text"
                  className="form-control"
                  placeholder="Full Name"
                  name="cardName"
                  validations={[required]}
                  onChange={this.handleInputChange}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label className="control-label">Card Number</label>
                <CardNumberElement className="card-element" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-5 col-12">
              <div className="form-group">
                <label className="control-label">Expiry</label>
                <CardExpiryElement className="card-element" />
              </div>
            </div>
            <div className="col-md-5 col-12">
              <div className="form-group">
                <label className="control-label">Security Code</label>
                <CardCVCElement className="card-element" />
              </div>
            </div>
          </div>
          {this.state.cardError && (
            <div className="alert-danger" role="alert">
              <strong>Error: </strong>
              {this.state.cardError}
            </div>
          )}
          <Button
            type="submit"
            onClick={this.handlePaymentRequest}
            className="float-right"
            data-toggle="modal"
          >
            Submit
          </Button>
        </Form>
      </div>
    );
  }
}
const InjectedCardForm = injectStripe(CardForm);

class Checkout extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="Checkout">
        <div>
          <Elements>
            <InjectedCardForm
              login={this.props.login}
              onFetchCustomerCardDetail={this.props.onFetchCustomerCardDetail}
              addNewCardSuccess={this.props.addNewCardSuccess}
            />
          </Elements>
        </div>
      </div>
    );
  }
}

export default class Card extends React.Component {
  render() {
    return (
      <Checkout
        login={this.props.login}
        onFetchCustomerCardDetail={this.props.onFetchCustomerCardDetail}
        addNewCardSuccess={this.props.addNewCardSuccess}
      />
    );
  }
}
