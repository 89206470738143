import React, { memo, FC } from 'react';
import moment from 'moment';
import get from 'lodash/get';
import { RoleCode } from 'store/Users/constants';
import { Button } from '../../components/common';
import { UserProfileProps } from './types';
import Badges from './Badges';
import ProfileName from './ProfileName';
import CoverPhoto from './CoverPhoto';
import PublicProfile from './PublicProfile';
import Priorities from './Priorities';
import { defaultProfileValue, getUserAvatar } from './utils';
import { openPublicSearchModal } from '../../js/actions/PublicSearchModal'
import './styles.scss';
import {useDispatch} from "react-redux";
import {PublicSearchModal} from "../../js/components/modals/public-search-modal";
// @ts-ignore
import {MetaTags} from "react-meta-tags";


function UserProfile({
	isAdmin,
	preferences,
	role,
	context,
	userId,
	connectionStatus,
	handleConnectClick,
	isLoggedIn,
}: UserProfileProps) {
	const dispatch = useDispatch();

	if (!preferences) {
		return <div>Loading</div>;
	}

	const handleAttemptedConnect = () => {
		dispatch(openPublicSearchModal());
	};

	const renderUserProfilePublicButton = () => {
		return (
			<>
				<div className="text-center">
					<Button
						href="#"
						color="teal"
						data-toggle="modal"
						onClick={() =>
							handleAttemptedConnect()
						}
					>
						Connect with {getHostLabel('household', 'housemate')}
					</Button>
				</div>
				<div className="clearfix"></div>
			</>
		)
	}


	const renderUserProfileButton = (status: string) => {
		const name = preferences.preferred_name
			? preferences.preferred_name
			: preferences.first_name;

		if (context === 'self') {
			return (
				<div className="text-center">
					<Button color="teal" href="/about/edit">
						Edit profile
					</Button>
				</div>
			);
		}

		if (
			[
				'PENDING',
				'ACCEPT',
				'PAID',
				'EXCHANGE_DECLINE',
				'EXCHANGE_PENDING',
				'EXCHANGE_ACCEPT'
			].includes(status)
		) {
			return (
				<div className="text-center">
					<Button disabled={true} color="grey" size="medium">
						{status === 'PENDING' && 'Request pending'}
						{status === 'ACCEPT' &&
							`Connected with ${name}`}
						{status === 'PAID' && `Exchange active`}
						{status === 'EXCHANGE_DECLINE' &&
							'Previously xchange declined'}
						{status === 'EXCHANGE_PENDING' &&
							'Exchange pending'}
						{status === 'EXCHANGE_ACCEPT' &&
							'Need to pay'}
					</Button>
				</div>
			);
		}

		if (status == 'INCOMING') {
			return (
                <>
                    <div className="text-center">
                        <Button
                            href="/message"
                            color="teal"
                        >
                            Accept or Decline Request
                        </Button>
                    </div>
                    <div className="clearfix"></div>
                </>
			)
		}

		return (
			<>
				<div className="text-center">
					<Button
						href="#"
						color="teal"
						data-toggle="modal"
						onClick={() =>
							handleConnectClick(name, role.toLowerCase())
						}
					>
						Connect with {getHostLabel('household', 'housemate')}
					</Button>
					{preferences.status === 'DECLINE' && (
						<Button disabled={true} color="grey">
							Previously Declined
						</Button>
					)}
				</div>
				<div className="clearfix"></div>
			</>
		);
	};

	const getReadyToXchangeDate = () => {
		if (preferences && preferences.exchange_date) {
			if (moment().isAfter(preferences.exchange_date)) {
				return 'Now for ' + preferences.exchange_period;
			} else {
				return (
					moment(preferences.exchange_date).format('DD/MM/YYYY')
				);
			}
		} else {
			return 'Any time';
		}
	};

	const getVal = (key: string, defaultVal = '') =>
		get(preferences, `${key}`, defaultVal);

	const getHostLabel = (houseHoldLabel: string, houseMateLabel: string) => {
		return role === RoleCode.HOST ? houseHoldLabel : houseMateLabel;
	};

	//name
	let name = preferences.preferred_name
		? preferences.preferred_name
		: preferences.first_name;

	//preferences_data
	let offering = getVal(getHostLabel('offering_to_guest', 'offering'));
	let basicInfo = getVal(getHostLabel('self_description', 'describes_you'));

	return (
		<>
			<MetaTags>
				<meta name="title" content={`${getHostLabel('Household', 'Housemate')} - ${name} in ${getVal('suburb')}, ${getVal('state')}`} />
				<meta name="description" content={basicInfo} />
				<meta property="og:title" content={`${getHostLabel('Household', 'Housemate')} - ${name} in ${getVal('suburb')}, ${getVal('state')}`} />
				<meta property="og:description" content={basicInfo} />
				<meta property="og:image" content={get(
								preferences,
								'profile_other[0].image_path',
								''
							)} />
				<meta property="og:site_name" content="The Room Xchange" />
				<meta property="og:url" content={window.location.href} />
				<meta name="twitter:title" content={`${getHostLabel('Household', 'Housemate')} - ${name} in ${getVal('suburb')}, ${getVal('state')}`} />
				<meta name="twitter:description" content={basicInfo} />
				<meta name="twitter:image" content={get(
					preferences,
					'profile_other[0].image_path',
					''
				)} />
				<meta name="robots" content="index, follow" />
			</MetaTags>

			<div className="pro-wrap user-profile">
			<div className="row">
				<div className="col-lg-7 col-12">
					<div className="box box-bottom-margin">
						<div className="pro-name">
							<ProfileName
								role={role}
								name={name}
								userId={userId}
								anchor={isAdmin}
							/>
							<Badges badges={preferences.badges} />
						</div>
						<CoverPhoto
							coverPhoto={get(
								preferences,
								'profile_other[0].image_path',
								''
							)}
							userPhoto={get(
								preferences,
								'profile_main.image_path',
								getUserAvatar()
							)}
							galleries={get(preferences, 'profile_other', [])}
						/>

						<div className="pro-lt-block padding-com profile">
							<div className="row rental-info">
								<div className="col-md-6 col-sm-5">
									<div className="block-title">
										Rent or rent offset:
									</div>
									<dd>{get(preferences, 'rent_offset') ? 'Rent offset' : 'Rent only'}</dd>
								</div>
								<div className="col-md-5 col-sm-5">
									{role === RoleCode.HOST ? (
										<>
											<div className="block-title">
												Rent value:
											</div>
											<dd>
												{get(preferences, 'room_value') ? `$${preferences?.room_value}/week ${getVal('room_value_all_inclusive') ? 'incl. utilities' : 'room only'}` : defaultProfileValue}
											</dd>
										</>
									) : (
										<>
											<div className="block-title">
												Rental budget:
											</div>
											<dd>
												{get(preferences, 'rental_budget') ? `$${preferences?.rental_budget}/week` : defaultProfileValue}
											</dd>
										</>
										)}
								</div>
							</div>
							<div className="block-title">Profile snapshot:</div>
							<div className="pro-desc">{basicInfo}</div>
							{getVal('additional_info') != null && (
								<>
									<div className="block-title">About:</div>
									<div className="pro-desc">
										{getVal('additional_info', defaultProfileValue)}
									</div>
								</>
							)}
							<div className="block-title">
								Offer:
							</div>
							<div className="pro-desc">{offering || defaultProfileValue}</div>

							<div className="block-title">
								I’d like to start house sharing:
							</div>
							<div className="pro-desc">
								{getReadyToXchangeDate()}
							</div>
							{(isLoggedIn && connectionStatus !== 'IMPOSSIBLE' && renderUserProfileButton(connectionStatus)) || (!isLoggedIn && renderUserProfilePublicButton())}
						</div>
					</div>
					<div className="box box-bottom-margin">
						<div className="padding-com clearfix">
							<Priorities
								role={role}
								exchange_priorities={getVal(
									'exchange_priorities'
								)}
								personality={role=== RoleCode.HOST ? getVal(
									'household_personalities'
								) : getVal(
									'personality'
								)}
								rent_offset={get(preferences, 'rent_offset')}
								help_tasks={getVal('help_tasks')}
								other_help_tasks={getVal('other_help_tasks')}
								tasks_to_do={getVal('tasks_to_do')}
								tasks_to_do_other={getVal('tasks_to_do_other')}
								talents={getVal('talents')}
								skills_other={getVal('skills_other')}
								activities={getVal('activities')}
								experiences_to_enjoy={getVal('experiences_to_enjoy')}
								experiences_to_enjoy_other={getVal('experiences_to_enjoy_other')}
							/>
						</div>
					</div>
				</div>
				<div className="col-lg-5 col-12">
					<PublicProfile
						preference={preferences}
						role={role}
						exchange_priorities={getVal('exchange_priorities', defaultProfileValue)}
					/>
				</div>
			</div>
		</div>
		{!isLoggedIn && <PublicSearchModal />}
		</>
);
}

export default memo(UserProfile);
