import { FETCH_PREFERENCE_DATA } from '../actions/HostPreferences';

const INITIAL_STATE = { preferenceData: null };

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_PREFERENCE_DATA:
      return { ...state, preferenceData: action.payload };
    default:
      return state;
  }
}
