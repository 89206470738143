import React, { FC, memo } from 'react';
import FRONTEND_RESPONSE_MESSAGE from '../../js/constants/FRONTEND_RESPONSE_MESSAGE';
import { Button } from '../../components/common';
import { SuburbField } from '../../js/components/forms';

interface Props {
	whomISearching: any;
	searchParam: any;
	handleLocationChange: any;
	searchByLocationHandler: any;
	postcodeError: any;
}

const SearchBar: FC<Props> = ({
	whomISearching,
	searchParam,
	handleLocationChange,
	searchByLocationHandler,
	postcodeError
}) => {
	return (
		<div className="search-wrap com-mod">
			<div className="box">
				<div className="box-body">
					<div className="find-title">
						Find your match from our list of {whomISearching.toLowerCase()}
					</div>
					<form
						className="form-signup"
					>
						<div className="form-group">
							<SuburbField
                              label="Suburb"
                              name="suburb"
                              placeholder="Location"
                              onChange={handleLocationChange}
                            />
						</div>
						<div className="form-group mb-0">
							<Button
								className="float-right"
								onClick={searchByLocationHandler.bind(
									this,
									whomISearching
								)}>
									Search
							</Button>
						</div>
						<div className="clearfix"></div>
						{!searchParam && postcodeError && (
							<div
								className="has-error"
								style={{ display: 'block' }}
							>
								<span className="help-block">
									{
										FRONTEND_RESPONSE_MESSAGE.VALIDATION_POSTCODE
									}
								</span>
							</div>
						)}
					</form>
				</div>
			</div>
		</div>
	);
};

export default memo(SearchBar);
