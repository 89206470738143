import React, {FC, useContext, useEffect, useState} from 'react';
import InfoBubble from "../../../components/common/InfoBubble";
import { useFormik } from 'formik';
import '../styles/profile_edit.scss';

import {personalInterests, rentOffsetTasks} from "../utils/formData";
import TalentsSchema from "../schemas/TalentsSchema";
import {
	getAccordionDefaultConfig,
	hasError,
	manageAccordion,
	postActionSuccess,
	setAjaxLoader,
	showAjaxError, includeTestId
} from "../utils/common";
import {editProfileService} from "../utils/editProfileService";
import {UserProps} from "../types/editProfileTypes";
import FormError from "./helpers/FormError";
import RemoteUpdateError from "./helpers/RemoteUpdateError";
import FormSubmitButtons from "./helpers/FormSubmitButtons";
import EditProfileContext from "../context/EditProfileContext";



const Interests: FC<UserProps>=({user})=>{
	const {accordionConfig, update, data}=useContext(EditProfileContext);
	const [buttonType, setButtonType]=useState('');
	let interests=editProfileService.GUEST.getInterestsSection({...data});
	const [submissionError, setSubmissionError] = useState('');

	const {values, errors, touched, handleBlur, handleChange, handleSubmit, isValid, setFieldError, validateForm} = useFormik({
		initialValues: interests as any,
		// validateOnMount: true,
		validationSchema: TalentsSchema,
		onSubmit: (values) => {
			const params={...values};
			setAjaxLoader(true,update);
			editProfileService.GUEST.updateInterests(params,user).then(result=>{
				const {data}=result;
				if(data.status==='success')
					postActionSuccess(buttonType, update,'photosCollapsed', user.user_id, user.token);
				else if(data.msg!==undefined)
					setSubmissionError(data.msg);
				else
					setSubmissionError('Some error occurred. Please try again later');

				setAjaxLoader(false,update);
			}).catch(res=>{
				showAjaxError(res,setSubmissionError,update);
			});
		}
	});

	useEffect(() => {
		(() => validateForm())();
	}, []);

	const errorMessages = () => {
		if (submissionError) {
			return submissionError
		} else if (!isValid) {
			return "There are some issues you need to resolve before saving"
		} else {
			return '';
		}
	}

	return (
		<div className="card">
			<div className="card-header" role="tab" id="headingSkill">
				<a className={accordionConfig.interestsCollapsed ? 'collapsed' : ''} href="#abt-skill"  aria-expanded={!accordionConfig.inclusionCollapsed} onClick={()=>{ manageAccordion({...getAccordionDefaultConfig(), interestsCollapsed: !accordionConfig.interestsCollapsed}, update)}}>
					<h2 className="mb-0">
						<div className="card-header__left" {...includeTestId('interest-heading')}>
							<i className={isValid ? 'fa fa-check icon-rounded' : 'fa fa-close icon-rounded'}/>
							Interests
						</div>
					</h2>
				</a>
			</div>

			<div id="abt-skill" className={`collapse ${accordionConfig.interestsCollapsed?'':'show'} `} role="tabpanel" aria-labelledby="headingSkill" data-parent="#profileAccordion">
				<form className="form-horizontal form-signup" role="form" onSubmit={handleSubmit}>
					<div className="card-body">
						{values.rent_offset===1 && <div className="row form-mod" {...includeTestId('things-for-rent-offset')}>
								<div className="col-12">
									<label className="control-label" >Things I can do to offset the rent
										<InfoBubble
											tooltip="If you have selected a rent offset, then list the things that you’d be willing to do to for a rent reduction. Please note: This is on top of what you would normally contribute as a housemate."
										/>
									</label>
									<FormError errors={errors} fieldName={'tasks_to_do'} touched={null} containerClass={'mt-1 mb-1'}/>

								</div>
								{rentOffsetTasks.map((item, key) => (
									<div className="col-lg-4 col-md-4 col-sm-12 col-12" key={`tasks_to_do-${item.value}`}>
										<div className="form-check">
											<input className="form-check-input" name="tasks_to_do" type="checkbox" value={item.value} checked={values.tasks_to_do.includes(item.value)} onChange={handleChange}/>
											<label className="form-check-label" htmlFor={`tasks_to_do-${item.value}`}>{item.label}</label>
										</div>

										{item.value === 'Other' && values.tasks_to_do.includes('Other') &&
											<div className="form-group">
												<input className={`form-control ${hasError(errors, touched, 'tasks_to_do_other') ? 'error-field' : ''}`} name="tasks_to_do_other" type="text" placeholder="Enter other task" onChange={handleChange} onBlur={handleBlur} value={values.tasks_to_do_other}/>
												<FormError errors={errors} fieldName={'tasks_to_do_other'} touched={touched}/>
											</div>
										}

									</div>
								))}
							</div>
						}
						{values.rent_offset===0 && <input type="hidden" name="tasks_to_do" value="N/A" checked={true} /> }

						<div className="row form-mod" {...includeTestId('personal-interests')}>
							<div className="col-12">
								<label htmlFor="priorities" className="control-label">
									Personal Interests
								</label>
								<FormError errors={errors} fieldName={'talents'} touched={null} containerClass={'mb-1 mt-1'}/>

							</div>
							{personalInterests.map((item, key) => (
								<div className="col-lg-4 col-md-4 col-sm-12 col-12" key={`talents-${item.value}`}>
									<div className="form-check" >
										<input className="form-check-input" name="talents" type="checkbox" value={item.value} checked={values.talents.includes(item.value)}  onChange={handleChange}/>
										<label className="form-check-label" htmlFor={`talents-${item.value}`}>{item.label}</label>
									</div>
									{item.value==='Other' && values.talents.includes('Other')  &&

										<div className="form-group">
											<input className={`form-control ${hasError(errors, touched,'skills_other')?'error-field':''}`} name="skills_other" type="text" placeholder="Enter other interest" onChange={handleChange} onBlur={handleBlur} value={values.skills_other}/>
											<FormError errors={errors} fieldName={'skills_other'} touched={touched} />
										</div>
									}
								</div>
							))}
						</div>
						<RemoteUpdateError submissionError={errorMessages()}/>
					</div>
					<FormSubmitButtons setButtonType={setButtonType} isValid={isValid}/>
				</form>
			</div>
		</div>
	);
}

export default Interests;
