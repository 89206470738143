import React, { useCallback, useState, useEffect } from 'react';
import map from 'lodash/map';
import get from 'lodash/get';
import pick from 'lodash/pick';
import size from 'lodash/size';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useSearchContainerStyles } from './styles';
import { UserCard } from '../user-card';
import SearchPagination from './SearchPagination';
import XchangeSearch, {
  getSearchMode
} from '../../../components/XchangeSearch/XchangeSearch';
import { roleLabel } from '../../../components/XchangeSearch/configs';
import { Loader } from '../../../components/common/Loader';
import { filter, includes } from 'lodash';

export const SearchContainer = ({ onSearch, cardAction, profile, isLoading }) => {
  const PAGINATION_DEFAULT_PER_PAGE = 12;
  const initialParams = new URLSearchParams(window.location.search);
  const { total, users, meta, login: loginData } = useSelector(
    ({ search: { total, users, meta }, login }) => ({
      total,
      users,
      meta,
      login
    }),
    shallowEqual
  );
  const history = useHistory();
  const dispatch = useDispatch();
  /* The default search (i.e. when the user clicks Browse) is to show all users
   * in the same state as the currently logged in user.
   * If there is no logged in user then default to a blank state value.
   */
  const findInitialStateValue = () => {
    if (initialParams.get('state')) {
      return initialParams.get('state');
    } else if (initialParams.get('suburb')) {
      //If the user has already searched for a suburb leave the state field blank
      return '';
    } else if (profile && profile.suburb.state) {
      return profile.suburb.state;
    }
    return '';
  };

  /* The initial state of the search results can be extracted from the URL query
   * string parameters to allow memory of existing search location as well as
   * direct linking and bookmarks.
   * If the query string params are not set then use the default for each */
  const [pagination, setPagination] = useState({
    perPage: PAGINATION_DEFAULT_PER_PAGE,
    page: initialParams.get('page') ? parseInt(initialParams.get('page')) : 0
  });
  const [searchParams, setSearchParams] = useState({
    suburb: initialParams.get('suburb') ? initialParams.get('suburb') : '',
    post_code: initialParams.get('post_code')
      ? initialParams.get('post_code')
      : '',
    state: findInitialStateValue(),
    gender: initialParams.get('gender') ? initialParams.get('gender') : '',
    age_range: initialParams.get('age_range') ? initialParams.get('age_range') : '',
    rent_period: initialParams.get('rent_period')
      ? initialParams.get('rent_period')
      : '',
    rent_offset: initialParams.get('rent_offset')
    ? initialParams.get('rent_offset')
    : '',
    rent_budget: initialParams.get('rent_budget')
    ? initialParams.get('rent_budget')
    : '',
    role: getSearchMode(
      initialParams.get('mode'),
      get(profile, 'role_code', '')
    ),
    userId: profile ? profile.user_id : null
  });

  const totalNumberPages = () => size(filter(get(meta, 'links', []), ({label}) => {
      return !(includes(label, 'Previous') || includes(label, 'Next'))
    }));

  useEffect(() => {
    const searchPayload = {
      ...searchParams,
      state: get(searchParams, 'state') || get(profile, 'suburb.state')
    };
    setSearchParams(searchPayload);
  }, [get(profile, 'suburb.state', '')]);

  useEffect(() => {
    if(searchParams.state)
      onSearch({
        ...pagination,
        ...searchParams
      });
  }, [pagination, searchParams]);

  const { resultsContainer, row, grid, resultsNotFound, loading, totalResults } =
    useSearchContainerStyles();

  //This function is for a new search being completed via the form
  const handleSearch = useCallback(
    ({ role, suburb, post_code, state, gender, age_range, rent_period, rent_offset, rent_budget }) => {
      setPagination({
        perPage: PAGINATION_DEFAULT_PER_PAGE,
        page: 0
      });
      setSearchParams({
        role: role || (searchParams && searchParams.role),
        state,
        gender,
        age_range,
        post_code,
        suburb: typeof suburb === 'string' ? suburb : get(suburb, 'locality', ''),
        rent_period,
        rent_offset, rent_budget,
        userId: searchParams.userId
      });
    },
    [dispatch, searchParams]
  );

  const handlePaginationChange = React.useCallback(
    ({ page }) => {
      setPagination({
        page,
        perPage: pagination.perPage
      });

      let params = {mode: searchParams.role, page, perPage: initialParams.get('perPage'), ...searchParams};
      params = pick(params, ["page", "perPage", "suburb", "post_code", "state", 'gender', 'age_range', "rent_period", 'rent_offset', 'rent_budget']);
      const queryString = new URLSearchParams(params).toString();
      history.push(`/${loginData && loginData.user ? 'search' : 'profile_search'}?${queryString}`);

      if (window.outerWidth < 768) {
        window.scrollTo({ top: 100, behavior: 'smooth' });
      }
    },
    [pagination]
  );

  const getPaginationStats = () => {
    const {from, total} = meta;
    const endIndex = parseInt(meta.from)+11;
    const to = endIndex >= total ? total : endIndex;
    return `Showing: ${parseInt(from)}-${to} of ${meta.total}`;
  }

  return (
    <div className={resultsContainer}>
      <XchangeSearch
        address={pick(searchParams, ['suburb', 'post_code', 'state'])}
        showHeading={false}
        totalResults={total}
        mode={searchParams.role}
        gender={searchParams.gender}
        ageRange={searchParams.age_range}
        rentPeriod={searchParams.rent_period}
        rentBudget={searchParams.rent_budget}
        rentOffset={searchParams.rent_offset}
        onSubmit={handleSearch}
      />
      {meta && meta.total === 0 && get(roleLabel, `${get(searchParams, 'role')}`) && (
        <p className={resultsNotFound}>
          <i className="fa fa-search"></i>
          {profile && profile.user_id ? (
            <>
              Sorry! we couldn't find any {roleLabel[searchParams.role]} that match your criteria.
              <span>Try adjusting your filters and searching again</span>
            </>
          ) : (
            <>
              Find your people! Select either Households or Housemates and enter
              a state, suburb or postcode in the search box
              <span>
                Tip: Use the additional filters to narrow down your search
              </span>
            </>
          )}
        </p>
      )}
      {isLoading ? <div className={`col-sm-12 ${loading}`}><Loader /></div> : (
        <>
          {meta && meta.total ? <div className={totalResults}><span>{getPaginationStats()}</span></div> : <></>}
          <div className={row}>
            <div className="clearfix"></div>
            {map(users, (user) => (
                <div className={grid} key={user.user_id}>
                  <UserCard action={cardAction} user={user} profile={profile} />
                </div>
              ))}
          </div>
          {size(users) > 0 && size(get(meta, 'links')) > 0 && (
            <SearchPagination
            numPages={totalNumberPages()}
            onPaginationChange={handlePaginationChange}
            page={pagination.page}
          />
          )}
      </>
      )}
    </div>
  );
};
