import React from 'react';
import { Button } from '../../components/common';

export const FooterButtons = () => (
	<div className="col-md-12 button-container" >
		<div className="row justify-content-center mt-4">
			<div className="col-lg-2 col-md-3 col-sm-3 col-xs-12 household-btn">
				<Button href="/register/household" color="blue" size="large">Households</Button>
			</div>
			<div className="col-lg-2 col-md-3 col-sm-3 col-xs-12 housemate-btn">
				<Button href="/register/housemate" color="teal" size="large">Housemates</Button>
			</div>
		</div>
	</div>
  );
  