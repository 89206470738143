import React, {FC, ReactChildren, useReducer} from 'react';
import EditProfileReducer from './EditProfileReducer';
import EditProfileContext from "./EditProfileContext";
import {getAccordionDefaultConfig} from "../utils/common";
import {RemoteResponse, RemoteUserType} from "../types/editProfileTypes";
type StateProviderProps={
	data: RemoteResponse,
	children: ReactChildren,
}
const EditProfileStateProvider: FC<StateProviderProps> = ({data, children}) => {

    const initialState = {
		data: data,
		accordionConfig: getAccordionDefaultConfig(),
		loader: false
	}

    const [state, dispatch]=useReducer(EditProfileReducer,initialState);
    const updateState = (params: any) => {
        dispatch({
            type: params.type,
            payload:  params.payload
        });
    };

    return (
        <EditProfileContext.Provider value={{data: state.data, update: updateState, accordionConfig: state.accordionConfig, loader: state.loader}}>
            {children}
        </EditProfileContext.Provider>
    );
};

export default EditProfileStateProvider;
