import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import URL from '../../constants/URL';
import { ProcessModal } from '../../actions/Modal';
import { required, radioRequired } from '../../utils/FormValidators.jsx';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import { HIDE_MODAL } from '../../actions/Modal';
import CardForInvoices from '../payments/CardForInvoices';
import { fetchConnectionList } from '../../actions/Connection';
import FRONTEND_RESPONSE_MESSAGE from '../../constants/FRONTEND_RESPONSE_MESSAGE';
import { RVButton, Button } from '../../../components/common';

class PaymentSubmitModal extends Component {
  constructor(props) {
    super(props);
    let requireOutstandingData = null;
    let invoiceId = null;
    let amount = null;
    let xchangeId = null;
    if (this.props.requireOutstandingData) {
      requireOutstandingData = this.props.requireOutstandingData.split(' ');
      invoiceId = requireOutstandingData[0];
      amount = requireOutstandingData[1];
      xchangeId = requireOutstandingData[2];
    }
    const fetchDefaultCardData = this.props.payment.cards
      ? this.props.payment.cards.filter(card => {
          return card.type === 'default';
        })
      : [];
    this.state = {
      requesting: false,
      payOutstandingXchangeError: '',
      addNewCard: false,
      payOutstandingXchangeSuccess: '',
      amount: amount,
      paymentFromCard: false,
      couponCheck: false,
      coupon: '',
      fields: {
        cardId: fetchDefaultCardData.length
          ? fetchDefaultCardData[0].card_id
          : '',
        invoiceId: invoiceId,
        xchangeId: xchangeId
      }
    };
  }

  handleInputChange = e => {
    e.preventDefault();
    this.setState({ ...this.state, [e.target.name]: e.target.value });
  };

  toggleChange = () => {
    if (!this.state.couponCheck) {
      this.setState({
        couponCheck: !this.state.couponCheck
      });
    } else {
      this.setState({
        couponCheck: !this.state.couponCheck,
        coupon: ''
      });
    }
  };

  payOutstandingXchange = e => {
    e.preventDefault();
    this.setState({ ...this.state, requesting: true });
    const request = axios({
      method: 'post',
      url: URL.PAYMENT_API,
      data: {
        user_token: this.props.login.token,
        card_id: this.state.fields.cardId,
        invoice_id: this.state.fields.invoiceId,
        exchange_id: this.state.fields.xchangeId,
        coupon: this.state.coupon
      }
    });
    request.then(result => {
      if (result.data.status === 'success') {
        this.setState({
          ...this.state,
          requesting: false,
          payOutstandingXchangeError: '',
          payOutstandingXchangeSuccess: result.data.msg
        });
        this.props.onFetchInvoices(this.props.login.token);
        this.props.onFetchStripeInvoices(this.props.login.token);
        this.props.onFetchConnectionList({
          token: this.props.login.token,
          page: 1,
          limit: 20,
          type: '',
          search: ''
        });
        //this.close();
      } else {
        this.setState({
          ...this.state,
          requesting: false,
          payOutstandingXchangeSuccess: '',
          payOutstandingXchangeError: result.data.msg
        });
      }
    });
  };

  close = () => {
    const modalProps = { showModal: false };
    this.props.dispatch(ProcessModal(HIDE_MODAL, null, modalProps));
  };

  addNewCard = e => {
    e.preventDefault();
    this.setState({ ...this.state, addNewCard: true });
  };

  useExistingCard = e => {
    e.preventDefault();
    this.setState({ ...this.state, addNewCard: false });
  };

  getNotifyFromCard = () => {
    this.setState({
      ...this.state,
      paymentFromCard: true,
      payOutstandingXchangeSuccess: true
    });
  };

  render() {
    const renderAvailableCard = this.props.payment.cards
      ? this.props.payment.cards.map((card, index) => {
          const expYear = card.exp_year;
          const last2DigitYear = expYear.toString().substring(2);

          return (
            <div className="row" key={index}>
              {this.state.requesting && <div className="page-loading"></div>}
              <div className="col-12">
                <div className="form-check">
                  <Input
                    className="form-check-input"
                    name="card"
                    onChange={e =>
                      this.setState({
                        ...this.state,
                        fields: { ...this.state.fields, cardId: e.target.value }
                      })
                    }
                    value={card.card_id}
                    type="radio"
                    checked={this.state.fields.cardId === card.card_id}
                    validations={[radioRequired]}
                  />
                  <label className="form-check-label">
                    <div className="input">
                      <span>
                        {card.brand} **** {card.last4} Expiry date{' '}
                        {card.exp_month}/{last2DigitYear}
                      </span>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          );
        })
      : null;

    return (
      <div>
        <div className="overlay modal-backdrop modal-dialog-centered payment-modal">
          <div className="col-md-5 mx-auto">
            {this.state.requesting && <div className="page-loading"></div>}
            <div className="modal-content padding-com">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  onClick={this.close}
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">
                    <i className="fa fa-close"></i>
                  </span>
                </button>
              </div>

              <div className="modal-body">
                <div>
                  {this.state.payOutstandingXchangeSuccess ? (
                    <h3>Payment Success</h3>
                  ) : (
                    <h3>Pay Outstanding Invoices</h3>
                  )}
                  {/**<p>You are paying for Invoice #{this.state.fields.invoiceId} for ${this.state.amount}</p>
                   **/}
                  <Form className="form-signup">
                    {!this.state.addNewCard ? (
                      !this.state.payOutstandingXchangeSuccess ? (
                        !this.state.paymentFromCard &&
                        this.props.payment.cards &&
                        this.props.payment.cards.length > 0 ? (
                          <div>
                            {renderAvailableCard}
                            <div className="row">
                              <div className="col-12">
                                <div className="form-check">
                                  <input
                                    name="couponCheck"
                                    checked={this.state.couponCheck}
                                    value={this.state.couponCheck}
                                    onClick={this.toggleChange}
                                    className="form-check-input"
                                    type="checkbox"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="experience-5"
                                  >
                                    Coupon
                                  </label>
                                </div>
                                {this.state.couponCheck && (
                                  <div className="form-group">
                                    <Input
                                      type="coupon"
                                      validations={[required]}
                                      className="form-control"
                                      name="coupon"
                                      value={this.state.coupon}
                                      onChange={this.handleInputChange}
                                      style={{ height: '42px' }}
                                      placeholder="Please enter your coupon"
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        ) : (
                          <CardForInvoices
                            login={this.props.login}
                            onFetchCustomerCardDetail={
                              this.props.onFetchCustomerCardDetail
                            }
                            onFetchStripeInvoices={
                              this.props.onFetchStripeInvoices
                            }
                            onFetchInvoices={this.props.onFetchInvoices}
                            invoiceId={this.state.fields.invoiceId}
                            xchangeId={this.state.fields.xchangeId}
                            payment={this.props.payment}
                            getNotifyFromCard={this.getNotifyFromCard}
                            onFetchConnectionList={
                              this.props.onFetchConnectionList
                            }
                          />
                        )
                      ) : (
                        <div>
                          <p>
                            {FRONTEND_RESPONSE_MESSAGE.PAYMENT.SUCCESS_RESPONSE}
                          </p>
                          <div className="modal-footer">
                            <Button
                              onClick={this.close}
                              type="button"
                              color="teal"
                            >
                              Close
                            </Button>
                          </div>
                        </div>
                      )
                    ) : (
                      <CardForInvoices
                        login={this.props.login}
                        onFetchCustomerCardDetail={
                          this.props.onFetchCustomerCardDetail
                        }
                        onFetchInvoices={this.props.onFetchInvoices}
                        onFetchStripeInvoices={this.props.onFetchStripeInvoices}
                        invoiceId={this.state.fields.invoiceId}
                        xchangeId={this.state.fields.xchangeId}
                        useExistingCard={this.useExistingCard}
                        payment={this.props.payment}
                        getNotifyFromCard={this.getNotifyFromCard}
                        onFetchConnectionList={this.props.onFetchConnectionList}
                      />
                    )}
                    {this.state.payOutstandingXchangeError &&
                      !this.state.payOutstandingXchangeSuccess && (
                        <div className="alert-danger" role="alert">
                          <strong>Error: </strong>
                          {this.state.payOutstandingXchangeError}
                        </div>
                      )}
                    {this.state.payOutstandingXchangeError &&
                      !this.state.payOutstandingXchangeSuccess && (
                        <div className="alert-danger" role="alert">
                          <strong>Error: </strong>
                          {this.state.payOutstandingXchangeError}
                        </div>
                      )}
                    {!this.state.paymentFromCard &&
                      this.props.payment.cards &&
                      this.props.payment.cards.length > 0 &&
                      !this.state.payOutstandingXchangeSuccess &&
                      !this.state.addNewCard && (
                        <div className="modal-footer">
                          <Button
                            anchor
                            href="#"
                            onClick={this.addNewCard}
                          >
                            Use new card
                          </Button>
                          <RVButton
                            onClick={this.payOutstandingXchange}
                            type="button"
                            color="teal"
                          >
                            make payment
                          </RVButton>
                        </div>
                      )}
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onFetchConnectionList: params => dispatch(fetchConnectionList(params)),
    dispatch
  };
};
const mapStateToProps = store => {
  return {
    login: store.login.user,
    payment: store.payment
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PaymentSubmitModal));
