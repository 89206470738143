import React, {useEffect, useState, memo} from 'react';

import Priorities from "./components/Priorities";
import Photos from "./components/Photos";
import Personality from "./components/Personality";

import {editProfileService} from "./utils/editProfileService";
import AboutEditGuest from "./components/AboutEditGuest";
import LifeStyleGuest from "./components/LifeStyleGuest";
import Interests from "./components/Interests";
import EditProfileStateProvider from "./context/EditProfileStateProvider";
import AjaxLoader from "./components/helpers/AjaxLoader";


const GuestEdit=({user_id, token, role_code})=>{

	const [data,setData]=useState(null);

	useEffect(  ()=>{
		async function fetchData() {
			if(data!==null)
				return;
			const remoteData=await editProfileService.GUEST.getDataFromServer(user_id,token)
			const res=await remoteData.data;
			setData(res);
		}
		fetchData();

	},[user_id, token, data])

	if(data===null)
		return <></>;
	const {about, lifestyle, priorities, personality, interests}=data;

	return (
		<EditProfileStateProvider data={data}>
			<div className="trx-abt-section container trx-com-wrap">
				<AjaxLoader/>
				<div className="tab-content">
					<div role="tabpanel" className="tab-pane active ">
						<div className="trx-abt-wrap">
							<div id="accordion1" role="tablist" aria-multiselectable="true">
								<AboutEditGuest  user={{user_id, token, role_code}}/>
								<LifeStyleGuest user={{user_id, token, role_code}}/>
								<Priorities user={{user_id, token, role_code}}/>
								<Personality user={{user_id, token, role_code}}/>
								<Interests user={{user_id, token, role_code}}/>
								<Photos user={{user_id, token}} profileType={role_code}/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</EditProfileStateProvider>
	);
}

export default memo(GuestEdit);
