import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import React from 'react';
import { Field } from 'formik';

export class TypeaheadField extends React.Component {
  handleChange = ({ form }) => selected => {
    const { name, onChange } = this.props;

    form.setFieldValue(name, selected[0]);

    if (onChange) {
      onChange(selected[0]);
    }
  };

  handleBlur = ({ form }) => event => {
    const { name, onChange } = this.props;

    form.setFieldValue(name, event.target.value);

    if (onChange) {
      onChange(event.target.value);
    }
  };

  render() {
    const {
      options,
      label,
      displayLabelIfEmpty = true,
      name,
      error,
      placeholder,
      onChange,
      typeaheadProps,
      value,
      ...rest
    } = this.props;

    return (
      <div className="form-group">
        {(label || displayLabelIfEmpty) && (
          <label
            htmlFor={name}
            className={`control-label ${label ? '' : 'empty-control-label'}`}
          >
            {label} &nbsp;
          </label>
        )}
        <Field name={name} {...rest}>
          {({ field, form }) => (
            <Typeahead
              value={value}
              onChange={this.handleChange({
                field,
                form
              })}
              onBlur={this.handleBlur({
                field,
                form
              })}
              options={options}
              placeholder={placeholder}
              selected={field.value ? [`${field.value}`] : []}
              {...typeaheadProps}
            />
          )}
        </Field>
        <div className="has-error">
          <span className="help-block">{error}</span>
        </div>
      </div>
    );
  }
}
