import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../components/layouts/Header';
import { login, logout } from '../actions/Login';
import { verifySMS, sendVerifySMS } from '../actions/SMSVerification';
import { removeRecentUser } from '../actions/Signup';
import Footer from '../../components/Footer';
import { VerificationForm } from '../components/verification-form';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';

class SignupThankYou extends Component {
  componentDidUpdate() {
    this.checkRecentUser();
    this.checkLoggedInUser();

    const {
      smsVerification: { isVerifiedSuccess },
      removeRecentUser
    } = this.props;

    if (isVerifiedSuccess) {
      setTimeout(() => {
        removeRecentUser();
      }, 5000);
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    this.checkRecentUser();
    this.checkLoggedInUser();
  }

  checkRecentUser() {
    const { history, signup } = this.props;

    if (!signup || !signup.recentUser) {
      history.push('/login');

      return;
    }
  }

  checkLoggedInUser() {
    const {
      history,
      login: { user }
    } = this.props;

    if (user) {
      history.push('/');

      return;
    }
  }

  handleVerifySMS = async data => {
    const {
      verifySMS,
      signup: { recentUser: { email } = {} },
      googleReCaptchaProps
    } = this.props;

    const googleRecaptcha = await googleReCaptchaProps.executeRecaptcha(
      'signup_thankyou'
    );

    verifySMS({ ...data, email, googleRecaptcha });
  };

  handleSendSMS = async () => {
    const {
      sendVerifySMS,
      signup: { recentUser: { email } = {} },
      googleReCaptchaProps
    } = this.props;

    const googleRecaptcha = await googleReCaptchaProps.executeRecaptcha(
      'signup_thankyou'
    );

    sendVerifySMS({ email, googleRecaptcha });
  };

  renderCommonMessage() {
    const {
      smsVerification: {
        isSuccess,
        verifyError,
        sendError,
        isVerifying,
        isSending,
        isVerifiedSuccess
      }
    } = this.props;

    return (
	<div className="row justify-content-center">
		<div className="col-md-6">
			{(isVerifying || isSending) && <div className="page-loading" />}
			<h1>Please verify your mobile phone number</h1>
			<hr className="horizontal-org"/>
			<div className="register-intro-text">
				<div>
					<p>
						We’ve just sent you a SMS to verify your mobile phone number so you
						can get started on your profile.
					</p>
					<p>
						Enter the code you received below. If you didn't get the SMS, you
						can request a new verification code.
					</p>

					<p>
						Keep your username and password safe, but if you ever forget it you
						can always reset it.
					</p>
					<p>
						Kind regards,
						<br />
						The Room Xchange Team
					</p>
					<p>
						PS: If you need any assistance, please{' '}
						<a style={{ color: '#EF7F49', cursor: 'pointer' }} target="_blank" href="/contact">contact us</a>
						&nbsp;or book a call{' '}
						<a href="https://calendly.com/customer_care_the_room_xchange" target="_blank" rel="noopener noreferrer">here</a>.
					</p>
				</div>
				{isVerifiedSuccess ? (
					<div className="verification__success">
						You have successfully verified your account. You will be redirected to the login page shortly.
						If it does not happen automatically, please click <a href="/app/login">here to login</a>.
					</div>
				) : (
					<VerificationForm
						onVerify={this.handleVerifySMS}
						onSend={this.handleSendSMS}
						isSuccess={isSuccess}
						isVerifying={isVerifying}
						error={verifyError || sendError}
					/>
				)}
			</div>
		</div>
	</div>
    );
  }

  render() {
    return (
      <div>
        <Header
          login={this.props.login}
          onLogout={this.props.onLogout}
          onLogin={this.props.onLogin}
          origin="app"
        />
        <div className="row justify-content-center">
            <div className="col-sm-6 py-3 mb-4">
              <div className="trx-com-wrap trx-signup-form register-page recom-mod header-mod">
                <div className="row">
                  <div className="col-md-12">{this.renderCommonMessage()}</div>
                </div>
              </div>
          </div>
        </div>
        <Footer user={this.props.login.user} />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onLogout: () => dispatch(logout()),
    onLogin: (postData, info) => dispatch(login(postData, info)),
    verifySMS: verifyData => dispatch(verifySMS(verifyData)),
    sendVerifySMS: data => dispatch(sendVerifySMS(data)),
    removeRecentUser: () => dispatch(removeRecentUser())
  };
};

const mapStateToProps = ({ login, signup, smsVerification }) => {
  return {
    login,
    signup,
    smsVerification
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withGoogleReCaptcha(SignupThankYou));
