import {RoleCode} from "../../../store/Users/constants";

export const cities = [
		"Melbourne",
		"Sydney",
		"Adelaide",
		"Perth",
		"Brisbane",
		"Hobart"
	];


	export const cityDistances = [
		"0-5 kilometres",
		"5-10 kilometres",
		"10-15 kilometres",
		"15+ kilometres"
	];
	export const homeTypes= [
		"House",
		"Apartment/Unit/Flat",
		"Townhouse Detached",
		"Townhouse Semidetached",
		"Farm House",
		"Beach House",
		"Boarding House"
	];
	export const languages= [
		"English",
		"Italian",
		"French",
		"Mandarin",
		"Spanish",
		"Hindi",
		"Arabic",
		"Portugese",
		"Russian",
		"Bengali",
		"Japanese",
		"Punjabi",
		"German",
		"Korean",
		"Javanese",
		"Tamil",
		"Turkish",
		"Urdu"
	];


	export const occupations= [
		"University student",
		"Leaving home",
		"Travelling",
		"Working full-time",
		"Working part-time",
		"Not working at the moment",
		'Entrepreneur',
		"Self-employed",
		"Other"
	];

	export const numberOfPeopleInHouse= [
		"1",
		"2",
		"3",
		"4",
		"5",
		"6"
	];


	export const exchangePeriods= [
		"1-3 months",
		"3-6 months",
		"6-12 months",
		"12+ months"
	];
	export const rentOffset= [
		'Rent offset',
		'Rent only'
	];

	export const yesOrNo=[
		'Yes',
		'No'
	];


	export const trueFalse=[
		true,
		false
	];

	export const foodPreferences=[
		{
			label: 'Meat eating',
			value: 'Meat eating'
		},
		{
			label: 'Vegetarian',
			value: 'Vegetarian'
		},
		{
			label: 'Pescetarian',
			value: 'Pescetarian'
		},
		{
			label: 'Vegan',
			value: 'Vegan'
		},
		{
			label: "I don't mind",
			value: "I don't mind"
		},
		{
			label: "Other",
			value: "other"
		}
	];

	export const helpTasks=[
		'Grocery shopping',
		'Cooking',
		'Food preparation',
		'General house cleaning',
		'Laundry',
		'Everyday help around the house',
		'Cleaning the car',
		'Basic gardening',
		'Mow the lawns',
		'General errands',
		'My to do list',
		'Pick-ups/drop-offs',
		'Childcare',
		'Company and support for elderly person',
		'Pet care',
		'Other'
	];

	export  const activities=[
		'Local markets',
		'Cooking',
		'Bush or beach walks',
		'Learning new skills',
		'Cultural learnings',
		'Seeing the local sights',
		'Exercise',

	];

	export const smokingChoices=[
		{
			label: 'Non-smoking',
			value: '0'
		},
		{
			label: 'Outside is okay',
			value: '1'
		}
	];

	export const zeroOneWithYesNo=[
		{
			label: 'Yes',
			value: '1'
		},
		{
			label: 'No',
			value: '0'
		}
	];


	export const petChoices=[
		{
			label: 'Yes',
			value: '1'
		},
		{
			label: 'No',
			value: '0'
		}
	];

	export const petTypes=[

		{
			label: 'Cat',
			value: 'Cat'
		},
		{
			label: 'Dog',
			value: 'Dog'
		},
		{
			label: 'Both',
			value: 'Both'
		},
		{
			label: 'Other',
			value: 'other'
		}
	];

	export const priorities=[
		{
			label: 'Compatibility',
			value: 'Compatibility'
		},
		{
			label: 'Initiative',
			value: 'Initiative'
		},
		{
			label: 'Acceptance',
			value: 'Acceptance of how we live'
		},
		{
			label: 'Patience',
			value: 'Patience in the process'
		},
		{
			label: 'Daily routine',
			value: 'Daily routine'
		},
		{
			label: 'Clean and organised',
			value: 'Clean and organised home'
		},
		{
			label: 'Easy to live with',
			value: 'Easy to live with'
		},
		{
			label: 'Understanding',
			value: 'Understanding of who we are'
		},
		{
			label: 'Comfortable home',
			value: 'Comfortable home'
		},
		{
			label: 'Respectful of personal space and boundaries',
			value: 'Respectful of personal space and boundaries'
		},
	]

	export const getPersonalityList=(role_code: string)=>{


		if(role_code===RoleCode.HOST)
			return [
				'Quiet, calm household',
				'Routine',
				'Plan ahead',
				'Structure and order',
				'Like things a certain way',
				'Conservative',
				'Very tidy',
				'Eat together',
				'Flexible',
				'Liberal',
				'Eat at random times',
				'Reasonably tidy',
				'Very busy',
				'Early birds',
				"Don't like talking about politics",
				'Work from home',
				'Fit and outdoorsy',
				'Watch online TV',
				'Play music in the house',
				'Spontaneous',
				'Night owls',
				'Not home much',
				'Love good political debate',
				'Social'
			]
		else
			return [
				'Quiet, calm housemate',
				'Routine',
				'Plan ahead',
				'Structure and order',
				'Like things a certain way',
				'Conservative',
				'Very tidy',
				'Eat together',
				'Flexible',
				'Liberal',
				'Eat at random times',
				'Reasonably tidy',
				'Very busy',
				'Early birds',
				"Dont like talking about politics",
				'Work from home',
				'Love the outdoors',
				'Watch online TV',
				'Play music in the house',
				'Spontaneous',
				'Night owls',
				'Not home much',
				'Love good political debate',
				'Social'
			]
	}



	export const guestRoomFeatures=[
		'TV',
		'Wifi',
		'Work desk',
		'Dressing table',
		'Wardrobe',
		'Lamp',
		'Linen & towels',
		'Bed'

	];

	export const bathroomTypes=[
		'Ensuite/private bathroom',
		'Shared bathroom'
	];

	export const homeFeatures=[
		'Swimming pool',
		'Vegetable garden',
		'Outdoor area',
		'Fireplace',
		'Outdoor balcony',
		'Other'
	];

	export const parkingOptions=[
		'Undercover',
		'Outdoor/street',
		'None'
	];

	export const publicTransport=[
		'Train',
		'Bus',
		'Tram'
	];

	export const furnitureList=[
		'Bed',
		'Wardrobe',
		'Dressing table',
		'Desk',
		'TV',
		'Coffee table',
		'Couch',
		'Dining table',
		'Fridge',
		'Washing machine'

	]

	export const additionalNeeds=[
		'Bed',
		'Dressing table',
		'Desk',
		'TV',
		'Wardrobe',
		'Wifi'
	];

	export const experiencesToEnjoy=[
		'Local market',
		'Cooking',
		'Bush or Beach walks',
		'Going to the movies',
		'Learning new skills',
		'Cultural learnings',
		'Seeing the local sites',
		'Exercise',
		'Other'
	];

	export const prioritiesGuest=[

		{
			label: 'Stability',
			value: 'Stability'
		},
		{
			label: 'Acceptance',
			value: 'Acceptance'
		},
		{
			label: 'Patience',
			value: 'Patience'
		},
		{
			label: 'Routine',
			value: 'Routine'
		},
		{
			label: 'Clean and organised home',
			value: 'Clean and organised home'
		},
		{
			label: 'Easy going people',
			value: 'Easy going people'
		},
		{
			label: 'Understanding',
			value: 'Understanding'
		},
		{
			label: 'Comfortable',
			value: 'Comfort'
		},
		{
			label: 'Friendly & respectful environment',
			value: 'Friendly & respectful environment'
		},
		{
			label: 'Supportive',
			value: 'Support'
		}
	]


	export const personalInterests=[
		{
			label: 'Languages',
			value: 'Teach a new language'
		},
		{
			label: 'Cooking',
			value: 'Teach a new cooking style'
		},
		{
			label: 'Exercise partner',
			value: 'Exercise partner'
		},
		{
			label: 'Art',
			value: 'Art'
		},
		{
			label: 'Music',
			value: 'Music'
		},
		{
			label: 'Sewing',
			value: 'Sewing'
		},
		{
			label: 'Healthy living ',
			value: 'Healthy living '
		},
		{
			label: 'Other',
			value: 'Other'
		},
	]

	export const rentOffsetTasks=[
		{
			label: 'Grocery shopping',
			value: 'Grocery shopping'
		},
		{
			label: 'Cooking',
			value: 'Cooking'
		},
		{
			label: 'Food preparation',
			value: 'Food preparation'
		},
		{
			label: 'General house cleaning',
			value: 'General house cleaning'
		},
		{
			label: 'Laundry',
			value: 'Laundry'
		},
		{
			label: 'Home organisation',
			value: 'Home organisation'
		},
		{
			label: 'Everyday help around the house',
			value: 'Everyday help around the house'
		},
		{
			label: 'Cleaning the car',
			value: 'Cleaning the car'
		},
		{
			label: 'Basic gardening',
			value: 'Basic gardening'
		},
		{
			label: 'Mow the lawns',
			value: 'Mow the lawns'
		},
		{
			label: 'General errands',
			value: 'General errands'
		},
		{
			label: 'To do list',
			value: 'To do list'
		},
		{
			label: 'Pick-ups/drop-offs',
			value: 'Pick-ups/drop-offs'
		},
		{
			label: 'Childcare',
			value: 'Childcare'
		},
		{
			label: 'Company and support for elderly person',
			value: 'Company and support for elderly person'
		},
		{
			label: 'Pet care',
			value: 'Pet care'
		},
		{
			label: 'Other',
			value: 'Other'
		},
	]
