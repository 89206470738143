import React,{ Component } from 'react';
import axios from 'axios';
import URL from '../../constants/URL';
import { connect } from 'react-redux';
import { ProcessModal } from '../../actions/Modal';
import { HIDE_MODAL }  from '../../actions/Modal';
import { Button } from '../../../components/common';

class CardDeleteModal extends Component {

  constructor(props){
    super(props);
    this.state={
      requesting:false,
      cardDeleteError:'',
    }
  }

  deleteCard = (e) => {
    e.preventDefault();
    this.setState({...this.setState,requesting:true})
    const request = axios({
      method:'delete',
      url:URL.DELETE_CARD_API(this.props.cardId),
      data:{
        token:this.props.login.token
      }
    });
    request.then((result)=> {
      if(result.data.status==="success"){
        this.setState({...this.setState,requesting:false})
        this.props.onFetchCustomerCardDetail(this.props.login.token)
        this.close();
      }else{
        this.setState({...this.setState,requesting:false,cardDeleteError:result.data.msg})
      }
    });
  }

  close = () => {
    const modalProps={showModal:false}
    this.props.onProcessModal(HIDE_MODAL,null,modalProps);
  }

  render(){
    return(
      <div>
        <div className="overlay modal-backdrop modal-dialog-centered">
          <div className="col-md-5 offset-md-4">
            {this.state.requesting && (
              <div className="page-loading"></div>
            )}
            <div className="modal-content padding-com">
              <div>
                <div className="modal-header">
                  <button type="button" className="close" onClick={this.close} data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true"><i className="fa fa-close"></i></span>
                  </button>
                </div>

                <div className="modal-body">
                  <div>
                    <h3>Are you sure you want to delete this card ?</h3>
                  </div>
                </div>
                <div className="modal-footer">
                  {this.state.cardDeleteError && (
                    <div className="alert-danger" role="alert">
                      <strong>Error: </strong>{this.state.cardDeleteError}
                      </div>
                    )}
                    <div>
                      <Button type="button" onClick={this.deleteCard}>Yes</Button>
                      <Button type="button" color="grey" onClick={this.close} data-dismiss="modal">cancel</Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  const mapDispatchToProps = (dispatch) => {
    return{
      onProcessModal:(actionType,modalType,modalProps)=>dispatch(ProcessModal(actionType,modalType,modalProps))
    }
  }
  const mapStateToProps = (store) => {
    return {
      login: store.login.user,
    }
  }
  export default connect(mapStateToProps,mapDispatchToProps)(CardDeleteModal)
