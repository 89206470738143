import React from 'react';

const signUpSteps = [
  {
    step: 1,
    label: 'General Information'
  },
  {
    step: 2,
    label: 'Personal Information'
  },
  {
    step: 3,
    label: 'Password & Terms and Conditions'
  }
];

export class SignUpStepNavigation extends React.Component {
  render() {
    const { activeStep } = this.props;

    return (
      <div className="signup__steps__navigation">
        {signUpSteps.map(step => (
          <div
            key={step.step}
            className={`single__step__navigation ${
              activeStep === step.step ? 'active' : ''
            }`}
          >
            <h3 className="single__step__title">{step.label}</h3>
          </div>
        ))}
      </div>
    );
  }
}
