import {Button} from "../../../../components/common";
import React, {FC, useRef, useState} from "react";
import {ProfileImage, RemoteUserType} from "../../types/editProfileTypes";
import GalleryThumb from "./GalleryThumb";
import {editProfileService} from "../../utils/editProfileService";
import { FileDrop } from 'react-file-drop';
import UploadModal from "./UploadModal";
import {includeTestId} from "../../utils/common";
import { RoleCode } from '../../../../store/Users/constants';

type PhotoGalleryProps={
	galleryImages: ProfileImage[],
	user: RemoteUserType,
	setGalleryImages: Function
	profileType: RoleCode,
}

const PhotoGallery: FC<PhotoGalleryProps>=({galleryImages, user,setGalleryImages, profileType})=>{

	const [images, setImages]=useState(galleryImages);
	const [isOpen,setIsOpen]=useState(false);

	const getImagesContent=(galleryImages: ProfileImage[])=>{
		return galleryImages.map((image)=>{
			return <GalleryThumb image={image} key={image.image_id} user={user} refreshImages={refreshImages}/>
		})
	}

	const refreshImages=async ()=>{
		const images=await editProfileService.COMMON.getGalleryImages(user);
		setImages(images);
		setGalleryImages(images);
	}

	const getButtonText=(images: ProfileImage[])=>{

		if(images.length<9)
			return `Upload ${10-images.length} additional images`;

		if(images.length===9)
			return 'Upload one more additional image';

		if(images.length===10)
			return 'Cannot upload more images';
	}



	console.log(user);

	return (
		<>
			<UploadModal isOpen={isOpen} setIsOpen={setIsOpen} user={user} refreshImages={refreshImages}/>
			<div className="pro-other" {...includeTestId('gallery-images-thumb-container')}>{getImagesContent(images)}</div>
			<div className="upload-photo">

					<div>
						Drop some files here!
					</div>
				{profileType===RoleCode.GUEST ?
					"Add gallery images that reflect your personality and lifestyle. Such as: places you've travelled to, meals that you like to cook, art that you paint or before and after photos of home organising etc."
				: "Include photos that show your household personality, photos of the bedroom and other features of the house. You can even add some photos highlighting your local area eg beach, cafes etc."}

				<div className="file-drop-target">
					<Button className={''} onClick={()=>{setIsOpen(true)}} disabled={images.length===10}>{getButtonText(images)}</Button><br />
				</div>
			</div>
		</>
	)
}

export default PhotoGallery;
