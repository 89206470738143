import React, {FC} from "react";
import {ProfileImage, RemoteUserType} from "../../types/editProfileTypes";
import axios from "axios";
import URL from "../../../constants/URL";
import {editProfileService} from "../../utils/editProfileService";
type GalleryThumbProps={
	image: ProfileImage,
	user: RemoteUserType,
	refreshImages: Function
}
const GalleryThumb: FC<GalleryThumbProps>=({image,user, refreshImages})=>{


	const deleteGalleryImage= (image_id: number)=>{
		let confirm = window.confirm('Do you want to delete this image?');
		if (confirm) {
			editProfileService.COMMON.deleteGalleryImage(image_id, user).then(result=>{
				refreshImages();
			})
		}
	}

	return (
		<span>
			<img src={image.image_path} className="img-thumbnail" style={{height: '150px', width: 'auto'}}/><i className="fa fa-close" onClick={()=>{deleteGalleryImage(image.image_id)}}></i>
		</span>
	);
}

export default GalleryThumb;
