import React from 'react';

interface Props {
	tooltip: string
}

/**
 * Render an info icon with a popup tooltip.
 * Typically used next to form labels or headings.
 */
export const InfoBubble: React.FC<Props> = props => {
	return (
		<span className="tooltip-info-bubble">
            <i className="fa fa-info-circle" data-toggle="tooltip" aria-hidden="true" title={props.tooltip}/>
        </span>
	);
};

export default InfoBubble;
