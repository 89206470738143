import React, {FC} from "react";

type RemoteError={
	submissionError: string | null
}
const RemoteUpdateError: FC<RemoteError>=({submissionError})=>{
	return (
		<div className={`row form-md ${submissionError===''?'d-none':'d-flex'} justify-content-center`}>
			<div className="alert alert-danger">
				{submissionError}
			</div>
		</div>
	)
};

export default RemoteUpdateError;
