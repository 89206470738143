import {
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  SIGNUP_FAILURE,
  SIGNUP_BEGIN
} from '../actions/Signup';
import * as LocalStorageHelper from '../utils/LocalStorage';

const initialState = {
  isProcessing: false,
  info: null,
  error: false,
  success: false,
  recentUser: LocalStorageHelper.load('recentUser')
};

// TODO: convert this one to redux-actions handleActions
export default function(state = initialState, action) {
  switch (action.type) {
    case SIGNUP_BEGIN:
      return { ...state, info: action.payload };
    case SIGNUP_REQUEST:
      return { ...state, isProcessing: true };
    case SIGNUP_SUCCESS:
      return {
        ...state,
        info: null,
        success: true,
        isProcessing: false,
        recentUser: action.payload
      };
    case SIGNUP_FAILURE:
      return { ...state, error: action.payload, isProcessing: false };
    case 'CLEAR_RECENT_USER':
      return { ...state, recentUser: undefined };
    default:
      return state;
  }
}
