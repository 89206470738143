import React, { FC } from 'react';
import { Redirect } from 'react-router-dom';
import includes from 'lodash/includes';
import get from 'lodash/get';
import SignupThankYou from '../containers/SignupThankYou';
import Signup from '../containers/Signup';

/**
 * @todo look into code & see, why it has not implemented through child routes of react-router.
 */
const RegisterRole: FC<any> = (props) => {
  const { match, location } = props;
  const role = get(match, 'params.role');

  if (
    includes(
      [
        'host/thankyou',
        'guest/thankyou',
        'household/thankyou',
        'housemate/thankyou'
      ],
      role
    )
  ) {
    return location.state && location.state.success ? (
      <SignupThankYou {...props} />
    ) : (
      <Redirect to="/register" />
    );
  }

  if (includes(['outside-au/thankyou', 'not-ready/thankyou'], role)) {
    if (location.state && location.state.success) {
      return (
        <Signup
          {...props}
          openProspect={match.params.role}
          prospectSuccess={true}
        />
      );
    }
  }

  if (includes(['outside-au', 'not-ready'], role)) {
    return (
      <Signup
        {...props}
        openProspect={match.params.role}
        prospectSuccess={false}
      />
    );
  }
  return <Signup {...props} />;
};

export default RegisterRole;
