import React, {FC, useRef} from 'react';

type UploaderProps={
	setImage: Function,
	setIsOpen: Function
}

const UploaderContent: FC<UploaderProps>=({setImage,setIsOpen})=>{

	const inputFileRef = useRef(null);
	const onImageSelect=(e: any)=>{
		let file=e.target.files[0];
		const reader = new FileReader()
		reader.addEventListener('load', () =>
		{
			setImage(reader.result);
		})
		reader.readAsDataURL(file)

	}

	const onBtnClick=(ref: any)=>{
		ref.current.click();

	}

	return (
		<div>
			<input type="file" onChange={onImageSelect}  ref={inputFileRef} className={'d-none'}  accept="image/*"/>
			<div className="modal-header">
				<button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>{setIsOpen(false)}}><span aria-hidden="true"><i className="fa fa-close"></i></span></button>
			</div>
			<div className="modal-body">
				<div id="modalinnerbody"  style={{cursor: 'pointer'}} onClick={()=>{onBtnClick(inputFileRef)}}><h3><i className="fa fa-plus"></i>Upload your profile photo</h3>
				</div>
			</div>
			<div className="modal-footer">
				<button className="button btn-teal mx-auto " type="button" onClick={()=>{onBtnClick(inputFileRef)}}>Upload your profile photo
				</button>
			</div>
		</div>
	)

}


export default UploaderContent
