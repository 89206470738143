import { createUseStyles } from 'react-jss';

export const usePublicSearchPageStyles = createUseStyles({
  actionSuccess: {
    backgroundColor: '#ef7f49',
    color: '#fff',
    borderRadius: 30,
    fontSize: 14,
    fontWeight: 600,
    padding: '8px 16px',
    border: 0,
    fontFamily: 'Montserrat, sans-serif',
    cursor: 'pointer',
    '&:hover': {
      color: '#fff',
    },
  },
});
