import React, {FC, useContext, useEffect, useState} from 'react';
import InfoBubble from "../../../components/common/InfoBubble";
import { useFormik } from 'formik';
import '../styles/profile_edit.scss';
import {
	getAccordionDefaultConfig,
	hasError,
	manageAccordion,
	postActionSuccess,
	setAjaxLoader,
	showAjaxError, includeTestId
} from "../utils/common";
import {editProfileService} from "../utils/editProfileService";
import GuestLifeStyleSchema from "../schemas/GuestLifeStlyeSchema";
import {additionalNeeds, experiencesToEnjoy, foodPreferences, furnitureList, zeroOneWithYesNo} from "../utils/formData";
import FormError from "./helpers/FormError";
import { UserProps} from "../types/editProfileTypes";
import RemoteUpdateError from "./helpers/RemoteUpdateError";
import FormSubmitButtons from "./helpers/FormSubmitButtons";
import EditProfileContext from "../context/EditProfileContext";


const LifeStyleGuest: FC<UserProps>=({user})=>{
	const {accordionConfig, update, data}=useContext(EditProfileContext);
	const [submissionError, setSubmissionError] = useState('');
	let lifestyle=editProfileService.GUEST.getLifeStyleSection({...data});
	const [buttonType, setButtonType]=useState('');


	const {values, errors, touched, handleBlur, handleChange, handleSubmit, isValid, validateForm} = useFormik({
		initialValues: lifestyle as any,
		validationSchema: GuestLifeStyleSchema,
		// validateOnMount: true,
		onSubmit: (values) => {
			const params={...values};
			setAjaxLoader(true,update);
			editProfileService.GUEST.updateLifestyleSection(params,user).then(result=>{
				const {data}=result;
				if(data.status==='success')
					postActionSuccess(buttonType, update, 'prioritiesCollapsed', user.user_id, user.token);
				else if(data.msg!==undefined)
					setSubmissionError(data.msg);
				else
					setSubmissionError('Some error occurred. Please try again later');

				setAjaxLoader(false,update);
			}).catch(res=>{
				showAjaxError(res,setSubmissionError,update);
			});
		}
	});

	useEffect(() => {
		(() => validateForm())();
	}, []);

	const errorMessages = () => {
		if (submissionError) {
			return submissionError
		} else if (!isValid) {
			return "There are some issues you need to resolve before saving"
		} else {
			return '';
		}
	}

	return (
		<div className="card">
			<div className="card-header" role="tab" id="headingLifestyle">
					<a className={accordionConfig.lifestyleCollapsed ? 'collapsed' : ''} href="#abt-lifestyle" aria-expanded={!accordionConfig.lifestyleCollapsed} onClick={()=>{ manageAccordion({...getAccordionDefaultConfig(), lifestyleCollapsed: !accordionConfig.lifestyleCollapsed}, update)}}>
					<h2 className="mb-0">
						<div className="card-header__left" {...includeTestId('lifestyle-heading-guest')}>
							<i className={isValid ? 'fa fa-check icon-rounded' : 'fa fa-close icon-rounded'}/>
							Lifestyle and practical needs
							<InfoBubble tooltip="This section is about how you like to live in your home, preferences you might have, furniture you have or need and the experiences you’d like to enjoy with your household." />
						</div>
					</h2>
				</a>
			</div>

			<div id="abt-lifestyle" className={`collapse ${accordionConfig.lifestyleCollapsed?'':'show'} `} role="tabpanel" aria-labelledby="headingLifestyle" data-parent="#profileAccordion">
				<form className="form-horizontal form-signup" role="form" onSubmit={handleSubmit}>
					<div className="card-body">
						<div className="row form-mod">
							<div className="col-lg-3 col-md-3 col-sm-3 col-12" {...includeTestId('guest-food-preference')}>
								<label htmlFor="food_preference" className="control-label">
									Food preferences
								</label>
								{foodPreferences.map((item, key) => (
									<div  key={`food-preference-item-${item.value}`}>
										<div className="form-check">
											<input className="form-check-input" name="food_preference" type="radio" value={item.value} id={`food-preference-${item.value}`} onChange={handleChange} onBlur={handleBlur} checked={values.food_preference===item.value}/>
											<label className="form-check-label" htmlFor={`food-preference-${item.value}`}>{item.label}</label>
										</div>
										{values.food_preference==='other' && item.value==='other' && <>
											<div className="form-group">
												<input {...includeTestId('other-food-preference')} className={`form-control ${hasError(errors, touched,'food_preference_other')?'error-field':''}`} name="food_preference_other" type="text" placeholder="Please enter other food preference" onChange={handleChange} onBlur={handleBlur} value={values.food_preference_other}/>
												<FormError errors={errors} fieldName={'food_preference_other'} touched={touched} containerClass={'mt-1'}/>
											</div>
										</>}
									</div>
								))}
							</div>

							<div className="col-lg-3 col-md-3 col-sm-3 col-12" {...includeTestId('smoking-preference')}>
								<label htmlFor="smoking_preference" className="control-label">Are you a smoker?</label>
								{zeroOneWithYesNo.map((item, key) => (
									<div className="form-check" key={`smoking-preferences-${item.value}`}>
										<input name="smoking_preference" className="form-check-input" id={`smoking-preferences--${item.value}`} type="radio" checked={item.value===values.smoking_preference} onChange={handleChange} value={item.value}/>
										<label className="form-check-label" htmlFor={`smoking-preferences--${item.value}`}>
											{item.label}
										</label>
									</div>
								))}
								<FormError errors={errors} fieldName={'smoking_preference'} touched={touched} containerClass={'mt-1'}/>
							</div>


							<div className="col-lg-3 col-md-3 col-sm-3 col-12">
								<div className="form-group" {...includeTestId('lgbtqia-friendly')}>
									<label htmlFor="LGBTQI friendly" className="control-label">
										Do you require a LGBTQIA+ friendly household?
										<InfoBubble tooltip="We want to help you find your people and feel at home. Therefore, if you are not comfortable living with people in the LGBTQIA+ community OR if it's important that you do live in a home that is supportive of LGBTQIA+ people, then please say so. Your preference will be visible in your profile. (LGBTQIA+ stands for people who identify as Lesbian, Gay, Bisexual, Transgender, Queer, Intersex and/or Asexual)." />
									</label>
									{zeroOneWithYesNo.map((item, key) => (
										<div className="form-check" key={`lgbtqia_friendly-${item.value}`}>
											<input name="lgbtqi_friendly" className="form-check-input" id={`lgbtqia_friendly-${item.value}`} type="radio" checked={item.value===values.lgbtqi_friendly} onChange={handleChange} value={item.value}/>
											<label className="form-check-label" htmlFor={`lgbtqi_friendly-${item.value}`}>
												{item.label}
											</label>
										</div>
									))}
									<FormError errors={errors} fieldName={'lgbtqi_friendly'} touched={touched} containerClass={'mt-1'}/>
								</div>
							</div>

							<div className="col-lg-3 col-md-3 col-sm-3 col-12">
								<div className="form-group" {...includeTestId('pets-comfortable')}>
									<label htmlFor="pet" className="control-label">Are you comfortable living with pets?</label>
									{zeroOneWithYesNo.map((item, key) => (
										<div className="form-check" key={`pet-${item.value}`}>
											<input name="pet" className="form-check-input" id={`pet-${item.value}`} type="radio" checked={item.value===values.pet} onChange={handleChange} value={item.value}/>
											<label className="form-check-label" htmlFor={`pet-${item.value}`}>
												{item.label}
											</label>
										</div>
									))}
									<FormError errors={errors} fieldName={'pet'} touched={touched} containerClass={'mt-1'}/>
								</div>
							</div>

						</div>

						<div className="row form-mod">

							<div className="col-lg-3 col-md-3 col-sm-3 col-12">
								<div className="form-group" {...includeTestId('bathroom-share')}>
									<label htmlFor="LGBTQI friendly" className="control-label">
										Are you happy to share a bathroom?
										<InfoBubble tooltip="If you are happy to share a bathroom it will increase your chances of finding a household. Some households have an ensuite but not all do." />
									</label>
									{zeroOneWithYesNo.map((item, key) => (
										<div className="form-check" key={`bathroom_share-input-${item.value}`}>
											<input name="bathroom_share" className="form-check-input" id={`bathroom_share-key-${item.value}`} type="radio" checked={item.value===values.bathroom_share} onChange={handleChange} value={item.value}/>
											<label className="form-check-label" htmlFor={`bathroom_share-key-${item.value}`}>
												{item.label}
											</label>
										</div>
									))}
									<FormError errors={errors} fieldName={'bathroom_share'} touched={touched} containerClass={'mt-1'}/>
								</div>
							</div>


							<div className="col-lg-3 col-md-3 col-sm-3 col-12">
								<div className="form-group" {...includeTestId('car-ownership')}>
									<label htmlFor="LGBTQI friendly" className="control-label">
										Do you have your own car?
									</label>
									{zeroOneWithYesNo.map((item, key) => (
										<div className="form-check" key={`car-own-${item.value}`}>
											<input name="car" className="form-check-input" id={`car-${item.value}`} type="radio" checked={item.value===values.car} onChange={handleChange} value={item.value}/>
											<label className="form-check-label" htmlFor={`car-${item.value}`}>
												{item.label}
											</label>
										</div>
									))}
									<FormError errors={errors} fieldName={'car'} touched={touched} containerClass={'mt-1'}/>
								</div>
							</div>



							<div className="col-lg-3 col-md-3 col-sm-3 col-12">
								<div className="form-group" {...includeTestId('parking-required')}>
									<label htmlFor="carpark_required" className="control-label">
										Parking required?
									</label>
									{zeroOneWithYesNo.map((item, key) => (
										<div className="form-check" key={`carpark_required-${item.value}`}>
											<input name="carpark_required" className="form-check-input" id={`carpark_required-${item.value}`} type="radio" checked={item.value===values.carpark_required} onChange={handleChange} value={item.value}/>
											<label className="form-check-label" htmlFor={`carpark_required-${item.value}`}>
												{item.label}
											</label>
										</div>
									))}
									<FormError errors={errors} fieldName={'carpark_required'} touched={touched} containerClass={'mt-1'}/>
								</div>
							</div>

							<div className="col-lg-3 col-md-3 col-sm-3 col-12">
								<div className="form-group" {...includeTestId('public-transport')}>
									<label htmlFor="public transport" className="control-label">
										Public transport required?
									</label>
									{zeroOneWithYesNo.map((item, key) => (
										<div className="form-check" key={`public_transport_required-${item.value}`}>
											<input name="public_transport_required" className="form-check-input" id={`public_transport_required-${item.value}`} type="radio" checked={item.value===values.public_transport_required} onChange={handleChange} value={item.value}/>
											<label className="form-check-label" htmlFor={`public_transport_required-${item.value}`}>
												{item.label}
											</label>
										</div>
									))}
									<FormError errors={errors} fieldName={'public_transport_required'} touched={touched} containerClass={'mt-1'}/>
								</div>
							</div>
						</div>

						<div className="row form-mod">
							<div className="col-sm-8" {...includeTestId('furniture-ownership')}>
								<label htmlFor="priorities" className="control-label">
									Furniture I have
								</label>
								<FormError errors={errors} fieldName={'furniture_have'} touched={null}/>

								<div className="row">
									{furnitureList.map((item, key) => (
										<div className="col-lg-3 col-md-3 col-sm-3 col-3" key={`furniture_have-${item}`}>
											<div className="form-check" >
												<input className="form-check-input" name="furniture_have" type="checkbox" value={item} checked={values.furniture_have.includes(item)}  onChange={handleChange}/>
												<label className="form-check-label" htmlFor={`furniture_have-${item}`}>{item}</label>
											</div>
										</div>
									))}

								</div>

							</div>
							<div className="col-sm-4" {...includeTestId('items-needed')}>
								<label htmlFor="priorities" className="control-label">
									Items Needed
								</label>
								<FormError errors={errors} fieldName={'additional_needs'} touched={null}/>

								{additionalNeeds.map((item, key) => (
										<div className="form-check" key={`additional_needs-${item}`}>
											<input className="form-check-input" name="additional_needs" type="checkbox" value={item} checked={values.additional_needs.includes(item)}  onChange={handleChange}/>
											<label className="form-check-label" htmlFor={`additional_needs-${item}`}>{item}</label>
										</div>
								))}
							</div>
						</div>


						<div className="row form-mod">
							<div className="col-sm-8" {...includeTestId('experience-to-enjoy')}>
								<label htmlFor="priorities" className="control-label">
									What experiences would you like to enjoy
									<InfoBubble tooltip="This will help your potential household see what interests you have in common." />

								</label>

								<FormError errors={errors} fieldName={'experiences_to_enjoy'} touched={null} containerClass={'mt-1 mb-1'}/>

								<div className="row">
									{experiencesToEnjoy.map((item, key) => (
										<div className="col-lg-4 col-md-4 col-sm-4 col-4" key={`experiences_to_enjoy-${item}`}>
											<div className="form-check" >
												<input className="form-check-input" name="experiences_to_enjoy" type="checkbox" value={item} checked={values.experiences_to_enjoy.includes(item)}  onChange={handleChange}/>
												<label className="form-check-label" htmlFor={`experiences_to_enjoy-${item}`}>{item}</label>
											</div>


											{item==='Other' && values.experiences_to_enjoy.includes('Other')  &&

												<div className="form-group">
													<input
														className={`form-control ${hasError(errors, touched,'experiences_to_enjoy_other')?'error-field':''}`}
														name="experiences_to_enjoy_other"
														type="text"
														placeholder="Please specify other experience"
														onChange={handleChange}
														onBlur={handleBlur}
														value={values.experiences_to_enjoy_other}
													/>
													<FormError errors={errors} fieldName={'experiences_to_enjoy_other'} touched={touched} containerClass={'mt-1'}/>
												</div>
											}
										</div>
									))}
								</div>

							</div>
						</div>
						<RemoteUpdateError submissionError={errorMessages()}/>

					</div>
					<FormSubmitButtons setButtonType={setButtonType} isValid={isValid}/>
				</form>
			</div>
		</div>
	);
}

export default LifeStyleGuest;
