import React, { useState } from 'react';
import get from 'lodash/get';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { searchUsers } from '../../actions/Search';
import { useSearchPageStyles } from './styles';
import { useHistory } from 'react-router-dom';
import { SearchContainer } from '../../components/search-container';

export const SearchPage = () => {
  const [isLoading, handleLoading] = useState(false);

  const { profile } = useSelector(
    ({ profile: { profile } }) => ({
      profile
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const handleSearch = searchParams => {
    handleLoading(true);
    dispatch(searchUsers(searchParams)).then(() => {
      handleLoading(false);
    }).catch(() => {
      handleLoading(false);
    });
  };

  const { actionDanger, actionSuccess } = useSearchPageStyles();

  const history = useHistory();

  const handleVerifyIdClick = React.useCallback(() => {
    history.push('/dashboard/badges');
  }, []);

  const handleCompleteYourProfile = React.useCallback(() => {
    history.push('/about/edit');
  }, []);

  const handleViewProfile = React.useCallback(({ user_type, id }) => {
    history.push(`/browse/${user_type.toLowerCase()}s/${id}`);
  }, []);

  const digital_id_verified = get(profile, 'digital_id_verified', '');
  const profileCompletion = get(profile, 'profileCompletion', ''); 

  const userCardAction = React.useMemo(() => {
    if (!digital_id_verified) {
      return {
        label: 'Verify Id',
        onClick: handleVerifyIdClick,
        className: actionDanger
      };
    }

    if (profileCompletion < 100) {
      return {
        label: 'Complete profile',
        onClick: handleCompleteYourProfile,
        className: actionDanger
      };
    }

    return {
      label: 'View profile',
      onClick: handleViewProfile,
      className: actionSuccess
    };
  }, [digital_id_verified, profileCompletion]);

  return (
      <SearchContainer
        isLoading={isLoading}
        profile={profile}
        onSearch={handleSearch}
        cardAction={userCardAction}
      />
  );
};
