import React, { FC, memo } from 'react';
// import InstaFeed from '../../../../components/InstaFeeds';
import Subscribe from '../../js/components/Subscribe';
import { FooterLinks } from './FooterLink';
import { FooterButtons } from './FooterButton';
import { SocialIcons } from './SocialIcons';
import { Copyright } from './Copyright';
import './styles.scss';

interface UserProps {
	email: string;
	user_id: number;
	role_code: string;
	name: string;
	preferred_name: string;
	token: string;
}

interface Props {
	user: UserProps;
}

const Footer: FC<Props> = ({ user }) => {
	return (
		<footer className="footer-wrapper">
			<div className="inner-footer">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<FooterLinks isLoggedIn={!!user} />
							{!user && <FooterButtons />}
							<SocialIcons />
						</div>
					</div>
					<hr className="divide-line-light" />
					<Subscribe />
				</div>
			</div>
			<Copyright />
		</footer>
	);
};

export default memo(Footer);
