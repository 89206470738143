import React, { useEffect } from 'react';
import map from 'lodash/map';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import PermissionRoute from './PermissionRoute';
import Login from '../containers/Login';
import Signup from '../containers/Signup';
import LostPassword from '../containers/LostPassword';
import VerifyPhoneNumber from '../containers/VerifyPhoneNumber';
import Dashboard from '../containers/Dashboard';
import Badges from '../containers/Badges';
import Account from '../containers/Account';
import About from '../containers/About';
import { SearchPage } from '../pages/SearchPage';
import ProfileView from '../containers/ProfileView';
import Message from '../../containers/Messages';
import Xchange from '../containers/Xchange';
import BrowseDetail from '../containers/BrowseDetail';
import {PublicSearchPage} from 'js/pages/PublicSearchPage/PublicSearchPage'
import DashboardLayout from '../../layouts/Dashboard/Layout';
import { DashboardContainerHOC } from '../components/layouts/dashboard-layout';
import RegisterRole from './RegisterRole'
import UserContextRoute from "./UserContextRoute";

const RoutesHOC = (routes: any, DEFAULT_PATH: any) => () => (
    <Switch>
      {map(routes, (route, index) => {
        if (route.isPrivate) {
          return (
            <PermissionRoute
              key={index}
              defaultPath={DEFAULT_PATH}
              {...route}
            />
          );
        }
        else if (route.needsContext) {
          return (
            <UserContextRoute
              key={index}
              defaultPath={DEFAULT_PATH}
              {...route}
            />
          );
        }
        return <Route key={index} path={route.path} component={route.component} />;
      })}
      <Redirect to='/login' />
    </Switch>
);

const ScrollPageToTop = (Content:any) => {
  const Component = (props:any) => {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    return (<Content {...props} />);
  }
  return Component;
}

export const dashboardRoutes = {
  XCHANGE: {
    path: '/xchange',
    component: Xchange,
    isPrivate: true,
    componentType: 'XCHANGES',
  },
  Message: {
    path: '/message/:id*',
    component: Message,
    isPrivate: true,
    componentType: 'MESSAGES'
  },
  BROWSE_USER_DETAIL: {
    path: '/browse/:userType/:id',
    component: BrowseDetail,
    isPrivate: true,
    componentType: 'BROWSE_USER_DETAIL',
  },
  ABOUT: {
    path: '/about/edit',
    component: About,
    isPrivate: true,
    componentType: 'PROFILE_EDIT',
    exact: true
  },
  PROFILE_VIEW: {
    path: '/about',
    component: ProfileView,
    isPrivate: true,
    componentType: 'PROFILE_VIEW',
    exact: true
  },
  SEARCH_PAGE: {
    path: '/search',
    component: SearchPage,
    isPrivate: false,
    componentType: 'SEARCH',
    exact: true
  },
  Account: {
    path: '/account',
    component: Account,
    isPrivate: true,
    componentType: 'ACCOUNT_SETTING',
    exact: true
  },
  BADGES: {
    path: '/dashboard/badges',
    component: Badges,
    isPrivate: true,
    componentType: 'BADGES',
    exact: true
  },
  DASHBOARD: {
    path: '/',
    component: Dashboard,
    isPrivate: true,
    componentType: 'DASHBOARD'
  }
};

export const routes = {
  LOGIN: {
    path: '/login',
    component: Login,
    isPrivate: false,
  },
  REGISTER_ROLE: {
    path: '/register/:role*',
    component: RegisterRole,
    isPrivate: false,
  },
  REGISTER: {
    path: '/register',
    component: Signup,
    isPrivate: false,
  },
  SEARCH: {
    path: '/profile_search',
    component: PublicSearchPage,
    isPrivate: false,
  },
  BROWSE_GUEST_DETAIL: {
    path: "/browse/:userType/:id",
    component: BrowseDetail,
    isPrivate: false,
    needsContext: true
  },
   BROWSE_HOST_DETAIL: {
	 path: "/browse/:userType/:id",
	 component: BrowseDetail,
	 isPrivate: false,
     needsContext: true
  },
  LOST_PASSWORD: {
    path: '/reset-password/:token*',
    component: LostPassword,
    isPrivate: false,
  },
  VERIFY_PHONE_NUMBER: {
    path: '/verify-phone-number',
    component: VerifyPhoneNumber,
    isPrivate: false,
  },
  Dashboard: {
    path: '/',
    component: DashboardLayout(DashboardContainerHOC(RoutesHOC(dashboardRoutes, "/"))),
    isPrivate: true,
    componentType: 'DASHBOARD'
  }
};

const AppRoutes = ScrollPageToTop(RoutesHOC(routes, '/login'));
export default AppRoutes;

export const DEFAULT_PATH = '/app/login';
export const USER_LANDING_PAGE = '/';
