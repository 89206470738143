export enum RoleCode {
  HOST = 'HOST',
  GUEST = 'GUEST'
}

export enum RoleLabel {
	HOST = 'Household',
	GUEST = 'Housemate',
	HOSTS = 'Households',
	GUESTS = 'Housemates'
}

export enum VerificationBadge {
	DIGITAL_ID = 'DIGITAL_ID',
	POLICE_CHECK = 'POLICE_CHECK'
}

export interface UserSelf {
	id: number,
	attributes: {
		first_name: string,
		last_name: string,
		preferred_name: string,
		email: string,
		profile_picture: string,
		role_code: RoleCode,
		location: UserLocation,
		ready_date: Date,
		xchange_period: string,
		id_verified: boolean,
		profile_completed: boolean,
		badges: VerificationBadge[]
	}
}

export interface UserLocation {
	suburb: string,
	postcode: string,
	state: string,
	city: string
}

export interface User {
	id: number,
	attributes: {
		display_name: string,
		profile_picture: string,
		user_type: RoleCode,
		bio: string,
		ready_date: Date,
		xchange_period: string,
		location: UserLocation
	}
}

/**
 * type for the data of new User Profile api
 */
export interface IUserProfile {
	id: string;
	type: string;
	first_name: string;
	last_name: string;
	preferred_name: string;
	email: string;
	profile_picture: string;
	user_type: RoleCode;
	bio: string;
	ready_date: string;
	xchange_period: string;
	location: UserLocation;
	badges: VerificationBadge[];
}
