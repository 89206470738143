import React, { CSSProperties } from 'react';

interface Props {
	/** The URL for the profile image. Can be any filetype passable to an img tag */
	profile_image_url: string;
	/** The users name to display under the image. */
	display_name?: string;
	/** Image size in px, defaults to 100px */
	diameter_in_px?: number;
}

export const UserIcon: React.FC<Props> = props => {
	const alt_text = `Profile photo of ${props.display_name || 'user'}`;

	const style: CSSProperties = {
		width: `${props.diameter_in_px || 100}px`,
		height: `${props.diameter_in_px || 100}px`
	};

	return (
		<React.Fragment>
			<div className="user-icon-profile-image">
				<img src={props.profile_image_url} alt={alt_text} style={style} />
			</div>
			{props.display_name ? (
				<span className="user-icon-user-name">{props.display_name}</span>
			) : null}
		</React.Fragment>
	);
};
