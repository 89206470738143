import get from 'lodash/get';
import URL from '../constants/URL';
import { axiosInstances } from './Axios';

export const searchGuests = ({
  page,
  perPage,
  suburb,
  state,
  post_code,
  gender,
  age_range,
  rent_period,
  userId,
  rent_offset, rent_budget,
}) =>
  axiosInstances
    .secured()
    .get(URL.BROWSE_GUEST_API, {
      params: {
        page: page+1,
        state,
        suburb: suburb || undefined,
        perPage: perPage || undefined,
        postcode: post_code || undefined,
        gender: gender || undefined,
        age_range: age_range || undefined,
        rent_period: rent_period || undefined,
        userId: userId || undefined,
        rent_offset: get({"0": 'false', "1": "true"}, rent_offset, undefined),
        rent_budget: rent_budget || undefined,
      }
    })
    .then(result => result.data);

export const searchHosts = ({
  page,
  perPage,
  suburb,
  state,
  post_code,
  gender,
  age_range,
  rent_period,
  userId,
  rent_offset, rent_budget,
}) =>
  axiosInstances
    .secured()
    .get(URL.BROWSE_HOST_API, {
      params: {
        page: page+1,
        state,
        suburb: suburb || undefined,
        perPage: perPage || undefined,
        postcode: post_code || undefined,
        gender: gender || undefined,
        age_range: age_range || undefined,
        rent_period: rent_period || undefined,
        userId: userId || undefined,
        rent_offset: get({"0": 'false', "1": "true"}, rent_offset, undefined),
        rent_budget: rent_budget || undefined,
      }
    })
    .then(result => result.data);

export const searchPublicUsers = ({
  page,
  role,
  perPage,
  suburb,
  state,
  post_code,
  gender,
  age_range,
  rent_period,
  userId,
  rent_offset, rent_budget,
}) => {
  return axiosInstances
    .public()
    .get(URL.BROWSE_PUBLIC_USER_API, {
      params: {
        page: page+1,
        user_type: role.toLowerCase(),
        state,
        suburb: suburb || undefined,
        perPage: perPage || undefined,
        postcode: post_code || undefined,
        gender: gender || undefined,
        age_range: age_range || undefined,
        rent_period: rent_period || undefined,
        rent_offset: get({"0": 'false', "1": "true"}, rent_offset, undefined),
        rent_budget: rent_budget || undefined,
      }
    })
    .then(result => result.data);
  }
