import React, { FC, memo } from 'react';
import PhotoGallery from '../../components/UserProfile/PhotoGallery';
import { UserIcon } from '../common';

interface CoverPhotoProps {
	coverPhoto: string;
	userPhoto: any;
	galleries: any;
}

const CoverPhoto: FC<CoverPhotoProps> = ({
	coverPhoto,
	userPhoto,
	galleries
}) => {
	const style = coverPhoto ? { backgroundImage: 'url(' + coverPhoto + ')'} : {};
	return (
		<>
			<div
				className="img-view-wrap user-cover-panel"
				style={style}
			>
				{galleries && <PhotoGallery gallery={galleries} />}
				<div className="pro-img col-md-3 col-5">
					<UserIcon profile_image_url={userPhoto} />
					{/*<img src={userPhoto} alt="profile" className="img-fluid" />*/}
				</div>
			</div>
		</>
	);
};

export default memo(CoverPhoto);
