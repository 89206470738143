import React, { memo } from 'react';
import { defaultProfileValue } from './utils'

interface DashboardInfoValueProps {
	label: string;
	value: string;
	className?: string | number;
}

function DashboardInfoValue({
	label,
	value,
	className = 'dashboard-info__value'
}: DashboardInfoValueProps) {
	return (
		<>
			<dt className={`${className}`}>{label}</dt>
			<dd>{value || defaultProfileValue}</dd>
		</>
	);
}

export default memo(DashboardInfoValue);
