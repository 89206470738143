import React, {memo} from 'react';
import get from 'lodash/get';
import {connect} from 'react-redux';
import {Route} from 'react-router-dom';
import {checkPermission} from '../constants/Permissions';
import {initUserSessionAction} from '../actions/Login';
import {DEFAULT_PATH} from '../routes';
import './styles.scss';

const UserContextRoute = (
	{
		component: Component,
		componentType,
		user,
		...rest
	}
) => {
	if (!get(user, 'id', false)) {
		rest.initUserSession(true).catch((e) => {
			window.location.href = DEFAULT_PATH;
		});
	}

	return (
		<Route
			{...rest}
			render={props =>
				<div className="content-section"><Component {...props} /></div>
			}
		/>
	);
}

const mapStateToProps = (state) => {
	return {
		user: state.profile.data,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		initUserSession: (optional) => dispatch(initUserSessionAction(optional)),
	}
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(memo(UserContextRoute));
