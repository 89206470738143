import * as Yup from 'yup';

const GuestLifeStlyeSchema=Yup.object().shape({

	food_preference: Yup.string().required('Please select your food preference').max(80),
	food_preference_other: Yup
		.string()
		.when("food_preference", {
			is: 'other',
			then: Yup.string().required("Please specify the food preference")
		}),
	smoking_preference: Yup.string().required('Please select your smoking preference'),
	lgbtqi_friendly: Yup.string().required('Please select if you are a LGBTQIA+ friendly household'),
	pet: Yup.string().required('Please select if you are comfortable living with pet'),
	bathroom_share: Yup.string().required('Please select if you are happy to share bathroom'),
	car: Yup.string().required('Please select if own a car'),
	carpark_required: Yup.string().required('Please select if you need car parking'),
	public_transport_required: Yup.string().required('Please select if you require public transport'),
	furniture_have: Yup.array(),
	additional_needs: Yup.array().min(1, "Please select at least one item that you need"),
	experiences_to_enjoy: Yup.array().min(1, 'Please select at one experience you would like to enjoy with your household'),
	experiences_to_enjoy_other: Yup.string().when('experiences_to_enjoy',{
		is: (experiences_to_enjoy)=>{
			return experiences_to_enjoy.includes('Other')
		},
		then: Yup.string().required('Please enter the other experience you would like to enjoy')
	}).nullable(true),
});

export default GuestLifeStlyeSchema;
