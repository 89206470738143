import React, { FC, memo } from 'react';
import replace from 'lodash/replace';
import { RoleCode } from 'store/Users/constants';
import { Pills } from './Priorities';
import DashboardInfoValue from './DashboardInfoValue';
import {
	getSmokingLabel,
	getHostPetsContent,
	getHomeFeature,
	getGuestRoomFeatures,
	getLanguageSpokenValue,
	renderRadioValue,
	getAdditionalNeeds,
	getTopPriorities
} from './utils';

interface PublicProfileProps {
	preference: any;
	role: string;
	exchange_priorities: any;
}

const Section = ({ children }: any) => {
	return (
		<div className="box box-bottom-margin">
			<div className="padding-com clearfix">
				<div className="row">{children}</div>
			</div>
		</div>
	);
};

const PublicProfile: FC<PublicProfileProps> = ({
	role,
	preference,
	exchange_priorities
}) => {
	const getVal = (key: string, defaultVal = '') =>
		preference && preference[key] !== undefined ? preference[key] : defaultVal;

	return (
		<>
			{role === RoleCode.HOST ? (
				<>
					<Section>
						<div className="col-md-12 col-sm-12">
							<div className="block-title">
								Household information:
							</div>
						</div>
						<div className="col-md-6 col-sm-6">
							<DashboardInfoValue
								label="Location"
								value={`${getVal('suburb')} ${getVal('state')}`}
							/>
						</div>
						<div className="col-md-6 col-sm-6">
							<DashboardInfoValue
								label="Type of dwelling"
								value={getVal('home_type')}
							/>
						</div>
						<div className="col-md-6 col-sm-6">
							<DashboardInfoValue
								label="People in household"
								value={getVal('no_of_people_in_house')}
							/>
						</div>
						<div className="col-md-6 col-sm-6">
							<DashboardInfoValue
								label="Ages"
								value={getVal('age_group')}
							/>
						</div>
						<div className="col-md-6 col-sm-6">
							<DashboardInfoValue
								label="Languages spoken"
								value={getLanguageSpokenValue(
									getVal('other_language'),
									getVal('first_language')
								)}
							/>
						</div>
						<div className="col-md-6 col-sm-6">
							<DashboardInfoValue
								label="Distance from CBD"
								value={replace(getVal('distance_from_city'), '<', '')}
							/>
						</div>
					</Section>
					<Section>
						<div className="col-md-12 col-sm-12">
							<div className="block-title">Lifestyle:</div>
						</div>
						<div className="col-md-6 col-sm-6">
							<DashboardInfoValue
								label='LGBTQIA+ friendly household'
								value={getVal('lgbtqi_friendly') !== null ? renderRadioValue(getVal('lgbtqi_friendly') ? '1' : '0') : ''}
							/>
						</div>
						<div className="col-md-6 col-sm-6">
							<DashboardInfoValue
								label="Food preferences"
								value={getVal('food_preference') === 'other'
								? getVal('other_food_preferences')
								: getVal('food_preference')}
							/>
						</div>
						<div className="col-md-6 col-sm-6">
							<DashboardInfoValue
								label="Smoking"
								value={getSmokingLabel(
									getVal('smoking_preference') !== null ? getVal('smoking_preference') ? 1 : 0 : -1,
									role
								)}
							/>
						</div>
						<div className="col-md-6 col-sm-6">
							<DashboardInfoValue
								label="Pets"
								value={getHostPetsContent(
									getVal('comfortable_with_pet'),
									getVal('pets'),
									getVal('other_pets')
								)}
							/>
						</div>
						<div className="col-md-6 col-sm-6">
							<DashboardInfoValue
								label="Gender preference"
								value={getVal('gender_preference')}
							/>
						</div>
					</Section>
					<Section>
						<div className="col-md-12 col-sm-12">
							<div className="block-title">Car and transport:</div>
						</div>
						<div className="col-md-6 col-sm-6">
							<DashboardInfoValue
								label="Parking"
								value={getVal('parking_available')}
							/>
						</div>
						<div className="col-md-6 col-sm-6">
							<DashboardInfoValue
								label="Public transport"
								value={getVal('nearby_public_transport')}
							/>
						</div>
					</Section>
					<Section>
						<div className="col-md-12 col-sm-12">
							<div className="block-title">
								Practical inclusions:
							</div>
						</div>
						<div className="col-md-6 col-sm-6">
							<DashboardInfoValue
								label="Bathroom"
								value={getVal('bathroom')}
							/>
						</div>
						<div className="col-md-6 col-sm-6">
							<DashboardInfoValue
								label="Bedroom"
								value={getGuestRoomFeatures(getVal('guest_room_features'))}
							/>
						</div>
						<div className="col-md-12 col-sm-12">
							<dt className="dashboard-info__value mb-1">
								Other features
							</dt>
							<dd>
								<Pills
									data={getHomeFeature(
										getVal('home_feature'),
										getVal('other_home_feature')
									)}
								/>
							</dd>
						</div>
					</Section>
				</>
			) : (
				<>
					<Section>
						<div className="col-md-12 col-sm-12">
							<div className="block-title">
								Personal information:
							</div>
						</div>
						<div className="col-md-6 col-sm-6">
							<DashboardInfoValue
								label="Gender"
								value={getVal('gender')}
							/>
						</div>
						<div className="col-md-6 col-sm-6">
							<DashboardInfoValue
								label="Age"
								value={getVal('age')}
							/>
						</div>
						<div className="col-md-6 col-sm-6">
							<DashboardInfoValue
								label="Occupation"
								value={getVal('current_work')}
							/>
						</div>
						<div className="col-md-6 col-sm-6">
							<DashboardInfoValue
								label="Languages spoken"
								value={getLanguageSpokenValue(
									getVal('other_language'),
									getVal('first_language')
								)}
							/>
						</div>
					</Section>
					<Section>
						<div className="col-md-12 col-sm-12">
							<div className="block-title">Lifestyle:</div>
						</div>
						<div className="col-md-6 col-sm-6">
							<DashboardInfoValue
								label="Looking for a LGBTQIA+ friendly household"
								value={renderRadioValue(getVal('lgbtqi_friendly'))}
							/>
						</div>
						<div className="col-md-6 col-sm-6">
							<DashboardInfoValue
								label="Food preferences"
								value={getVal('food_preference') === 'other'
								? getVal('other_food_preferences')
								: getVal('food_preference')}
							/>
						</div>
						<div className="col-md-6 col-sm-6">
							<DashboardInfoValue
								label="Smoker"
								value={getSmokingLabel(
									getVal('smoking_preference') !== null ? getVal('smoking_preference') ? 1 : 0 : -1,
									role
								)}
							/>
						</div>
						<div className="col-md-6 col-sm-6">
							<DashboardInfoValue
								label="Like pets"
								value={getVal('pet') !== null ? renderRadioValue(getVal('pet') ? '1' : '0') : ''}
							/>
						</div>
					</Section>
					<Section>
						<div className="col-md-12 col-sm-12">
							<div className="block-title">Car and transport:</div>
						</div>

						<div className="col-md-6 col-sm-6">
							<DashboardInfoValue
								label="Own car"
								value={getVal('car') !== null ? renderRadioValue(getVal('car') ? '1' : '0') : ''}
							/>
						</div>
						<div className="col-md-6 col-sm-6">
							<DashboardInfoValue
								label="Parking required?"
								value={getVal('carpark_required') !== null ? renderRadioValue(getVal('carpark_required') ? '1' : '0') : ''}
							/>
						</div>
						<div className="col-md-12 col-sm-12">
							<DashboardInfoValue
								label="Need public transport"
								value={getVal('public_transport_required') !== null ? renderRadioValue(getVal('public_transport_required') ? '1' : '0') : ''}
							/>
						</div>
					</Section>
					<Section>
						<div className="col-md-12 col-sm-12">
							<div className="block-title">
								Practical needs:
							</div>
						</div>

						<div className="col-md-6 col-sm-6">
							<DashboardInfoValue
								label="Share bathroom"
								value={getVal('bathroom_share') !== null ? renderRadioValue(getVal('bathroom_share') ? '1' : '0') : ''}
							/>
						</div>

						<div className="col-md-6 col-sm-6">
							<DashboardInfoValue
								label="What I need"
								value={getAdditionalNeeds(getVal('additional_needs'))}
							/>
						</div>
					</Section>
				</>
			)}
			<Section>
				<div className="col-md-12 col-sm-12">
					<div className="block-title">Priorities:</div>
				</div>
				<div className="col-md-12 col-sm-12">
					<Pills data={getTopPriorities(exchange_priorities)} />
				</div>
			</Section>
		</>
	);
};

export default memo(PublicProfile);
