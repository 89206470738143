import React, { Component } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import ModalRoot from 'js/components/modals/ModalRoot';
import { ProcessModal } from 'js/actions/Modal';
import { ModalTypes } from 'js/constants/ModalTypes';
import { SHOW_MODAL } from 'js/actions/Modal';
import { paramRoles } from 'components/XchangeSearch/configs'
import axios from 'axios';
import URL from 'js/constants/URL';
import UserProfile from 'components/UserProfile';
import { fetchConnectionList } from 'js/actions/Connection';
import Header from "../components/layouts/Header";
import Footer from "../../components/Footer";
import {login, logout} from "../actions/Login";

class BrowseDetail extends Component {
  state = {
    preferences: null,
    photoIndex: 0,
    isOpen: false,
    params: {
      user_id: this.props.match.params.id,
      token: this.props && this.props.login  && this.props.login.user ? this.props.login.user.token : null
    }
  };

  handleConnectCompleted = () => {
    const request = axios({
      method: 'get',
      url: URL.GET_PREFERENCES(
        this.state.params.user_id,
        this.state.params.token
      )
    });
    request.then(result => {
      if (result.data.status === 'success') {
        this.props.onFetchConnectionList({
          token: this.props && this.props.login && this.props.login.user ? this.props.login.token : null,
          page: 1,
          limit: 20,
          search: '',
          type: ''
        });
        this.setState({ ...this.state, preferences: result.data });
      }
    });
  };

  openConnectModal = (name, role) => {
    const modalProps = {
      showModal: true,
      name: name,
      role: role,
      userId: this.props.match.params.id,
      onRequestCompleted: this.handleConnectCompleted,
      preferences: this.state.preferences ? this.state.preferences.data : null
    };
    this.props.dispatch(
      ProcessModal(SHOW_MODAL, ModalTypes.CONNECT_MODAL, modalProps)
    );
  };

  componentDidMount() {
    const request = axios({
      method: 'get',
      url: URL.GET_PREFERENCES(
        this.state.params.user_id,
        this.state.params.token
      )
    });
    request.then(result => {
      if (result.data.status === 'success') {
        this.setState({ ...this.state, preferences: result.data });
      } else {
        //dispatch(hostPreferenceFailure(result.data.msg))
      }
    });
    this.props.onFetchConnectionList({
	  token: this.props && this.props.login && this.props.login.user ? this.props.login.user.token : null,
	  page: 1,
	  limit: 20,
	  search: '',
	  type: ''
    });
  }

  goBack() {
    window.history.back();
  }

  getConnectionStatus(user_id) {
      if (!this.props.connection || !this.props.connection.connections) {
          return 'NOT CONNECTED';
      }
      return this.props.connection.connections.reduce((a, c) => {
		  if (c.from_user_id.toString() === user_id || c.to_user_id.toString() === user_id) {
			  switch (c.status) {
                  case 'PENDING':
                  case 'EXCHANGE_PENDING':
					  if (c.from_user_id.toString() === user_id) {
						  return 'INCOMING';
					  } else {
						  return 'PENDING';
					  }
                  case 'ACCEPT':
                      return 'ACCEPT'
              }
		  }
		  return a;
      }, 'NOT CONNECTED');
  }

  render() {
    const preferences = this.state.preferences
      ? this.state.preferences.data
      : null;

    const { params }  = this.props.match;
    const whomISearching = get(paramRoles, `${params.userType}`, '');


    const isAdmin = (process.env && process.env.REACT_APP_ADMIN_USER) ? process.env.REACT_APP_ADMIN_USER.split(',').includes(
		this.props.login && this.props.login.user ? this.props.login.user.email : null) : false;

    const backButton = () => {
      if (window.history.length > 2) {
        return (<div onClick={this.goBack} className="back-link text-org">
          <i className="fa fa-angle-left text-org" />
          Back
        </div>);
      } else {
        return '';
      }
    }

	const currentStatus = this.getConnectionStatus(this.props.match.params.id);
    const canConnect = this.props.login && this.props.login.user && whomISearching !== this.props.login.user.role_code && this.props.profile.profile_completed && this.props.profile.id_verified;

    return (
      <>
		<Header
		  login={this.props.login}
		  onLogout={this.props.onLogout}
		  onLogin={this.props.onLogin}
		  origin="app"
		/>
        <div className="trx-abt-section container header-mod">
          {backButton()}
          <div className="tab-content  trx-com-wrap">
            <div role="tabpanel" className="tab-pane active">
              <UserProfile
                preferences={preferences}
                role={whomISearching}
                context="other"
                handleConnectClick={this.openConnectModal}
                isAdmin={isAdmin}
                userId={this.props.match.params.id}
                connectionStatus={canConnect ? currentStatus : 'IMPOSSIBLE' }
				isLoggedIn={!!(this.props.login && this.props.login.user && this.props.login.user.token)}
              />
            </div>
            {/*tab-panel*/}
          </div>
          {/*tab-content*/}
        </div>
		<Footer user={this.props.login.user} />
        <ModalRoot />
      </>
      );
  }
}
const mapStateToProps = store => {
  return {
    login: store.login,
    profile: store.profile.data,
	connection: store.connection
  };
};
const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    onFetchConnectionList: params => dispatch(fetchConnectionList(params)),
	onLogin: (postData, info) => dispatch(login(postData, info)),
	onLogout: () => dispatch(logout())
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(BrowseDetail);
