import axios from 'axios';
import URL from '../constants/URL';
import { isAuthenticated } from '../utils/LocalStorage'
import { signupBegin } from './Signup';
import { viewProfile } from './Profile';
import { getBadges } from './Badges';
import { fetchConnectionList } from './Connection';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN_RESTORE = 'LOGIN_RESTORE';
export const USER_LOGOUT = 'USER_LOGOUT';

export const loginRequest = () => ({
  type: LOGIN_REQUEST
});

export const loginSuccess = payload => ({
  type: LOGIN_SUCCESS,
  payload: payload
});

export const loginFailure = error => ({
  type: LOGIN_FAILURE,
  payload: error
});

export const loginRestore = user => ({
  type: LOGIN_RESTORE,
  payload: user
});

export const logout = user => {
  localStorage.removeItem('trx-user-session');

  return {
    type: USER_LOGOUT
  };
};

export const login = (postData, info, onLoginSuccess) => dispatch => {
  dispatch(loginRequest());

  return axios({
    method: 'POST',
    url: URL.LOGIN,
    data: postData
  }).then(result => {
    if (result.data.status === 'success') {
      window.localStorage.setItem(
        'trx-user-session',
        JSON.stringify(result.data.data)
      );
      dispatch(loginSuccess(result.data.data));
      dispatch(viewProfile(result.data.data.user_id, result.data.data.token));

      if (!onLoginSuccess) {
        return;
      }

      onLoginSuccess();

      return;
    }

    if (!info || result.data.msg === 'Email not verified') {
      dispatch(loginFailure(result.data.msg));
      return;
    }

    if (result.data.msg === 'login is disabled') {
      dispatch(loginFailure(result.data.msg));
      return;
    }

    window.localStorage.setItem('trx-signup-info', JSON.stringify(info));
    dispatch(signupBegin(info));
  });
};

export const initUserSessionAction = (optional) => (dispatch, getState) => {
  const user = isAuthenticated();
  if(!(user && user.token)) {
    if (optional) return Promise.resolve();
    dispatch(logout());
    return Promise.reject(false);
  }
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.token;
  axios.defaults.headers.common['Accept'] = 'application/vnd.api+json';
  return dispatch(viewProfile(null, user.token)).then(() => {
    const { token } = user;
    dispatch(getBadges(user));
    dispatch(fetchConnectionList({
      token,
      page: 1,
      limit: 20,
      search: '',
      type: ''
    }));
    return true;
  }).catch((e) => {
    dispatch(logout());
    return Promise.reject(false);
  });
}
