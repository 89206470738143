import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../components/layouts/Header';
import Footer from '../../components/Footer';
import { login, logout } from '../actions/Login';
import { RequestVerificationForm } from '../components/request-verification-form';
import {
  verifySMS,
  sendVerifySMS,
  resetSMSVerification,
  requestSMSVerification
} from '../actions/SMSVerification';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { VerificationForm } from '../components/verification-form';

class VerifyPhoneNumber extends Component {
  componentDidMount() {
    document.title = 'Verify | The Room Xchange';

    const { login } = this.props;

    if (!login.user) {
      return;
    }

    this.props.history.push('/');
  }

  componentDidUpdate() {
    const {
      login,
      smsVerification: { isVerifiedSuccess }
    } = this.props;

    if (isVerifiedSuccess) {
      setTimeout(() => {
        this.props.history.push('/login');
      }, 5000);
    }

    if (!login.user) {
      return;
    }

    this.props.history.push('/');
  }

  handleSendSMS = async (data) => {
    const { sendVerifySMS, googleReCaptchaProps } = this.props;

    const googleRecaptcha = await googleReCaptchaProps.executeRecaptcha(
      'verify_phone_number'
    );

    sendVerifySMS({ ...data, googleRecaptcha });
  };

  handleVerifySMS = async (data) => {
    const {
      verifySMS,
      googleReCaptchaProps,
      smsVerification: {
        verificationData: { email }
      }
    } = this.props;

    const googleRecaptcha = await googleReCaptchaProps.executeRecaptcha(
      'verify_phone_number'
    );

    verifySMS({ ...data, email, googleRecaptcha });
  };

  handleResendSMS = () => {
    this.props.requestVerificationSMS();
  };

  render() {
    const {
      smsVerification: {
        isSendSuccess,
        verifyError,
        sendError,
        isVerifying,
        isSending,
        isVerifiedSuccess
      }
    } = this.props;

    return (
      <>
        <Header
          login={this.props.login}
          onLogout={this.props.onLogout}
          onLogin={this.props.onLogin}
          origin="app"
        />

        <div className="trx-verify-phone-number-form com-mod header-mod">
          <div className="container">
            {(isVerifying || isSending) && <div className="page-loading" />}
            <div className="row">
              <div className="box box-bottom-margin col-md-12 py-4">
                <div className="col-md-12">
                  <h3>Please verify your mobile phone number</h3>
                  <p>
                    Please enter your email address and phone number below so we
                    can send you a verification sms.
                  </p>
                </div>
                {!isSendSuccess && (
                  <div className="col-md-6">
                    <RequestVerificationForm
                      onRequest={this.handleSendSMS}
                      error={sendError}
                    />
                  </div>
                )}
                {isSendSuccess && !isVerifiedSuccess && (
                  <div className="col-md-9">
                    <VerificationForm
                      error={verifyError}
                      onVerify={this.handleVerifySMS}
                      onSend={this.handleResendSMS}
                    />
                  </div>
                )}
                {isVerifiedSuccess && (
                  <div className="col-md-9">
                    <div className="verification__success">
                      You have successfully verified your account. You will be
                      redirected to the login page shortly. If it does not
                      happen automatically, please click{' '}
                      <a href="/app/login">here to login</a>.
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLogin: (postData, info) => dispatch(login(postData, info)),
    onLogout: () => dispatch(logout()),
    sendVerifySMS: (data) => dispatch(sendVerifySMS(data)),
    verifySMS: (data) => dispatch(verifySMS(data)),
    resetVerificationSMS: () => dispatch(resetSMSVerification()),
    requestVerificationSMS: () => dispatch(requestSMSVerification())
  };
};

const mapStateToProps = ({ login, smsVerification }) => {
  return {
    login,
    smsVerification
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withGoogleReCaptcha(VerifyPhoneNumber));
