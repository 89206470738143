import axios from 'axios';
import URL from '../constants/URL';

export const MY_BADGES = "MY_BADGES";

export const getBadges = (user) => {
  return (dispatch) => {
    const request = axios({
      method:'get',
      url:URL.VIEW_VERIFICATION_BAGDES(user.user_id,user.token)
    });
    request.then((result)=>{
      if(result.data.status === "success"){
        dispatch({type:MY_BADGES,payload:result.data.data.badges})
      }
    });
  }

}
