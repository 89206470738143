import React, { Component } from 'react';
import axios from 'axios';
import URL from '../../constants/URL';
import {
  injectStripe,
  Elements,
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement
} from 'react-stripe-elements';
import { Button } from '../../../components/common';

class CardForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // cardName: '',
      cardNumber: '',
      cardExpiry: '',
      requesting: false,
      cardError: '',
      cardName: ''
    };
  }

  handleInputChange = e => {
    e.preventDefault();
    this.setState({ ...this.state, [e.target.name]: e.target.value });
  };

  handleUpdateCardRequest = (cardId, e) => {
    e.preventDefault();
    const { token } = this.props.login;
    const expiryDate = this.state.cardExpiry.split('/');
    this.setState({ ...this.state, requesting: true });
    const request = axios({
      method: 'put',
      url: URL.EDIT_CARD_API(cardId),
      data: {
        token: token,
        exp_year: expiryDate[0],
        exp_month: expiryDate[1]
      }
    });
    request.then(result => {
      if (result.data.status === 'success') {
        this.props.onFetchCustomerCardDetail(token);
        this.props.editCard();
        this.setState({ ...this.setState, requesting: false });
      } else {
        this.setState({
          ...this.setState,
          cardError: result.data.msg,
          requesting: false
        });
      }
    });
  };

  render() {
    const {
      brand,
      card_id,
      exp_month,
      exp_year,
      funding,
      last4,
      type
    } = this.props.cardDetails;
    const last2DigitYear = exp_year.toString().substring(2);
    return (
      <div>
        {this.state.requesting && <div className="page-loading"></div>}
        <form>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label className="control-label">Name on card</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="First Name Last Name"
                  name="cardName"
                  onChange={e =>
                    this.setState({ ...this.state, cardName: e.target.value })
                  }
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label className="control-label">Card Number</label>
                <CardNumberElement
                  className="card-element"
                  value={this.state.cardNumber}
                  name="cardNumber"
                  onBlur={cardNumber =>
                    this.setState({ ...this.state, cardNumber: cardNumber })
                  }
                  value={last4}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-5 col-12">
              <div className="form-group">
                <label className="control-label">Expiry</label>
                <CardExpiryElement
                  name="cardExpiry"
                  onChange={this.handleInputChange}
                  className="card-element"
                  value={`${exp_month}/${last2DigitYear}`}
                />
              </div>
            </div>
            <div className="col-md-5 col-12">
              <div className="form-group">
                <label className="control-label">Security Code</label>
                <CardCVCElement className="card-element" value="***" />
              </div>
            </div>
          </div>
        </form>
        {this.state.cardError && (
          <div className="alert-danger" role="alert">
            <strong>Error: </strong>
            {this.state.cardError}
          </div>
        )}
        <Button
          type="submit"
          onClick={this.handleUpdateCardRequest.bind(this, card_id)}
          className="float-right"
          data-toggle="modal"
        >
          Submit
        </Button>
      </div>
    );
  }
}
const InjectedCardForm = injectStripe(CardForm);

class Checkout extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="Checkout">
        <div>
          <Elements>
            <InjectedCardForm
              login={this.props.login}
              onFetchCustomerCardDetail={this.props.onFetchCustomerCardDetail}
              editCard={this.props.editCard}
              cardDetails={this.props.cardDetails}
            />
          </Elements>
        </div>
      </div>
    );
  }
}

export default class EditCard extends React.Component {
  render() {
    return (
      <Checkout
        login={this.props.login}
        onFetchCustomerCardDetail={this.props.onFetchCustomerCardDetail}
        cardDetails={this.props.cardDetails}
        editCard={this.props.editCard}
      />
    );
  }
}
