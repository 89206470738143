import React from 'react';

interface Props {
	title?: string;
	className?: string;
}

/**
 * A convenience component that wraps any children within a styled div.
 * Used to ensure consistent styling for components on the dashboard and other
 * areas of the site.
 * @param props
 */
export const BoxedComponent: React.FC<Props> = props => {
	return (
		<div className={`dashboard-component-boxed ${props.className ? props.className : ''}`}>
			{props.title ? <h3>{props.title}</h3> : null}
			{props.children}
		</div>
	);
};
