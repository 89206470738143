import React, { FC, ChangeEvent, memo } from 'react';
import map from 'lodash/map';

interface Props {
	title: string;
	items: ListItems[];
	defaultValue?: string | number;
	valueChangeCallback: Function;
}

interface ListItems {
	label: string,
	value: string
}

const FilterSelect: FC<Props> = ({ title, defaultValue, items, valueChangeCallback }) => {

	const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
		valueChangeCallback(event.target.value);
	};

	return (
		<div className="xchange-search-filter">
			<select onChange={handleChange} defaultValue={defaultValue || ""}>
				<option value="">{title}</option>
				{map(items, ({value, label}) => {
					return <option key={value} value={value}>{label}</option>
				})}
			</select>
		</div>
	);
};

export default memo(FilterSelect);
