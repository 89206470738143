import {
  FETCH_CONNECTION_LIST,
  FETCH_CONNECTION_LIST_REQUEST,
  PUSHER_MESSAGE_RECEIVED,
  CONNECTION_MESSAGE_READ,
  NEW_MESSAGE_CREATED
} from '../actions/Connection';

const INITIAL_STATE = {
  connections: [],
  isProcessingConnections: false,
  search: '',
  type: '',
  page: 0,
  loadMore: false,
  pusherMessageReceived: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_CONNECTION_LIST_REQUEST:
      return { ...state, isProcessingConnections: true };
    case FETCH_CONNECTION_LIST:
      if (action.payload.page > 1) {
        return {
          ...state,
          isProcessingConnections: false,
          connections: state.connections.concat(action.payload.connections),
          search: action.payload.search,
          type: action.payload.type,
          page: action.payload.page,
          loadMore: action.payload.loadMore
        };
      } else {
        return {
          ...state,
          isProcessingConnections: false,
          connections: action.payload.connections,
          search: action.payload.search,
          type: action.payload.type,
          page: action.payload.page,
          loadMore: action.payload.loadMore
        };
      }
    case PUSHER_MESSAGE_RECEIVED:
      if (state.connections.length) {
        return {
          ...state,
          pusherMessageReceived: action.payload,
          connections: state.connections.map((connection, index) => {
            if (connection.connection_id == action.payload.connections_id) {
              if (action.payload.current_user == connection.from_user_id) {
                return {
                  ...connection,
                  created_at: action.payload.created_at,
                  text: action.payload.text,
                  from_user_seen_status: 'Unseen'
                };
              } else {
                return {
                  ...connection,
                  created_at: action.payload.created_at,
                  text: action.payload.text,
                  to_user_seen_status: 'Unseen'
                };
              }
            }
            return connection;
          })
        };
      }
    case CONNECTION_MESSAGE_READ:
      if (state.connections.length) {
        return {
          ...state,
          connections: state.connections.map((connection, index) => {
            if (connection.connection_id == action.payload.connection_id) {
              if (action.payload.current_user == connection.from_user_id) {
                return {
                  ...connection,
                  from_user_seen_status: 'Seen'
                };
              } else {
                return {
                  ...connection,
                  to_user_seen_status: 'Seen'
                };
              }
            }
            return connection;
          })
        };
      }
    case NEW_MESSAGE_CREATED:
      if (state.connections.length) {
        return {
          ...state,
          connections: state.connections.map((connection, index) => {
            if (connection.connection_id == action.payload.connections_id) {
              return {
                ...connection,
                created_at: action.payload.created_at,
                text: action.payload.text
              };
            }
            return connection;
          })
        };
      }
    default:
      return state;
  }
};
