import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './css/index.scss';
import './css/fitzroy-it.css';
import registerServiceWorker from './js/utils/registerServiceWorker';
import { App } from './js/App';

/**
 * Check if you user is logged in and if so, restore the user
 **/

ReactDOM.render(<App />, document.getElementById('root'));

registerServiceWorker();
