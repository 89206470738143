import React, {FC, useContext, useState} from "react";
import {editProfileService} from "../../../utils/editProfileService";
import {setAjaxLoader, showAjaxError} from "../../../utils/common";
import {RemoteUserType} from "../../../types/editProfileTypes";
import EditProfileContext from "../../../context/EditProfileContext";
import RemoteUpdateError from "../RemoteUpdateError";
import Cropper from 'react-cropper';

type CropperProps={
	image: string,
	setIsOpen: Function
	user: RemoteUserType,
	setAvatar: Function
};
const ImageCropper: FC<CropperProps>=({image,user, setIsOpen,setAvatar})=>{

	const {update}=useContext(EditProfileContext);
	const [submissionError, setSubmissionError] = useState('');
	const [cropper, setCropper] = useState<any>();

	const saveProfilePhoto = () => {
		if (typeof cropper !== "undefined") {
			let extensionName=editProfileService.getExtensionName(image);
			const result=cropper.getCroppedCanvas().toDataURL(extensionName);
			setAjaxLoader(true,update);

			editProfileService.COMMON.uploadProfileAvatar(result, user).then(result=>{
				const {data}=result;
				if(data.status==='success')
				{
					editProfileService.COMMON.postUploadAvatar(user).then(result=>{
						setIsOpen(false);
						setAvatar();
					});
				}
				else if(data.msg!==undefined)
					setSubmissionError(data.msg);
				else
					setSubmissionError('Some error occurred. Please try again later');

				setAjaxLoader(false,update);
			}).catch(res=>{
				showAjaxError(res,setSubmissionError,update);
			});
		}
	};

	return (


		<div>
			<div className="modal-header">
				<button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>{setIsOpen(false)}}><span aria-hidden="true"><i className="fa fa-close"></i></span></button>
			</div>
			<div className="modal-body">
				<div id="cropperwrap">
					<div style={{width: '400px',height: '400px'}}>
						<Cropper
							style={{ height: 400, width: 400 }}
							minCropBoxHeight={250}
							minCropBoxWidth={250}
							aspectRatio={1}
							autoCropArea={1}
							preview=".img-preview"
							src={image}
							responsive={true}
							checkOrientation={false}
							onInitialized={(instance) => {
								setCropper(instance);
							}}
						/>

					</div>

				</div>
			</div>
			<div className="modal-footer">
				<RemoteUpdateError submissionError={submissionError}/>
				<div>
					<button className="button btn-orange  " type="submit" onClick={saveProfilePhoto}>Save profile photo</button>
					<button className="button btn-grey  " type="button" onClick={()=>{setIsOpen(false)}}>Cancel</button>
				</div>
			</div>
		</div>




	);
};


export default ImageCropper;
