import React,{ Component } from 'react';
import Cropper from 'react-cropper';
import { connect } from 'react-redux';
import axios from 'axios';
import URL from '../../constants/URL';
import { ProcessModal } from '../../actions/Modal';
import { SHOW_MODAL, HIDE_MODAL } from '../../actions/Modal';
import { Button } from '../../../components/common';
import { RoleLabel } from '../../../store/Users/constants';

class AcceptProfile extends Component {

  constructor(props){
    super(props);
    this.state={
      requesting:false,
      mainsrc:''
    }
  }


  close = () => {
    const modalProps={showModal:false}
        this.props.dispatch(ProcessModal(HIDE_MODAL,null,modalProps));
    }

  acceptConnectionRequest = (e) => {
    e.preventDefault();
    const request = axios({
      method:'patch',
      url:URL.EDIT_CONNECTION_API(this.props.connectionId),
      data:{
        token:this.props.token,
        status:this.props.status
      }
    });
    request.then((result) => {
      if(result.data.status==="success"){
        this.close();
      }
    });
  }

  render(){
    return(
      <div>
      <div className="overlay modal-backdrop modal-dialog-centered">
        <div className="col-md-5 offset-md-4">

          <div className="modal-content padding-com">
            <div>
              <div className="modal-header">
                <button type="button" className="close" onClick={this.close} data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true"><i className="fa fa-close"></i></span>
                </button>
              </div>

              <div className="modal-body">
                <div>
                  <h3>Are you sure you want to accept an Xchange with {RoleLabel.GUEST} First Name from 13/4/2018 - 15/6/2018?</h3>
               </div>
              </div>
              <div className="modal-footer">
                <Button color="grey" onClick={this.close} data-dismiss="modal">cancel</Button>
                <Button onClick={this.acceptConnectionRequest}>Yes</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    )
  }
}
const mapDispatchToProps = (dispatch) => {
  return{
    dispatch
  }
}
const mapStateToProps = (store) => {
  return {
    login: store.login.user,
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(AcceptProfile)
