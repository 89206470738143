import { handleActions } from 'redux-actions';
import {
  openPublicSearchModal,
  closePublicSearchModal,
} from '../actions/PublicSearchModal';

const defaultState = {
  isOpened: false,
};

export const publicSearchModalReducer = handleActions(
  {
    [openPublicSearchModal]: (state, { payload }) => ({
      ...state,
      ...payload,
      isOpened: true,
    }),
    [closePublicSearchModal]: (state) => ({ isOpened: false }),
  },
  defaultState
);
