import React, {FC} from "react";
import Modal from "react-modal";
import AvatarUpload from "./AvatarUpload";
import {RemoteUserType} from "../../../types/editProfileTypes";


type AvatarModalProps={
	isOpen: boolean,
	setIsOpen: Function,
	user: RemoteUserType,
	setAvatar: Function
}
if (process.env.NODE_ENV !== 'test') Modal.setAppElement('#root');



const AvatarModal: FC<AvatarModalProps>=({isOpen, setIsOpen, user,setAvatar})=>{


	const customStyles = {
		content: {
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			transform: 'translate(-50%, -50%)',
			border: 'unset',
			width: '600px',
		},
		overlay: {
			background: '#000000bf'
		}
	};


	return (
		<>
			<Modal isOpen={isOpen} style={customStyles}>
				<AvatarUpload user={user} setIsOpen={setIsOpen} setAvatar={setAvatar}/>
			</Modal>
		</>
	)
}


export default AvatarModal;
