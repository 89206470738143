import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';

export class SignUpStep extends React.Component {
  static propTypes = {
    validation: PropTypes.object,
    data: PropTypes.object,
    renderForm: PropTypes.func,
    onSubmit: PropTypes.func
  };

  render() {
    const { renderForm, data, validation, onSubmit } = this.props;

    return (
      <React.Fragment>
        <Formik
          initialValues={data}
          validationSchema={validation}
          onSubmit={onSubmit}
          validateOnBlur={true}
          render={renderForm}
        />
      </React.Fragment>
    );
  }
}
