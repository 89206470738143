import { handleActions } from 'redux-actions';
import {
  verifyingSMSCode,
  verifySMSCodeSuccess,
  verifySMSCodeFailed,
  sendingSMSCode,
  sendSMSFailed,
  sendSMSSuccess,
  requestSMSVerification
} from '../actions/SMSVerification';

const defaultState = {
  isVerifying: false,
  verificationData: undefined,
  verifyError: undefined,
  isVerifiedSuccess: false,
  isSending: false,
  sendError: undefined,
  isSendSuccess: false
};

export const smsVerificationReducer = handleActions(
  {
    [verifyingSMSCode]: state => ({ ...state, isVerifying: true }),
    [verifySMSCodeFailed]: (state, { payload }) => ({
      ...state,
      isVerifying: false,
      isVerifiedSuccess: false,
      verifyError: payload
    }),
    [verifySMSCodeSuccess]: (state, { payload }) => ({
      ...state,
      isVerifying: false,
      isVerifiedSuccess: true,
      verificationResult: payload,
      verifyError: undefined
    }),
    [sendingSMSCode]: state => ({ ...state, isSending: true }),
    [sendSMSSuccess]: (state, { payload }) => ({
      ...state,
      isSending: false,
      isSendSuccess: true,
      sendError: undefined,
      verificationData: payload
    }),
    [sendSMSFailed]: (state, { payload }) => ({
      ...state,
      isSending: false,
      isSendSuccess: false,
      sendError: payload
    }),
    [requestSMSVerification]: state => ({
      ...state,
      isSendSuccess: false
    })
  },
  defaultState
);
