import React, { FC, memo } from 'react';
import axios from 'axios';
import get from 'lodash/get';
import { connect } from 'react-redux';
import {ProcessModal } from '../../../js/actions/Modal';
import { fetchConnectionList } from '../../../js/actions/Connection';
import URL from '../../../js/constants/URL';
import { UserNotificationProps } from '../types';
import {
	PendingTo,
	PendingFrom,
	AcceptFrom,
	AcceptTo,
	DeclineFrom,
	DeclineTo
} from './Messages';

const ComponentConfig = {
	PENDING_TO: PendingTo,
	PENDING_FROM: PendingFrom,
	ACCEPT_FROM: AcceptFrom,
	ACCEPT_TO: AcceptTo,
	DECLINE_FROM: DeclineFrom,
	DECLINE_TO: DeclineTo
};

const UserNotification: FC<UserNotificationProps> = ({
	connectionId,
	onProcessModal,
	connectionDetail,
	currentuser,
	onFetchConnectionList,
	connection
}) => {
	//
	const getRoleCode = () =>
		currentuser.role_code === 'HOST' ? 'GUEST' : 'HOST';

	const acceptConnectionRequest = (e: any) => {
		e.preventDefault();
		if (!connectionDetail) {
			return;
		}
		const request = axios({
			method: 'patch',
			url: URL.EDIT_CONNECTION_API(connectionId),
			data: {
				token: currentuser.token,
				status: 'ACCEPT'
			}
		});
		request.then((result) => {
			if (result.data.status === 'success') {
				onFetchConnectionList({
					token: currentuser.token,
					page: 1,
					limit: 20,
					type: '',
					search: ''
				});
				// this.setState({ requesting: false });
			} else {
				// this.setState({ requesting: false });
			}
		});
	};

	const declineConnectionRequest = (e: any) => {
		e.preventDefault();
		if (!connectionDetail) {
			return;
		}
		const request = axios({
			method: 'patch',
			url: URL.EDIT_CONNECTION_API(connectionId),
			data: {
				token: currentuser.token,
				status: 'DECLINE'
			}
		});
		request.then((result) => {
			if (result.data.status === 'success') {
				onFetchConnectionList({
					token: currentuser.token,
					page: 1,
					limit: 20,
					type: '',
					search: ''
				});
				// this.setState({ requesting: false });
			} else {
				// this.setState({ requesting: false });
			}
		});
	};

	// const openPaymentCompleteModal = (e: any) => {};

	let userType =
		connectionDetail &&
		currentuser &&
		currentuser.user_id === connectionDetail.from_user_id
			? 'to'
			: 'from';
	const Content = get(
		ComponentConfig,
		`${get(connectionDetail, 'status', '')}_${userType.toUpperCase()}`,
		null
	);
	const props = {
		...connectionDetail,
		acceptConnectionRequest,
		declineConnectionRequest
	};

	const connectionDetails = connection.connections.reduce((r, c) => c.connection_id === connectionId ? c : r, connection.connections[0]);

	return (
		<>
			{connectionDetails && connectionDetails.status === 'PENDING' && Content && (
				<div className="user-notification">
					<Content {...props} />
					<div className="clearfix"></div>
				</div>
			)}
		</>
	);
};

const mapStateToProps = (store: any) => {
	return {
		currentuser: store.login.user,
		connection: store.connection
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		onProcessModal: (actionType: any, modalType: any, modalProps: any) =>
			dispatch(ProcessModal(actionType, modalType, modalProps)),
		onFetchConnectionList: (params: any) =>
			dispatch(fetchConnectionList(params))
		// onFetchInvoices: (token:any) => dispatch(fetchInvoices(token)),
		// onPusherMessageRead: (connection_id:any, user:any, context:any) =>
		// 	dispatch(pusherMessageRead(connection_id, user, context))
	};
};

export default memo(
	connect(mapStateToProps, mapDispatchToProps)(UserNotification)
);
