import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import 'cropperjs/dist/cropper.css';
import URL from '../constants/URL';
import { editProfile } from '../actions/Profile';
import { Button } from '../../components/common';

class EditProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: this.props.profile.name,
      postCode: this.props.profile.post_code,
      email: this.props.profile.email,
      gender: '',
      otherGender: '',
      dob: this.props.profile.dob,
      phone: this.props.profile.phone_no,
      imageID: '',
      src: this.props.profile.image,
      cropResult: null
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    //this._handleImageChange=this._handleImageChange.bind(this);

    this.handleCancel = this.handleCancel.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.cropImage = this.cropImage.bind(this);

    this.onChange = this.onChange.bind(this);
    //this.useDefaultImage = this.useDefaultImage.bind(this);
  }

  onChange(e) {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      this.setState({ src: reader.result });
    };
    reader.readAsDataURL(files[0]);
  }

  b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  cropImage(e) {
    e.preventDefault();

    if (typeof this.cropper.getCroppedCanvas() === 'undefined') {
      return;
    }

    if (this.cropper.getCroppedCanvas().toDataURL() != null) {
      var form = new FormData();
      var ImageURL = this.cropper.getCroppedCanvas().toDataURL();
      // Split the base64 string in data and contentType
      var block = ImageURL.split(';');
      // Get the content type
      var contentType = block[0].split(':')[1]; // In this case "image/gif"
      // get the real base64 content of the file
      var realData = block[1].split(',')[1]; // In this case "iVBORw0KGg...."
      var blob = this.b64toBlob(realData, contentType);
      form.append('token', this.props.user.token);
      form.append('image', blob);
      const request = axios({
        method: 'post',
        url: URL.UPLOAD_IMAGE,
        data: form,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      request.then(result => {
        if (result.data.status === 'success') {
          this.setState({ imageID: result.data.image_id });
        }
      });
    }
    this.setState({
      cropResult: this.cropper.getCroppedCanvas().toDataURL()
    });
  }

  handleInputChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleCancel() {
    this.props.profileMode();
  }
  handleSubmit(e) {
    e.preventDefault();
    this.props.onEditProfile(
      this.props.user.user_id,
      this.props.user.token,
      this.state
    );
    this.props.profileMode();
  }

  render() {
    return (
      <div>
        {this.props.profile != null ? (
          <form onSubmit={this.handleSubmit} encType="multipart/form-data">
            <table>
              <tbody>
                <tr>
                  <td>
                    <dt>NAME</dt>
                    <dd>
                      <input
                        type="text"
                        name="name"
                        value={this.state.name}
                        className="form-control"
                        onChange={this.handleInputChange}
                      />
                    </dd>
                  </td>
                  <td>
                    <dt>Gender</dt>
                    <dd>
                      <div className="col-md-6">
                        <div className="form-group">
                          <div className="row">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                name="gender"
                                type="radio"
                                value="Male"
                                radio={this.state.gender}
                                checked={this.state.gender === 'Male'}
                                onClick={this.handleInputChange}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="gender"
                              >
                                Male
                              </label>
                            </div>

                            <div className="form-check">
                              <input
                                className="form-check-input"
                                name="gender"
                                type="radio"
                                value="Female"
                                radio={this.state.gender}
                                checked={this.state.gender === 'Female'}
                                onClick={this.handleInputChange}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="gender"
                              >
                                Female
                              </label>
                            </div>

                            <div className="form-check">
                              <input
                                className="form-check-input"
                                name="gender"
                                type="radio"
                                radio={this.state.gender}
                                value="Other"
                                checked={this.state.gender === 'Other'}
                                onClick={this.handleInputChange}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="gender"
                              >
                                Other
                              </label>
                            </div>
                            {this.state.gender === 'Other' && (
                              <input
                                type="text"
                                className="form-control"
                                name="otherGender"
                                value={this.state.otherGender}
                                onChange={this.handleInputChange}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </dd>
                  </td>
                </tr>
                <tr>
                  <td>
                    <dt>Post Code</dt>
                    <dd>
                      <input
                        type="text"
                        name="postCode"
                        value={this.state.postCode}
                        className="form-control"
                        onChange={this.handleInputChange}
                      />
                    </dd>
                  </td>
                  <td>
                    <dt>BIRTHDATE</dt>
                    <dd>
                      <input
                        type="date"
                        name="dob"
                        value={this.state.dob}
                        className="form-control"
                        onChange={this.handleInputChange}
                      />
                    </dd>
                  </td>
                </tr>
                <tr>
                  <td>
                    <dt>EMAIL</dt>
                    <dd>
                      <input
                        type="email"
                        name="username"
                        value={this.state.email}
                        className="form-control"
                        onChange={this.handleInputChange}
                      />
                    </dd>
                  </td>
                  <td>
                    <dt>PHONE NUMBER</dt>
                    <dd>
                      <input
                        type="text"
                        name="phone"
                        value={this.state.phone}
                        className="form-control"
                        onChange={this.handleInputChange}
                      />
                    </dd>
                  </td>
                </tr>

                <tr>
                  <td>
                    <Button type="submit" size="medium">Update Profile</Button>
                  </td>
                  <td>
                    <Button type="button" onClick={this.handleCancel}>Cancel</Button>
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
        ) : (
          <p>Loading Profile</p>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  onEditProfile: (user_id, token, profile) => {
    dispatch(editProfile(user_id, token, profile));
  }
});

const mapStateToProps = store => ({
  user: store.login.user
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditProfile);
