import React, {FC, useContext, useEffect, useState} from 'react';
import InfoBubble from "../../../components/common/InfoBubble";
import { useFormik } from 'formik';
import '../styles/profile_edit.scss';
import {
	getAccordionDefaultConfig,
	hasError,
	manageAccordion,
	postActionSuccess,
	setAjaxLoader,
	showAjaxError,
	includeTestId
} from "../utils/common";
import {editProfileService} from "../utils/editProfileService";
import {UserProps} from "../types/editProfileTypes";
import AboutGuestSchema from "../schemas/AboutGuestSchema";
import {exchangePeriods, languages, occupations, rentOffset, yesOrNo} from "../utils/formData";
import FormError from "./helpers/FormError";
import CharCount from "./helpers/CharCount";
import RemoteUpdateError from "./helpers/RemoteUpdateError";
import FormSubmitButtons from "./helpers/FormSubmitButtons";
import EditProfileContext from "../context/EditProfileContext";
import AsyncSelect from "react-select/async";
import {SingleValue} from "react-select";



const AboutEditGuest: FC<UserProps>=({user})=>{

	const {accordionConfig, update,data}=useContext(EditProfileContext);
	let about=editProfileService.GUEST.getAboutSection({...data});
	const [submissionError, setSubmissionError] = useState('');
	const [buttonType, setButtonType]=useState('');


	useEffect(()=>{
		editProfileService.GUEST.mapValues(about);
	},[about]);

	const {values, errors, touched, handleBlur, handleChange, handleSubmit, isValid, setFieldValue, validateForm} = useFormik({
		initialValues: about as any,
		// validateOnMount: true,
		validationSchema: AboutGuestSchema,
		onSubmit: (values, formikHelpers) => {
			setAjaxLoader(true,update);
			const params={...values};

			if(params.current_work==='Other')
				params.current_work=params.current_work_other;


			editProfileService.GUEST.updateAboutSection(params,user).then(result=>{
				const {data}=result;
				if(data.status==='success')
					postActionSuccess(buttonType, update, 'lifestyleCollapsed', user.user_id, user.token);
				else if(data.msg!==undefined)
					setSubmissionError(data.msg);
				else
					setSubmissionError('Some error occurred. Please try again later');
				setAjaxLoader(false,update);
			}).catch(res=>{
				showAjaxError(res,setSubmissionError,update);
			});
		}
	});

	const selectStyles = {
		control: (styles: any) => ({ ...styles, height: '54px !important',marginBottom: '10px', borderRadius: '30px' })

	};

	useEffect(() => {
		(() => validateForm())();
	}, []);

	const errorMessages = () => {
		if (submissionError) {
			return submissionError
		} else if (!isValid) {
			return "There are some issues you need to resolve before saving"
		} else {
			return '';
		}
	}

	return (
		<div className="card">
			<div className="card-header" role="tab" id="aboutHeading">
				<a className={accordionConfig.aboutCollapsed ? 'collapsed' : ''} href="#about-guest" aria-expanded={!accordionConfig.aboutCollapsed} onClick={()=>{ manageAccordion({...getAccordionDefaultConfig(), aboutCollapsed: !accordionConfig.aboutCollapsed}, update) }}>
					<h2 className="mb-0">
						<div className="card-header__left" {...includeTestId('about-section-heading')}>
							<i className={isValid ? 'fa fa-check icon-rounded' : 'fa fa-close icon-rounded'}/>
							About
							<InfoBubble tooltip="The first three questions in this section are your chance to tell potential housemates who you are. Make sure you answer them clearly, check for typos and misspellings before you publish." />
						</div>
					</h2>
				</a>
			</div>

			<div id="about-guest" className={`collapse ${accordionConfig.aboutCollapsed?'':'show'} `} role="tabpanel" aria-labelledby="aboutHeading" data-parent="#profileAccordion">
				<form className="form-horizontal form-signup section-about" role="form" onSubmit={handleSubmit}  noValidate={true}>
					<div className="card-body">
						<div className="row form-mod">
							<div className="col-md-12">
								<div className="form-group" {...includeTestId('profile-snapshot')}>
									<label htmlFor="describes_you" className="control-label">Profile snapshot</label>
									<InfoBubble tooltip="Write one sentence that best describes you. This is the first thing that users will read about you on the search results page."/>
									<textarea name="describes_you" id={'describes_you'} className={`form-control ${hasError(errors, touched,'describes_you')?'error-field':''}`} placeholder={`For example: I'm an easy going professional person who loves to cook and socialise.`} onChange={handleChange} onBlur={handleBlur} value={values.describes_you} maxLength={80}/>
									<div className="row mt-1">
										<div className="col-sm-10 col-12"><FormError errors={errors} fieldName={'describes_you'} touched={touched}/></div>
										<div className="col-sm-2"><CharCount dangerLimit={70} field={values.describes_you} maxLimit={80} fieldName={'describes_you'}/></div>
									</div>
								</div>
							</div>
						</div>
						<div className="row form-mod">
							<div className="col-md-12">
								<div className="form-group" {...includeTestId('guest-profile-about')}>
									<label htmlFor="describe_about" className="control-label">About</label>
									<InfoBubble tooltip="This section is about highlighting your unique personality. Include any quirks you have and things of interest that will attract a housemate who is interested in living with someone just like you"/>
									<br />
									<textarea name="additional_info" id={'additional_info_guest'} className={`form-control ${hasError(errors, touched,'additional_info')?'error-field':''}`} placeholder={`For example: I'm a budding entrepreneur who's in the throws of starting an online business. A rent offset will give me the time to develop my business without the financial burden of having to work full time.`} onChange={handleChange} onBlur={handleBlur} value={values.additional_info} maxLength={500}/>

									<div className="row mt-1">
										<div className="col-sm-10 col-12"><FormError errors={errors} fieldName={'additional_info'} touched={touched}/></div>
										<div className="col-sm-2"><CharCount dangerLimit={450} field={values.additional_info} maxLimit={500} fieldName={'additional_info'}/></div>
									</div>
								</div>
							</div>
						</div>
						<div className="row form-mod">
							<div className="col-md-12">
								<div className="form-group">
									<label htmlFor="describe" className="xh-offer" {...includeTestId('guest-offer-label')}>
										Offer
									</label>
									<InfoBubble tooltip="Are you wanting a straight rental arrangement or a rent offset? If it’s a rent offset, what additional tasks can you help out with in return for a reduction in rent? What skills or talents can you bring to the household? Are you an incredible cook, love home organising or gardening? Do you speak multiple languages or have great knowledge in a particular subject?"
									/>
									<br />
									<textarea name="offering" id="offer_info_guest" className={`form-control ${hasError(errors, touched,'offering')?'error-field':''}`} placeholder={`For example:  I'm an adventurous, outdoors person who loves animals, organising and hiking. I speak English and French and play piano. I'm told I'm a little particular in how I like to organise things so you might like to take advantage of that!`} onChange={handleChange} onBlur={handleBlur} value={values.offering} maxLength={500}/>

									<div className="row mt-1">
										<div className="col-sm-10 col-12"><FormError errors={errors} fieldName={'offering'} touched={touched}/></div>
										<div className="col-sm-2"><CharCount dangerLimit={450} field={values.offering} maxLimit={500} fieldName={'offering'}/></div>
									</div>
								</div>
							</div>
						</div>

						<div className="row form-mod">
							<div className="col-lg-4 col-md-4 col-sm-4 col-12">
								<div className="form-group" {...includeTestId('first_language')}>
									<label htmlFor="first-language" className="control-label">First language</label>
									<select className={`form-control ${hasError(errors, touched,'first_language')?'error-field':''}`} name="first_language" onChange={handleChange} value={values.first_language} onBlur={handleBlur}>
										<option value="">Select</option>
										{languages.map((language, index) => <option key={index} value={language}>{language}</option>)}
									</select>
									<FormError errors={errors} fieldName={'first_language'} touched={touched}/>
								</div>
							</div>
							<div className="col-lg-4 col-md-4 col-sm-4 col-12">
								<div className="form-group" {...includeTestId('other-language-radio')}>
									<label htmlFor="other-language" className="control-label">
										Do you speak any other languages?
										<InfoBubble tooltip="Some people like to learn a language, so if you speak multiple languages, list them here as it may be of interest to a user. If you speak multiple languages, please separate them with a comma." />
									</label>
									{yesOrNo.map((item, key) => (
										<div className="form-check" key={`other-language-key-${item}`}>
											<input name="yesOtherLanguage" className="form-check-input" id={`yesOtherLanguage-key-${item}`} type="radio" checked={item===values.yesOtherLanguage} onChange={handleChange} value={item}/>
											<label className="form-check-label" htmlFor={`yesOtherLanguage-key-${item}`}>{item}</label>
										</div>
									))}
								</div>
							</div>
							<div className="col-lg-4 col-md-4 col-sm-4 col-12 pt-2">
								<div className="form-group">
									{values.yesOtherLanguage==='Yes'&&<>
										<input {...includeTestId('other-language-name')} className={`form-control ${hasError(errors, touched,'other_language')?'error-field':''}`} name="other_language" type="text" placeholder="Please enter other language" onChange={handleChange} onBlur={handleBlur} value={values.other_language}/>
										<FormError errors={errors} fieldName={'other_language'} touched={touched} containerClass={'mt-1'}/>
									</>}
								</div>
							</div>
						</div>

						<div className="row form-mod">
							<div className="col-lg-4 col-md-4 col-sm-10 col-12">
								<div className="form-group" {...includeTestId('work-status')}>
									<label htmlFor="describesyou" className="control-label">Which of the following best describes your current situation?</label>
									<select className={`form-control ${hasError(errors, touched,'current_work')?'error-field':''}`} name="current_work" onChange={handleChange} value={values.current_work} onBlur={handleBlur}>
										<option value="">Select</option>
										{occupations.map((language, index) => <option key={index} value={language}>{language}</option>)}
									</select>
									<FormError errors={errors} fieldName={'current_work'} touched={touched} containerClass={'mt-1'}/>

									{values.current_work==='Other' &&
									<div>
										<input className={`form-control ${hasError(errors, touched,'current_work_other')?'error-field':''}`} name="current_work_other" type="text" placeholder="Please describe your current situation" onChange={handleChange} onBlur={handleBlur} value={values.current_work_other}/>
										<FormError errors={errors} fieldName={'current_work_other'} touched={touched} containerClass={'mt-1'}/>
									</div>
									}
								</div>
							</div>
						</div>
						<div className="row form-mod">
							<div className="col-lg-4 col-md-4 col-sm-4 col-12" {...includeTestId('house-sharing-date')}>
								<div className="form-group trx-about-host-date-picker">
									<label htmlFor="xchangebegin" className="control-label">
										When would you like your house share to begin?
										<InfoBubble tooltip="Make sure keep this updated if your circumstances change." />
									</label>
									<input type="date" name="exchange_date" min={new Date().toISOString().split('T')[0]} onChange={handleChange} value={values.exchange_date} className={`form-control ${hasError(errors, touched,'exchange_date')?'error-field':''}`} placeholder="Select Date" onBlur={handleBlur}/>
									<FormError errors={errors} fieldName={'exchange_date'} touched={touched} containerClass={'mt-1'}/>
								</div>
							</div>
							<div className="col-lg-4 col-md-4 col-sm-4 col-12">
								<div className="form-group" {...includeTestId('house-sharing-duration')}>
									<label htmlFor="exchange_period" className="control-label">
										How long would you like your house share to be?
										<InfoBubble tooltip="Make sure you keep this updated in your circumstances change." />
									</label>
									<select id="exchange_period" name="exchange_period" onChange={handleChange} value={values.exchange_period} className={`form-control ${hasError(errors, touched,'exchange_period')?'error-field':''}`} onBlur={handleBlur}>
										<option value="">Select</option>
										{exchangePeriods.map((period, index) => <option key={index} value={period}>{period}</option>)}
									</select>
									<FormError errors={errors} fieldName={'exchange_period'} touched={touched} containerClass={'mt-1'}/>
								</div>
							</div>
						</div>
						<div className="row form-mod">
							<div className="col-lg-4 col-md-4 col-sm-4 col-12">
								<div className="form-group" {...includeTestId('rent-offset')}>
									<label htmlFor="rent" className="control-label">Rent/Rent offset</label>
									<InfoBubble tooltip="Select which option you prefer." />
									{rentOffset.map((item, key) => (
										<div className="form-check" key={`rent-offset-${item}`}>
											<input name="rent_offset" className="form-check-input" id={`rent-offset-id-${item}`} type="radio" checked={item===values.rent_offset} onChange={handleChange} value={item}/>
											<label className="form-check-label" htmlFor={`rent-offset-id-${item}`}>{item}</label>
										</div>
									))}
								</div>
							</div>
							<div className="col-lg-4 col-md-4 col-sm-4 col-12">
								<div className="form-group" {...includeTestId('room-value')}>
									<label htmlFor="room_value" className="control-label">
										Budget (per week)
									</label>
									<InfoBubble tooltip="Add the amount of rent you are happy to pay." />
									<input id="rental_budget" name="rental_budget" type="text" onChange={handleChange} value={values.rental_budget} className={`form-control ${hasError(errors, touched,'rental_budget')?'error-field':''}`} onBlur={handleBlur} placeholder="eg. $400"/>
									<FormError errors={errors} fieldName={'rental_budget'} touched={touched} containerClass={'mt-1'}/>
								</div>
							</div>
						</div>
						<RemoteUpdateError submissionError={errorMessages()}/>
					</div>
					<FormSubmitButtons setButtonType={setButtonType} isValid={isValid}/>
				</form>
			</div>
		</div>
	);
}
export default AboutEditGuest;
