import React, {FC, useContext, useEffect, useState} from 'react';
import InfoBubble from "../../../components/common/InfoBubble";
import {useFormik} from 'formik';
import '../styles/profile_edit.scss';

import {bathroomTypes, guestRoomFeatures, homeFeatures, parkingOptions, publicTransport} from "../utils/formData";
import PracticalInclusionsSchema from "../schemas/PracticalInclusionsSchema";
import {
	getAccordionDefaultConfig,
	hasError,
	manageAccordion,
	postActionSuccess,
	setAjaxLoader,
	showAjaxError, includeTestId
} from "../utils/common";
import {editProfileService} from "../utils/editProfileService";
import {UserProps} from "../types/editProfileTypes";
import FormError from "./helpers/FormError";
import RemoteUpdateError from "./helpers/RemoteUpdateError";
import FormSubmitButtons from "./helpers/FormSubmitButtons";
import EditProfileContext from "../context/EditProfileContext";



const PracticalInclusions: FC<UserProps>=({user})=>{
	const {accordionConfig, update, data}=useContext(EditProfileContext);
	const [buttonType, setButtonType]=useState('');
	const [submissionError, setSubmissionError] = useState('');
	let practicalInclusion=editProfileService.HOST.getPracticalInclusions({...data});

	const {values, errors, touched, handleBlur, handleChange, setFieldTouched, handleSubmit, isValid, validateForm} = useFormik({
		initialValues: practicalInclusion as any,
		validationSchema: PracticalInclusionsSchema,
		onSubmit: (values) => {
			const params={...values};
			setAjaxLoader(true,update);
			editProfileService.HOST.updatePracticalInclusionSection(params,user).then(result=>{
				const {data}=result;
				if(data.status==='success')
					postActionSuccess(buttonType, update, 'photosCollapsed', user.user_id, user.token);
				else if(data.msg!==undefined)
					setSubmissionError(data.msg);
				else
					setSubmissionError('Some error occurred. Please try again later');

				setAjaxLoader(false,update);
			}).catch(res=>{
				showAjaxError(res,setSubmissionError,update);
			});
		}
	});

	useEffect(() => {
		(() => validateForm())();
	}, []);

	const errorMessages = () => {
		if (submissionError) {
			return submissionError
		} else if (!isValid) {
			return "There are some issues you need to resolve before saving"
		} else {
			return '';
		}
	}

	return (
		<div className="card">
			<div className="card-header" role="tab" id="practicalInclusionHeading">
				<a className={accordionConfig.inclusionCollapsed ? 'collapsed' : ''} href="#abt-practical-inclusion" aria-expanded={!accordionConfig.inclusionCollapsed} onClick={()=>{ manageAccordion({...getAccordionDefaultConfig(), inclusionCollapsed: !accordionConfig.inclusionCollapsed}, update)}}>
					<h2 className="mb-0">
						<div className="card-header__left"  {...includeTestId('practical-inclusions-heading')}>
							<i className={isValid ? 'fa fa-check icon-rounded' : 'fa fa-close icon-rounded'}/>
							Practical Inclusions
							<InfoBubble tooltip="This section covers the practical inclusions your household is offering the housemate." />
						</div>
					</h2>
				</a>
			</div>

			<div id="abt-practical-inclusion" className={`collapse ${accordionConfig.inclusionCollapsed?'':'show'} `} role="tabpanel" aria-labelledby="practicalInclusionHeading" data-parent="#profileAccordion">
				<form className="form-horizontal form-signup" role="form" onSubmit={handleSubmit}>
					<div className="card-body">
						<div className="row form-mod">
							<div className="col-8" {...includeTestId('room-feature')}>
								<label htmlFor="room-feature" className="control-label">
									Which of the following features are provided for the housemate?
								</label>
								<FormError errors={errors} fieldName={'guest_room_features'} touched={null} containerClass={'mt-1 mb-1'}/>
								<div className="row">
									{guestRoomFeatures.map((item, key) => (
									<div className="col-6" key={`guest-room-features-${item}`}>
										<div className="form-check" >
											<input className="form-check-input" id={`guest-feature-${item}`} name="guest_room_features" type="checkbox" value={item} checked={values.guest_room_features.includes(item)} onChange={handleChange} onClick={()=>{setFieldTouched('guest_room_features')}}/>
											<label className="form-check-label" htmlFor={`guest-feature-${item}`}>{item}</label>
										</div>
									</div>
									))}
								</div>

							</div>
							<div className="col-4">
								<div className="form-group"  {...includeTestId('bathroom-type')}>
									<label htmlFor="bathroom-type" className="control-label">
										Bathroom
									</label>
									<FormError errors={errors} fieldName={'bathroom'} touched={touched} containerClass={'mt-1 mb-1'}/>
									{bathroomTypes.map((item, key) => (
										<div className="form-check" key={`bathroom-type-${item}`}>
											<input name="bathroom" className="form-check-input" id={`bathroom-${item}`} type="radio" checked={item===values.bathroom} onChange={handleChange} value={item}/>
											<label className="form-check-label" htmlFor={`bathroom-${item}`}>
												{item}
											</label>
										</div>
									))}

								</div>
							</div>
						</div>


						<div className="row form-mod">
							<div className="col-4"  {...includeTestId('other-home-features')}>
								<label htmlFor="home_feature" className="control-label">
									What other features does your home have?
								</label>
								<FormError errors={errors} fieldName={'home_feature'} touched={null} containerClass={'mt-1 mb-1'}/>

								{homeFeatures.map((item, key) => (
										<div className="form-check" key={`home-features-${item}`} >
											<input className="form-check-input" id={`home-features-type-${item}`} name="home_feature" type="checkbox" value={item} checked={values.home_feature.includes(item)} onChange={handleChange} onClick={()=>{setFieldTouched('home_feature')}}/>
											<label className="form-check-label" htmlFor={`home-features-type-${item}`}>{item}</label>
										</div>
								))}

								{values.home_feature.includes('Other')&&<>
									<input className={`form-control ${hasError(errors, touched,'other_home_feature')?'error-field':''}`} name="other_home_feature" type="text" placeholder="Please enter other home feature" onChange={handleChange} onBlur={handleBlur} value={values.other_home_feature}/>
									<FormError errors={errors} fieldName={'other_home_feature'} touched={touched} containerClass={'mt-1 mb-1'}/>
								</>}
							</div>

							<div className="col-4"  {...includeTestId('parking-facility')}>
								<label htmlFor="home_feature" className="control-label">Parking</label>
								<FormError errors={errors} fieldName={'parking_available'} touched={touched} containerClass={'mt-1 mb-1'}/>

								{parkingOptions.map((item, key) => (
										<div className="form-check" key={`parking-option-key-${item}`} >
											<input className="form-check-input" id={`parking-option-${item}`} name="parking_available"  type="radio" checked={item===values.parking_available}  value={item} onChange={handleChange}/>
											<label className="form-check-label" htmlFor={`parking-option-${item}`}>{item}</label>
										</div>

								))}
							</div>

							<div className="col-4"  {...includeTestId('public-transport')}>
								<label htmlFor="home_feature" className="control-label">Public transport</label>
								<FormError errors={errors} fieldName={'nearby_public_transport'} touched={touched} containerClass={'mt-1 mb-1'}/>
								{publicTransport.map((item, key) => (
									<div className="form-check" key={`transport-key-${item}`} >
										<input className="form-check-input" id={`nearby_public_transport-${item}`} name="nearby_public_transport" type="radio" checked={item===values.nearby_public_transport}  value={item} onChange={handleChange} onBlur={handleBlur}/>
										<label className="form-check-label" htmlFor={`nearby_public_transport-${item}`}>{item}</label>
									</div>
								))}
							</div>
						</div>
						<RemoteUpdateError submissionError={errorMessages()}/>
					</div>
					<FormSubmitButtons setButtonType={setButtonType} isValid={isValid}/>
				</form>
			</div>
		</div>
	);
}

export default PracticalInclusions;
