import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import URL from '../../constants/URL';
import { ProcessModal } from '../../actions/Modal';
import { HIDE_MODAL } from '../../actions/Modal';
import { required, radioRequired } from '../../utils/FormValidators.jsx';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import { fetchCustomerCardDetail } from '../../actions/Payment';
import CardForXchangeCompletion from '../payments/CardForXchangeCompletion';
import { fetchConnectionList } from '../../actions/Connection';
import FRONTEND_RESPONSE_MESSAGE from '../../constants/FRONTEND_RESPONSE_MESSAGE';
import { viewProfile } from '../../actions/Profile';
import { RVButton, Button } from '../../../components/common';

class PaymentCompleteModal extends Component {
  constructor(props) {
    super(props);
    const fetchDefaultCardData = this.props.payment.cards
      ? this.props.payment.cards.filter(card => {
          return card.type === 'default';
        })
      : [];

    this.state = {
      requesting: true,
      payOutstandingXchangeError: '',
      payOutstandingXchangeSuccess: '',
      couponCheck: false,
      paymentFromCard: false,
      coupon: '',
      addNewCard: false,
      admin_fee: '',
      fields: {
        cardId: fetchDefaultCardData.length
          ? fetchDefaultCardData[0].card_id
          : '',
        invoiceId: this.props.invoiceId ? this.props.invoiceId : '',
        xchangeId: this.props.xchangeId
      }
    };
  }

  componentWillMount() {
    const request = axios({
      method: 'get',
      url: URL.ADMIN_FEE_API(this.props.login.token)
    });

    request.then(result => {
      if (result.data.status === 'success') {
        this.setState({
          ...this.state,
          requesting: false,
          admin_fee: result.data.data.admin_fee
        });
      } else {
        alert('Error connecting to Admin Fee API');
      }
    });
  }

  close = () => {
    const modalProps = { showModal: false };
    this.props.dispatch(ProcessModal(HIDE_MODAL, null, modalProps));
  };

  handleInputChange = e => {
    e.preventDefault();
    this.setState({ ...this.state, [e.target.name]: e.target.value });
  };

  toggleChange = () => {
    if (!this.state.couponCheck) {
      this.setState({
        couponCheck: !this.state.couponCheck
      });
    } else {
      this.setState({
        couponCheck: !this.state.couponCheck,
        coupon: ''
      });
    }
  };

  addNewCard = e => {
    e.preventDefault();
    this.setState({ addNewCard: true });
  };

  useExistingCard = e => {
    e.preventDefault();
    this.setState({ addNewCard: false });
  };

  getNotifyFromCard = () => {
    this.setState({
      paymentFromCard: true,
      payOutstandingXchangeSuccess: true
    });
  };

  payOutstandingXchange = e => {
    e.preventDefault();
    this.setState({ requesting: true });
    const request = axios({
      method: 'post',
      url: URL.PAYMENT_API,
      data: {
        user_token: this.props.login.token,
        card_id: this.state.fields.cardId,
        invoice_id: this.state.fields.invoiceId,
        exchange_id: this.props.xchangeId,
        coupon: this.state.coupon
      }
    });
    request.then(result => {
      if (result.data.status === 'success') {
        this.setState({
          requesting: false,
          payOutstandingXchangeError: '',
          payOutstandingXchangeSuccess: result.data.msg
        });
        this.props.onFetchConnectionList({
          token: this.props.login.token,
          page: 1,
          limit: 20,
          type: '',
          search: ''
        });
        this.props.fetchConnectionDetailComponent(this.props.connections);
        this.props.onFetchInvoices(this.props.login.token);
        this.props.onFetchStripeInvoices(this.props.login.token);
        this.props.onViewProfile(
          this.props.login.user_id,
          this.props.login.token
        );
      } else {
        this.setState({
          requesting: false,
          payOutstandingXchangeSuccess: '',
          payOutstandingXchangeError: result.data.msg
        });
      }
    });
  };

  getAdminFeeDisplayMessage() {
    if (this.state.admin_fee === 'PAID') return false;

    return (
      <div className="monthly-fee-message-container">
        <span className="payment-message-key">Admin fee:</span>
        <span className="payment-message-value">
          {this.state.admin_fee === 'DISABLED'
            ? 'Free for a limited time!'
            : '$99 inc GST once off payment'}
        </span>
      </div>
    );
  }

  getMonthlyFeeDisplayMessage() {
    return (
      <div className="monthly-fee-message-container">
        <span className="payment-message-key">Monthly charge:</span>
        <span className="payment-message-value">$60 inc GST</span>
      </div>
    );
  }

  getTotalOfInvoiceDisplayMessage() {
    const { connectionDetail } = this.props;

    return (
      <div className="monthly-fee-message-container">
        <span className="payment-message-key">Total of this invoice:</span>
        <span className="payment-message-value">
          {connectionDetail.exchange_type === 'long-term'
            ? `
          ${
            this.state.admin_fee === 'UNPAID' ? '$159' : '$60'
          } inc GST - charged on ${moment(
                this.props.exchange_start_date
              ).format('DD MMM YYYY')}`
            : '$25 inc GST'}
        </span>
      </div>
    );
  }

  getMonthlyRecurringDisplayMessage() {
    return (
      <div className="monthly-fee-message-container">
        <span className="payment-message-key">Monthly recurring cost:</span>
        <span className="payment-message-value">
          $60 inc GST - per month until{' '}
          {moment(this.props.exchange_end_date).format('DD MMM YYYY')}
        </span>
      </div>
    );
  }

  getEarlyInvoiceCalulateResult() {
    const { connectionDetail } = this.props;

    return (
      <div className="xch-text payment-popup-totalinvoice-message">
        <div>
          {connectionDetail.exchange_type === 'long-term' &&
            this.getAdminFeeDisplayMessage()}
          {connectionDetail.exchange_type === 'long-term' &&
            this.getMonthlyFeeDisplayMessage()}
          {this.getTotalOfInvoiceDisplayMessage()}
          {connectionDetail.exchange_type === 'long-term' &&
            this.getMonthlyRecurringDisplayMessage()}
        </div>
        <p className="margin-top-12" style={{ fontStyle: 'italic' }}>
          Note: Credit card details are still required if you’re using a coupon
          code. Please check your invoice to confirm the coupon has been
          applied. Invoices may take a few hours to appear on your account.
          <br />
        </p>
        <p className="margin-top-12">
          Find out more about our{' '}
          <a href="/pricing" target="_blank" style={{ color: '#EF7F49' }}>
            payment policy
          </a>
          .
        </p>
      </div>
    );
  }

  render() {

    const renderAvailableCard = this.props.payment.cards
      ? this.props.payment.cards.map((card, index) => {
          const expYear = card.exp_year;
          const last2DigitYear = expYear.toString().substring(2);
          return (
            <div className="row" key={index}>
              {this.state.requesting && <div className="page-loading" />}
              <div className="col-md-8 col-12">
                <div className="form-check">
                  <Input
                    className="form-check-input"
                    name="card"
                    onChange={e =>
                      this.setState({
                        ...this.state,
                        fields: { ...this.state.fields, cardId: e.target.value }
                      })
                    }
                    value={card.card_id}
                    type="radio"
                    checked={this.state.fields.cardId === card.card_id}
                    validations={[radioRequired]}
                  />
                  <label className="form-check-label">
                    <div className="input">
                      <span>
                        {card.brand} **** {card.last4} Expiry date{' '}
                        {card.exp_month}/{last2DigitYear}
                      </span>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          );
        })
      : null;

    return (
      <div>
        <div className="overlay modal-backdrop modal-dialog-centered payment-modal">
          <div className="col-md-5 mx-auto">
            {this.state.requesting && <div className="page-loading" />}
            <div className="modal-content padding-com">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  onClick={this.close}
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">
                    <i className="fa fa-close" />
                  </span>
                </button>
              </div>

              <div className="modal-body">
                {this.state.payOutstandingXchangeSuccess ? (
                  <h3>Payment Success</h3>
                ) : (
                  <h3>Complete payment to finalise your Xchange</h3>
                )}
                <div>
                  <Form className="form-signup">
                    {!this.state.addNewCard ? (
                      !this.state.payOutstandingXchangeSuccess ? (
                        !this.state.paymentFromCard &&
                        this.props.payment.cards &&
                        this.props.payment.cards.length > 0 ? (
                          <div>
                            {renderAvailableCard}
                            <div className="row">
                              <div className="col-12">
                                <div className="form-check">
                                  <input
                                    name="couponCheck"
                                    checked={this.state.couponCheck}
                                    onChange={this.toggleChange}
                                    className="form-check-input"
                                    type="checkbox"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="experience-5"
                                  >
                                    Coupon
                                  </label>
                                </div>
                                {this.state.couponCheck && (
                                  <div className="form-group">
                                    <Input
                                      type="coupon"
                                      validations={[required]}
                                      className="form-control"
                                      name="coupon"
                                      value={this.state.coupon}
                                      onChange={this.handleInputChange}
                                      style={{ height: '42px' }}
                                      placeholder="Please enter your coupon"
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        ) : (
                          <CardForXchangeCompletion
                            login={this.props.login}
                            invoiceId={this.state.fields.invoiceId}
                            xchangeId={this.state.fields.xchangeId}
                            fetchConnectionDetailComponent={
                              this.props.fetchConnectionDetailComponent
                            }
                            onFetchConnectionList={
                              this.props.onFetchConnectionList
                            }
                            connections={this.props.connections}
                            onFetchInvoices={this.props.onFetchInvoices}
                            onFetchStripeInvoices={
                              this.props.onFetchStripeInvoices
                            }
                            onFetchCustomerCardDetail={
                              this.props.onFetchCustomerCardDetail
                            }
                            payment={this.props.payment}
                            getNotifyFromCard={this.getNotifyFromCard}
                          />
                        )
                      ) : (
                        <div>
                          <p>
                            {FRONTEND_RESPONSE_MESSAGE.PAYMENT.SUCCESS_RESPONSE}
                          </p>
                          <div className="modal-footer">
                            <Button
                              onClick={this.close}
                              type="button"
                              color="teal"
                            >
                              Close
                            </Button>
                          </div>
                        </div>
                      )
                    ) : (
                      <CardForXchangeCompletion
                        login={this.props.login}
                        invoiceId={this.state.fields.invoiceId}
                        xchangeId={this.state.fields.xchangeId}
                        fetchConnectionDetailComponent={
                          this.props.fetchConnectionDetailComponent
                        }
                        onFetchConnectionList={this.props.onFetchConnectionList}
                        connections={this.props.connections}
                        onFetchInvoices={this.props.onFetchInvoices}
                        onFetchStripeInvoices={this.props.onFetchStripeInvoices}
                        onFetchCustomerCardDetail={
                          this.props.onFetchCustomerCardDetail
                        }
                        useExistingCard={this.useExistingCard}
                        payment={this.props.payment}
                        getNotifyFromCard={this.getNotifyFromCard}
                      />
                    )}

                    {this.state.payOutstandingXchangeError &&
                      !this.state.payOutstandingXchangeSuccess && (
                        <div className="alert-danger" role="alert">
                          <strong>Error: </strong>
                          {this.state.payOutstandingXchangeError}
                        </div>
                      )}

                    {!this.state.paymentFromCard &&
                      this.props.payment.cards &&
                      this.props.payment.cards.length > 0 &&
                      !this.state.payOutstandingXchangeSuccess &&
                      !this.state.addNewCard && (
                        <div className="modal-footer">
                          <Button anchor href="#" onClick={this.addNewCard}>Use new card</Button>
                          <RVButton
                            onClick={this.payOutstandingXchange}
                            type="button"
                            colo="teal"
                          >
                            Pay now
                          </RVButton>
                        </div>
                      )}
                    {!this.state.payOutstandingXchangeSuccess &&
                      this.getEarlyInvoiceCalulateResult()}
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  onFetchCustomerCardDetail: token => dispatch(fetchCustomerCardDetail(token)),
  onFetchConnectionList: params => dispatch(fetchConnectionList(params)),
  onViewProfile: (user_id, token) => dispatch(viewProfile(user_id, token)),
  dispatch
});

const mapStateToProps = store => ({
  login: store.login.user,
  payment: store.payment,
  profile: store.profile.profile
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PaymentCompleteModal));
