import React from 'react';
import { SignUpStep } from './SignUpStep';
import { object, string, addMethod, mixed, ref } from 'yup';
import { Form, Field } from 'formik';
import { TextField } from '../forms';
import { Button } from 'components/common';

const Step3Fields = {
  PASSWORD: 'password',
  CONFIRM_PASSWORD: 'confirm_password',
  POLICY: 'policy'
};

export const sameAsValidator = function(ref, message) {
  return this.test('sameAs', message, function(value) {
    const other = this.resolve(ref);

    return (!other && !value) || value === other;
  });
};

addMethod(mixed, 'sameAs', sameAsValidator);

const step3Validation = object({
  [Step3Fields.PASSWORD]: string()
    .required('Please enter your password')
    .min(8, 'Password must have at least 8 characters')
    .matches(/(?=.*\d)(?=.*[a-zA-Z])/, {
      message: 'Password requires at least 1 letter and 1 number'
    }),
  [Step3Fields.CONFIRM_PASSWORD]: mixed()
    .required('Please confirm your password')
    .sameAs(ref('password'), 'Confirm password does not match'),
  [Step3Fields.POLICY]: string().required(
    'Please accept our terms'
  )
});

export const step3InitialData = {
  password: '',
  confirm_password: '',
  policy: ''
};

export const SignUpStep3 = ({
  onSubmit,
  onPrevious,
  data,
  errors: signupErrors = ''
}) => (
  <SignUpStep
    onSubmit={onSubmit}
    data={data}
    validation={step3Validation}
    renderForm={({ errors, touched, values }) => (
      <Form>
        <div className="card-wrapper bg-light">
          <div className="row">
            <div className="col-md-6">
              <TextField
                type="password"
                placeholder="Enter your password"
                name={Step3Fields.PASSWORD}
                label="Password"
              />
            </div>
            <div className="col-md-6">
              <TextField
                type="password"
                placeholder="Confirm your password"
                name={Step3Fields.CONFIRM_PASSWORD}
                error={
                  errors.confirm_password && touched.confirm_password
                    ? errors.confirm_password
                    : null
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="password__requirement">
                <h4
                  className={`password__requirement__term ${
                    touched.password
                      ? values.password && values.password.length >= 8 &&
                        /(?=.*\d)(?=.*[a-zA-Z])/.test(values.password)
                        ? 'correct'
                        : 'error'
                      : ''
                  }`}
                >
                  ***Your password should have at least 8 characters, including 1 alphabetical letter and 1 number.
                </h4>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <div className="form-check">
                  <Field
                    className="form-check-input"
                    name={Step3Fields.POLICY}
                    type="checkbox"
                  />
                  <label className="form-check-label">
                    <p className="term_and_conditions">
                      I have read and accept The Room Xchange{' '}
                      <a target="_blank" href="/privacy">
                        privacy policy
                      </a>
                      ,{' '}
                      <a target="_blank" href="/marketplace">
                        marketplace terms and conditions
                      </a>{' '}
                      and{' '}
                      <a target="_blank" href="/terms">
                        website terms of use
                      </a>
                      .
                    </p>
                  </label>
                </div>
                <div className="has-error">
                  <span className="help-block">
                    {errors.policy && touched.policy ? errors.policy : null}
                  </span>
                </div>
              </div>
            </div>
          </div>
          {signupErrors && <div className="signup__errors">{signupErrors}</div>}
          <div className="signup__controls">
            <Button
              type="button"
              color="grey"
              onClick={onPrevious}
            >
              Previous
            </Button>
            <Button 
              type="submit"
              color="teal"
            >
              Submit
            </Button>
          </div>
        </div>
      </Form>
    )}
  />
);
