import React,{ Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import {required, confirmpassword, gt, passwordValidation} from '../utils/FormValidators.jsx';
import URL from '../constants/URL';
import { RVButton as Button } from '../../components/common'

class ChangePassword extends Component {
  constructor(props){
    super(props);
    this.state={
      requesting:false,
      oldPassword:'',
      password:'',
      confirmPassword:'',
      successMessage:'',
      errorMessage:''
    }

    this.handleSubmit=this.handleSubmit.bind(this);
    this.handleInputChange=this.handleInputChange.bind(this);
    this.displaySuccessMessage = this.displaySuccessMessage.bind(this);
    this.displayErrorMessage = this.displayErrorMessage.bind(this);
  }

  handleErrorMessage = (response) => {
    this.setState({
      requesting:false
    });
    this.displayErrorMessage(response.data.msg);
  }

  handleSubmit(event){
    event.preventDefault();

    this.setState({requesting:true, successMessage:'', errorMessage:''});
    const request = axios({
      method:"put",
      url:URL.CHANGE_PASSWORD(this.props.user.user_id, this.props.user.token),
      data:{
        oldPassword:this.state.oldPassword,
        newPassword:this.state.password,
        confirmPassword:this.state.confirmPassword

      }
    })

    request.then((result) => {
      if(result.data.status==="success"){
        this.setState({
          requesting:false
        });
        this.displaySuccessMessage(result.data.msg);
      }else{
        this.handleErrorMessage(result);
      }
    }).catch((e) => {
      this.handleErrorMessage(e.response);
    })
  }

  displaySuccessMessage(message) {
      this.setState({
        successMessage:message
      });

      setTimeout(function(){
        this.setState({successMessage: ""});
      }.bind(this), 5000);

    }

    displayErrorMessage(message) {
      this.setState({
        errorMessage: message
      });

      setTimeout(function(){
        this.setState({errorMesssage: ""});
      }.bind(this), 5000);

    }


  handleInputChange(event){
    this.setState({[event.target.name]:event.target.value})
  }

  render(){
    return(
      <div>
        <Form className="form-signup" onSubmit={this.handleSubmit}>
          <div className="row">
            {this.state.requesting ===true && (
              <div className="page-loading"></div>
            )}
            <div className="col-md-4 col-sm-6 col-12">
              <div className="form-group">
                <label className="control-label">Current password</label>
                <Input
                  validations={[required]}
                  type="password"
                  name="oldPassword"
                  value={this.state.oldPassword}
                  onChange={this.handleInputChange}
                  className="form-control"
                  id="old-password"
                />
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-12">
              <div className="form-group">
                <label className="control-label">New password</label>
                <Input
                  minLength={5}
                  validations={[required, passwordValidation]}
                  type="password"
                  name="password"
                  onChange={this.handleInputChange}
                  className="form-control"
                  id="new-password"
                  value={this.state.password}
                />
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-12">
              <div className="form-group">
                <label className="control-label">Confirm password</label>
                <Input
                  validations={[required, confirmpassword]}
                  type="password"
                  name="confirmPassword"
                  value={this.state.confirmPassword}
                  onChange={this.handleInputChange}
                  className="form-control"
                  id="confirm-password"
                  />
                <div>

                </div>

              </div>{/*form-group*/}
            </div>{/*col-4*/}
          </div>{/*row*/}
          {this.state.errorMessage  && (
            <div className="alert alert-danger" role="alert">
              <strong>Error: </strong>{this.state.errorMessage}
              </div>
          )}
          {this.state.successMessage && (
            <div className="alert alert-success" role="alert">
              <strong>Success: </strong>{this.state.successMessage}
              </div>
          )}
          <Button type="submit" className="float-right">Submit</Button>
        </Form>
      </div>

    )
  }

}


const mapStateToProps = (store) => {
  return{
    user:store.login.user
  }
}
export default connect(mapStateToProps)(ChangePassword);
