import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import URL from '../../constants/URL';
import { ProcessModal } from '../../actions/Modal';
import { HIDE_MODAL } from '../../actions/Modal';
import momentTimeZone from 'moment-timezone';

class PreviousInvoicesModal extends Component {
  state = {
    requesting: false,
    fields: {}
  };

  close = () => {
    const modalProps = { showModal: false };
    this.props.dispatch(ProcessModal(HIDE_MODAL, null, modalProps));
  };

  render() {
    return (
      <div>
        <div className="overlay modal-backdrop modal-dialog-centered invoice-modal">
          <div className="col-md-6 offset-md-3">
            <div className="modal-content padding-com">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  onClick={this.close}
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">
                    <i className="fa fa-close" />
                  </span>
                </button>
              </div>

              <div className="modal-body">
                <div>
                  <h3 className="text-left">Previous Invoices</h3>
                  <div className="pre-invoice">
                    <ul>
                      {this.props.stripeInvoices.map(
                        (stripeInvoices, index) => {
                          const date = momentTimeZone(
                            stripeInvoices.date * 1000
                          )
                            .tz('Australia/Sydney')
                            .format('DD/MM/YYYY');
                          const amountPaid = stripeInvoices.amount_paid / 100;
                          return (
                            <li key={index}>
                              <a
                                href={URL.DOWNLOAD_INVOICE_API(
                                  this.props.login.token,
                                  stripeInvoices.id
                                )}
                                className="download-inv"
                                target="_blank"
                                download={`invoice ${stripeInvoices.id}`}
                              >
                                <span />
                              </a>
                              <span>
                                Xchange Invoice {date} - ${amountPaid}
                              </span>
                            </li>
                          );
                        }
                      )}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                <button
                  onClick={this.close}
                  type="button"
                  className="btn btn-apps btn-org text-white"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  };
};
const mapStateToProps = store => {
  return {
    login: store.login.user
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PreviousInvoicesModal));
