import React, { FC } from 'react';
import moment from 'moment';
import { Button } from '../../../components/common';
import toLower from "lodash/toLower";

const formatDate = (date: string) => {
	return moment(date).format('DD MMM YYYY');
};

interface PendingToProps {
	to_user_first_name: string;
	exchange_start_date: string;
	exchange_period: string;
	to_user_seen_status: 'Unseen' | 'Seen';
}

export const PendingTo: FC<PendingToProps> = ({
	to_user_first_name,
	exchange_start_date,
	exchange_period,
	to_user_seen_status,
}) => {
	return (
		<div className="text msg-note">
			<p>
				You have requested to connect with {to_user_first_name} about a
				possible house-sharing arrangement from {formatDate(exchange_start_date)}.
				If they accept, then you can safely and securely chat with them.
			</p>
			<p>
				{to_user_first_name}{toLower(to_user_seen_status) == 'seen' ? ' has viewed your request but not accepted or declined, yet.' : ' has not viewed your request, yet.'}
			</p>
		</div>
	);
};

interface PendingFromProps {
	from_user_first_name: string;
	exchange_start_date: string;
	exchange_period: string;
	acceptConnectionRequest: () => void;
	declineConnectionRequest: () => void;
}

export const PendingFrom: FC<PendingFromProps> = ({
	from_user_first_name,
	exchange_start_date,
	exchange_period,
	acceptConnectionRequest,
	declineConnectionRequest
}) => {
	return (
		<React.Fragment>
			<div className="text msg-note">
				{from_user_first_name} has requested to connect with you and
				have a chat about a possible house share from{' '}
				{formatDate(exchange_start_date)} . If you accept, then you can
				safely chat with them here. They will also be notified via
				text message that you have reached out to them. Make sure you
				come back and check if they have replied.
			</div>
			<div className="btn-panel">
				<Button
					color="teal"
					data-toggle="modal"
					onClick={acceptConnectionRequest}
				>
					accept
				</Button>
				<Button data-toggle="modal" onClick={declineConnectionRequest}>
					decline
				</Button>
			</div>
		</React.Fragment>
	);
};

interface AcceptFromProps {
	from_user_first_name: string;
	opponentUserRole: string;
}

export const AcceptFrom: FC<AcceptFromProps> = ({
	from_user_first_name
}) => {
	return (
		<div className="text msg-note">
			You have accepted the request from {from_user_first_name} to
			connect. Ask some good questions in the chat area to see if you're the right fit.
             If it all goes well you can take the next step and meet offline or set up a phone call.
		</div>
	);
};

interface AcceptToProps {
	to_user_first_name: string;
	opponentUserRole: string;
}
export const AcceptTo: FC<AcceptToProps> = ({
	to_user_first_name
}) => {
	return (
		<div className="text msg-note">
			{to_user_first_name} has accepted your request to connect.
			 Ask some good questions in the chat area to see if you're the right fit.
             If it all goes well you can take the next step and meet offline or set up a phone call.
		</div>
	);
};

export const DeclineFrom: FC<any> = () => {
	return (
		<div className="text msg-note">
			You have declined this house-sharing arrangement, you will not be able to communicate
			again unless you request another house-sharing arrangement.
		</div>
	);
};

interface DeclineToProps {
	to_user_first_name: string;
}
export const DeclineTo: FC<DeclineToProps> = ({ to_user_first_name }) => {
	return (
		<div className="text msg-note">
			{to_user_first_name} have declined this house-sharing arrangement, you will not be
			able to communicate again unless they request another house-sharing arrangement.
		</div>
	);
};

export const ExchangePendingTo: FC<any> = ({
	exchange_to_user_first_name,
	current_exchange_start_date,
	current_exchange_end_date
}) => {
	return (
		<div className="text msg-note">
			Congratulations! You have sent an house-sharing arrangement request to{' '}
			{exchange_to_user_first_name} starting{' '}
			{formatDate(current_exchange_start_date)} and ending{' '}
			{formatDate(current_exchange_end_date)}. Once they have responded,
			you will get an update here. You can then make your payment and
			finalise your house-sharing arrangement. When you finalise your payment you are
			covered by our insurance and have access to our customer care team.
		</div>
	);
};
export const ExchangePendingFrom: FC<any> = ({
	exchange_from_user_first_name,
	current_exchange_start_date,
	current_exchange_end_date,
	acceptXchangeRequest,
	openXchangeDeclineModal
}) => {
	return (
		<React.Fragment>
			<div className="text msg-note">
				How exciting! {exchange_from_user_first_name} has requested to
				start an house-sharing arrangement with you from{' '}
				{formatDate(current_exchange_start_date)} to{' '}
				{formatDate(current_exchange_end_date)}.
			</div>
			<div className="btn-panel">
				<Button
					color="teal"
					data-toggle="modal"
					onClick={acceptXchangeRequest}
				>
					accept
				</Button>
				<Button data-toggle="modal" onClick={openXchangeDeclineModal}>
					decline
				</Button>
			</div>
		</React.Fragment>
	);
};
export const ExchangeAcceptTo: FC<any> = ({
	invoice,
	exchange_to_user_first_name,
	openPaymentCompleteModal,
	current_exchange_start_date
}) => {
	return invoice && invoice.from_user === 'OUTSTANDING' ? (
		<React.Fragment>
			<div className="text msg-note">
				Great news! {exchange_to_user_first_name} has accepted your
				house-sharing arrangement request. To finalise the house-sharing arrangement, both parties need to
				complete the payment process.
			</div>
			<div className="btn-panel">
				<Button data-toggle="modal" onClick={openPaymentCompleteModal}>
					PAY NOW
				</Button>
			</div>
		</React.Fragment>
	) : (
		<div className="text msg-note">
			Thank you for your payment details, once{' '}
			{exchange_to_user_first_name} provides their payment information
			your house-sharing arrangement will be finalised, with the first payment amount to be
			charged on {formatDate(current_exchange_start_date)}.
		</div>
	);
};
export const ExchangeAcceptFrom: FC<any> = ({
	invoice,
	openPaymentCompleteModal,
	exchange_from_user_first_name,
	current_exchange_start_date
}) => {
	return invoice && invoice.from_user === 'OUTSTANDING' ? (
		<React.Fragment>
			<div className="text msg-note">
				Almost there! To finalise the house-sharing arrangement, both parties need to
				complete the payment process.
			</div>
			<div className="btn-panel">
				<Button
					type="submit"
					data-toggle="modal"
					onClick={openPaymentCompleteModal}
				>
					Pay now
				</Button>
			</div>
		</React.Fragment>
	) : (
		<div className="text msg-note">
			Thank you for your payment details, once{' '}
			{exchange_from_user_first_name} provides their payment information
			your house-sharing arrangement will be finalised, with the first payment amount to be
			charged on {formatDate(current_exchange_start_date)}.
		</div>
	);
};
export const PaidTo: FC<any> = ({
	exchange_to_user_first_name,
	current_exchange_start_date,
	current_exchange_end_date
}) => {
	return (
		<div className="text msg-note">
			Fantastic news, {exchange_to_user_first_name} has finalised their
			payment as well, so you’re all set. The house-sharing arrangement will start on{' '}
			{formatDate(current_exchange_start_date)} and end on{' '}
			{formatDate(current_exchange_end_date)}. As a reminder, your first
			payment will be charged on your start date. If you have any
			questions, please{' '}
			<a style={{ color: '#EF7F49' }} target="_blank" href="/contact">
				contact us
			</a>
			. We also have lots of resources on our{' '}
			<a style={{ color: '#EF7F49' }} target="_blank" href="/blog">
				blog
			</a>{' '}
			to help you get the best out of your stay. Enjoy!
		</div>
	);
};
export const PaidFrom: FC<any> = ({
	exchange_from_user_first_name,
	current_exchange_start_date,
	current_exchange_end_date
}) => {
	return (
		<div className="text msg-note">
			Fantastic news, {exchange_from_user_first_name} has finalised their
			payment as well, so you’re all set. The house-sharing arrangement will start on{' '}
			{moment(current_exchange_start_date)} and end on{' '}
			{moment(current_exchange_end_date)}. As a reminder, your first
			payment will be charged on your start date. If you have any
			questions, please{' '}
			<a style={{ color: '#EF7F49' }} target="_blank" href="/contact">
				contact us
			</a>
			. We also have lots of resources on our{' '}
			<a style={{ color: '#EF7F49' }} target="_blank" href="/blog">
				blog
			</a>{' '}
			to help you get the best out of your stay. Enjoy!
		</div>
	);
};
export const ExchangeDeclineTo: FC<any> = ({ exchange_to_user_first_name }) => {
	return (
		<div className="text msg-note">
			{exchange_to_user_first_name} have declined this house-sharing arrangement, you will
			not be able to communicate again unless they request another
			house-sharing arrangement.
		</div>
	);
};
export const ExchangeDeclineFrom: FC<any> = () => {
	return (
		<div className="text msg-note">
			You have declined this house-sharing arrangement, you will not be able to communicate
			again unless you request another house-sharing arrangement.
		</div>
	);
};
