import { RoleCode } from '../../store/Users/constants';

export const roleLabel = {
  [RoleCode.HOST]: 'households',
  [RoleCode.GUEST]: 'housemates'
};

export const paramRoles = {
  hosts: RoleCode.HOST,
  guests: RoleCode.GUEST
};

export const optPeriod = [
  { value: '1-3 months', label: `1-3 months` },
  { value: '3-6 months', label: `3-6 months` },
  { value: '6-12 months', label: `6-12 months` },
  { value: '12+ months', label: `12+ months` }
];

export const optRentOffset = [
  { value: '1', label: 'Yes' },
  { value: '0', label: 'No' }
];

export const optRentBudget = [
    {label: 'Less than $100', value: `less than 100`},
    {label: '$100-$150', value: `100-150`},
    {label: '$151-$200', value: `151-200`},
    {label: 'More than $200', value: `more than 200`}
];

export const optAge = [
  { value: '0', label: 'Any' },
  { value: '18-25', label: '18-25' },
  { value: '25-35', label: '25-35' },
  { value: '35-45', label: '35-45' },
  { value: '45-55', label: '45-55' },
  { value: '55-65', label: '55-65' },
  { value: '65+', label: '65+' },
];

export const optGender = [
  { value: 'male', label: 'Male' },
  { value: 'female', label: 'Female' },
  { value: '0', label: "Don't mind" }
];
