import React, { memo } from 'react';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { checkPermission } from '../constants/Permissions';
import { initUserSessionAction } from  '../actions/Login';
import { DEFAULT_PATH } from '../routes';
import './styles.scss';


const AccessDenied = () => (
  <div>
    <div className="header-mod col-md-12 col-sm-12 col-12">
      <div className="col-md-10 col-sm-12 col-12">
        <div className="main-content">
          <h1>ACCESS DENIED</h1>
          <p>You do not have permission to view this page.</p>
        </div>
      </div>
    </div>
  </div>
);

 const PermissionRoute = ({
  component: Component,
  componentType,
  permissions,
  user,
  ...rest
}) => {
  if(!get(user, 'id', false)){
    rest.initUserSession(false).catch((e) => {
      window.location.href = DEFAULT_PATH;
    });
  }
  if(permissions.length === 0)
    return (<div className="content-section"></div>);
  else
    return (
      <Route
        {...rest}
        render={props =>
          checkPermission(componentType, permissions) ? (
            <div className="content-section"><Component {...props} /></div>
          ) : (<AccessDenied />)
        }
      />
    );
}


const mapStateToProps = (state) => {
  return {
    user: state.profile.data,
    permissions: state.profile.userPermissions,
  };
};


const mapDispatchToProps = dispatch => {
  return {
    initUserSession: (optional) => dispatch(initUserSessionAction(optional)),
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(memo(PermissionRoute));
