import { RoleCode } from 'store/Users/constants';
import get from 'lodash/get';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import size from 'lodash/size';
import remove from 'lodash/remove';
import compact from 'lodash/compact';
import join from 'lodash/join';
import toString from 'lodash/toString';
import includes from 'lodash/includes';

export const defaultProfileValue = '—';

export const profileSections = {
	host: {
		about: 'about',
		lifestyle: 'lifestyle',
		priorities: 'priorities',
		personality: 'personality',
		practicalInclusion: 'practicalInclusion',
		photos: 'photos'
	},
	guest: {
		about: 'about',
		lifestyle: 'lifestyle',
		priorities: 'priorities',
		interests: 'interests',
		personality: 'personality',
		photos:'photos'
	}
}

export const profileRequiredFields = {
	host: {
		[profileSections.host.about]: ["self_description", "offering_to_guest", "first_language", "gender_preference", "lgbtqi_friendly", "home_type", "distance_from_city", "no_of_people_in_house", "age_group", "exchange_period", "hours_of_help", "room_value"],
		[profileSections.host.lifestyle]: ["food_preference", "activities",  (state: any) => state.rent_offset === 'Rent offset' ? isValidValue(state.help_tasks) : false, "smoking_preference"],
		[profileSections.host.priorities]: ["priorities"],
		[profileSections.host.personality]: ["household_personalities"],
		[profileSections.host.practicalInclusion]: ["guest_room_features", "bathroom", "home_feature", "parking_available", "nearby_public_transport"],
		[profileSections.host.photos]: [(state: any) => !get(state.profileMain, 'image_id', false)],
	},
	guest: {
		[profileSections.guest.about]: ["describes_you", "additional_info", "offering", "first_language", "describesyou", "rental_budget"],
		[profileSections.guest.lifestyle]: ["food_preference", "smoking_preference", "lgbtqi_friendly", "pet", "bathroom_share", "car", "carpark_required", "public_transport_required", "furniture_have", "dealbreakers", "experiencesWithHost"],
		[profileSections.guest.priorities]: ["priorities"],
		[profileSections.guest.interests]: [(state: any) => state.rent_offset === 'Rent offset' ? isValidValue(state.tasks) : false, "talents"],
		[profileSections.guest.personality]: ["personality"],
		[profileSections.guest.photos]: [(state: any) => !get(state.profileMain, 'image_id', false)]
	}
}

const isValidValue = (value: string) => {
	if(Array.isArray(value))
		return isEmpty(value);
	return isEmpty(`${value}`) || isNull(value)
}

export const isProfileComplete = (userType: "host" | "guest", preference: any, section: keyof typeof profileSections.host | keyof typeof profileSections.guest) => {
	if(section) {
		const data = filter(get(profileRequiredFields, `${userType}.${section}`), (field) => {
			if(typeof field === "function")
				return field(preference);
			return isValidValue(preference[field])
		});
		return size(data) === 0;
	}
	return true;
}

export const getUserAvatar = () => process.env.PUBLIC_URL + '/themes/theroomxchange/assets/images/useravatar.png';

export const getGuestLabel = (
	role: string,
	guestLabel: string,
	defaultVal: string
) => {
	return role === RoleCode.GUEST ? guestLabel : defaultVal;
};

export const isJson = (str: string) => {
	try {
		JSON.parse(str);
	} catch (e) {
		return false;
	}
	return true;
};

export const renderRadioValue = (value: null | string) => {
	const radioVal = { '0': 'No', '1': 'Yes' };
	return get(radioVal, `[${toString(value)}]`, '');
};

export const getSmokingLabel = (value: number, role: string) => {
	switch (value) {
		case 0:
			return getGuestLabel(role, 'Non-smoker', 'Non-smoking');
		case 1:
			return getGuestLabel(role, 'Smoker', 'Outside is okay');
		case 2:
			return "I don't mind";
		default:
			return '';
	}
};

export const getLanguageSpokenValue = (
	other_language: string,
	first_language: string
) => {
	const data = [
		first_language,
		other_language !== 'No' ? other_language : ''
	];
	return join(compact(data), ' | ');
};

export const getHostPetsContent = (
	comfortable_with_pet: string | number,
	pets: string,
	other_pets: string
) => {
	if (comfortable_with_pet === null) return '';
	if (comfortable_with_pet){
		let resp = pets === 'other' ? other_pets : pets;
		if(pets === 'Both'){
			return 'Dog & Cat';
		}
		return resp;
	}
	return `No`;
};

export const getHomeFeature = (home_feature: string, other_home_feature: string) => {
	const data = home_feature && isJson(home_feature) && JSON.parse(home_feature);
	if(includes(data, 'Other')){
		remove(data, (task: string) => task === 'Other');
		return [...data, other_home_feature];
	}
	return data;
};

export const getGuestRoomFeatures = (guest_room_features: string) => {
	return guest_room_features && isJson(guest_room_features) && JSON.parse(guest_room_features).join(", ");
};

export const getAdditionalNeeds = (additional_needs: string) => {
	return additional_needs && isJson(additional_needs) && JSON.parse(additional_needs).join(", ");
};

export const getHouseholdPersonalities = (household_personalities: string) => {
	return household_personalities && isJson(household_personalities) && JSON.parse(household_personalities);
};

export const getHelpTasks = (help_tasks: string, other_help_tasks: string) => {
	const tasks = help_tasks && isJson(help_tasks) && JSON.parse(help_tasks);
	if(includes(tasks, 'Other')){
		remove(tasks, (task: string) => task === 'Other');
		return [...tasks, other_help_tasks];
	}
	return tasks;
};

export const getDomesticHelps = (tasks_to_do: string, tasks_to_do_other: string) => {
	const tasks = tasks_to_do && isJson(tasks_to_do) && JSON.parse(tasks_to_do);
	if(includes(tasks, 'Other')){
		remove(tasks, (task: string) => task === 'Other');
		return [...tasks, tasks_to_do_other];
	}
	return tasks;
};

export const getTalents = (talents: string, skills_other: string) => {
	const data = talents && isJson(talents) && JSON.parse(talents);
	if(includes(data, 'Other')){
		remove(data, (task: string) => task === 'Other');
		return [...data, skills_other];
	}
	return data;
};

export const getActivities = (activities: string, otherActivities?: string) => {
	const data = activities && isJson(activities) && JSON.parse(activities);
	if(includes(data, 'Other') && otherActivities){
		remove(data, (task: string) => task === 'Other');
		return [...data, otherActivities];
	}
	return data;
};

export const getTopPriorities = (exchange_priorities: string) => {
	return (
		exchange_priorities &&
		isJson(exchange_priorities) &&
		JSON.parse(exchange_priorities)
	);
};

