import React, { Component } from 'react';
import get from 'lodash/get';
import capitalize from 'lodash/capitalize';
import { Link } from 'react-router-dom';
import { checkPermission } from '../../constants/Permissions';
import Login from './Login';
import MENUS from '../../constants/Menu';
import NotificationBubble from '../messages/NotificationBubble.js';
import Nav from '../../../components/UserNav';
import { Button } from '../../../components/common';
import { RoleLabel } from '../../../store/Users/constants';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      profileDropdownView: false
    };
    this.handleLogout = this.handleLogout.bind(this);
    this.closeMenuOnOutsideClick = this.closeMenuOnOutsideClick.bind(this);
  }

  handleLogout() {
    this.props.onLogout();
  }



  // LOGO CONTENT
  getLogoContent = () => {
    return (
      <div className="col-3">
        <div className="logo">
          <a href="/">
            <img
              src={process.env.PUBLIC_URL + "/themes/theroomxchange/assets/images/trx-logo.png"}
              alt="logo"
              className="img-fluid"
            />
          </a>
        </div>
      </div>
    );
  };

  getMobileViewLogo = () => (
    <a className="header__logo" href="/">
      <img
        src={process.env.PUBLIC_URL + "/themes/theroomxchange/assets/images/trx-small-logo.png"}
        alt="logo"
        className="img-fluid small-logo"
      />
    </a>
  );

  //HOW IT WORKS MENU
  getHowItWorksMenuItem = () => (
    <li className="nav-item">
      <a className="nav-link" href="/how-it-works">
        How it works
      </a>
    </li>
  );

  getHowItWorksMenuDropDown = () => {
    return (
      <li className="nav-item dropdown how-it-works-dropdown">
        <a
          className="nav-link dropdown-toggle"
          href="/how-it-works"
          id="navbarDropdown"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        />
        <div
          className="dropdown-menu dropdown-menu-right"
          aria-labelledby="navbarDropdown"
        >
          <a className="dropdown-item" href="/faq">
            FAQ's
          </a>
          <a className="dropdown-item" href="/insurance">
            Insurance
          </a>
        </div>
      </li>
    );
  };

  //GENERIC MENU ITEM
  getGenericMenuItem = (name, href) => (
    <li className="nav-item">
      <a className="nav-link" href={href}>
        {name}
      </a>
    </li>
  );

  getLoginMenuItem = () => {
    return (
      <li className="nav-item">
        <a
          style={{ cursor: 'pointer' }}
          href="#"
          className="nav-link"
          data-toggle="modal"
          data-target="#trx-login"
        >
          Login
        </a>
      </li>
    );
  };

  //CHECK USERS
  isLoggedInUser = () => !!this.props.login.user;

  showPublicLinks = () =>
    !this.isLoggedInUser() || this.props.origin == 'publicsite';

  //DESKTOP VIEW
  getDesktopViewNonLoggedInMenu = () => (
    <ul className="navbar-nav">
      {this.showPublicLinks() && (
        <React.Fragment>
          {this.getGenericMenuItem(`${capitalize(RoleLabel.HOST.toLowerCase())}s`, '/households')}
          {this.getGenericMenuItem(`${capitalize(RoleLabel.GUEST.toLowerCase())}s`, '/housemates')}
          {this.getGenericMenuItem(`Blog`, '/blog')}
          {this.getGenericMenuItem('Services', '/services')}
        </React.Fragment>
      )}
      {!this.isLoggedInUser() && (
        <>
          {this.getLoginMenuItem()}
          <li className="nav-item">
            <Button anchor size="large" href={"/register"}>Get started</Button>
          </li>
        </>
      )}
    </ul>
  );

  getBrowseGuestDesktopView = permission => {
    return checkPermission('BROWSE_GUEST', permission) && (
      <li className="nav-item nav-item-header-browse-button">
        {this.showPublicLinks() ? (
          <Button size="large" anchor href={process.env.PUBLIC_URL + "/search?mode=guests"}>
              Search
          </Button>
        ) : (
          <Button size="large" href="/search?mode=guests">Search</Button>
        )}
      </li>
    );
  }

  getBrowseHostDesktopView = permission => {
    if (checkPermission('BROWSE_HOST', permission)) {
      return (
        <li className="nav-item">
          {this.showPublicLinks() ? (
            <Button size="large" anchor href={process.env.PUBLIC_URL + "/search?mode=hosts"}>
              Search
            </Button>
          ) : (
            <Button size="large" href="/search?mode=guests">Search</Button>
          )}
        </li>
      );
    }

    return false;
  };

  getMessageNotificationDesktopView = permission => {
    if (checkPermission('MESSAGES', permission)) {
      return (
        <li className="nav-item">
          {this.showPublicLinks() ? (
            <a href={process.env.PUBLIC_URL + "/message"} className="nav-link messagecta">
              <i className="fa fa-envelope-o" />
              <NotificationBubble
                connection={this.props.connection}
                userId={this.props.login.user.user_id}
              />
              <span className="msg-nav">Messages</span>
            </a>
          ) : (
            <Link to="/message" className="nav-link messagecta">
              <i className="fa fa-envelope-o" />
              <NotificationBubble
                connection={this.props.connection}
                userId={this.props.login.user.user_id}
              />
              <span className="msg-nav">Messages</span>
            </Link>
          )}
        </li>
      );
    }

    return false;
  };

  closeMenuOnOutsideClick = event => {
    if (this.state.profileDropdownView) {
      this.setState({
        profileDropdownView: false
      });
      document.removeEventListener('click', this.closeMenuOnOutsideClick);
    }
  };

  activateDocumentClickListner = () => {
    if (this.state.profileDropdownView) {
      document.addEventListener('click', this.closeMenuOnOutsideClick);
    } else {
      document.removeEventListener('click', this.closeMenuOnOutsideClick);
    }
  };

  onProfileDropdownClick = () => {
    this.setState(
      {
        profileDropdownView: !this.state.profileDropdownView
      },
      this.activateDocumentClickListner
    );
  };

  getProfileDropdownMenu = () => {
    const avatar = process.env.PUBLIC_URL + '/themes/theroomxchange/assets/images/useravatar.png';
    const image = get(this.props, 'profile.profile_picture', avatar)

    return (
      <span
        className="header-profile-dropdown-wrapper"
        onClick={this.onProfileDropdownClick.bind()}
      >
        <button
          type="button"
          className="btn btn-primary dropdown-toggle"
          style={{ backgroundImage: `url(${image})` }}
        />
      </span>
    );
  };

  renderGetVerifiedLink = permission => {
    if (
      permission.indexOf('ACTIVE') > -1 &&
      permission.indexOf('VERIFIED') < 0
    ) {
      if (this.props.origin === 'publicsite') {
        return (
          <li>
            <Button anchor className="dropdown-item" color="teal" href="/app/dashboard/badges" >Get Verified</Button>
          </li>
        );
      } else {
        return (
          <li>
            <Button className="dropdown-item" color="teal" href="/dashboard/badges" >Get Verified</Button>
          </li>
        );
      }
    }

    return false;
  };

  getMenuFromConstants = permission => {
    return MENUS.map((menu, index) => {
      if (checkPermission(menu.key, permission) === true) {
        if (this.props.origin === 'publicsite') {
          return (
            <li key={menu.text}><a className="dropdown-item" key={index} href={`/app${menu.url}`}>
              {menu.icon && <i className={`fa fa-${menu.icon}`}></i>}
              {menu.text}
            </a></li>
          );
        } else {
          return (
            <li key={menu.text}><Link className="dropdown-item" key={index} to={`${menu.url}`}>
              {menu.icon && <i className={`fa fa-${menu.icon}`}></i>}
              {menu.text}
            </Link></li>
          );
        }
      }
    });
  };

  getDesktopLoggedInUserMenu = () => {
    let permission = this.props.permission ? this.props.permission : [];
    const origin = this.props.origin;
    const dropdownProfileClassName = this.state.profileDropdownView
      ? 'dropdown profile show'
      : 'dropdown profile';
    const dropdownMenuClassName = this.state.profileDropdownView
      ? 'dropdown-menu dropdown-menu-right show'
      : 'dropdown-menu dropdown-menu-right';

    return (
      <div className="login-menu">
        <ul className="navbar-nav">
          {this.showPublicLinks() &&
            this.getGenericMenuItem('Services', '/services')}

          {this.showPublicLinks() && this.getGenericMenuItem('Blog', '/blog')}

          {this.getBrowseGuestDesktopView(permission)}
          {this.getBrowseHostDesktopView(permission)}
          {this.getMessageNotificationDesktopView(permission)}

          <li>
            <div className={dropdownProfileClassName}>
              {this.getProfileDropdownMenu()}

              <div className={dropdownMenuClassName}>
                <ul className="header-dropdown-menu">
                  {this.renderGetVerifiedLink(permission)}

                  {this.getMenuFromConstants(permission)}
                  <li>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={this.handleLogout}
                    >
                      <i className="fa fa-sign-out"></i>
                      Logout
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </div>
    );
  };

  getDesktopViewMenu = () => {
    let permission = this.props.permission ? this.props.permission : [];
    const { origin, login } = this.props;

    let linkToHome = login.user == null ? '/' : '/app';

    return (
      <div className="desktop-main-nav">
        <div className="container">
          <div className="row">
            {this.getLogoContent()}

            <div className="col-9">
              <nav className="navbar navbar-expand">
                {!this.isLoggedInUser() && this.getDesktopViewNonLoggedInMenu()}
                {this.isLoggedInUser() && this.getDesktopLoggedInUserMenu()}
              </nav>
            </div>
          </div>
        </div>
      </div>
    );
  };

  getMobileViewMenuToggle = () => {
    return (
      <button
        className="navbar-toggler navbar-toggler-left collapsed"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNav1"
        aria-controls="navbarNav1"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon open" />
        <span
          className="navbar-toggler-icon close"
          style={{ background: 'none' }}
        >
          <i
            style={{ fontSize: '30px', lineHeight: 'normal' }}
            className="fa fa-close"
          />
        </span>
      </button>
    );
  };

  renderNonLoggedInMobileMenu = () => {
    return (
      <div className="collapse navbar-collapse" id="navbarNav1">
        <ul className="navbar-nav">
          {this.showPublicLinks() && (
            <React.Fragment>
              {this.getGenericMenuItem(`${capitalize(RoleLabel.HOST.toLowerCase())}s`, '/households')}
              {this.getGenericMenuItem(`${capitalize(RoleLabel.GUEST.toLowerCase())}s`, '/housemates')}
              {this.getGenericMenuItem(`How it works`, '/how-it-works')}
              {this.getGenericMenuItem('Services', '/services')}
              {this.getGenericMenuItem("FAQ's", '/faq')}
              {this.getGenericMenuItem('Blog', '/blog')}
              {this.getGenericMenuItem('Get Started', '/app/register')}
            </React.Fragment>
          )}
        </ul>
      </div>
    );
  };

  renderLoggedInMobileMenu = () => {
    let permission = this.props.permission ? this.props.permission : [];
    const { origin } = this.props;
    const dropdownProfileClassName = this.state.profileDropdownView
      ? 'dropdown profile show'
      : 'dropdown profile';
    const dropdownMenuClassName = this.state.profileDropdownView
      ? 'dropdown-menu dropdown-menu-right show'
      : 'dropdown-menu dropdown-menu-right';

    return (
      <div className="login-menu">
        <ul className="navbar-nav">
          {checkPermission('BROWSE_GUEST', permission) && (
            <li>
              {this.showPublicLinks() ? (
                <Button
                  anchor
                  href="/app/search?mode=guests"
                  >
                    Search
                </Button>
              ) : (
                <Button
                  href="/search?mode=guests"
                  >
                    Search
                </Button>
              )}
            </li>
          )}

          {checkPermission('BROWSE_HOST', permission) && (
            <li>
              {this.showPublicLinks() ? (
                <Button
                  anchor
                  href="/search?mode=hosts"
                  >
                    Search
                </Button>
              ) : (
                <Button
                  href="/search?mode=hosts"
                  >
                    Search
                </Button>
              )}
            </li>
          )}
          {checkPermission('MESSAGES', permission) === true && (
            <li className="nav-item">
              {this.showPublicLinks() ? (
                <a href="/app/message" className="nav-link messagecta">
                  <i className="fa fa-envelope-o" />
                  <NotificationBubble
                    connection={this.props.connection}
                    userId={this.props.login.user.user_id}
                  />
                  <span className="msg-nav">Messages</span>
                </a>
              ) : (
                <Link to="/message" className="nav-link messagecta">
                  <i className="fa fa-envelope-o" />
                  <NotificationBubble
                    connection={this.props.connection}
                    userId={this.props.login.user.user_id}
                  />
                  <span className="msg-nav">Messages</span>
                </Link>
              )}
            </li>
          )}

          <li>
            <div className={dropdownProfileClassName}>
              {this.getProfileDropdownMenu()}
              <div className={dropdownMenuClassName}>
                <ul className="header-dropdown-menu">
                  {this.renderGetVerifiedLink(permission)}
                  {this.getMenuFromConstants(permission)}
                  <li>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={this.handleLogout}
                    >
                      <i className="fa fa-sign-out"></i>
                      Logout
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </div>
    );
  };

  getMobileViewMenu = () => {
    return (
      <div className="mobile-main-nav">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <nav className="navbar navbar-toggleable-md navbar-light bg-faded">
                {this.showPublicLinks() && this.getMobileViewMenuToggle()}

                {this.getMobileViewLogo()}

                {!this.isLoggedInUser() && (
                  <button
                    className="btn btn__login"
                    data-toggle="modal"
                    data-target="#trx-login"
                  >
                    Login
                  </button>
                )}

                {/*Not Logged In*/}
                {this.showPublicLinks() && this.renderNonLoggedInMobileMenu()}
                {this.isLoggedInUser() && this.renderLoggedInMobileMenu()}
              </nav>
            </div>
          </div>
        </div>
      </div>
    );
  };

  getLoginForm = () => {
    if (this.isLoggedInUser()) return false;

    return (
      <div className="trx-login">
        <div className="login__modal modal fade visible-xs" id="trx-login">
          <div className="modal-dialog modal-dialog-centered visible-xs">
            <div className="modal-content">
              <div className="modal-body login__modal-body">
                <button
                  type="button"
                  className="close login__modal-close"
                  data-dismiss="modal"
                >
                  &times;
                </button>
                <Login
                  onLogin={this.props.onLogin}
                  login={this.props.login}
                  origin={this.props.origin}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className={`header-wrapper ${this.isLoggedInUser() && 'loggedin-header'}`}>
        <header>
          {this.getDesktopViewMenu()}
          {this.getMobileViewMenu()}
          {this.isLoggedInUser() && (<Nav />)}
        </header>

        {this.getLoginForm()}
      </div>
    );
  }
}

export default Header;
