import React,{ Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import FileDrop  from 'react-file-drop';
import URL from '../../constants/URL';
import {fetchExchangePreferences} from '../../actions/HostPreferences';
import { viewProfile } from '../../actions/Profile';
import { ProcessModal } from '../../actions/Modal';
import { HIDE_MODAL } from '../../actions/Modal';
import { Button } from '../../../components/common'

class AdditionalImageModal extends Component {
  constructor(props){
    super(props);
    this.state={
      requesting:false,
      othersrc:''
    }
  }
  handleImageChange = (e) => {
    e.preventDefault()
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;

    }
    const reader = new FileReader();
    reader.onload = () => {

      this.setState({ othersrc: reader.result });
    };
    reader.readAsDataURL(files[0]);
  }

  handleFileDrop= (files, event) => {
    const reader = new FileReader();
    reader.onload = () => {
      this.setState({ othersrc: reader.result });
    };
    reader.readAsDataURL(files[0]);
  }

  b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }

  saveImage =(type, e) => {

    e.preventDefault();
    this.setState({...this.state,requesting:true});
    let otherContext ="post";
    let imageId="";
    // if(this.state.updateMultipleImageId===this.state.fields.profileOther[0].image_id){
    //   otherContext ="post";
    //   imageId=this.state.updateMultipleImageId
    // }
    if(this.state.othersrc!=null){
      var form = new FormData();
      var ImageURL=this.state.othersrc;
      // Split the base64 string in data and contentType
      var block = ImageURL.split(";");
      // Get the content type
      var contentType = block[0].split(":")[1];// In this case "image/gif"
      // get the real base64 content of the file
      var realData = block[1].split(",")[1];// In this case "iVBORw0KGg...."
      var blob =this.b64toBlob(realData, contentType);
      form.append("token", this.props.login.token);
      form.append("image", blob);
          if(this.props.updateMultipleImageId){
          otherContext ="post";
          imageId=this.props.updateMultipleImageId
          form.append("image_id", imageId);
        }

      form.append("type",type)
      const request =axios({
        method:otherContext,
        url:URL.UPLOAD_IMAGE,
        data:form,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      request.then((result) => {
        if(result.data.status === "success"){
          this.props.fetchExchangePreferences(this.props.login)
          this.close();
          this.setState({...this.state,othersrc:'',requesting:false})
        }

      }).catch(e => {
        this.setState({requesting:false})
      });
    }
  }
  close = () => {
    const modalProps={showModal:false}
        this.props.dispatch(ProcessModal(HIDE_MODAL,null,modalProps));
    }

  render(){
    return(
      <div>
        <div className="overlay modal-backdrop modal-dialog-centered">
          <div className="col-md-6 offset-md-3">
            <div>
              <input
                type="file"
                accept="image/*"
                ref="openImages"
                style={{display: 'none'}}
                onChange={this.handleImageChange}
                />
              </div>
              <div className="modal-content padding-com">
                {this.state.requesting && (
                  <div className="page-loading"></div>
                  )}
                  <div>
                    <div className="modal-header">
                      <button type="button" className="close" onClick={this.close} data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true"><i className="fa fa-close"></i></span>
                        </button>
                      </div>

                      <div className="modal-body">
                        {this.state.othersrc ?
                          <img src={this.state.othersrc} style={{ maxHeight: '200px', maxWidth: '100%' }} alt="" />
                            :
                            <div>
                              <h3><i
                                className="fa fa-plus mr-2"
                                onClick={(refs)=>{
                                  this.refs.openImages.click();
                                }}>
                              </i>Upload additional photos</h3>
                              <FileDrop frame={document} onDrop={this.handleFileDrop}>
                                Drop some files here!
                              </FileDrop>
                              <span>Drag and drop your file here</span>
                              </div>
                            }


                          </div>
                          <div className="modal-footer">
                            {this.state.othersrc ?
                              (
                                <div>
                                  <Button type="submit" onClick={this.saveImage.bind(this,'profile_other')}>Save photo</Button>
                                  <Button color="grey" type="button" onClick={this.close}>Cancel</Button>
                                </div>
                                  ):
                                  <Button color="teal" type="button"
                                    onClick={(refs)=> this.refs.openImages.click()}>
                                      Upload your additional photo
                                  </Button>
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  }
                }
                const mapDispatchToProps = (dispatch) => {
                  return{
                    fetchExchangePreferences:(user) => {dispatch(fetchExchangePreferences(user))},
                    onViewProfile:(user_id,token) => dispatch(viewProfile(user_id,token)),
                    dispatch
                  }
                }
                const mapStateToProps = (store) => {
                  return {
                    login: store.login.user,
                  }
                }
                export default connect(mapStateToProps,mapDispatchToProps)(AdditionalImageModal)
