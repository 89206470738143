import React, { Component } from 'react';
import get from 'lodash/get';
import { viewProfile } from '../actions/Profile';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import EditProfile from '../components/EditProfile';
import ModalRoot from '../components/modals/ModalRoot';
import { ProcessModal } from '../actions/Modal';
import { ModalTypes } from '../constants/ModalTypes';
import { SHOW_MODAL } from '../actions/Modal';
import { getBadges } from '../actions/Badges';
import ConnectionList from '../components/messages/ConnectionList';
import ProfileCompletionStaus from '../../components/ProfileCompletionStatus';
import ProfileBadges from '../../components/ProfileBadges';
import AccountSettings from '../../components/AccountSettingsCard';
import SearchBar from '../../components/SearchBar';
import { RoleLabel, RoleCode } from '../../store/Users/constants';
import { Button } from '../../components/common';
import { getUserAvatar } from '../../components/UserProfile/utils';


class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchParam: '',
      requesting: false,
      myBadges: '',
      profileMain: props.profile ? props.profile.profile_main : null,
      postcodeError: false
    };
  }

  componentWillMount() {
    if (this.props.profile == null) {
      this.props.onViewProfile(
        this.props.login.user_id,
        this.props.login.token
      );
    }
    //hack for hiding dropdown
    let modalBackdrop = window.document.getElementsByClassName(
      'modal-backdrop'
    );
    if (modalBackdrop.length > 0) {
      modalBackdrop[0].style.display = 'none';
    }
  }

  componentDidMount() {
    document.title = 'Dashboard | The Room Xchange';
    if (this.props.myBadges === null) {
      this.props.onGetBadges(this.props.login);
    }
  }

  handleLocationChange = value => {
    console.log(value);
    this.setState({ ...this.state, searchParam: value.target });
  };

  searchByLocationHandler = (whomISearching, e) => {
    e.preventDefault();
    if (this.state.searchParam) {
      const getAttr = (attr) => get(this.state.searchParam, `value[${attr}]`, '');
      this.props.history.push(`/search?mode=${whomISearching}&page=0&perPage=12&suburb=${getAttr('locality')}&state=${getAttr('state')}&post_code=${getAttr('post_code')}`);
    } else {
      this.setState({
        ...this.state,
        postcodeError: true
      });
    }
  };

  openProfileModal = () => {
    const modalProps = {
      showModal: true,
      profileMain: this.props.profile ? this.props.profile.profile_main : []
    };
    this.props.dispatch(
      ProcessModal(SHOW_MODAL, ModalTypes.PROFILE_MODAL, modalProps)
    );
  };

  openActivateUserModal = e => {
    e.preventDefault();
    const modalProps = { type: 'activateUser' };
    this.props.onActivateUser(
      SHOW_MODAL,
      ModalTypes.DEACTIVATE_MODAL,
      modalProps
    );
  };


  getConnectionList = () => {
    if (this.props.profile && this.props.profile.profileCompletion === 100 && this.props.connection) {
      if (this.props.connection.connections.length > 0) {
        return (
          <div className="box box-bottom-margin">
            <div className="x-req padding-com clearfix">
              <div>
                <ConnectionList />
                <Button href="/message" className="float-right">View</Button>
              </div>
            </div>
          </div>
        );
      } else if (this.props.connection.connections.length === 0) {
        return (
          <div className="box box-bottom-margin">
            <div className="x-req padding-com clearfix">
              <ConnectionList />
              <p>You do not currently have any messages.</p>
            </div>
          </div>
        );
      }
    }
  };

  render() {
    let image = getUserAvatar();
    
    let whomISearching = null;
    if (this.props.login.role_code === RoleCode.HOST) {
      whomISearching = RoleLabel.GUESTS;
    } else {
      whomISearching = RoleLabel.HOSTS;
    }
    
    //profileCompletion
    let profileCompletion = 0;
    if (this.props.profile != null) {
      profileCompletion = this.props.profile.profileCompletion;
    }
    //profileCompletion test
    
    if (this.props.profile) {
      if (this.props.profile.profile_main.length !== 0) {
        image = this.props.profile.profile_main.image_path;
      }
    }

    if (this.state.editingMode) {
      return (
        <div>
          <EditProfile
            profileMode={this.profileMode}
            editingMode={this.state.editingMode}
            profile={this.props.profile}
            user={this.props.user}
          />
        </div>
      );
    }

    const profileName = get(this.props.login, 'preferred_name', false)  ||  get(this.props.profile, 'first_name', '');

    const displayProfileCompleteMotivationMessage =
      profileCompletion < 100
        ? "<p>To complete your profile you need to have your verification by <a href='/app/dashboard/badges'>Digital ID</a> and ensure your public profile is complete.</p>"
        : '';

    const { profile } = this.props;
    
    return (
      <div>
        <div className="trx-com-wrap">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                {/* Tab panes */}
                <div className="tab-content">
                  <div
                    role="tabpanel"
                    className="tab-pane active "
                    id="dashboard"
                  >
                    <div className="box box-bottom-margin">
                      <div className="box-body">
                        <div className="dashboard__top-title">
                          Hi {profileName}, welcome to your dashboard.
                        </div>
                        <div className="dashboard__reminder">
                          The first step is to complete your profile. To enable other users to connect with you, 
                          your profile needs to be 100% completed. This also includes a profile photo. 
                          If you have any questions, look out for the green information bubble near each section, 
                          visit our <a href="/faq" target="_blank">FAQ page</a> or&nbsp;
                          <a href="https://calendly.com/customer_care_the_room_xchange" target="_blank">book a call</a>&nbsp;
                          with one of our customer care team. 
                        </div>
                      </div>
                    </div>
                    {profile && profile.status !== 1 ? (
                      <div className="deactive-acc">
                        <div className="top-title">Reactivate Account</div>
                        <Button onClick={this.openActivateUserModal}>Reactivate</Button>
                      </div>
                    ) : (
                      <div className="row">
                        <div className="col-md-6 col-12">
                          <div className="nopadding">
                            {profile != null ? (
                              <AccountSettings 
                                profile={this.props.profile}
                                image={image}
                                roleCode={profile.role_code}
                                openProfileModal={this.openProfileModal}
                              />
                            ) : (
                              <p>Loading Profile</p>
                            )}
                          </div>
                          {profile && profile.role_code && <ProfileBadges myBadges={this.props.myBadges} roleCode={profile.role_code} />}
                        </div>
                        {/*col-6*/}

                        <div className="col-md-6 col-12">
                            <div className="box box-bottom-margin">
                              <div className="padding-com clearfix">
                                
                                  {profile && (<ProfileCompletionStaus status={profileCompletion} />)}
                                
                                <div
                                  className="profile-complete-motivation-text"
                                  dangerouslySetInnerHTML={{
                                    __html: displayProfileCompleteMotivationMessage
                                  }}
                                />

                                <div className="float-right">
                                  <Button color="teal" href="/about">View</Button>
                                  <Button href="/about/edit">Edit</Button>
                                </div>
                              </div>
                            </div>
                            <div className="clearfix" />
                            {this.getConnectionList()}
                            {this.props.profile && this.props.profile.status !== 0 && (
                              <SearchBar 
                                whomISearching={whomISearching}
                                searchParam={this.state.searchParam}
                                handleLocationChange={this.handleLocationChange}
                                searchByLocationHandler={this.searchByLocationHandler}
                                postcodeError={this.state.postcodeError}
                              />
                            )}
                            
                          {/*block-border*/}
                        </div>
                        {/*col-6*/}

                      </div>
                    )}
                  </div>
                  {/*tab-panel*/}
                </div>
              </div>
              {/*col-md-12*/}
            </div>
          
          {/*container*/}
          </div>
          <ModalRoot />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onViewProfile: (user_id, token) => dispatch(viewProfile(user_id, token)),
    onGetBadges: user => dispatch(getBadges(user)),
    onActivateUser: (actionType, modalType, modalProps) =>
      dispatch(ProcessModal(actionType, modalType, modalProps)),
    dispatch
  };
};

const mapStateToProps = store => {
  return {
    login: store.login.user,
    profile: store.profile.profile,
    myBadges: store.badges.myBadges,
    preferences: store.preferences.preferenceData,
    connection: store.connection
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Dashboard)
);
