export const ModalTypes = Object.freeze({
  ADDITIONAL_IMAGE_MODAL: 'ADDITIONAL_IMAGE_MODAL',
  PROFILE_MODAL: 'PROFILE_MODAL',
  DEACTIVATE_MODAL: 'DEACTIVATE_MODAL',
  DROP_AND_DRAG_MODAL: 'DROP_AND_DRAG_MODAL',
  POLICE_CHECK_MODAL: 'POLICE_CHECK_MODAL',
  PROSPECTIVE_USER_MODAL: 'PROSPECTIVE_USER_MODAL',
  CONNECT_MODAL: 'CONNECT_MODAL',
  PREVIOUSINVOICES_MODAL: 'PREVIOUSINVOICES_MODAL',
  PAYMENTSUBMIT_MODAL: 'PAYMENTSUBMIT_MODAL',
  PAYMENTCOMPLETE_MODAL: 'PAYMENTCOMPLETE_MODAL',
  ACCEPT_MODAL: 'ACCEPT_MODAL',
  DECLINE_MODAL: 'DECLINE_MODAL',
  XCHANGE_DECLINE_MODAL: 'XCHANGE_DECLINE_MODAL',
  CARD_DELETE_MODAL: 'CARD_DELETE_MODAL',
  XCHANGE_MODAL: 'XCHANGE_MODAL'
});
