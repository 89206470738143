import React, { Component } from 'react';
import axios from 'axios';
import URL from '../constants/URL';
import Form from 'react-validation/build/form';
import { Button } from 'components/common';

class Subscribe extends Component {
  state = {
    requesting: false,
    firstName: '',
    email: '',
    successMessage: '',
    errorMesssage: ''
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = (e) => {
    this.setState({ requesting: true });
    e.preventDefault();
    const request = axios({
      method: 'post',
      url: URL.SUBSCRIBE_API,
      data: {
        first_name: this.state.firstName,
        email: this.state.email
      }
    });

    request.then((result) => {
      if (result.data.status === 'success') {
        this.setState({
          ...this.state,
          successMessage: result.data.msg,
          requesting: false
        });
      } else {
        this.setState({
          ...this.state,
          errorMesssage: result.data.msg,
          requesting: false
        });
      }
    });
  };

  render() {
    return (
      <div className="container">
        <div className="footer-form-wrapper col-lg-8 col-sm-8">
          {this.state.requesting && <div className="page-loading"></div>}
          <Form
            className="form-inline text-center row"
            onSubmit={this.handleSubmit}
          >
            <div className="form-group">
              <div className="input-group">
                <input
                  type="firstName"
                  name="firstName"
                  onChange={this.handleChange}
                  className="form-control col-lg-4 col-sm-4 mr-2 mb-1"
                  id="first-name"
                  placeholder="First Name"
                />
                <input
                  type="email"
                  name="email"
                  onChange={this.handleChange}
                  className="form-control col-lg-4 col-sm-4 mr-2"
                  id="email-address"
                  placeholder="Email"
                />
                <div className="subscribe-wrapper">
                  <Button type="submit" className="subscribe-btn">Subscribe</Button>
                  </div>
                {this.state.successMessage && (
                  <div className="subscribe-success-message">
                    <strong>Success: </strong>
                    {this.state.successMessage}
                  </div>
                )}
                {this.state.errorMesssage && !this.state.successMessage && (
                  <div className="subscribe-error-message">
                    <strong>Error: </strong>
                    {this.state.errorMesssage}
                  </div>
                )}
              </div>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

export default Subscribe;
