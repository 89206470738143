import React, { Component } from 'react';
import includes from 'lodash/includes';
import toLower from 'lodash/toLower';
import { connect } from 'react-redux';
import { signup } from '../actions/Signup';
import { login, logout } from '../actions/Login';
import Header from '../components/layouts/Header';
import Footer from '../../components/Footer';
import { ProcessModal } from '../actions/Modal';
import { SHOW_MODAL } from '../actions/Modal';
import { ModalTypes } from '../constants/ModalTypes';
import ModalRoot from '../components/modals/ModalRoot';
import SignupThankYou from '../containers/SignupThankYou';
import {
  SignUpHeading,
  SignUpRoleSelect,
  SignUpRole,
  SignUpStep1,
  SignUpStep2,
  SignUpStep3,
  step2InitialData,
  step1InitialData,
  step3InitialData
} from '../components/signup';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';

class Signup extends Component {
  state = {
    activeFormStep: 1,
    completedSteps: [],
    signupData: {
      ...step1InitialData,
      ...step2InitialData,
      ...step3InitialData
    }
  };

  signUpFormRef = React.createRef();

  componentDidMount() {
    document.title = 'Register | The Room Xchange';

    // This is an idiotic way to do authentication / authorization
    if (this.props.login.user != null) {
      this.props.history.push('/');

      return;
    }

    this.hideBackdropModal();

    // Read signup info from local storage for some still unknown reason
    this.updateSignUpInfo();

    // Handle open prospect
    if (!this.props.openProspect) {
      return;
    }

    this.handleProspectsClick(
      this.props.openProspect == 'not-ready'
        ? 'NOT_READY_TO_COMMIT_USER'
        : 'INTERNATIONAL_USER',
      this.props.prospectSuccess,
      true
    );
  }

  componentDidUpdate() {
    if (!this.props.login.user) {
      return;
    }

    this.props.history.push('/');
  }

  componentWillUnmount() {
    window.onbeforeunload = function() {
      window.localStorage.removeItem('trx-signup-info');
    };
  }

  hideBackdropModal() {
    const modalBackdrop = window.document.getElementsByClassName(
      'modal-backdrop'
    );

    if (modalBackdrop.length > 0) {
      modalBackdrop[0].style.display = 'none';
    }
  }

  updateSignUpInfo() {
    let {
      signup: { info },
      match: {
        params: { role }
      }
    } = this.props;

    const { signupData } = this.state;

    info =
      info || localStorage.getItem('trx-signup-info')
        ? JSON.parse(localStorage.getItem('trx-signup-info'))
        : null;

    window.localStorage.removeItem('trx-signup-info');

    this.setState({
      ...info,
      signupData: { ...signupData, role: role && role.toUpperCase() }
    });
  }

  /**
   * success param overrides the dialog default view with success message
   **/
  handleProspectsClick = (type, success = false, fromURL = false) => {
    const modalProps = {
      showModal: true,
      type: type,
      success
    };
    this.props.onProcessModal(
      SHOW_MODAL,
      ModalTypes.PROSPECTIVE_USER_MODAL,
      modalProps
    );

    if (!fromURL) {
      const prospectURLValue =
        type === 'INTERNATIONAL_USER' ? 'outside-au' : 'not-ready';
      this.props.history.push('/register/' + prospectURLValue);
    }
  };

  handleRoleSelect = role => {
    const { history } = this.props;
    const { signupData } = this.state;
    const groupCode = window.location.search;
    switch (role) {
      case SignUpRole.HOST:
      case SignUpRole.GUEST:
        this.setState({ signupData: { ...signupData, role, groupCode} });
        history.push(`/register/${role.toLowerCase()}${groupCode}`);
        break;
      case SignUpRole.INTERNATIONAL_USER:
      case SignUpRole.NOT_READY_TO_COMMIT_USER:
        this.setState({ signupData: { ...signupData, role: undefined } });
        history.push('/register/');
        this.handleProspectsClick(role, false, false);
        break;
      default:
        break;
    }
  };

  handleStepComplete = async data => {
    const { activeFormStep, signupData, completedSteps } = this.state;
    const { onSignup, googleReCaptchaProps } = this.props;

    const token = await googleReCaptchaProps.executeRecaptcha('signup');

    this.setState({
      signupData: { ...signupData, ...data, google_recaptcha: token },
      completedSteps: completedSteps.includes(activeFormStep)
        ? completedSteps
        : [...completedSteps, activeFormStep]
    });

    if (activeFormStep < 3) {
      this.setState({ activeFormStep: activeFormStep + 1 });

      if (this.signUpFormRef.current) {
        window.scrollTo(0, 0);
      }
    } else {
       const urlParams = new URLSearchParams(window.location.search);

        // Submit the data
      const dataToSubmit = {
        ...signupData,
        ...data,
        gender: signupData.otherGender ? 'Other' : signupData.gender,
		campaign: urlParams.get('campaign')
      };
      dataToSubmit.role = dataToSubmit.role === SignUpRole.HOST ? 'HOST' : 'GUEST';

		try {
			console.log(dataToSubmit);
			const response = await onSignup(dataToSubmit);

			if (response && response.success) {
				const thankYouUrl = '/register/' + this.props.match.params.role + '/thankyou';
				if (typeof window.gtag_report_conversion === 'function') {
					window.gtag_report_conversion(thankYouUrl);
				} else {
					this.props.history.push({
						pathname: thankYouUrl,
						state: { success: true }
					});
				}
			}
		} catch (error) {
			console.log(error);
		}
	}
  };

  handleStepClick = step => {
    this.setState({ activeStep: step });
  };

  handleStepGoBack = () => {
    const { activeFormStep, completedSteps } = this.state;

    if (activeFormStep <= 1) {
      return;
    }

    this.setState({
      activeFormStep: activeFormStep - 1,
      completedSteps: completedSteps.filter(_step => _step < activeFormStep)
    });
  };

  handleRecaptcha = token => {
    const { signupData } = this.state;

    this.setState({
      signupData: { ...signupData, google_recaptcha: token }
    });
  };

  render() {
    const { signupData, activeFormStep } = this.state;
    const {
      signup: { isProcessing, error },
      login,
      onLogout,
      onLogin
    } = this.props;

    const { role } = signupData;

    if (includes(toLower(role), 'thankyou')) {
      return (
        <div>
          <Header
            login={login}
            onLogout={onLogout}
            onLogin={onLogin}
            origin="app"
          />
          <div className="row justify-content-center">
            <div className="col-md-6">
              <div
                ref={this.signUpFormRef}
                className="trx-com-wrap trx-signup-form register-page recom-mod header-mod container-fluid"
              >
                <div className="row">
                  <div className="col-md-12">
                    <SignupThankYou {...this.props} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div>
        {isProcessing && <div className="page-loading" />}
        <Header
          login={login}
          onLogout={onLogout}
          onLogin={onLogin}
          origin="app"
        />
        <div className="row justify-content-center">
          <div className="col-md-6">
            <div
              ref={this.signUpFormRef}
              className="trx-com-wrap trx-signup-form register-page recom-mod header-mod container-fluid"
            >
              <div className="row">
                <div className="col-md-12">
                  {activeFormStep === 1 && !role && <SignUpHeading />}
                  <div
                    className="form-horizontal form-signup"
                    style={{ padding: '32px 0' }}
                  >
                    {activeFormStep === 1 && !role && (
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group">
                            <SignUpRoleSelect
                              role={role}
                              onRoleSelect={this.handleRoleSelect}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {role && (
                      <React.Fragment>
                        {activeFormStep === 1 && (
                          <SignUpStep1
                            onSubmit={this.handleStepComplete}
                            onPrevious={this.handleStepGoBack}
                            data={signupData}
                          />
                        )}
                        {activeFormStep === 2 && (
                          <SignUpStep2
                            onSubmit={this.handleStepComplete}
                            onPrevious={this.handleStepGoBack}
                            data={signupData}
                          />
                        )}
                        {activeFormStep === 3 && (
                          <SignUpStep3
                            onSubmit={this.handleStepComplete}
                            onPrevious={this.handleStepGoBack}
                            data={signupData}
                            errors={error}
                          />
                        )}
                      </React.Fragment>
                    )}
                  </div>
                </div>
                {/*col-md-9*/}
                <ModalRoot />
              </div>
              {/*container*/}
            </div>
          </div>
        </div>
        <Footer user={this.props.login.user} />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  onSignup: signupData => dispatch(signup(signupData)),
  onLogout: () => dispatch(logout()),
  onLogin: (postData, info) => dispatch(login(postData, info)),
  onProcessModal: (actionType, modalType, modalProps) =>
    dispatch(ProcessModal(actionType, modalType, modalProps))
});

const mapStateToProps = store => ({
  login: store.login,
  signup: store.signup
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withGoogleReCaptcha(Signup));
