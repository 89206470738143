import React, { FC, useState, useEffect, memo } from 'react';
import get from 'lodash/get';
import size from 'lodash/size';
// @ts-ignore
import Lightbox from 'react-image-lightbox';
import { Button } from '../../components/common';

interface Gallery {
    id: number;
    image_path: string;
}

interface Props {
    gallery: Gallery[];
}

const message = {
    load: 'Loading ...',
    failed: 'This image failed to load.'
}


const PhotoGallery: FC<Props> = ({ gallery }) => {
    const [isOpen, handleLightbox] = useState(false);
    const [activeMediaIndex, handleMediaIndex] = useState(0);
    const [imageLoadMessage, handleImageLoadMessage] = useState(message.load);
    
    useEffect(() => {
        handleImageLoadMessage(message.load);
        const path = get(gallery[activeMediaIndex], 'image_path', '');
        const galleryImage = new Image();
        galleryImage.onerror = () => handleImageLoadMessage(message.failed);   
        galleryImage.src = path;
    }, [activeMediaIndex, gallery])

    const nextIndex = (activeMediaIndex + 1) % gallery.length;
    const prevIndex = (activeMediaIndex + gallery.length - 1) % gallery.length;
    
    if(size(gallery) === 0) return (<></>);

	return (
		<>
			<Button className="view-gallery-btn" onClick={() => handleLightbox(!isOpen)}>View gallery</Button>
			    <div>
                  {isOpen && (
                    <Lightbox
                      mainSrc={gallery[activeMediaIndex].image_path}
                      nextSrc={gallery[nextIndex]}
                      prevSrc={gallery[prevIndex]}
                      onMovePrevRequest={() => handleMediaIndex(prevIndex)}
                      onMoveNextRequest={() => handleMediaIndex(nextIndex)}
                      imageLoadErrorMessage={imageLoadMessage}
                      onCloseRequest={() => handleLightbox(!isOpen)}
                    />
                  )}
                  </div>
		</>
	);
};

export default memo(PhotoGallery);