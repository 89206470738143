import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { fetchXchangeList } from '../actions/Xchange';
import FRONTEND_RESPONSE_MESSAGE from '../constants/FRONTEND_RESPONSE_MESSAGE';
import { Button } from '../../components/common';

class Xchange extends Component {
  state = {
    requesting: false,
    emptyMessage: ''
  };

  componentDidMount() {
    const { token } = this.props.login;
    if (this.props.xchanges.xchangesData.length == 0) {
      this.props.onFetchXchangeList(token);
    }
  }

  render() {
    if (!this.props.xchanges) {
      return <div>Loading page</div>;
    }
    const currenXchangesData = this.props.xchanges.xchangesData.filter(
      currenXchangeData => {
        return currenXchangeData.type === 'Current';
      }
    );
    const pastXchangesData = this.props.xchanges.xchangesData.filter(
      currenXchangeData => {
        return currenXchangeData.type === 'Past';
      }
    );

    const renderCurrentXchanges = currenXchangesData.map(
      (xchangeData, index) => {
        const {
          exchange_id,
          exchange_end_date,
          exchange_with_first_name,
          exchange_with_image
        } = xchangeData;

        return (
          <div className="xchange-block  padding-com" key={index}>
            <div className="row">
              <div className="col-sm-3 col-3">
                <div className="img-wrapper">
                  <img src={exchange_with_image} className="img-fluid" />
                </div>
              </div>
              <div className="col-sm-8 col-9 nopadding">
                <p>
                  You are <span className="text-org">Xchanging</span> with{' '}
                  <span className="text-org">{exchange_with_first_name} </span>
                  until
                  <br />
                  <span className="text-org">
                    {moment(exchange_end_date).format('DD MMMM YYYY')}
                  </span>
                </p>
                <div className="xch-btn">
                  <Button
                    anchor
                    href={`mailto:${FRONTEND_RESPONSE_MESSAGE.THE_ROOM_XCHANGE_MAIL}?subject=TheRoomXchange:Extend our Xchange #${exchange_id} ${exchange_with_first_name}&body=Dear Admin,%0D%0APlease contact me in regards to extending my current Xchange.%0D%0AThank you.`}
                    style={{
                      marginRight: '10px',
                      lineHeight: '22px',
                      height: '36px'
                    }}
                  >
                    Extend
                  </Button>
                  <Button
                    anchor
                    href={`mailto:${FRONTEND_RESPONSE_MESSAGE.THE_ROOM_XCHANGE_MAIL}?subject=TheRoomXchange:End #${exchange_id} ${exchange_with_first_name}&body=Dear Admin,%0D%0APlease contact me in regards to ending my current Xchange.%0D%0AThank you.`}
                    style={{
                      marginRight: '0px',
                      lineHeight: '22px',
                      height: '36px'
                    }}
                    color="grey"
                  >
                    End
                  </Button>
                </div>
              </div>
            </div>
          </div>
        );
      }
    );

    const renderPastXchanges = pastXchangesData.map((xchangeData, index) => {
      const {
        exchange_id,
        exchange_start_date,
        exchange_end_date,
        exchange_with_first_name,
        exchange_with_last_name,
        exchange_with_image
      } = xchangeData;
      return (
        <div className="xchange-block  padding-com" key={index}>
          <div className="row">
            <div className="col-sm-3 col-3">
              <div className="img-wrapper">
                <img src={exchange_with_image} className="img-fluid" />
              </div>
            </div>
            <div className="col-sm-8 col-9 nopadding">
              <p>
                You <span className="text-org">Xchanged</span> with{' '}
                <span className="text-org">{exchange_with_first_name}</span>{' '}
                between
                <br />
                <span className="text-org">
                  {' '}
                  {moment(exchange_start_date).format('DD MMMM YYYY')} -{' '}
                  {moment(exchange_end_date).format('DD MMMM YYYY')}
                </span>
              </p>
              <div className="xch-btn">
                <Button
                  anchor
                  href={`mailto:admin@theroomxchange.com?subject=TheRoomXchange:Extend our Xchange #${exchange_id} ${exchange_with_first_name}&body=Dear Admin,%0D%0APlease contact me in regards to extending my current Xchange.%0D%0AThank you.`}
                  style={{
                    marginRight: '10px',
                    lineHeight: '22px',
                    height: '36px'
                  }}
                >
                  Extend
                </Button>
                <Button
                  anchor
                  href={`mailto:admin@theroomxchange.com?subject=TheRoomXchange:End #${exchange_id} ${exchange_with_first_name}`}
                  target="_blank"
                  style={{
                    marginRight: '0px',
                    lineHeight: '22px',
                    height: '36px'
                  }}
                  color="grey"
                >
                  End
                </Button>
              </div>
            </div>
          </div>
        </div>
      );
    });

    return (
      <div className="xchange container trx-com-wrapper">
        <div className="tab-content">
          <div
            role="tabpanel"
            className="tab-pane active "
            id="xchange"
          >
            <div className="row">
              <div className="col-md-4 col-12">
                <div className="current-xch block-border">
                  <div className="top-title  padding-com">
                    Current Xchange
                  </div>
                  {currenXchangesData.length === 0 ? (
                    <div
                      className="padding-com"
                      style={{ paddingTop: '0px' }}
                    >
                      You don't have any current xchanges.
                    </div>
                  ) : (
                    renderCurrentXchanges
                  )}
                </div>
                {/*block-border*/}
              </div>
              <div className="col-md-4 col-12">
                <div className="block-border">
                  <div className="top-title  padding-com">
                    Past Xchanges
                  </div>
                  {pastXchangesData.length === 0 ? (
                    <div
                      className="padding-com"
                      style={{ paddingTop: '0px' }}
                    >
                      You don't have any past xchanges.
                    </div>
                  ) : (
                    renderPastXchanges
                  )}
                </div>
                {/*block-border*/}
              </div>
              {/*col-6*/}

            </div>
          </div>
          {/*tab-panel*/}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  onFetchXchangeList: token => dispatch(fetchXchangeList(token))
});

const mapStateToProps = store => ({
  login: store.login.user,
  xchanges: store.xchange
});

export default connect(mapStateToProps, mapDispatchToProps)(Xchange);
