export const BASE_URL = (process.env.REACT_APP_OLD_API_URL ? process.env.REACT_APP_OLD_API_URL : '/api');
export const BASE_URL_V2 = `${process.env.REACT_APP_API_URL}`;

export default {
  LOGIN: `${BASE_URL}/login`,
  USERS: `${BASE_URL}/users`,
  HOST_PREFERENCE: (userID) => `${BASE_URL}/users/${userID}/preferences`,
  USER_PROFILE: (userID, token) => `${BASE_URL}/users/${userID}?token=${token}`,
  EDIT_PROFILE: (userID) => `${BASE_URL}/users/${userID}`,
  VERIFY_EMAIL: `${BASE_URL}/users/forgetpassword`,
  VERIFY_CODE: `${BASE_URL}/setpassword/verify`,
  EMAIL_VERIFY_TOKEN: `${BASE_URL}/email/verify`,
  EMAIL_VERIFY_EMAIL: `${BASE_URL}/verify/resendemail`,
  RESET_PASSWORD: `${BASE_URL}/setpassword`,
  CHANGE_PASSWORD: (userID, token) => `${BASE_URL}/users/${userID}/changepassword?token=${token}`,
  UPLOAD_IMAGE: `${BASE_URL}/image`,
  IMAGE_SAVE: (userID) => `${BASE_URL}/users/${userID}/profile`,
  DELETE_IMAGE: `${BASE_URL}/image/delete`,
  GET_PREFERENCES: (userID, token) =>
    `${BASE_URL}/users/${userID}/preferences?token=${token}`,
  DEACTIVATE_ACCOUNT: (userID) => `${BASE_URL}/users/${userID}/deactivate`,
  SUBSCRIBE_API: `${BASE_URL}/subscribe`,
  VIEW_VERIFICATION_BAGDES: (userID, token) =>
    `${BASE_URL}/users/${userID}/badges?token=${token}`,
  BROWSE_PUBLIC_USER_API: `${BASE_URL_V2}/v1/public/profiles`,
  BROWSE_HOST_API: `${BASE_URL_V2}/v1/profiles?user_type=host`,
  BROWSE_GUEST_API: `${BASE_URL_V2}/v1/profiles?user_type=guest`,
  DIGITAL_ID_API: `${BASE_URL}/digital`,
  PROSPECTIVE_USER_API: `${BASE_URL}/subscribe/perspectiveuser/`,
  POLICE_CHECK_API: `${BASE_URL}/policecheck`,
  BROWSE_API: (token, page, location) =>
    `${BASE_URL}/host?token=${token}&&page=${page}&&location=${location}`,
  CONNECTION_API: `${BASE_URL}/connections`,
  PUSHER_AUTH_API: `${BASE_URL}/pusher/auth`,
  FETCH_CONNECTION_LIST_API: (params) =>
    `${BASE_URL}/connections?token=${params.token}&&page=${params.page}&&limit=${params.limit}&&search=${params.search}&&type=${params.type}`,
  FETCH_CONNECTION_DETAIL_API: (connectionId, token) =>
    `${BASE_URL}/connections/${connectionId}?token=${token}`,
  CONNECTION_MESSAGE_API: (connectionId, token) =>
    `${BASE_URL}/connections/${connectionId}/messages?token=${token}`,
  CONNECTION_MESSAGE_CREATE_API: (connectionId) =>
    `${BASE_URL}/connections/${connectionId}/messages`,
  EDIT_CONNECTION_API: (connectionId) =>
    `${BASE_URL}/connections/${connectionId}`,
  SEEN_CONNECTION_API: (connectionId) =>
    `${BASE_URL}/connection/${connectionId}/seen`,
  CREATE_XCHANGE_API: (connectionId) =>
    `${BASE_URL}/connections/${connectionId}/exchanges`,
  EDIT_XCHANGE_API: (connectionId, xchangeId) =>
    `${BASE_URL}/connections/${connectionId}/exchanges/${xchangeId}`,
  FETCH_XCHANGE_API: (token) => `${BASE_URL}/exchanges?token=${token}`,
  FETCH_INVOICES_API: (token) => `${BASE_URL}/invoices?token=${token}`,
  FETCH_STRIPE_INVOICES_API: (token) =>
    `${BASE_URL}/invoicestripe?token=${token}`,
  CREATE_CUSTOMER_CARD_API: `${BASE_URL}/cards`,
  FETCH_CUSTOMER_CARD_DETAIL_API: (token) => `${BASE_URL}/cards?token=${token}`,
  EDIT_CARD_API: (cardId) => `${BASE_URL}/cards/${cardId}`,
  DELETE_CARD_API: (cardId) => `${BASE_URL}/cards/${cardId}`,
  PAYMENT_API: `${BASE_URL}/payments`,
  BROWSE_MAP_API: (token, currentLocation) =>
    `${BASE_URL}/maps?token=${token}&&current_location=${currentLocation}`,
  DOWNLOAD_INVOICE_API: (token, invoiceId) =>
    `${BASE_URL}/invoice-download/?token=${token}&&invoice_id=${invoiceId}`,
  ADMIN_FEE_API: (token) => `${BASE_URL}/admin-fee?token=${token}`,
  VERIFY_SMS_CODE: `${BASE_URL}/verify-sms`,
  SEND_VERIFY_SMS_CODE: `${BASE_URL}/send-verify-sms`,
  GET_SUBURB_SUGGESTIONS: `${BASE_URL}/suggest-postcodes`,
  GET_CITY_SUGGESTIONS: `${BASE_URL}/suggest-cities`,
  GET_STRIPE_KEY: `${BASE_URL}/stripe-key`,
};
