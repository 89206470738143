import React from 'react';
import {Link} from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { closePublicSearchModal } from '../../../actions/PublicSearchModal';

export const PublicSearchModal = () => {
  const { isOpened } = useSelector(
    ({ publicSearchModal }) => publicSearchModal,
    shallowEqual
  );

  const dispatch = useDispatch();

  const handleCloseModal = () => dispatch(closePublicSearchModal());

  React.useEffect(() => {
    if (!isOpened) {
      window.$('#public-search-modal').modal('hide');

      return;
    }

    window.$('#public-search-modal').modal('show');
  }, [isOpened]);

  return (
    <div
      className="login__modal modal fade visible-xs"
      id="public-search-modal"
    >
      <div className="modal-dialog modal-dialog-centered modal-lg visible-xs">
        <div className="modal-content">
          <div className="modal-body login__modal-body">
            <button type="button" className="close login__modal-close" onClick={handleCloseModal}>&times;</button>
            <div className="public-search__body">
              <h2 className="public-search__title">Welcome to The Room Xchange</h2>
              <p>Is there someone you'd like to connect with?</p>
              <p>Register your free account and create your profile.</p>
              <p>Then come back here and start connecting.</p>
              <div className="public-search__buttons">
				  <Link to="/register" className="button btn-orange public-search__register">Get started</Link>
				  <Link to="/login" className="button btn-green public-search__login">Login</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
