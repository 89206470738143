import React,{ Component } from 'react';
import Cropper from 'react-cropper';
import { connect } from 'react-redux';
import axios from 'axios';
import URL from '../../constants/URL';
import { ProcessModal } from '../../actions/Modal';
import { SHOW_MODAL, HIDE_MODAL } from '../../actions/Modal';
import { Button } from '../../../components/common';

class XchangeDeclineModal extends Component {

  constructor(props){
    super(props);
    this.state={
      requesting:false,
      errorMessage:'',
    }
  }


  close = () => {
    const modalProps={showModal:false}
        this.props.dispatch(ProcessModal(HIDE_MODAL,null,modalProps));
    }

  declineXchangeRequest = (e) => {
    e.preventDefault();
    this.setState({...this.state,requesting:true});
    const request = axios({
      method:'patch',
      url:URL.EDIT_XCHANGE_API(this.props.connectionId,this.props.xchangeId),
      data:{
        token:this.props.token,
        status:this.props.status
      }
    });
    request.then((result) => {
      if(result.data.status==="success"){
        this.setState({...this.state,requesting:false});
        this.props.onFetchConnectionList({
          token:this.props.token,
          page:1,
          limit:20,
          type:'',
          search:''
        })
        this.close();
      }else{
        this.setState({...this.state,requesting:false,errorMessage:result.data.msg});
      }
    });
  }

  render(){

    let {connectedWith, connectionDetail} = this.props;
    let user = connectionDetail.exchange_to_user_first_name;
    if(this.props.userType === "from"){
      user = connectionDetail.exchange_from_user_first_name;
    }
    return(
      <div>
      <div className="overlay modal-backdrop modal-dialog-centered">
        <div className="col-md-5 offset-md-4">
          {this.state.requesting && (
           <div className="page-loading"></div>
           )}
          <div className="modal-content padding-com">
            <div>
              <div className="modal-header">
                <button type="button" className="close" onClick={this.close} data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true"><i className="fa fa-close"></i></span>
                </button>
              </div>

              <div className="modal-body">
                <div>
                  <h3>Are you sure you want to decline an Xchange with {connectedWith.charAt(0).toUpperCase() + connectedWith.slice(1).toLowerCase()} {user}?</h3>
               </div>
              </div>
              <div className="modal-footer">
                <Button type="button" color="grey" onClick={this.close} data-dismiss="modal">cancel</Button>
                <Button type="button" onClick={this.declineXchangeRequest}>Yes</Button>
              </div>
              {this.state.errorMessage && (
                <div className="alert-danger" role="alert">
                  <strong>Error: </strong>{this.state.errorMessage}
                  </div>
              )}
            </div>
          </div>
        </div>
      </div>
      </div>
    )
  }
}
const mapDispatchToProps = (dispatch) => {
  return{
      dispatch
  }
}
const mapStateToProps = (store) => {
  return {
    login: store.login.user,
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(XchangeDeclineModal)
