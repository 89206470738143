import React, { FC, memo } from 'react';
import size from 'lodash/size';
import { RoleCode } from 'store/Users/constants';
import {
	getHouseholdPersonalities,
	getHelpTasks,
	getDomesticHelps,
	getTalents,
	getActivities
} from './utils';

interface PrioritiesProps {
	role: string;
	rent_offset: boolean;
	personality: string;
	help_tasks: string;
	other_help_tasks: string;
	tasks_to_do: string;
	tasks_to_do_other: string;
	talents: string;
	skills_other: string;
	activities: string;
	experiences_to_enjoy: string;
	experiences_to_enjoy_other: string;
	exchange_priorities: string[];
}

export const Pills = ({ data }: {data: string[]}) => {
	if (size(data) === 0)
		return (<>—</>);
	return (
		<>
			{Array.isArray(data) && data.map((row: string, index: number) => (
				<span className="pill" key={index}>
					{row}
				</span>
			))}
		</>
	);
};

const Priorities: FC<PrioritiesProps> = ({
	role,
	rent_offset,
	personality,
	help_tasks,
	other_help_tasks,
	tasks_to_do,
	tasks_to_do_other,
	talents,
	skills_other,
	activities,
	experiences_to_enjoy,
	experiences_to_enjoy_other
}) => (
	<>
		{role === RoleCode.HOST ? (
			<div>
				<dt className="pb-1">Household personality:</dt>
				<dd>
					<Pills
						data={getHouseholdPersonalities(personality)}
					/>
				</dd>
				{rent_offset && (
					<>
						<dt className="pb-1">You can offset some of the rent for help with the following:</dt>
						<dd>
							<Pills data={getHelpTasks(help_tasks, other_help_tasks)} />
						</dd>
					</>
				)}
				
				<dt className="pb-1">Activities we like to do:</dt>
				<dd>
					<Pills
						data={getActivities(activities)}
					/>
				</dd>
			</div>
		) : (
			<div>
				<dt className="pb-1">Personality:</dt>
				<dd>
					<Pills
						data={getHouseholdPersonalities(personality)}
					/>
				</dd>
				{rent_offset ? (
					<>
						<dt className="pb-1">Things I can do to offset the rent:</dt>
						<dd>
							<Pills
								data={getDomesticHelps(tasks_to_do, tasks_to_do_other)}
							/>
						</dd>
					</>
				) : (<></>)}

				<dt className="pb-1">Interests I have:</dt>
				<dd>
					<Pills data={getTalents(talents, skills_other)} />
				</dd>
				<dt className="pb-1">Activities I like to do:</dt>
				<dd>
					<Pills
						data={getActivities(experiences_to_enjoy, experiences_to_enjoy_other)}
					/>
				</dd>
			</div>
		)}
	</>
);

export default memo(Priorities);
