import React from 'react';
import {ReducerActionType} from "./EditProfileReducer";
import {getAccordionDefaultConfig} from "../utils/common";
import {RemoteResponse} from "../types/editProfileTypes";

const EditProfileContext = React.createContext({
	accordionConfig: getAccordionDefaultConfig(),
    update: (params: ReducerActionType) => {},
	data: {} as RemoteResponse,
	loader: false
});
EditProfileContext.displayName='EditProfileContext';

export default EditProfileContext;
