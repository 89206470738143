import React, { Component } from 'react';
import Cropper from 'react-cropper';
import { connect } from 'react-redux';
import axios from 'axios';
import URL from '../../constants/URL';
import { fetchExchangePreferences } from '../../actions/HostPreferences';
import FileDrop from 'react-file-drop';
import { viewProfile } from '../../actions/Profile';
import { ProcessModal } from '../../actions/Modal';
import { HIDE_MODAL } from '../../actions/Modal';
import { Button } from '../../../components/common';

class ProfileModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requesting: false,
      image: null,
      wrapperWidth: 0,
      wrapperHeight: 0
    };
  }

  handleImageChange = e => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      var self = this;
      var image = new Image();
      image.src = reader.result;
      var wrapperWidth = document.getElementById('modalinnerbody').clientWidth;
      image.onload = function() {
        self.setState({
          image: image,
          imageMimeType: files[0].type,
          wrapperWidth:
            image.width > image.height
              ? wrapperWidth
              : 400 * (image.width / image.height),
          wrapperHeight:
            image.width > image.height
              ? wrapperWidth / (image.width / image.height)
              : 400
        });
      };
    };
  };

  handleFileDrop = (files, e) => {
    e.preventDefault();
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      var self = this;
      var image = new Image();
      image.src = reader.result;
      var wrapperWidth = document.getElementById('modalinnerbody').clientWidth;
      image.onload = function() {
        self.setState({
          image: image,
          imageMimeType: files[0].type,
          wrapperWidth:
            image.width > image.height
              ? wrapperWidth
              : 400 * (image.width / image.height),
          wrapperHeight:
            image.width > image.height
              ? wrapperWidth / (image.width / image.height)
              : 400
        });
      };
    };
  };

  b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  saveProfileImage = (type, e) => {
    e.preventDefault();
    this.setState({ requesting: true });

    if (typeof this.cropper.getCroppedCanvas() === 'undefined') {
      return;
    }

    if (this.cropper) {
      const canvas = this.cropper.getCroppedCanvas({
        width: 320,
        height: 320
      });
      const {
        onViewProfile,
        login,
        profileMain,
        fetchExchangePreferences
      } = this.props;

      canvas.toBlob(
        blob => {
          const form = new FormData();
          form.append('token', this.props.login.token);
          form.append('type', type);
          form.append('image', blob);
          if (profileMain.image_id) {
            form.append('image_id', profileMain.image_id);
          }

          axios({
            method: 'post',
            url: URL.UPLOAD_IMAGE,
            data: form,
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
            .then(result => {
              if (result.data.status !== 'success') {
                return;
              }

              return axios({
                method: 'put',
                url: URL.HOST_PREFERENCE(login.user_id),
                data: {
                  image_upload: 'success',
                  token: login.token,
                  type: 'photos'
                }
              });
            })
            .then(() => {
              onViewProfile(login.user_id, login.token);
              fetchExchangePreferences(login);

              this.close();
              this.setState({ image: '', requesting: false });
            });
        },
        this.state.imageMimeType,
        '0.8'
      );
    }
  };

  close = () => {
    const modalProps = { showModal: false };
    this.props.dispatch(ProcessModal(HIDE_MODAL, null, modalProps));
  };

  render() {
    return (
      <div>
        <div className="overlay modal-backdrop modal-dialog-centered">
          <div className="col-md-10 offset-md-1 col-lg-6 offset-lg-3">
            <div>
              <input
                type="file"
                accept="image/*"
                ref="openImages"
                style={{ display: 'none' }}
                onChange={this.handleImageChange}
              />
            </div>
            <div className="modal-content padding-com">
              {this.state.requesting && <div className="page-loading" />}
              <div>
                <div className="modal-header">
                  <button
                    type="button"
                    className="close"
                    onClick={this.close}
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">
                      <i className="fa fa-close" />
                    </span>
                  </button>
                </div>

                <div className="modal-body">
                  {this.state.image && this.state.image.src ? (
                    <div id="cropperwrap">
                      <Cropper
                        style={{
                          height: this.state.wrapperHeight,
                          width: this.state.wrapperWidth
                        }}
                        aspectRatio={1 / 1}
                        autoCropArea={1}
                        guides={false}
                        zoomable={false}
                        src={this.state.image.src}
                        ref={cropper => {
                          this.cropper = cropper;
                        }}
                        responsive={true}
                      />
                    </div>
                  ) : (
                    <div id="modalinnerbody">
                      <h3>
                        <i
                          className="fa fa-plus"
                          onClick={refs => {
                            this.refs.openImages.click();
                          }}
                        />
                        Upload your profile photo
                      </h3>
                      {this.props.from === 'public_profile' ? (
                        ''
                      ) : (
                        <div>
                          <FileDrop
                            frame={document}
                            onDrop={this.handleFileDrop}
                          >
                            Drop some files here!
                          </FileDrop>
                          <span>Drag and drop your file here</span>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div className="modal-footer">
                  {this.state.image && this.state.image.src ? (
                    <div>
                      <Button
                        type="submit"
                        onClick={this.saveProfileImage.bind(
                          this,
                          'profile_main'
                        )}
                      >
                        Save profile photo
                      </Button>
                      <Button
                        type="button"
                        color="grey"
                        onClick={this.close}
                      >
                        Cancel
                      </Button>
                    </div>
                  ) : (
                    <Button
                      type="button"
                      color="teal"
                      className="mx-auto"
                      onClick={refs => {
                        this.refs.openImages.click();
                      }}
                    >
                      Upload your profile photo
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchExchangePreferences: user => {
      dispatch(fetchExchangePreferences(user));
    },

    onViewProfile: (user_id, token) => dispatch(viewProfile(user_id, token)),
    dispatch
  };
};

const mapStateToProps = store => {
  return {
    login: store.login.user
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileModal);
