export enum ConnectionStatus {
	ACCEPT = 'ACCEPT',
	PENDING = 'PENDING',
	DECLINE = 'DECLINE'
}

export interface ConnectionProps {
	connection_id: number;
	from_user_id: number;
	from_user_first_name: string;
	from_user_last_name: string;
	from_user_image: string;
	to_user_id: number;
	to_user_first_name: string;
	to_user_last_name: string;
	to_user_image: string;
	text: string;
	status: string;
	from_user_seen_status: 'Seen' | 'Unseen';
	to_user_seen_status:  'Seen' | 'Unseen';
	created_at: string;
	exchange_id?: any;
	exchange_type?: any;
	exchange_from: string;
	exchange_with: string;
	seen_status:  'Seen' | 'Unseen';
}
export interface RecipientProps {
	connection_id: number;
	userId: number;
	name: string;
	photo: string;
	text: string;
	badges?: any[];
	status?: string;
	seenStatus:  'Seen' | 'Unseen',
}

export interface MessageCardProps {
	recipient: RecipientProps;
	activeConnectionId: number;
	handleActiveMessage: (id: number) => void;
}
export interface RecipientsListProps {
	searchKeyword?: string;
	user?: any;
	connection?: { connections: ConnectionProps[] };
	activeConnectionId: number;
	handleActiveMessage: (id: number) => void;
	toggleList: (show: boolean) => void;
}

export interface SearchPanelProps {
	onSearch: (keyword: string) => void;
}

export interface UserInfoProps {
	details?: ConnectionProps | null;
	userId: number;
	userRole: string;
	hideList: () => void;
}

export interface UserMessagesProps {
	messages?: any;
	msgDetails: ConnectionProps | null;
	userId: number;
}

export interface MessageBoxProps {
	created_at: string;
	created_by: number;
	userId: number;
	text: string;
	userPhoto: string;
	nextMessageUser: number;
	prevMessageUser: number;
}

export interface UserNotificationProps {
	connectionId?: number;
	onProcessModal: (showModal: any, modalType: any, modalProps: any) => void;
	connectionDetail?: any;
	currentuser?: any;
	onFetchConnectionList: (data: any) => void;
	connection: { connections: ConnectionProps[] };
}
