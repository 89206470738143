import React, { FC, memo, useState, useCallback } from 'react';
import debounce from 'lodash/debounce';
import { SearchPanelProps } from '../types';

const SearchPanel: FC<SearchPanelProps> = ({ onSearch }) => {
	const [search, handleSearch] = useState('');
	const debounceLoadData = useCallback(
		debounce((keyword: string) => onSearch(keyword), 500),
		[]
	);

	const onChange = (e: any) => {
		handleSearch(e.target.value);
		debounceLoadData(e.target.value);
	};

	return (
		<div className="search-panel">
			<form>
				<div className="search-field box">
					<input
						autoComplete="off"
						type="text"
						name="search"
						placeholder="Search"
						value={search}
						onChange={onChange}
					/>
				</div>
			</form>
		</div>
	);
};

export default memo(SearchPanel);
