import React from 'react';

export const DashboardLayout = ({ children }) => {
  return (
    <>{children}</>
  );
};


export const DashboardContainerHOC = (Content) => {
  return (props) => (
    <DashboardLayout><Content {...props}/></DashboardLayout>
  );
}