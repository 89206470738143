import React from 'react';
import { Formik, Form, Field } from 'formik';
import { object, string, mixed, ref } from 'yup';
import PropTypes from 'prop-types';
import { TextField } from '../forms';
import moment from 'moment';

const xchangeFormInitialValues = {
  xchangeType: '',
  startDate: '',
  endDate: ''
};

const xchangeFormValidation = object({
  xchangeType: string().required('Please enter verification code'),
  startDate: mixed()
    .required('Please select your start date')
    .test('notPastDate', 'Exchange start date cannot be in the past', value =>
      moment().isSameOrBefore(value, 'days')
    ),
  endDate: mixed().when('xchangeType', {
    is: 'long-term',
    then: mixed()
      .required('Please select your end date')
      .test(
        'minimumTerm',
        'A long term exchange must last at least one month',
        function(value) {
          const startDate = this.resolve(ref('startDate'));
          return moment(value).diff(moment(startDate), 'months') >= 1;
        }
      )
  })
});

export class XchangeForm extends React.Component {
  static propTypes = {
    isVerifying: PropTypes.bool,
    isSuccess: PropTypes.bool,
    onSubmit: PropTypes.func,
    error: PropTypes.string
  };

  renderForm = ({ errors, touched, values }) => {
    const { error } = this.props;

    return (
      <Form className="xchange__form">
        <div className="xchange__wrapper">
          <label className="control-label">
            Please select a Xchange type:{' '}
          </label>
          <div class="row xchange__row">
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-check">
                  <div>
                    <Field
                      className="form-check-input"
                      name="xchangeType"
                      type="radio"
                      value="short-term"
                      checked={values.xchangeType === 'short-term'}
                    />
                  </div>
                  <span className="form-check-label">Short-term Xchange</span>
                </label>
              </div>
              <p>
                A short-term Xchange is our casual rate charged at $25 for one
                week (or part thereof) from the date your Xchange begins. If you
                would like to Xchange for two weeks, you will need to re-book
                your Xchange at the end of the first week.
              </p>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-check">
                  <div>
                    <Field
                      className="form-check-input"
                      name="xchangeType"
                      type="radio"
                      value="long-term"
                      checked={values.xchangeType === 'long-term'}
                    />
                  </div>
                  <span className="form-check-label">Long-term Xchange</span>
                </label>
              </div>
              <p>
                A long-term Xchange is for a minimum of one month (or part
                thereof). It is a special rate for ongoing Xchanges. There is a
                $60 per month recurring charge for the duration of your Xchange.
                When your Xchange ends, email: admin@theroomxchange.com and
                we'll cease the payment.
              </p>
            </div>
          </div>
          <div class="row xchange__row">
            <div className="col-md-6">
              <TextField
                type="date"
                label="Exchange start date:"
                name="startDate"
                placeholder="DD/MM/YYYY"
                error={
                  errors.startDate && touched.startDate
                    ? errors.startDate
                    : null
                }
              />
            </div>
            {values.xchangeType === 'long-term' && (
              <div className="col-md-6">
                <TextField
                  type="date"
                  label="Exchange end date:"
                  name="endDate"
                  placeholder="DD/MM/YYYY"
                  error={
                    errors.endDate && touched.endDate ? errors.endDate : null
                  }
                />
              </div>
            )}
          </div>
          <div className="xchange__controls">
            <button
              type="submit"
              className="xchange__controls__btn btn btn-green text-white"
            >
              Start Xchange
            </button>
          </div>
        </div>
        {error && <div className="xchange__errors">{error}</div>}
      </Form>
    );
  };

  render() {
    const { onSubmit } = this.props;

    return (
      <Formik
        initialValues={xchangeFormInitialValues}
        validationSchema={xchangeFormValidation}
        onSubmit={onSubmit}
        validateOnBlur={true}
        render={this.renderForm}
      />
    );
  }
}
