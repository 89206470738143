import React from 'react';
import { isEmail, isPostalCode, isMobilePhone } from 'validator';
import moment from 'moment';
import FRONTEND_RESPONSE_MESSAGE from '../constants/FRONTEND_RESPONSE_MESSAGE';

const DOB_PATTERN = /^(0?[1-9]|[12][0-9]|3[01])[\/](0?[1-9]|1[012])[\/]\d{4}$/;
const DOB_MAX_DATE = moment().subtract(18, 'years');
const DOB_MIN_DATE = moment().subtract(100, 'years');
const XCHANGE_MIN_DATE = moment();

export const required = (value) => {
  try {
    if (!value.toString().trim().length) {
      return (
        <div className="has-error">
          <span className="help-block">
            {FRONTEND_RESPONSE_MESSAGE.VALIDATION_INPUT.IS_REQUIRED_FIELD}
          </span>
        </div>
      );
    }
  } catch (e) {
    // console.log(e);
  }
};

export const recaptchaFormValidator = (value) => {
  if (!value.toString().trim().length) {
    return (
      <div className="has-error">
        <span className="help-block">
          {FRONTEND_RESPONSE_MESSAGE.RECAPTCHA_FIELD_ERROR}
        </span>
      </div>
    );
  }
};

export const postCodeValidation = (value) => {
  if (!value.toString().trim().length) {
    return (
      <div className="has-error">
        <span className="help-block">
          {FRONTEND_RESPONSE_MESSAGE.VALIDATION_POSTCODE}
        </span>
      </div>
    );
  }
};

export const postCodeValidationAtSignUpPage = (value) => {
  if (!value.toString().trim().length) {
    return (
      <div className="has-error">
        <span className="help-block">
          {FRONTEND_RESPONSE_MESSAGE.VALIDATION_POSTCODE_FOR_REGISTRATION_PAGE}
        </span>
      </div>
    );
  }
};

export const DOBValidation = (value) => {
  if (!value) {
    return (
      <div className="has-error">
        <span className="help-block">
          {FRONTEND_RESPONSE_MESSAGE.VALIDATION_DOB.IS_EMPTY}
        </span>
      </div>
    );
  }

  if (!value.match(DOB_PATTERN)) {
    return (
      <div className="has-error">
        <span className="help-block">
          {FRONTEND_RESPONSE_MESSAGE.VALIDATION_DOB.PATTERN_NOT_MATCH}
        </span>
      </div>
    );
  }

  if (!moment(value, 'DD/MM/YYYY').isSameOrBefore(DOB_MAX_DATE)) {
    return (
      <div className="has-error">
        <span className="help-block">
          {FRONTEND_RESPONSE_MESSAGE.VALIDATION_DOB.MIN_DATE}
        </span>
      </div>
    );
  }
};

export const ExchangeDateValidation = (value) => {
  if (!value) {
    return (
      <div className="has-error">
        <span className="help-block">
          {FRONTEND_RESPONSE_MESSAGE.VALIDATION_XCHANGE_DATE.IS_EMPTY}
        </span>
      </div>
    );
  }

  if (!value.match(DOB_PATTERN)) {
    return (
      <div className="has-error">
        <span className="help-block">
          {FRONTEND_RESPONSE_MESSAGE.VALIDATION_XCHANGE_DATE.PATTERN_NOT_MATCH}
        </span>
      </div>
    );
  }
  if (moment(value, 'DD/MM/YYYY').isBefore(XCHANGE_MIN_DATE, 'day')) {
    return (
      <div className="has-error">
        <span className="help-block">
          {FRONTEND_RESPONSE_MESSAGE.VALIDATION_XCHANGE_DATE.MIN_DATE}
        </span>
      </div>
    );
  }
};

export const radioRequired = (value, props, components) => {
  let checkedValues = components[props.name]
    .filter((input) => input.checked == true)
    .map((input) => input.value);
  if (checkedValues.length == 0) {
    return (
      <div className="has-error">
        <span className="help-block">
          {FRONTEND_RESPONSE_MESSAGE.VALIDATION_RADIO.IS_REQUIRED}
        </span>
      </div>
    );
  }
};

export const radioRequiredIf = (value, props, components) => {
  let checkedValues = components[props.name]
    .filter((input) => input.checked == true)
    .map((input) => input.value);
  if (props.field == 'pets') {
    let conditionCheckedValue = components[props.field]
      .filter((input) => input.checked == true)
      .map((input) => input.value);
    if (conditionCheckedValue.length && conditionCheckedValue[0] === '1') {
      if (checkedValues.length == 0) {
        return (
          <div className="has-error">
            <span className="help-block">
              {FRONTEND_RESPONSE_MESSAGE.VALIDATION_RADIO.IS_REQUIRED}
            </span>
          </div>
        );
      }
    }
  } else {
    if (checkedValues.length == 0) {
      return (
        <div className="has-error">
          <span className="help-block">
            {FRONTEND_RESPONSE_MESSAGE.VALIDATION_RADIO.IS_REQUIRED}
          </span>
        </div>
      );
    }
  }
};

export const email = (value) => {
  if (!isEmail(value)) {
    return (
      <div className="has-error">
        <span className="help-block">
          {FRONTEND_RESPONSE_MESSAGE.VALIDATION_EMAIL(value)}
        </span>
      </div>
    );
  }
};

export const mobilePhone = (value) => {
  if (!isMobilePhone(value, 'en-AU')) {
    return (
      <div className="has-error">
        <span className="help-block">
          {FRONTEND_RESPONSE_MESSAGE.VALIDATION_MOBILE_PHONE}
        </span>
      </div>
    );
  }
};

export const gt = (value, props) => {
  if (value.toString().trim().length < props.minLength) {
    return (
      <div className="has-error">
        <span className="help-block">
          {FRONTEND_RESPONSE_MESSAGE.VALIDATION_MIN_LENGTH(
            props.name,
            props.minLength
          )}
        </span>
      </div>
    );
  }
};

export const lt = (value, props) => {
  try {
    if (!value.toString().trim().length > props.maxLength) {
      return (
        <div className="has-error">
          <span className="help-block">
            {FRONTEND_RESPONSE_MESSAGE.VALIDATION_MAX_LENGTH(props.maxLength)}
          </span>
        </div>
      );
    }
  }catch(e){
    // console.log(e);
  }
};

export const passwordValidation = (value, props, components) => {
  if (
    !value.match(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/
    )
  ) {
    return (
      <div className="has-error">
        <span className="help-block">
          {FRONTEND_RESPONSE_MESSAGE.VALIDATION_PASSWORD}
        </span>
      </div>
    );
  }

  if (
    components &&
    components['confirmpassword'] &&
    components['confirmpassword'][0].isUsed &&
    components['confirmpassword'][0].value &&
    value != components['confirmpassword'][0].value
  ) {
    return (
      <div className="has-error">
        <span className="help-block">
          {FRONTEND_RESPONSE_MESSAGE.VALIDATION_CONFIRM_PASSWORD}
        </span>
      </div>
    );
  }
};

export const confirmpassword = (value, props, components) => {
  if (value !== components['password'][0].value) {
    return (
      <div className="has-error">
        <span className="help-block">
          {FRONTEND_RESPONSE_MESSAGE.VALIDATION_CONFIRM_PASSWORD}
        </span>
      </div>
    );
  }
};
export const checkBoxTrue = (value, props) => {
  if (value == '0' || value == '') {
    if (props.context === 'age') {
      return (
        <div className="has-error">
          <span className="help-block">
            {FRONTEND_RESPONSE_MESSAGE.VALIDATION_CHECKBOX.AGE}
          </span>
        </div>
      );
    } else if (props.context === 'policy') {
      return (
        <div className="has-error">
          <span className="help-block">
            {FRONTEND_RESPONSE_MESSAGE.VALIDATION_CHECKBOX.POLICY}
          </span>
        </div>
      );
    } else if (props.context === 'agreement') {
      return (
        <div className="has-error">
          <span className="help-block">
            {FRONTEND_RESPONSE_MESSAGE.VALIDATION_CHECKBOX.AGREEMENT}
          </span>
        </div>
      );
    }
  }
};

export const checkBoxRequired = (value, props, components) => {
  if (typeof value === 'object') {
    if (value.length <= 0) {
      return (
        <div className="has-error">
          <span className="help-block">
            {FRONTEND_RESPONSE_MESSAGE.VALIDATION_CHECKBOX.IS_REQUIRED}
          </span>
        </div>
      );
    } else if (props.maxValue && value.length != props.maxValue) {
      return (
        <div className="has-error">
          <span className="help-block">
            {FRONTEND_RESPONSE_MESSAGE.VALIDATION_CHECKBOX.IS_REQUIRED_LENGTH(
              props.maxValue
            )}
          </span>
        </div>
      );
    }
  }
};

export const minXchangeStartDate = (value, props) => {
  if (
    value &&
    props.dateChanged &&
    moment(value).diff(moment(props.min), 'days') < 0
  ) {
    return (
      <div className="has-error">
        <span className="help-block">
          {FRONTEND_RESPONSE_MESSAGE.VALIDATION_MIN_XCHANGE_START_DATE}
        </span>
      </div>
    );
  }
};
