import { VIEW_PROFILE_SUCCESS, SET_USER_PROFILE } from '../actions/Profile';

const INITIAL_STATE = { profile: null, permission: null, data: {}, userPermissions: [] };

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case VIEW_PROFILE_SUCCESS:
      const isVerified = () => {
        if (action.payload.digital_id_verified !== 0) {
          return 'VERIFIED';
        }
      };
      const isActive = () => {
        if (action.payload.status !== 0) {
          return 'ACTIVE';
        }
      };
      

      // const isUser = () => {
      //   return action.payload.role_code;
      // };
      const profileCompleted = () => {
        if (action.payload.profileCompletion === 100) {
          return 'COMPLETED';
        }
      };
      let permission = [
        isActive(),
        isVerified(),
        action.payload.role_code,
        profileCompleted()
      ];

      state = { ...state, profile: action.payload, permission: permission };
      break;
    case SET_USER_PROFILE:
      state = { 
        ...state, 
        data: action.payload.data,
        userPermissions: action.payload.permissions,
       };
      break;
      default:
  }
  return state;
}
