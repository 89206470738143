import axios from 'axios';
import * as LocalStorageUtil from '../utils/LocalStorage';

const axiosInstance = isSecured => {
  let instance;

  if (!isSecured) {
    instance = axios.create({
      baseURL: process.env.REACT_APP_BASE_API_URL,
      headers: {
        Accept: 'application/json'
      }
    });
  } else {
    const { token } = LocalStorageUtil.load('trx-user-session');

    instance = axios.create({
      baseURL: process.env.REACT_APP_BASE_API_URL,
      headers: {
        Accept: 'application/json'
      },
      params: {
        token
      }
    });
  }

  return instance;
};

export const axiosInstances = Object.freeze({
  public: () => axiosInstance(),
  secured: () => axiosInstance(true)
});
