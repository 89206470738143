import React, { Component } from 'react';
import { viewProfile } from '../actions/Profile';
import axios from 'axios';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import URL from '../constants/URL';
import DigitalID from '../components/DigitalID';
import { getBadges } from '../actions/Badges';
import { fetchExchangePreferences } from '../actions/HostPreferences';
import { ProcessModal } from '../actions/Modal';
import { SHOW_MODAL } from '../actions/Modal';
import { ModalTypes } from '../constants/ModalTypes';
import ModalRoot from '../components/modals/ModalRoot';
import {
  MY_VERIFICATION_BADGE_SUBTITLE,
  VERIFICATION_PAGE_DIGITAL_ID_TEXT,
  VERIFICATION_PAGE_CV_CHECK_TEXT
} from '../constants/COPY';
import { Button } from '../../components/common';

class Badges extends Component {
  constructor(props) {
    super(props);

    this.state = {
      successMessage: '',
      errorMessage: '',
      myBadges: ''
    };
  }

  componentDidMount() {
    document.title = 'My Verification Badges | The Room Xchange';
    if (this.props.myBadges == null) {
      this.props.onGetBadges(this.props.login);
    }
  }

  onCompleted = response => {
    if (response.code === undefined) {
      this.setState({ errorMessage: response.error_description });
    } else {
      const request = axios({
        method: 'post',
        url: URL.DIGITAL_ID_API,
        data: {
          token: this.props.login.token,
          code: response.code
        }
      });
      request.then(result => {
        if (result.data.status == 'success') {
          this.props.onViewProfile(
            this.props.login.user_id,
            this.props.login.token
          );
          this.props.fetchExchangePreferences(this.props.login);
          this.props.onGetBadges(this.props.login);
          this.setState({ successMessage: result.data.msg });
        } else {
          this.setState({ errorMessage: result.data.msg });
        }
      });
    }
  };

  openPoliceCheckModal = e => {
    e.preventDefault();

    const modalProps = {
      showModal: true
    };
    this.props.onProcessModal(
      SHOW_MODAL,
      ModalTypes.POLICE_CHECK_MODAL,
      modalProps
    );
  };

  render() {
    let myBadges = null;
    if (this.props.myBadges) {
      myBadges = this.props.myBadges.map((myBadges, index) => {
        if (myBadges.badge_code == 'POLICE_CHECK') {
          if (myBadges.verification_status == 'Police Check Processing') {
            return 'POLICE_CHECK_PROCESSING';
          } else if (myBadges.verification_status == 'Police Check Verified') {
            return myBadges.badge_code;
          } else {
            return '';
          }
        }
        return myBadges.badge_code;
      });
    }
    return (
      <div className="container trx-com-wrap com-mod header-mod badge-page">
        <div className="">
          <div className="row">
            <div className="col-md-12">
              <div className="box p-4">
              <h3>Verification badges</h3>
              <div
                className="badge-des sub-text"
                dangerouslySetInnerHTML={{
                  __html: MY_VERIFICATION_BADGE_SUBTITLE
                }}
              ></div>
              <div className="row">
                <div className="badge-block col-md-12">
                  <div className="row">
                    <div className="col-12">
                      <div className="digi-id">
                        <div className="d-id">
                          <img
                            style={{ width: '30px' }}
                            className="img-fluid"
                            src={require('../../assets/icons/digital_id_logo.svg')}
                            alt="digitalid"
                          />
                        </div>
                        <div className="id-des">
                          <span>Digital iD </span>
                          <br />
                          <div
                            className="verify_page_digital_text mt-2"
                            dangerouslySetInnerHTML={{
                              __html: VERIFICATION_PAGE_DIGITAL_ID_TEXT
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div className="col-10 mt-3 ml-4 pl-4 btn-badge ">
                      {myBadges && myBadges.indexOf('DIGITAL_ID') > -1 ? (
                        <Button>Added</Button>
                      ) : (
                        <DigitalID
                          onCompleted={this.onCompleted}
                          digitalID={myBadges}
                        />
                      )}
                    </div>
                  </div>
                  {this.state.successMessage && (
                    <div className="alert alert-success" role="alert">
                      <strong>Success: </strong>
                      {this.state.successMessage}
                    </div>
                  )}
                  {this.state.errorMessage && (
                    <div className="alert alert-danger" role="alert">
                      <strong>Error: </strong>
                      {this.state.errorMessage}
                    </div>
                  )}
                </div>
              </div>
              <Button href="/" color="teal" size="medium" className="float-right">Return To Dashboard</Button>
              <div className="clearfix"></div>
              </div>
            </div>
            {/*col-md-12*/}
          </div>
          {/*row*/}
          <ModalRoot />
        </div>
        {/*container*/}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onGetBadges: user => dispatch(getBadges(user)),
    onViewProfile: (user_id, token) => dispatch(viewProfile(user_id, token)),
    fetchExchangePreferences: user => {
      dispatch(fetchExchangePreferences(user));
    },
    onProcessModal: (actionType, modalType, modalProps) =>
      dispatch(ProcessModal(actionType, modalType, modalProps))
  };
};
const mapStateToProps = store => {
  return {
    login: store.login.user,
    myBadges: store.badges.myBadges,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Badges);
