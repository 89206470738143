import React, { useState, FC } from 'react';
import map from 'lodash/map';
import get from 'lodash/get';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { checkPermission } from '../../js/constants/Permissions';
import './styles.scss';

const navItems = [
	{
		icon: 'columns',
		label: 'Dashboard',
		url: '/',
		key: 'DASHBOARD'
	},
	{
		icon: 'user-o',
		label: 'Public profile',
		url: '/about',
		key: 'PROFILE_VIEW'
	},
	{
		icon: 'search',
		label: 'Search',
		url: '/search',
		key: 'SEARCH'
	},
	{
		icon: 'envelope-o',
		label: 'Messages',
		url: '/message',
		key: 'MESSAGES'
	},
	{
		icon: 'cog',
		label: 'Settings',
		url: '/account',
		key: 'ACCOUNT_SETTING'
	}
];

interface NavProps {
	permittedNavItems: typeof navItems[0][];
}

const Nav: FC<NavProps> = ({ permittedNavItems }) => {
	const location = useLocation();
	const [isOpen, handleMenu] = useState<boolean>(false);
	const toggleMenu = () => handleMenu(!isOpen);
	const isActiveLink = (url: string) => 
		url === location.pathname ||
		(url.length > 1 && location.pathname.includes(url));

	return (
		<ul className={`top-nav ${isOpen ? 'responsive' : ''}`}>
			{map(permittedNavItems, ({ label, url, icon }) => {
				return (
					<li key={label} className="top-nav-li">
						<NavLink
							className={`nav-item-link ${
								isActiveLink(url) && 'active'
							}`}
							onClick={toggleMenu}
							to={url}
							exact
						>
							<i className={`fa fa-${icon}`}></i>
							{label}
						</NavLink>
					</li>
				);
			})}
			<li>
				<span className="icon" onClick={toggleMenu}>
					<i className="fa fa-bars"></i>
				</span>
			</li>
		</ul>
	);
};

const mapStateToProps = (store: any) => {
	const permissions = get(store.profile, 'permission', []);

	const permittedNavItems = navItems.filter(({ key }: { key: string }) =>
		checkPermission(key, permissions)
	);
	return {
		permittedNavItems
	};
};
export default connect(mapStateToProps, null)(Nav);
