import React from "react";

export const SignUpHeading = () => (
	<React.Fragment>
		<h1>Get started</h1>
		<hr className="horizontal-org" />
		<div className="register-intro-text">
			<p>
				Let's get you started! If you are listing a room to rent in your home, register as a "household". If you
				are looking for a room to rent register as a "housemate".
			</p>
			<p>
				By the way, your personal information will always remain private. To find out more, read our{" "}
				<a href="/privacy" target="_blank">
					privacy policy
				</a>
				.
			</p>
		</div>
	</React.Fragment>
);
