import React, { FC, memo } from 'react';
import map from 'lodash/map';
import { BadgeProps } from './types';

interface BadgesComponentProps {
	badges: BadgeProps[];
}

const Badges: FC<BadgesComponentProps> = ({ badges }) => (
	<div className="badges-wrap">
			{map(badges, (myBadges, index) => {
				if (
					myBadges.badge_code === 'DIGITAL_ID' ||
					(myBadges.badge_code === 'POLICE_CHECK' &&
						myBadges.verification_status ===
							'Police Check Verified')
				) {
					return (
						<span
							className={myBadges.badge_code.toLowerCase()}
							key={index}
						/>
					);
				}
			})}
		</div>
	);

export default memo(Badges);
