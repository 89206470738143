import axios from 'axios';
import get from 'lodash/get';
import URL from '../../js/constants/URL';
import { ConnectionProps, RecipientProps } from './types';

export const getRecipientInfo = (
	loggedInUserId: number,
	data: ConnectionProps
): RecipientProps => {
	if (!(loggedInUserId && data && data.to_user_id))
		return {
			connection_id: 0,
			userId: 0,
			name: '',
			photo: '',
			text: '',
			status: '',
			seenStatus: 'Seen',
		};

	if (loggedInUserId === data.to_user_id) {
		return {
			connection_id: data.connection_id,
			userId: data.from_user_id,
			name: data.from_user_first_name,
			photo: data.from_user_image,
			text: data.text,
			status: data.status,
			seenStatus: data.to_user_seen_status,
		};
	} else {
		return {
			connection_id: data.connection_id,
			userId: data.to_user_id,
			name: data.to_user_first_name,
			photo: data.to_user_image,
			text: data.text,
			status: data.status,
			seenStatus: data.from_user_seen_status,
		};
	}
};

export const getRecipientInfoFromDetails = (
	loggedInUserId: number,
	data: ConnectionProps
): RecipientProps => {
	if (!(loggedInUserId && data.to_user_id))
		return {
			connection_id: 0,
			userId: 0,
			name: '',
			photo: '',
			text: '',
			badges: [],
			seenStatus:  'Seen',
		};
	if (loggedInUserId === data.to_user_id) {
		return {
			connection_id: data.connection_id,
			userId: data.from_user_id,
			name: data.from_user_first_name,
			photo: get(data, 'from_user_image.image_path', ''),
			text: get(data, 'from_user_basic_intro', ''),
			badges: get(data, 'from_user_badges', []),
			seenStatus: get(data, 'to_user_seen_status',  'Seen'),
		};
	} else {
		return {
			connection_id: data.connection_id,
			userId: data.to_user_id,
			name: data.to_user_first_name,
			photo: get(data, 'to_user_image.image_path', ''),
			text: get(data, 'to_user_basic_intro', ''),
			badges: get(data, 'to_user_badges', []),
			seenStatus: get(data, 'from_user_seen_status',  'Seen'),
		};
	}
};

export const fetchMessages = (
	page: number,
	connection_id: number,
	token: number
) => {
	const request = axios({
		method: 'get',
		url: URL.CONNECTION_MESSAGE_API(connection_id, token)
	});
	return request.then((result) => {
		if (result.data.status === 'success') {
			return result.data.messages;
		}
	});
};

export const showDetailComponent = (connectionId: number, token: number) => {
	const request = axios({
		method: 'get',
		url: URL.FETCH_CONNECTION_DETAIL_API(connectionId, token)
	});
	return request.then((result) => {
		if (result.data.status === 'success') return result.data.data;
	});
};
