export const states = {  
    'ACT': 'Australian Capital Territory',   
    'NSW': 'New South Wales',   
    'NT': 'Northern Territory',   
    'QLD': 'Queensland',   
    'SA': 'South Australia',   
    'TAS': 'Tasmania',   
    'VIC': 'Victoria',   
    'WA': 'West Australia',   
}
