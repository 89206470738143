import React, { FC, memo, useMemo } from 'react';
import map from 'lodash/map';
import range from 'lodash/range';
import clsx from 'clsx';
import { useSearchPaginationStyles } from './styles';
import { ISearchPagination, IPaginationItem } from './types';

const MAX_PAGINATION_ITEMS = 5;

const SearchPagination: FC<ISearchPagination> = ({
  page,
  numPages,
  onPaginationChange,
  shouldShowNumberItem = true,
  className
}) => {
  const {
    paginationContainer,
    paginationItemsContainer,
    paginationItemWrapper,
    paginationItem,
    paginationItemActive
  } = useSearchPaginationStyles();

  const handlePaginationCreator = (_page: number) => () => {
    onPaginationChange({ page: _page});
  };

  const paginationItems = useMemo<IPaginationItem[]>(() => {
    if (numPages <= MAX_PAGINATION_ITEMS) {
      return map(range(0, numPages), index => ({
        itemPage: index,
        isClickable: true
      }));
    }

    if (page < 3) {
      return [
        ...map(range(0, 3), index => ({
          itemPage: index,
          isClickable: true
        })),
        { itemPage: '...', isClickable: false },
        { itemPage: numPages - 1, isClickable: true }
      ];
    }

    if (page >= numPages - 3) {
      return [
        { itemPage: 0, isClickable: true },
        { itemPage: '...', isClickable: false },
        ...map(range(0, 3), index => ({
          itemPage: numPages - 3 + index,
          isClickable: true
        }))
      ];
    }

    return [
      { itemPage: 0, isClickable: true },
      { itemPage: '...', isClickable: false },
      { itemPage: page - 1, isClickable: true },
      { itemPage: page, isClickable: true },
      { itemPage: page + 1, isClickable: true },
      { itemPage: '...', isClickable: false },
      { itemPage: numPages, isClickable: false }
    ];
  }, [numPages, page]);

  return (
    <div className={clsx(paginationContainer, className)}>
      {shouldShowNumberItem && (
        <div className={paginationItemsContainer}>
          {page > 0 && (
              <div 
                title="Previous"
                onClick={handlePaginationCreator(Math.max(page - 1, 0))}
                className={paginationItemWrapper}
                >
                  <div className={paginationItem}>
                    <i className="fa fa-angle-left" />
                  </div>
              </div>
          )}
          {paginationItems.map(({ itemPage, isClickable }: IPaginationItem, index:any) => (
            <div
              key={index}
              onClick={
                isClickable ? handlePaginationCreator(itemPage) : undefined
              }
              className={paginationItemWrapper}
            >
              <div
                className={clsx(paginationItem, {
                  [paginationItemActive]: itemPage === page
                })}
              >
                {isClickable ? itemPage + 1 : itemPage}
              </div>
            </div>
          ))}
          {page < numPages - 1 && (
              <div 
                title="Next"
                onClick={handlePaginationCreator(Math.min(page + 1, numPages - 1))}
                className={paginationItemWrapper}
                >
                  <div className={paginationItem}>
                    <i className="fa fa-angle-right" />
                  </div>
              </div>
          )}
        </div>
      )}
    </div>
  );
};

export default memo(SearchPagination);