import * as Yup from 'yup';

const AboutGuestSchema=Yup.object().shape({

	describes_you: Yup.string().required('Please enter your profile snapshot').max(80),
	additional_info: Yup.string().required('Please enter some information about yourself').max(500),
	offering: Yup.string().required('Please specify the details of the offer').max(500),
	first_language: Yup.string().required('Please select your first spoken language'),
	yesOtherLanguage: Yup.string(),
	other_language: Yup
		.string()
		.when("yesOtherLanguage", {
			is: 'Yes',
			then: Yup.string().required("Please enter the other language that you speak")
		}),
	current_work: Yup.string().required('Please describe about your current situation'),
	current_work_other: Yup
		.string()
		.when("current_work", {
			is: 'Other',
			then: Yup.string().required("Please describe your current situation")
		}).nullable(true),
	exchange_date: Yup.string(),
	exchange_period: Yup.string().required('Please select house sharing duration'),
	rent_offset: Yup.string().required('Please select Rent or Rent Offset'),
	rental_budget: Yup.number().required('Please enter budget per week')
});

export default AboutGuestSchema;
