import React, { FC, memo, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import reverse from 'lodash/reverse';
import RecipientsList from '../../components/Messages/RecipientsList';
import SearchPanel from '../../components/Messages/SearchPanel';
import UserInfo from '../../components/Messages/UserInfo';
import UserInput from '../../components/Messages/UserInput';
import UserMessages from '../../components/Messages/UserMessages';
import UserNotification from '../../components/Messages/UserNotification';
import FRONTEND_RESPONSE_MESSAGE from '../../js/constants/FRONTEND_RESPONSE_MESSAGE';
import {
	showDetailComponent,
	fetchMessages
} from '../../components/Messages/api';
import { pusherMessageRead, fetchConnectionList } from '../../js/actions/Connection';
import './styles.scss';

export const Messages: FC<any> = ({
	user,
	connection,
	onPusherMessageRead,
	onFetchConnectionList
}) => {
	const [activeConnectionId, handleConnection] = useState(-1);
	const [msgDetails, handleMsgDetails] = useState(null);
	const [messages, handleMessages] = useState<any[]>([]);
	const [currentPage, handleCurrentPage] = useState<number>(0);
	const [searchKeyword, handleSearch] = useState('');
	const [showList, toggleList] = useState(true);
	let messageInterval: any = null;

	const onChangeConnection = (id: number) => {
		if (user.token !== '' && id > 0 && activeConnectionId !== id) {
			handleMessages([]);
			handleConnection(id);
			showDetailComponent(id, user.token).then((resp) => {
				handleMsgDetails(resp);
				onPusherMessageRead(id, user, 'CONNECTION_VIEWED');
			});
		}
	};

	const fetchConnections = useCallback(() => {
		onFetchConnectionList({
			token: user.token,
			page: 1,
			limit: 20,
			type: '',
			search: ''
		});
	}, [onFetchConnectionList]);


	const fetchConnectionMessages = useCallback(
		(activeConnectionId: number) => {
			fetchConnections();
			fetchMessages(1, activeConnectionId, user.token).then((resp) => {
				if (resp && resp.length !== messages.length) {
					handleMessages(reverse(resp));
					handleCurrentPage(1);
				}
			});
		},
		[activeConnectionId]
	);
/*
	const handlePagination = () => {
		if (currentPage > 0) {
			const page = currentPage + 1;
			fetchMessages(page, activeConnectionId, user.token)
				.then((resp) => {
					if (resp && resp.length !== messages.length) {
						handleMessages(reverse(resp));
						handleCurrentPage(page);
					}
				})
				.catch((e) => {});
		}
	};
	*/

	useEffect(() => {
		if (activeConnectionId) {
			fetchConnectionMessages(activeConnectionId);
			messageInterval && clearInterval(messageInterval);
			messageInterval = setInterval(
				() => fetchConnectionMessages(activeConnectionId),
				5000
			);
		}
		return () => {
			messageInterval && window.clearInterval(messageInterval);
		};
	}, [activeConnectionId, fetchConnectionMessages]);

	return (
		<div className="container messagesWrapper">
				<div
					className={`messages-list ${
						showList && 'show-connections'
					}`}
				>
					<SearchPanel onSearch={handleSearch} />
					{connection.connections && (
						<RecipientsList
							searchKeyword={searchKeyword}
							activeConnectionId={activeConnectionId}
							handleActiveMessage={onChangeConnection}
							toggleList={toggleList}
						/>
					)}
				</div>
				<div
					className={`message-detail-panel box ${
						!showList && 'show-connection-Details'
					}`}
				>
					<UserInfo
						details={msgDetails}
						userId={user.user_id}
						userRole={user.role_code}
						hideList={() => toggleList(true)}
					/>
					<UserNotification
						connectionId={activeConnectionId}
						connectionDetail={msgDetails}
					/>
					<UserMessages
						messages={messages}
						msgDetails={msgDetails}
						userId={user.user_id}
					/>
					<UserInput
						fetchMessages={() =>
							fetchConnectionMessages(activeConnectionId)
						}
						activeConnectionId={activeConnectionId}
						token={user.token}
					/>
				</div>
				<div className="clearfix"></div>
				<div
					className="box message-abuse-title"
					dangerouslySetInnerHTML={{
						__html:
							FRONTEND_RESPONSE_MESSAGE.MESSAGE_LIST_ABUSE_MESSAGE
					}}
				/>
			</div>
	);
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		onPusherMessageRead: (connection_id: number, user: any, context: any) =>
			dispatch(pusherMessageRead(connection_id, user, context)),
		onFetchConnectionList: (params: any) =>
			dispatch(fetchConnectionList(params))
	};
};
const mapStateToProps = (store: any) => {
	return {
		user: store.login.user,
		connection: store.connection,
		payment: store.payment
	};
};

export default memo(connect(mapStateToProps, mapDispatchToProps)(Messages));
