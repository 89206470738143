import React from 'react';

export const SocialIcons = () => (
	<div className="col-md-12 social-media text-center">
		<ul className="social-icons">
			<li>
				<a href="https://www.facebook.com/theroomxchange" target="_blank"><i className="fa fa-facebook"></i></a>
			</li>
			<li>
				<a href="https://twitter.com/TheRoomXchange" target="_blank"><i className="fa fa-twitter"></i></a>
			</li>
			<li>
				<a href="https://www.instagram.com/theroomxchange/" target="_blank"><i className="fa fa-instagram"></i></a>
			</li>
		</ul>
	</div>
  );
  
  