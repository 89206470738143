
import LANGUAGES from "../../constants/selectOptions/LANGUAGES";
import React from "react";
import {getUserAvatar} from "../../../components/UserProfile/utils";
import {FormikErrors, FormikValues} from "formik";
import {FormikTouched} from "formik/dist/types";
import {AccordionConfigType, RemoteResponse} from "../types/editProfileTypes";
import {ACCORDION_ACTION, SET_LOADER, UPDATE_GLOBAL_STATE} from "../context/actions";
import {editProfileService} from "./editProfileService";

export const ACTION_CONTINUE='contine';
export const ACTION_EXIT='exit'
export const remainingChar = (str: string, maxLength: number) => {
	return maxLength-str.length;
};

export const selectedOptionLanguages = LANGUAGES.map(
	(selectedOptionLanguage, index) => {
		return (
			<option key={index} value={selectedOptionLanguage}>
				{selectedOptionLanguage}
			</option>
		);
	}
);

export const  hasError=(errors: FormikErrors<FormikValues>, touched: FormikTouched<FormikValues>, fieldName: string)=>{

	if(Object.keys(touched).length>1)
		return (touched[fieldName] && errors[fieldName]);
	else
		return errors[fieldName];
}

export const userAvatar = getUserAvatar();



export const getAccordionDefaultConfig= ()=>{
	return {
		aboutCollapsed: true,
		lifestyleCollapsed: true,
		prioritiesCollapsed: true,
		personalityCollapsed: true,
		inclusionCollapsed: true,
		interestsCollapsed: true,
		photosCollapsed: true
	} as AccordionConfigType;
}


export const manageAccordion=(config: AccordionConfigType, update: Function)=>{
	update({
		type: ACCORDION_ACTION,
		payload: {
			accordionConfig: config
		}
	});
}

export const postActionSuccess=(buttonType: string, update: Function, key: string|null, user_id: number, token: string)=>{
	let config=getAccordionDefaultConfig();
	if(buttonType===ACTION_CONTINUE)
	{
		if(key!=null)
			config[key]=false;
	}

	editProfileService.COMMON.getDataFromServer(user_id,token).then(result=>{
		update({
			type: UPDATE_GLOBAL_STATE,
			payload: {
				accordionConfig: config,
				data: result.data as RemoteResponse
			}
		});
	})
}

export const setAjaxLoader=(flag: boolean, update: Function)=>{
	update({
		type: SET_LOADER,
		payload: {
			loader: flag
		}
	});
}

export const showAjaxError=(res: any, setSubmissionError: Function, update: Function)=>{

	if(res.response.data.errors!==undefined)
	{
		let errorKeys=Object.keys(res.response.data.errors);
		errorKeys.forEach((key)=>{
			let errorList=res.response.data.errors[key];
			setSubmissionError(errorList[0]);
		})
	}
	else
		setSubmissionError('Some error occured. Please try again later');

	setAjaxLoader(false,update);
}


export const getValFromObj = (object: any, key: string, defaultVal: string | Array<string> | boolean | object | Array<object> = '',type='key')=> {

	let elem=null;
	if(object!==undefined)
		elem=object[key];
	if(elem===undefined||elem===null)
		return defaultVal;


	switch (type)
	{
		case 'key':
		case 'boolean':
		case 'object':
		case 'array':
			return elem;
		case 'stringArray':
			let parsed=JSON.parse(elem);
			return parsed?parsed:defaultVal;
	}
}




export const includeTestId = (id: string) => {
	if (process.env.NODE_ENV !== 'test') return {};
	return {
		'data-testid': id
	};
};
