import React, { FC, memo } from 'react';
import InfoBubble from '../../components/common/InfoBubble';

interface Props {
	status: number;
}


const StatusBar: FC<Props> = ({status}) => (
    <>
        <label>
            {status || 'N/A'}% COMPLETE
        </label>
        <div
            className="progress"
            style={{ height: '4px' }}
        >
            <div
            className="progress-bar"
            style={{width: `${status || 0}%`}}
            />
        </div>
    </>
)

const ProfileCompletionStaus: FC<Props> = ({ status }) => {
    const isComplete = status === 100;
    return (
        <>
            <div className="pre-det">
                <div className="block-title dashboard-info__value">
                    Public profile
                    <InfoBubble
                        tooltip="Your profile needs to be 100% complete before you can connect with users."
                    />
                </div>
                {!isComplete && (
                    <div>It will take about 15 minutes to complete the profile questions and verification process. It must be 100% before you can connect with other users.</div>
                )}
            </div>
            <div className="pre-bar">
                {!isComplete ? (<StatusBar status={status} />) : (
                    <>Congratulations! Your profile is complete and you're ready to start connecting.</>
                )}
            </div>
        </>
	);
};

export default memo(ProfileCompletionStaus);
