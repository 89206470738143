import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchConnectionList } from '../../actions/Connection';
import moment from 'moment';
import { CONNECTION_STATUS } from '../../constants/CONNECTION_STATUS';
import InfoBubble from "../../../components/common/InfoBubble";

class ConnectionList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requesting: false,
      emptyMessage: 'Loading messages'
    };
  }

  onConnectionListClick = (connectionSummary, e) => {
    this.props.history.push('/message/' + connectionSummary.connection_id);
  };

  showConnectionStatus = (connectionSummary, userId) => {
    let userType = userId == connectionSummary.from_user_id ? 'to' : 'from';
    if (connectionSummary.xchange_from_user_id) {
      userType =
        userId == connectionSummary.xchange_from_user_id ? 'to' : 'from';
    }
    return CONNECTION_STATUS[
      connectionSummary.status + '_' + userType.toUpperCase()
    ];
  };

  renderStatus = (connectionSummary, index, userId) => {
    const createdAt = moment
      .utc(connectionSummary.created_at)
      .local()
      .format('MMM DD');

    let userType = userId == connectionSummary.from_user_id ? 'to' : 'from';
    if (connectionSummary.xchange_from_user_id) {
      userType =
        userId == connectionSummary.xchange_from_user_id ? 'to' : 'from';
    }
    let currentUserType =
      userId == connectionSummary.from_user_id ? 'from' : 'to';
    let activeClass =
      this.state.connectionSummary &&
      connectionSummary.connection_id ==
        this.state.connectionSummary.connection_id
        ? ' active'
        : '';
    let image = '/themes/theroomxchange/assets/images/useravatar.png';
    if (connectionSummary[userType + '_user_image']) {
      image = connectionSummary[userType + '_user_image'];
    }
    return (
      <div
        className={'msg-block padding-com ' + activeClass}
        key={index}
        onClick={this.onConnectionListClick.bind(this, connectionSummary)}
      >
        <div className="row">
          <div className="col-12 msg-img">
            <img src={image} className="img-fluid" />
            {connectionSummary[currentUserType + '_user_seen_status'] ===
              'Unseen' && <i className="fa fa-star" />}
          </div>
          <div className="col-12 msg-list">
            <div className="display-name">
              {connectionSummary[userType + '_user_first_name']}
            </div>
            <p>{connectionSummary.text}</p>
          </div>
          <div className="col-lg-5 col-2 ">
            <div className="msg-date float-right">{createdAt}</div>
            <div className="clearfix" />
            <div
              className={
                'msg-status float-right text-green ' +
                connectionSummary.status.toLowerCase()
              }
            >
              {this.showConnectionStatus(connectionSummary, userId)}
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const xchangeRequestList = this.props.connection.connections.length
      ? this.props.connection.connections.length
      : '0';
    
    return (
      <div>
        <div className="msg-page">
          <div className="msg-lt ">
            <div className="msg-wrap block-border">
              <div>
                <div className="block-title">Messages
                  <InfoBubble tooltip="Check your messages regularly and reply to people who reach out to you. It’s a nice thing to do even if you think they’re not ideal for you."/>
                  <span className="xch-num">{xchangeRequestList}</span>
                </div>
                {this.props.connection.connections.map(
                  (connectionSummary, index) => {
                    if (index > 4) {
                      return;
                    }
                    return this.renderStatus(
                      connectionSummary,
                      index,
                      this.props.login.user_id
                    );
                  }
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = dispatch => {
  return {
    onFetchConnectionList: params => dispatch(fetchConnectionList(params)),
    dispatch
  };
};
const mapStateToProps = store => {
  return {
    login: store.login.user,
    profile: store.profile.profile,
    connection: store.connection
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ConnectionList)
);
