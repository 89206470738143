import { combineReducers } from 'redux';
import LoginReducer from './Login';
import SignupReducer from './Signup';
import ProfileReducer from './Profile';
import PreferenceReducer from './HostPreferences';
import ModalReducer from './Modal';
import BagdesReducer from './Badges';
import { searchStore } from './Search';
import ConnectionReducer from './Connection';
import { exchangeReducer } from './Xchange';
import PaymentReducer from './Payment';
import { smsVerificationReducer } from './SMSVerification';
import { publicSearchModalReducer } from './PublicSearchModal';

const appReducer = combineReducers({
  login: LoginReducer,
  signup: SignupReducer,
  preferences: PreferenceReducer,
  profile: ProfileReducer,
  modalReducer: ModalReducer,
  badges: BagdesReducer,
  search: searchStore,
  connection: ConnectionReducer,
  xchange: exchangeReducer,
  payment: PaymentReducer,
  smsVerification: smsVerificationReducer,
  publicSearchModal: publicSearchModalReducer,
});

export default (state, action) =>
  appReducer(action.type === 'USER_LOGOUT' ? undefined : state, action);
