import {remainingChar} from "../../utils/common";
import React, {FC} from "react";

type CharCountProps={
	dangerLimit: number,
	field: string,
	maxLimit: number,
	fieldName?: string
}
const CharCount: FC<CharCountProps>=({dangerLimit, field, maxLimit,fieldName})=>{
	return (
		<span id={fieldName+'_character_counter'} className={`d-inline-block float-right font-weight-semi-bold ${field.length>dangerLimit?'error':''}`}>{remainingChar(field,maxLimit)}/{maxLimit}</span>
	)
}

export default CharCount;
