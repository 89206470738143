import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import URL from '../../constants/URL';
import { viewProfile } from '../../actions/Profile';
import { ProcessModal } from '../../actions/Modal';
import { HIDE_MODAL } from '../../actions/Modal';
import { Button } from '../../../components/common'

class DeactivateModal extends Component {
  constructor(props){
    super(props)
    this.state={
      requesting:false
    }
  }

  closeHandler = () => {
    this.props.onClose(HIDE_MODAL,null,null)
  }

  deactivateAccount = (e) => {
    e.preventDefault();
    this.setState({...this.state,requesting:true})
    const request = axios({
      method:"put",
      url:URL.DEACTIVATE_ACCOUNT(this.props.login.user_id),
      data:{
        token:this.props.login.token
      }
    });
    request.then((result) => {
      if(result.data.status==="success"){
        this.setState({...this.state,requesting:false})
        this.props.onViewProfile(this.props.login.user_id,this.props.login.token,)
        this.closeHandler()
        this.props.history.push("/")
      }
    })


  }


  render(){
    let type="DEACTIVATE"
    if(this.props.type==="activateUser"){
      type="REACTIVATE"
    }

    let nameFromLogin =  this.props.login.name.split(" ");
    let name = nameFromLogin[0]
    if(this.props.profile){
      if(this.props.profile.preferred_name){
        name = this.props.profile.preferred_name;
      }else{
        let nameFromProfile =  this.props.profile.name.split(" ");
        name=nameFromProfile[0]
      }
    }

    return(
      <div>
        <div className="overlay modal-backdrop modal-dialog-centered">
          <div className="col-md-6 offset-md-3">
            <div className="modal-content padding-com">
              {this.state.requesting && (
                <div className="page-loading"></div>
              )}
              <div>
                <div className="modal-header">
                  <button type="button" className="close" onClick={this.closeHandler} data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true"><i className="fa fa-close"></i></span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="">
                    {this.props.type !=="activateUser" ?
                      <div>
                        <h3 className="text-center">Are you sure you want to deactivate your account?</h3>
                        <p className="text-center">
                          We hate to see you go, but understand that The Room Xchange is not for everyone. We would really appreciate some feedback about your decision to leave. Please send us feedback <a style={{color:"#EF7F49",cursor:'pointer'}} target="_blank" href="/contact">here</a>.
                          <br/><br/>Thank you kindly.<br/>
                          PS - Just so you know, you can come back at anytime and reactivate your account. We would love to welcome you back.
                        </p>
                      </div>
                      :<div><div className="top-title">Reactivate Account</div>
                    <p>Hi {name}, we’re glad you decided to come back. Click that orange button below and start changing the way you live.</p></div>
                  }
                </div>

              </div>
              <div className="modal-footer">
                <div>
                  <Button className="text-capitalize" type="submit" onClick={this.deactivateAccount}>{type.toLowerCase()}</Button>
                  <Button type="button" color="teal" onClick={this.closeHandler} >Close</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
}
const mapDispatchToProps = (dispatch) => {
  return{
    onViewProfile:(user_id,token) => dispatch(viewProfile(user_id,token)),
    onClose:(actionType,modalType,modalProps)=>dispatch(ProcessModal(actionType,modalType,modalProps))
  }
}
const mapStateToProps = (store) => {
  return {
    login: store.login.user,
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(DeactivateModal));
