import axios from 'axios';
import URL from '../constants/URL';

export const FETCH_CONNECTION_LIST = 'FETCH_CONNECTION_LIST';
export const FETCH_CONNECTION_LIST_REQUEST = 'FETCH_CONNECTION_LIST_REQUEST';
export const PUSHER_MESSAGE_RECEIVED = 'PUSHER_MESSAGE_RECEIVED';
export const PUSHER_MESSAGE_DELETE = 'DELETE_PUSHER_MESSAGE';
export const CONNECTION_MESSAGE_READ = 'CONNECTION_MESSAGE_READ';
export const NEW_MESSAGE_CREATED = 'NEW_MESSAGE_CREATED';

export const fetchConnectionListRequest = () => {
  return {
    type: FETCH_CONNECTION_LIST_REQUEST
  };
};

export const fetchConnectionList = params => dispatch => {
  dispatch(fetchConnectionListRequest());

  const request = axios({
    method: 'get',
    url: URL.FETCH_CONNECTION_LIST_API(params)
  });

  request.then(result => {
    if (result.data.status === 'success') {
      return dispatch({
        type: FETCH_CONNECTION_LIST,
        payload: {
          connections: result.data.data,
          search: params.search,
          type: params.type,
          page: params.page,
          loadMore: result.data.data.length >= 20
        }
      });
    }
  });
};

export const pusherMessageReceived = (message, user_id) => {
  message.current_user = user_id;

  return dispatch =>
    dispatch({
      type: PUSHER_MESSAGE_RECEIVED,
      payload: message
    });
};

export const pusherMessageRead = (connection_id, current_user, context) => {
  return dispatch => {
    if (context === 'PUSHER_RECEIVED') {
      const request = axios({
        method: 'post',
        url: URL.SEEN_CONNECTION_API(connection_id),
        data: {
          token: current_user.token
        }
      });
      request.then(result => {
        if (result.data.status === 'success') {
          return dispatch({
            type: CONNECTION_MESSAGE_READ,
            payload: {
              connection_id: connection_id,
              current_user: current_user.user_id
            }
          });
        }
      });
    } else {
      return dispatch({
        type: CONNECTION_MESSAGE_READ,
        payload: {
          connection_id: connection_id,
          current_user: current_user.user_id
        }
      });
    }
  };
};

export const newMessageCreated = (message, user_id) => {
  message.current_user = user_id;

  return dispatch =>
    dispatch({
      type: NEW_MESSAGE_CREATED,
      payload: message
    });
};
