import React, { FC, Fragment } from 'react';

const links = [
	{ url: '/', title: 'Home' },
	{ url: '/how-it-works', title: 'How it works' },
	{ url: '/faq', title: 'FAQs' },
	{ url: '/verification', title: 'Verify' },
	{ url: '/pricing', title: 'Pricing' },
	{ url: '/blog', title: 'Blog' },
	{ url: '/contact', title: 'Contact' },
	{ url: '/login', title: 'Login' }
];

export const FooterLinks: FC<any> = ({ isLoggedIn }) => (
	<ul className="nav justify-content-center">
		{links.map(({ url, title }) => {
			if (isLoggedIn && title === 'Login') return <Fragment key={title}></Fragment>;
			return (
				<li key={title} className="nav-item">
					<a className="nav-link" href={url}>
						{title}
					</a>
				</li>
			);
		})}
	</ul>
);
