import * as Yup from 'yup';


const TalentsSchema=Yup.object().shape({
	talents: Yup.array().min(1, "Please select at least one interest"),
	skills_other: Yup.string().when('talents',{
		is: (talents)=>{
			return (talents.includes('Other'))
		},
		then: Yup.string().required('Please enter the other interest')
	}).nullable(true),
	tasks_to_do: Yup.array().when("rent_offset", {
		is: (rent_offset)=>{
			return rent_offset
		},
		then: Yup.array().min(1, "Please select at least one task to offset rent")
	}),
	tasks_to_do_other: Yup.string().when('tasks_to_do',{
		is: (tasks_to_do)=>{
			return (tasks_to_do.includes('Other'))
		},
		then: Yup.string().required('Please enter the other task')
	}).nullable(true),
	rent_offset: Yup.boolean()
});

export default TalentsSchema;
