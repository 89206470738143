import React, { FC, memo, useCallback, useEffect } from 'react';
import filter from 'lodash/filter';
import map from 'lodash/map';
import get from 'lodash/get';
import toLower from 'lodash/toLower';
import includes from 'lodash/includes';
import { connect } from 'react-redux';
import { RecipientsListProps } from '../types';
import MessageCard from './MessageCard';
import { getRecipientInfo } from '../api';

export const RecipientsList: FC<RecipientsListProps> = ({
	searchKeyword,
	user,
	connection,
	activeConnectionId,
	toggleList,
	handleActiveMessage
}) => {
	const onChangeActiveConnection = useCallback((connectionId: number) => {
		handleActiveMessage(connectionId);
	}, []);

	const showDefaultConnection = useCallback(() => {
		const id = get(connection, 'connections[0].connection_id', 0);
		onChangeActiveConnection(id);
	}, [connection?.connections.length]);

	useEffect(() => {
		showDefaultConnection();
	}, [showDefaultConnection]);

	const getFilteredList = () => {
		const connections = get(connection, 'connections', []);
		if (searchKeyword === '') return connections;

		return filter(connections, (connection: any) => {
			const recipient = getRecipientInfo(user.user_id, connection);
			return (
				includes(toLower(recipient.name), toLower(searchKeyword)) ||
				includes(toLower(recipient.text), toLower(searchKeyword))
			);
		});
	};

	return (
		<div className="recipients-list">
			{map(getFilteredList(), (connection, key) => (
				<MessageCard
					key={key}
					recipient={getRecipientInfo(user.user_id, connection)}
					activeConnectionId={activeConnectionId}
					handleActiveMessage={(id: number) => {
						handleActiveMessage(id);
						toggleList(false);
					}}
				/>
			))}
		</div>
	);
};

const mapStateToProps = (store: any) => {
	return {
		user: store.login.user,
		connection: store.connection
	};
};
export default memo(connect(mapStateToProps, null)(RecipientsList));
