import React from 'react';
import { connect } from 'react-redux';
import AdditionalImageModal from './AdditionalImageModal';
import ProfileModal from './ProfileModal';
import DeactivateModal from './DeactivateModal';
import DragAndDropModal from './DragAndDropModal';
import PoliceCheckModal from './PoliceCheckModal';
import ProspectiveUserModal from './ProspectiveUserModal';
import ConnectModal from './ConnectModal';
import AcceptModal from './AcceptModal';
import DeclineModal from './DeclineModal';
import XchangeDeclineModal from './XchangeDeclineModal';
import PreviousInvoicesModal from './PreviousInvoicesModal';
import PaymentSubmitModal from './PaymentSubmitModal';
import PaymentCompleteModal from './PaymentCompleteModal';
import CardDeleteModal from './CardDeleteModal';
import { XchangeModal } from './XchangeModal';
import { ModalTypes } from '../../constants/ModalTypes';

const ModalRoot = ({ modal }) => {
  const MODAL_COMPONENT = {
    [ModalTypes.ADDITIONAL_IMAGE_MODAL]: AdditionalImageModal,
    [ModalTypes.PROFILE_MODAL]: ProfileModal,
    [ModalTypes.DEACTIVATE_MODAL]: DeactivateModal,
    [ModalTypes.DROP_AND_DRAG_MODAL]: DragAndDropModal,
    [ModalTypes.POLICE_CHECK_MODAL]: PoliceCheckModal,
    [ModalTypes.PROSPECTIVE_USER_MODAL]: ProspectiveUserModal,
    [ModalTypes.CONNECT_MODAL]: ConnectModal,
    [ModalTypes.DECLINE_MODAL]: DeclineModal,
    [ModalTypes.ACCEPT_MODAL]: AcceptModal,
    [ModalTypes.XCHANGE_DECLINE_MODAL]: XchangeDeclineModal,
    [ModalTypes.PREVIOUSINVOICES_MODAL]: PreviousInvoicesModal,
    [ModalTypes.PAYMENTSUBMIT_MODAL]: PaymentSubmitModal,
    [ModalTypes.PAYMENTCOMPLETE_MODAL]: PaymentCompleteModal,
    [ModalTypes.CARD_DELETE_MODAL]: CardDeleteModal,
    [ModalTypes.XCHANGE_MODAL]: XchangeModal
  };

  const { modalType, modalProps } = modal;

  if (!modalType) {
    document.body.removeAttribute('class');
    return null;
  }

  document.body.className = 'modal-open';
  const SpecificModal = MODAL_COMPONENT[modalType];
  return <SpecificModal {...modalProps} />;
};

const mapStateToProps = store => ({
  modal: store.modalReducer
});

export default connect(mapStateToProps)(ModalRoot);
