import axios from 'axios';
import moment from 'moment';
import URL from '../constants/URL';
import * as LocalStorageHelper from '../utils/LocalStorage';
import { createAction } from 'redux-actions';

export const SIGNUP_BEGIN = 'SIGNUP_BEGIN';
export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE';
const CLEAR_RECENT_USER = 'CLEAR_RECENT_USER';

export const signupBegin = info => {
  return {
    type: SIGNUP_BEGIN,
    payload: info
  };
};

export const signupRequest = () => {
  return {
    type: SIGNUP_REQUEST
  };
};

export const signupSuccess = signUpResponse => {
  return {
    type: SIGNUP_SUCCESS,
    payload: signUpResponse
  };
};

export const signupFailure = error => {
  return {
    type: SIGNUP_FAILURE,
    payload: error
  };
};

export const signup = signUpData => {
  return dispatch => {
    dispatch(signupRequest());

    const group_code = new URLSearchParams(window.location.search).get('group_code')
    const data = {
      campaign: signUpData.campaign,
      email: signUpData.email,
      facebook_id: signUpData.facebookID,
      google_id: signUpData.googleID,
      password: signUpData.password,
      first_name: signUpData.firstName,
      last_name: signUpData.lastName,
      preferred_name: signUpData.preferredName,
      phone_no: signUpData.phone,
      post_code: signUpData.suburb && signUpData.suburb.post_code,
      suburb: signUpData.suburb && signUpData.suburb.locality,
      city: signUpData.suburb && signUpData.suburb.city,
      state: signUpData.suburb && signUpData.suburb.state,
      gender: signUpData.gender,
      gender_other: signUpData.otherGender,
      dob: moment(signUpData.dob).format('YYYY-MM-DD'),
      policy: signUpData.policy,
      foundout: signUpData.foundout,
      foundout_other: signUpData.otherFoundout,
      role: signUpData.role,
      google_recaptcha: signUpData.google_recaptcha,
      group_code : group_code
    };

    const request = axios({
      method: 'post',
      url: URL.USERS,
      data
    });

    return request
      .then(result => {
        if (result.data.status === 'success') {
          dispatch(signupSuccess(result.data.data));

          // TODO: move these side effects to their own actions
          LocalStorageHelper.save('recentUser', result.data.data);

          return { success: true, data: result.data.data };
        } else {
          dispatch(signupFailure(result.data.msg));

          return { success: false, data: result.data.data };
        }
      })
      .catch(error => {
        console.log(error);

        dispatch(
          signupFailure(
            'Some server issue occured while processing your request. Please try again later.'
          )
        );
      });
  };
};

export const clearRecentUser = createAction(CLEAR_RECENT_USER);

export const removeRecentUser = () => dispatch => {
  LocalStorageHelper.clear('recentUser');

  dispatch(clearRecentUser());

  return;
};
