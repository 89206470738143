import {hasError} from "../../utils/common";
import React, {FC} from "react";
import {FormikErrors, FormikValues} from "formik";
import {FormikTouched} from "formik/dist/types";

type ErrorProps={
	errors: FormikErrors<FormikValues>,
	fieldName: string,
	touched: FormikTouched<FormikValues> | null,
	containerClass?: string

}

const FormError: FC<ErrorProps>=({errors, fieldName, touched, containerClass})=>{

	return (
		<div className={`${containerClass?containerClass:''}`}>
			<span className="d-inline-block"><p className="error font-weight-semi-bold" id={`${fieldName}_field_error`}>{touched===null?(hasError(errors,{},fieldName)?errors[fieldName]:''):hasError(errors,touched,fieldName)?errors[fieldName]:''}</p></span>
		</div>
	)
}

export default FormError;
