import React, { Component } from 'react';
import scriptLoader from 'react-async-load-script';

class DigitalID extends Component {
  componentDidUpdate(prevProps) {
    if (
      prevProps.isScriptLoadSucceed !== this.props.isScriptLoadSucceed &&
      this.props.isScriptLoadSucceed
    ) {
      window.digitalId.init({
        clientId: 'ctid5OyiqRgIpJIMND8GgYL0mF',
        onComplete: this.props.onCompleted,
        buttonConfig: {
          type: 'basic', // supported types: basic | branded.
          label: 'Add',
          classNames: 'btn btn-gray btn-x-small text-white' // single css classname as
          // String or multiple as an Array.
        }
      });
    }
  }

  render() {
    if (this.props.digitalID && this.props.digitalID[0] === 'DIGITAL_ID') {
      return (
        <div button btn-orange>
          Added
        </div>
      );
    }

    return <div id="digitalid-verify"></div>;
  }
}

export default scriptLoader(['https://digitalid.com/sdk/app.js'])(DigitalID);
