import axios from 'axios';
import URL from '../constants/URL';

export const HOST_PREFERENCE_REQUEST="HOST_PREFERENCE_REQUEST";
export const HOST_PREFERENCE_SUCCESS="HOST_PREFERENCE_SUCCESS";
export const FETCH_PREFERENCE_DATA="FETCH_HOST_PREFERENCE_DATA"
export const HOST_PREFERENCE_FAILURE="HOST_PREFERENCE_FAILURE";

Object.from = arr => Object.assign(...arr.map( (v, k) => ({[v]: v}) ));

export const hostPreferenceRequest = () => {
   return{
     type:HOST_PREFERENCE_REQUEST
   }
}

export const hostPreferenceSuccess = (response) => {
    return{
        type:HOST_PREFERENCE_SUCCESS,
        payload:response
    }
}


export const fetchHostPreferenceData = (hostPreferenceData) => {
    return{
        type:FETCH_PREFERENCE_DATA,
        payload:hostPreferenceData
    }
}


export const hostPreferenceFailure = (error) => {
  return{
    type:HOST_PREFERENCE_FAILURE,
    payload:error
  }
}

export const hostPreference = (user,hostPreferenceData) => {
      return (dispatch) => {
        let tasks = Object.from(hostPreferenceData.task);
        if(tasks["Other"]) tasks["Other"] = hostPreferenceData.otherTask;
        const request= axios({
          method:hostPreferenceData.context,
          url:URL.HOST_PREFERENCE(user.user_id),
          data:{
              gender_preference:hostPreferenceData.dropdownGender,
              first_language:hostPreferenceData.language,
              other_language:hostPreferenceData.otherLanguage,
              home_type:hostPreferenceData.dropdownHome,
              distance_from_city:hostPreferenceData.cityDistance,
              nearby_public_transport: hostPreferenceData.transport,
              exchange_date:hostPreferenceData.timeToExchange,
              exchange_period:hostPreferenceData.durationToExchange,
              age_group:hostPreferenceData.age,
              specific_home_feature:hostPreferenceData.feature,
              household_personality:JSON.stringify(hostPreferenceData.check),
              smokin_preference:hostPreferenceData.smokingPreference,
              features_access:JSON.stringify(hostPreferenceData.features),
              pet:hostPreferenceData.pets,
              bathroom_share:hostPreferenceData.bathroom,
              help_tasks:JSON.stringify(tasks),
              additional_info:hostPreferenceData.additionalInformation,
              token: user.token
          }
        });
        request.then((result)  =>{
          if(result.data.status==="success"){
            dispatch(hostPreferenceSuccess(result.data.msg));
            fetchExchangePreferences(user);
          }else{
            dispatch(hostPreferenceFailure(result.data.msg))
          }
        })

      }
}

export const fetchExchangePreferences = (user) => {
    return (dispatch) => {
        const request= axios({
          method:"get",
          url:URL.GET_PREFERENCES(user.user_id, user.token)
        });
        request.then((result)  =>{
          if(result.data.status==="success"){
            dispatch(fetchHostPreferenceData(result.data))
          }else{
            dispatch(hostPreferenceFailure(result.data.msg))
          }
        })
    }
}
