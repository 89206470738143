import React, {FC, useContext, useEffect, useState} from 'react';
import InfoBubble from "../../../components/common/InfoBubble";
import {useFormik} from 'formik';
import '../styles/profile_edit.scss';
import {priorities, prioritiesGuest} from "../utils/formData";
import PrioritiesSchema from "../schemas/PrioritiesSchema";
import {RoleCode} from "../../../store/Users/constants";
import {editProfileService} from "../utils/editProfileService";
import {
	getAccordionDefaultConfig, getValFromObj,
	manageAccordion,
	postActionSuccess,
	setAjaxLoader,
	showAjaxError, includeTestId
} from "../utils/common";
import { UserProps} from "../types/editProfileTypes";
import FormError from "./helpers/FormError";
import FormSubmitButtons from "./helpers/FormSubmitButtons";
import EditProfileContext from "../context/EditProfileContext";
import RemoteUpdateError from "./helpers/RemoteUpdateError";



const Priorities: FC<UserProps>=({user})=>{
	const {accordionConfig, update,data}=useContext(EditProfileContext);
	const [buttonType, setButtonType]=useState('');
	const [submissionError, setSubmissionError] = useState('');
	let priorityList=getValFromObj(data,'exchange_priorities',[],'stringArray');

	const {values, errors, handleChange, handleSubmit, isValid, validateForm} = useFormik({
		initialValues: {priorities: priorityList},
		validationSchema: PrioritiesSchema,
		onSubmit: (values) => {

			const params={...values};
			setAjaxLoader(true,update);
			editProfileService.COMMON.updatePriorities(params.priorities,user).then(result=>{
				const {data}=result;
				if(data.status==='success')
					postActionSuccess(buttonType, update, 'personalityCollapsed', user.user_id, user.token);
				else if(data.msg!==undefined)
					setSubmissionError(data.msg);
				else
					setSubmissionError('Some error occurred. Please try again later');
				setAjaxLoader(false,update);
			}).catch(res=>{
				showAjaxError(res,setSubmissionError,update);
			});
		}
	});

	useEffect(() => {
		(() => validateForm())();
	}, []);

	const errorMessages = () => {
		if (submissionError) {
			return submissionError
		} else if (!isValid) {
			return "There are some issues you need to resolve before saving"
		} else {
			return '';
		}
	}

	return (
		<div className="card">
			<div className="card-header" role="tab" id="headingPriorities">
				<a className={accordionConfig.prioritiesCollapsed ? 'collapsed' : ''} href="#abt-priorities" aria-expanded={!accordionConfig.prioritiesCollapsed} onClick={()=>{ manageAccordion({...getAccordionDefaultConfig(), prioritiesCollapsed: !accordionConfig.prioritiesCollapsed}, update)}}>
					<h2 className="mb-0">
						<div className="card-header__left" {...includeTestId('priorities-heading')}>
							<i className={isValid ? 'fa fa-check icon-rounded' : 'fa fa-close icon-rounded'}/>
							Priorities
							<InfoBubble tooltip="What are the main priorities in how you like to live?" />
						</div>
					</h2>
				</a>
			</div>

			<div id="abt-priorities" className={`collapse ${accordionConfig.prioritiesCollapsed?'':'show'} `} role="tabpanel" aria-labelledby="headingPriorities" data-parent="#profileAccordion">
				<form className="form-horizontal form-signup" role="form" onSubmit={handleSubmit}>
					<div className="card-body">
						<div className="row form-mod"  {...includeTestId('priorities-exchange')}>
							<div className="col-12">
								<label htmlFor="priorities" className="control-label">
									{user.role_code===RoleCode.HOST?'Please select five things that are a priority in how you live.':'Please select five of the following that are most important to you.'}
								</label>
								<FormError errors={errors} fieldName={'priorities'} touched={null} containerClass={'mt-1 mb-1'}/>
							</div>
							{(user.role_code===RoleCode.GUEST?prioritiesGuest: priorities).map((item, key) => (
								<div className="col-lg-4 col-md-4 col-sm-12 col-12" key={`priorities-${item.value}`}>
									<div className="form-check" >
										<input className="form-check-input" name="priorities" type="checkbox" value={item.value} checked={values.priorities.includes(item.value)}  onChange={handleChange}/>
										<label className="form-check-label" htmlFor={`priorities-${item.label}`}>{item.label}</label>
									</div>
								</div>
							))}
						</div>
						<RemoteUpdateError submissionError={errorMessages()}/>
					</div>
					<FormSubmitButtons setButtonType={setButtonType} isValid={isValid}/>
				</form>
			</div>
		</div>
	);
}

export default Priorities;
