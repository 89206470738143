import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import Header from '../components/layouts/Header';
import Footer from '../../components/Footer';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import {
  required,
  email,
  confirmpassword,
  passwordValidation
} from '../utils/FormValidators.jsx';
import { login, logout } from '../actions/Login';
import URL from '../constants/URL';
import FRONTEND_RESPONSE_MESSAGE from '../constants/FRONTEND_RESPONSE_MESSAGE';
import { RVButton as Button } from '../../components/common';

export const TokenVerificationForm = props => (
  <div>
    <h1>Reset Password</h1>
    <hr className="horizontal-org" />
    <Form
      onSubmit={props.onFormSubmit}
      className="form-horizontal form-signup"
      role="form"
    >
      <div className="row">
        <div className="form-group">
          <label htmlFor="enter-code" className="control-label col-md-12">
            {
              FRONTEND_RESPONSE_MESSAGE.LOST_PASSWORD
                .EMAIL_SUBMIT_SUCCESS_RESPONSE
            }
          </label>
        </div>
      </div>
    </Form>
  </div>
);

export const EmailVerificationForm = props => (
  <div>
    <h1>Reset Password</h1>
    <hr className="horizontal-org" />
    <Form
      onSubmit={props.onFormSubmit}
      className="form-horizontal form-signup"
      role="form"
    >
      <div className="row">
        <div className="form-group">
          <label htmlFor="email" className="control-label col-md-12">
            Enter the email address associated with your account to receive the
            reset password link.
          </label>
        </div>
        <div className=" col-md-6">
          <div className="form-group">
            <Input
              type="email"
              className="form-control"
              name="email"
              placeholder="Your Email Address"
              onChange={props.onInputChange}
              validations={[required, email]}
              value={props.email}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="form-group">
            <Button type="submit">
              Continue
            </Button>
          </div>
        </div>
      </div>
    </Form>
  </div>
);

export const ResetPassword = props => (
  <div>
    <h1>Reset Password</h1>
    <hr className="horizontal-org" />
    <Form onSubmit={props.onFormSubmit} className="form-horizontal form-signup">
      <div className="form-group row">
        <label htmlFor="new-password" className="col-md-3 control-label">
          New Password
        </label>
        <div className="col-md-4">
          <Input
            minLength={5}
            validations={[required, passwordValidation]}
            type="password"
            name="password"
            onChange={props.onInputChange}
            className="form-control"
            id="new-password"
          />
        </div>
        {/*col-md-4*/}
      </div>
      {/*form-group*/}
      <div className="form-group row">
        <label htmlFor="confirm-password" className="col-md-3 control-label">
          Confirm Password
        </label>
        <div className="col-md-4">
          <Input
            validations={[required, confirmpassword]}
            type="password"
            name="confirmpassword"
            onChange={props.onInputChange}
            className="form-control"
            id="confirm-password"
          />
        </div>
        {/*col-md-4*/}
      </div>
      {/*form-group*/}
      <Button type="submit">
        Submit
      </Button>
    </Form>
  </div>
);

class LostPassword extends Component {
  componentDidMount() {
    document.title = 'Reset Password | The Room Xchange';
    if (this.props.login.user != null) {
      this.props.history.push('/');
    }

    if (this.props.match.params.token) {
      const token = this.props.match.params.token;
      const request = axios({
        method: 'post',
        url: URL.VERIFY_CODE,
        data: { token: token }
      });
      request.then(result => {
        if (result.data.status === 'success') {
          this.setState({
            code: token,
            tokenVerified: true,
            emailVerified: false
          });
        } else {
          this.props.history.push('/reset-password');
        }
      });
    }
  }

  componentDidUpdate() {
    if (this.props.login.user != null) {
      this.props.history.push('/');
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      emailError: false,
      codeError: false,
      code: '',
      emailVerified: false,
      tokenVerified: false,
      password: '',
      confirmpassword: '',
      resetPassword: false
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleEmailSubmit = this.handleEmailSubmit.bind(this);
    this.handleCodeSubmit = this.handleCodeSubmit.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleEmailSubmit(event) {
    event.preventDefault();
    this.setState({ requesting: true });
    const request = axios({
      method: 'post',
      url: URL.VERIFY_EMAIL,
      data: { email: this.state.email }
    });
    request.then(result => {
      if (result.data.status === 'success') {
        this.setState({ emailVerified: true, requesting: false });
      } else {
        this.setState({ emailError: true, requesting: false });
      }
    });
  }

  handleCodeSubmit(event) {
    event.preventDefault();
    const request = axios({
      method: 'post',
      url: URL.VERIFY_CODE,
      data: { token: this.state.code }
    });
    request.then(result => {
      if (result.data.status === 'success') {
        this.setState({ tokenVerified: true, emailVerified: false });
      } else {
        this.setState({ codeError: true });
      }
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ requesting: true });
    const request = axios({
      method: 'post',
      url: URL.RESET_PASSWORD,
      data: {
        token: this.state.code,
        password: this.state.password
      }
    });
    request.then(result => {
      if (result.data.status === 'success') {
        this.setState({ requesting: false });
        this.props.history.push('/');
      }
    });
  }

  render() {
    let component = (
      <EmailVerificationForm
        onFormSubmit={this.handleEmailSubmit}
        onInputChange={this.handleInputChange}
        emailError={this.state.emailError}
      />
    );
    if (this.state.emailVerified) {
      component = (
        <TokenVerificationForm
          onInputChange={this.handleInputChange}
          onFormSubmit={this.handleCodeSubmit}
          codeError={this.state.codeError}
        />
      );
    } else if (this.state.tokenVerified) {
      component = (
        <ResetPassword
          onInputChange={this.handleInputChange}
          onFormSubmit={this.handleSubmit}
        />
      );
    }

    return (
      <div>
        {this.state.requesting === true && <div className="page-loading" />}
        <Header
          login={this.props.login}
          onLogout={this.props.onLogout}
          onLogin={this.props.onLogin}
          origin="app"
        />
        <div className="row justify-content-center">
          <div className="col-md-6">
            <div
              className="trx-com-wrap trx-signup-form register-page recom-mod header-mod"
            >
              <div className="row">
                <div className="col-md-12">{component}</div>
                <div className="col-md-12">
                {this.state.emailError && (
              <div className="alert alert-danger autohide" role="alert">
                <strong>Error: </strong>
                {
                  FRONTEND_RESPONSE_MESSAGE.LOST_PASSWORD
                    .EMAIL_SUBMIT_ERROR_RESPONSE
                }
              </div>
            )}
                </div>
                </div>
                </div>
                </div>
                </div>
        <Footer />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onLogin: (postData, info) => dispatch(login(postData, info)),
    onLogout: () => dispatch(logout())
  };
};

const mapStateToProps = store => {
  return {
    login: store.login
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LostPassword);
