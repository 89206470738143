import { RoleCode } from '../../store/Users/constants';

const menu = [
  {
    text: 'Dashboard',
    url: '/',
    id: 'dashboard',
    key: 'DASHBOARD',
    icon: 'columns',
    activeURL: ['/', '/dashboard/badges']
  },
  {
    text: 'Public Profile',
    url: '/about',
    id: 'Public Profile',
    key: 'PROFILE_VIEW',
    icon: 'user-o',
    activeURL: ['/about', '/about/edit'],
    submenu: [
      {
        text: 'Edit',
        url: '/about/edit',
        id: 'Public Profile',
        key: 'PROFILE_EDIT'
      },
      {
        text: 'View',
        url: '/about',
        id: 'Public Profile',
        key: 'PROFILE_VIEW'
      }
    ]
  },
  {
    text: 'Search',
    url: '/search?mode=hosts',
    id: RoleCode.HOST,
    icon: 'search',
    key: 'BROWSE_HOST',
    activeURL: ['/search?mode=hosts']
  },
  {
    text: 'Search',
    url: '/search?mode=guests',
    id: RoleCode.GUEST,
    key: 'BROWSE_GUEST',
    icon: 'search',
    activeURL: ['/search?mode=guests']
  },
  {
    text: 'Messages',
    url: '/message',
    key: 'MESSAGES',
    icon: 'envelope-o',
    activeURL: ['/message']
  },
  {
    text: 'Account Settings',
    url: '/account',
    id: 'Account Setting',
    key: 'ACCOUNT_SETTING',
    icon: 'cog',
    activeURL: ['/account']
  }
];

export default menu;
