import { MY_BADGES } from '../actions/Badges';

const INITIAL_STATE = {
  myBadges: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MY_BADGES:
      return { ...state, myBadges: action.payload };
    default:
      return state;
  }
};
