import React, { FC } from 'react';
import FRONTEND_RESPONSE_MESSAGE from 'js/constants/FRONTEND_RESPONSE_MESSAGE';
import { Button } from '../../../components/common';
import { useFormik } from 'formik';
import { Link } from 'react-router-dom';

interface Props {
	onLogin: (arg0: UserCredentials) => void,
	login: {error?: string, isProcessing: boolean},
}

interface UserCredentials {
	email?: string,
	password?: string,
}

function validate(values: UserCredentials) {
	const errors: UserCredentials = {}
	if (!values.email) {
		errors.email = "Required";
	} else {
		const emailRegex = /^[a-zA-Z\d.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z\d-]+(?:\.[a-zA-Z\d-]+)*$/;
		if (!values.email?.match(emailRegex)) {
			errors.email = `${values.email} is not a valid email`
		}
	}

	if (!values.password) {
		errors.password = "Required";
	}
	return errors;
}

const Login: FC<Props> = ({onLogin, login}) => {
	const {
		handleSubmit, handleChange, handleBlur, touched, errors
	} = useFormik({
		initialValues: {
			email: '',
			password: ''
		},
		validate,
		onSubmit: (values) => {
			onLogin(values);
		},
	});


	return <div className="trx-login-form">
		<form onSubmit={handleSubmit}>
			<h3 className="login-form__heading">Login</h3>
			<hr className="horizontal-org" />
			{/* This is where to display errors! */}
			{login.error && <div className="has-error"><span className="help-block">{getErrorDetail(login.error)}</span></div>}
			<div className="col-md-12">
				<div className="form-group">
					<label htmlFor="email" className="control-label text-field__label">Email</label>
					<input type="text" name="email" data-testid="email" autoComplete="username"
						   className={`form-control ${touched.email && errors.email && ' is-invalid-input'}`}
						   onChange={handleChange} onBlur={handleBlur} disabled={login.isProcessing} />
					{touched.email && errors.email ?
						<div className="has-error"><span className="help-block">{errors.email}</span></div>
						: null}
				</div>
				<div className="form-group">
					<label htmlFor="email"  className="control-label text-field__label">Password</label>
					<input type="password" data-testid="password" name="password" autoComplete="password"
						   className={`form-control ${touched.password && errors.password && ' is-invalid-input'}`}
						   onChange={handleChange} onBlur={handleBlur} disabled={login.isProcessing}  />
					{touched.password && errors.password ?
						<div className="has-error"><span className="help-block">{errors.password}</span></div>
						: null}
				</div>
			</div>
			<div className="col-md-12" style={{textAlign: 'right'}}>
				<Link className="small text-org" to="/reset-password">Forgot password?</Link>
			</div>
			&nbsp;
			<div className="col-md-12" style={{textAlign: 'center'}}>
				<Button type="submit" color="orange" size={"medium"} disabled={login.isProcessing} data-testid="login-btn">Login</Button>
			</div>
			<hr className="my-3 hr-text letter-spacing-2" data-content="Don't have an account?" />
			<div className="col-md-12" style={{textAlign: 'center'}}>
				<Button anchor={true} color="orange" size="medium" href="register" disabled={login.isProcessing}>Get started</Button>
			</div>
		</form>
	</div>;
}

const getErrorDetail = (err: string) => {
	switch(err) {
		case "Email not verified":
			return <>
				<p>{FRONTEND_RESPONSE_MESSAGE.LOGIN.ACCOUNT_NOT_VERIFIED}</p>
				<a className="reset-link text-org" href="/app/verify-phone-number">
					If you did not receive the verification SMS, click
					here to resend.
				</a>
			</>;
		case "login is disabled":
			return <>
				{FRONTEND_RESPONSE_MESSAGE.LOGIN.IS_DISABLED}
				<a href={`mailto:${FRONTEND_RESPONSE_MESSAGE.THE_ROOM_XCHANGE_MAIL}`}
				   target="_blank"
				   rel="noopener noreferrer"
				   className="disable-link"
				>{FRONTEND_RESPONSE_MESSAGE.THE_ROOM_XCHANGE_MAIL}</a>
			</>;
		case "User not found":
			return <>
				{FRONTEND_RESPONSE_MESSAGE.LOGIN.IS_INVALID_EMAIL_OR_PASSWORD}
			</>;
	}
	return <>{err}</>;
}

export default Login;
