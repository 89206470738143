import axios from 'axios';
import URL from '../constants/URL';
import { createAction } from 'redux-actions';
import moment from 'moment';

const CREATING_EXCHANGE = 'CREATING_EXCHANGE';
const CREATING_EXCHANGE_FAILED = 'CREATING_EXCHANGE_FAILED';
const CREATING_EXCHANGE_SUCCESS = 'CREATING_EXCHANGE_SUCCESS';
const FETCH_XCHANGE_LIST = 'FETCH_XCHANGE_LIST';

export const creatingExchange = createAction(CREATING_EXCHANGE);
export const creatingExchangeSuccess = createAction(CREATING_EXCHANGE_SUCCESS);
export const creatingExchangeFailed = createAction(CREATING_EXCHANGE_FAILED);
export const fetchXchangeListSuccess = createAction(FETCH_XCHANGE_LIST);

export const fetchXchangeList = token => dispatch => {
  const request = axios({
    method: 'get',
    url: URL.FETCH_XCHANGE_API(token)
  });

  request.then(result => {
    if (result.data.status === 'success') {
      dispatch(fetchXchangeListSuccess(result.data.data));
    }
  });
};

export const createExchange = exchangeData => async dispatch => {
  dispatch(creatingExchange());

  const {
    startDate,
    endDate,
    xchangeType,
    userId,
    token,
    connectionId
  } = exchangeData;

  try {
    const result = await axios.post(URL.CREATE_XCHANGE_API(connectionId), {
      token: token,
      exchange_start_date: startDate
        ? moment(startDate).format('YYYY-MM-DD')
        : '',
      exchange_end_date: endDate ? moment(endDate).format('YYYY-MM-DD') : '',
      user_id: userId,
      exchange_type: xchangeType
    });

    if (result.data.status === 'success') {
      dispatch(creatingExchangeSuccess(result.data));
    } else {
      dispatch(creatingExchangeFailed(result.data));
    }
  } catch (error) {
    // Handle the error
    dispatch(creatingExchangeFailed());
  }
};
