import {
	AboutGuestType,
	GuestLifeStyleType,
	GuestProfileType,
	HostLifeStyleType,
	PracticalInclusionType,
	RemoteParamsHost,
	RemoteUserType,
	HostAboutType,
	HostProfileType,
	RemoteResponse,
	RemoteParamsGuest,
	LocationOption
} from "../types/editProfileTypes";
import axios from "axios";
import URL from "../../constants/URL";
import {FormikValues} from "formik";
import {RoleCode} from "../../../store/Users/constants";
import {occupations} from "./formData";
import {getValFromObj} from "./common";

export const editProfileService={

	COMMON: {
		getDataFromServer: async (user_id: number, token: string)=>{
			const response = await axios.get(URL.GET_PREFERENCES(user_id, token));
			return await response.data;
		},
		updatePriorities: async (priorities: string[], user: RemoteUserType)=>{

			let data={
				exchange_priorities: JSON.stringify(priorities),
				token: user.token,
				type: 'priorities'
			};
			return axios({method: 'put', url: URL.HOST_PREFERENCE(user.user_id), data})
		},
		updatePersonalityAttributes: (personality_attributes: string[], user: RemoteUserType)=>{

			if(user.role_code===RoleCode.HOST)
			{
				let data={
					household_personalities: JSON.stringify(personality_attributes),
					token: user.token,
					type: 'household_personality'
				}
				return axios({method: 'put', url: URL.HOST_PREFERENCE(user.user_id), data})
			}
			else
			{
				let data={
					personality: JSON.stringify(personality_attributes),
					token: user.token,
					type: 'personality'
				}
				return axios({method: 'put', url: URL.HOST_PREFERENCE(user.user_id), data})
			}

		},
		uploadProfileAvatar: (image: string, user: RemoteUserType)=>{

			let imageFIle=editProfileService.COMMON.dataURITOBlob(image);
			const form = new FormData();
			form.append('token', user.token);
			form.append('type', 'profile_main');
			form.append('image', imageFIle);


			return axios({
				method: 'post',
				url: URL.UPLOAD_IMAGE,
				data: form,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
		},
		postUploadAvatar: (user: RemoteUserType)=>{
			return axios({
				method: 'put',
				url: URL.HOST_PREFERENCE(user.user_id),
				data: {
					image_upload: 'success',
					token: user.token,
					type: 'photos'
				}
			});
		},
		getGalleryImages: async (user: RemoteUserType)=>{

			const remoteData=await editProfileService.COMMON.getDataFromServer(user.user_id,user.token)
			const res=await remoteData.data;
			return res.profile_other;
		},
		getAvatarImage: async (user: RemoteUserType)=>{

			const remoteData=await editProfileService.COMMON.getDataFromServer(user.user_id,user.token)
			const res=await remoteData.data;
			return res.profile_main;
		},
		deleteGalleryImage: (image_id: number, user: RemoteUserType)=>{
			return  axios({
				method: 'delete',
				url: `${URL.DELETE_IMAGE}?token=${user.token}`,
				data: {
					image_id: image_id,
					token:user.token
				}
			});
		},
		dataURITOBlob: (dataURI: string)=>{
			let byteString;
			if (dataURI.split(',')[0].indexOf('base64') >= 0)
				byteString = atob(dataURI.split(',')[1]);
			else
				byteString = unescape(dataURI.split(',')[1]);

			let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

			let ia = new Uint8Array(byteString.length);
			for (let i = 0; i < byteString.length; i++) {
				ia[i] = byteString.charCodeAt(i);
			}
			return new Blob([ia], {type:mimeString});
		},
		uploadGalleryPhoto: (image: string, user: RemoteUserType)=>{

			let imageFIle=editProfileService.COMMON.dataURITOBlob(image);
			const form = new FormData();
			form.append('token', user.token);
			form.append('type', 'profile_other');
			form.append('image', imageFIle);


			return axios({
				method: 'post',
				url: URL.UPLOAD_IMAGE,
				data: form,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
		},
	},

	HOST: {
		prepareSectionsFromData: (data: RemoteResponse): HostProfileType=>{

			return {
				about: editProfileService.HOST.getAboutSection(data),
				lifestyle: editProfileService.HOST.getLifeStyleSection(data),
				household_personalities: getValFromObj(data,'household_personalities',[],'stringArray'),
				practicalInclusion: editProfileService.HOST.getPracticalInclusions(data),
				priorities: getValFromObj(data,'exchange_priorities',[],'stringArray'),
				profileImage: getValFromObj(data,'profile_main', {},'object'),
				photos: getValFromObj(data,'profile_other',[],'array')
			} as HostProfileType;
		},
		getAboutSection: (data: RemoteResponse): HostAboutType=>{


			return {
				self_description: getValFromObj(data,'self_description'),
				additional_info: getValFromObj(data,'additional_info'),
				offering_to_guest: getValFromObj(data,'offering_to_guest'),
				first_language: getValFromObj(data,'first_language'),
				yesOtherLanguage: getValFromObj(data,'yesOtherLanguage'),
				other_language: getValFromObj(data,'other_language'),
				gender_preference: getValFromObj(data,'gender_preference'),
				lgbtqi_friendly: getValFromObj(data,'lgbtqi_friendly'),
				home_type: getValFromObj(data,'home_type'),
				distance_from_city: getValFromObj(data,'distance_from_city'),
				no_of_people_in_house: getValFromObj(data,'no_of_people_in_house'),
				age_group: getValFromObj(data,'age_group'),
				exchange_date: getValFromObj(data,'exchange_date'),
				exchange_period: getValFromObj(data,'exchange_period'),
				rent_offset: getValFromObj(data,'rent_offset'),
				hours_of_help: getValFromObj(data,'hours_of_help'),
				room_value: getValFromObj(data,'room_value'),
				room_value_all_inclusive: getValFromObj(data,'room_value_all_inclusive'),
				help_tasks: getValFromObj(data,'help_tasks',[],'stringArray')
			}
		},
		getLifeStyleSection: (data: RemoteResponse): HostLifeStyleType=>{


			return {
				activities: getValFromObj(data,'activities',[],'stringArray'),
				comfortable_with_pet: (getValFromObj(data,'comfortable_with_pet','','boolean'))?"1":"0",
				food_preference: getValFromObj(data,'food_preference'),
				help_tasks: getValFromObj(data,'help_tasks',[],'stringArray'),
				other_pets: getValFromObj(data,'other_pets'),
				other_food_preference: getValFromObj(data,'other_food_preference'),
				other_help_tasks: getValFromObj(data,'other_help_tasks'),
				pets: getValFromObj(data,'pets'),
				rent_offset: getValFromObj(data,'rent_offset',false,'boolean'),
				smoking_preference: (getValFromObj(data,'smoking_preference','','boolean'))?"1":"0"
			}
		},
		getPracticalInclusions: (data: RemoteResponse):PracticalInclusionType=>{

			return {
				bathroom: getValFromObj(data,'bathroom'),
				guest_room_features:getValFromObj(data,'guest_room_features',[],'stringArray'),
				home_feature: getValFromObj(data,'home_feature',[],'stringArray'),
				other_home_feature: getValFromObj(data,'other_home_feature'),
				nearby_public_transport: getValFromObj(data,'nearby_public_transport'),
				parking_available: getValFromObj(data,'parking_available')
			}
		},
		updateAboutSection: async (params: RemoteParamsHost, user: RemoteUserType)=>{
			params.lgbtqi_friendly=params.lgbtqi_friendly==='Yes';
			params.rent_offset=params.rent_offset==='Rent offset';
			if (!params.rent_offset) {
				delete params.hours_of_help
			}
			params.room_value_all_inclusive=params.room_value_all_inclusive==='Yes';
			params.type='basics';
			params.token=user.token;
			params.help_tasks=JSON.stringify(params.help_tasks);
			if(params.yesOtherLanguage==='No')
				params.other_language="No";

			delete params.yesOtherLanguage;

			return axios({
				method: 'put',
				url: URL.HOST_PREFERENCE(user.user_id),
				data: params
			})
		},
		updateLifestyleSection:  async (params: RemoteParamsHost, user: RemoteUserType)=>{

			params.activities=JSON.stringify(params.activities);
			params.help_tasks=JSON.stringify(params.help_tasks);
			params.other_help_tasks=params.other_help_tasks===""?null: params.other_help_tasks;
			params.type='preferences';
			params.token=user.token;
			return axios({
				method: 'put',
				url: URL.HOST_PREFERENCE(user.user_id),
				data: params
			})

		},
		updatePracticalInclusionSection: (params: RemoteParamsHost, user: RemoteUserType)=>{
			params.guest_room_features=JSON.stringify(params.guest_room_features);
			params.home_feature=JSON.stringify(params.home_feature);
			params.other_home_feature=params.other_home_feature===""?null: params.other_home_feature;
			params.type='guest_room_features';
			params.token=user.token;
			return axios({
				method: 'put',
				url: URL.HOST_PREFERENCE(user.user_id),
				data: params
			})
		},
	},




	ABOUT: {

		mapValues: (values: FormikValues)=>{
			values.yesOtherLanguage=values.other_language==="No"?"No":"Yes";
			if(values.yesOtherLanguage==="No")
				values.other_language="";
			values.lgbtqi_friendly=values.lgbtqi_friendly?'Yes':'No';
			values.rent_offset=values.rent_offset?'Rent offset':'Rent only';
			values.room_value_all_inclusive=values.room_value_all_inclusive?'Yes':'No';
			return values;
		}
	},

	GUEST: {
		mapValues: (values: FormikValues)=>{
			values.yesOtherLanguage=values.other_language===""?"No":"Yes";
			if(values.yesOtherLanguage==="No")
				values.other_language="";
			values.rent_offset=values.rent_offset?'Rent offset':'Rent only';

			if(!occupations.includes(values.current_work))
			{
				values.current_work_other=values.current_work;
				values.current_work='Other';
			}

			return values;
		},
		getDataFromServer:async (user_id: number, token: string)=>{
			const response = await axios.get(URL.GET_PREFERENCES(user_id, token));
			return await response.data;
		},
		getAboutSection: (data: AboutGuestType)=>{
			return {
				describes_you: getValFromObj(data,'describes_you'),
				additional_info: getValFromObj(data,'additional_info'),
				offering: getValFromObj(data,'offering'),
				first_language: getValFromObj(data,'first_language'),
				other_language: getValFromObj(data,'other_language'),
				current_work: getValFromObj(data,'current_work'),
				current_work_other: getValFromObj(data,'current_work_other'),
				exchange_date: getValFromObj(data,'exchange_date'),
				exchange_period: getValFromObj(data,'exchange_period'),
				rent_offset: getValFromObj(data,'rent_offset'),
				rental_budget: getValFromObj(data,'rental_budget')
			}
		},
		getLifeStyleSection: (data: GuestLifeStyleType)=>{
			return {
				food_preference: getValFromObj(data,'food_preference'),
				food_preference_other: getValFromObj(data,'food_preference_other'),
				smoking_preference: (getValFromObj(data,'smoking_preference','','boolean'))?"1":"0",
				lgbtqi_friendly: data.lgbtqi_friendly===1?"1":"0",
				pet: (getValFromObj(data,'pet',false,'boolean'))?"1":"0",
				bathroom_share: (getValFromObj(data,'bathroom_share',false,'boolean'))?"1":"0",
				car: (getValFromObj(data,'car',false,'boolean'))?"1":"0",
				carpark_required: (getValFromObj(data,'carpark_required',false,'boolean'))?"1":"0",
				public_transport_required: (getValFromObj(data,'public_transport_required',false,'boolean'))?"1":"0",
				furniture_have: getValFromObj(data,'furniture_have',[],'stringArray'),
				additional_needs: getValFromObj(data,'additional_needs',[],'stringArray'),
				experiences_to_enjoy: getValFromObj(data,'experiences_to_enjoy',[],'stringArray'),
				experiences_to_enjoy_other: getValFromObj(data,'experiences_to_enjoy_other'),

			}
		},
		getInterestsSection: (data: RemoteResponse)=>{

			return {
				talents: getValFromObj(data,'talents',[],'stringArray'),
				skills_other: getValFromObj(data,'skills_other'),
				rent_offset: getValFromObj(data,'rent_offset'),
				tasks_to_do: getValFromObj(data,'tasks_to_do',[],'stringArray'),
				tasks_to_do_other: getValFromObj(data,'tasks_to_do_other'),
			}
		},
		prepareSectionsFromData: (data: RemoteResponse): GuestProfileType=>{

			return {

				about: editProfileService.GUEST.getAboutSection(data),
				lifestyle: editProfileService.GUEST.getLifeStyleSection(data),
				priorities: getValFromObj(data,'exchange_priorities',[],'stringArray'),
				personality: getValFromObj(data,'personality',[],'stringArray'),
				interests: editProfileService.GUEST.getInterestsSection(data)

			}
		},
		updateAboutSection: async (params: RemoteParamsGuest, user: RemoteUserType)=>{
			if(params.current_work_other==='Other')
				params.current_work='Other';


			if(params.yesOtherLanguage==='No')
				params.other_language="";

			delete params.yesOtherLanguage;
			params.rent_offset=params.rent_offset==='Rent offset';
			params.type='basics';
			params.token=user.token;

			return axios({
				method: 'put',
				url: URL.HOST_PREFERENCE(user.user_id),
				data: params
			})
		},
		updateLifestyleSection:  async (params: RemoteParamsGuest, user: RemoteUserType)=>{

			params.bathroom_share=params.bathroom_share==="1";
			params.car=params.car==="1";
			params.carpark_required=params.carpark_required==="1";
			params.lgbtqi_friendly=params.lgbtqi_friendly==="1";
			params.pet=params.pet==="1";
			params.public_transport_required=params.public_transport_required==="1";
			params.smoking_preference=params.smoking_preference==="1";
			params.furniture_have=JSON.stringify(params.furniture_have);
			params.experiences_to_enjoy=JSON.stringify(params.experiences_to_enjoy);
			params.additional_needs=JSON.stringify(params.additional_needs);
			params.type='preferences';
			params.token=user.token;
			return axios({
				method: 'put',
				url: URL.HOST_PREFERENCE(user.user_id),
				data: params
			})

		},
		updateInterests: async (params: RemoteParamsGuest, user: RemoteUserType)=>{
			params.talents=JSON.stringify(params.talents);
			params.tasks_to_do=JSON.stringify(params.tasks_to_do);
			params.type='skills';
			params.token=user.token;
			return axios({
				method: 'put',
				url: URL.HOST_PREFERENCE(user.user_id),
				data: params
			})
		},
		mapOptionsToValues: (options: LocationOption[]) => {
			return options.map(option => ({
				value: option.locality,
				label: option.locality
			}));
		},
		getOptions: (inputValue: string, callback: Function) => {
			if (!inputValue) {
				return callback([]);
			}
			let fetchURL=`${URL.GET_CITY_SUGGESTIONS}?query=${inputValue}`

			fetch(fetchURL).then(response => {
				response.json().then(data => {
					const cities = data.cities;
					let options=editProfileService.GUEST.mapOptionsToValues(cities);
					callback(options);
				});
			});
		}
	},
	getExtensionName: (image: string)=>{
		let type=image.substring("data:image/".length, image.indexOf(";base64"))
		return type?'image/'+type:'image/png';
	}
};



