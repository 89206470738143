import axios from 'axios';
import get from 'lodash/get';
import URL from '../constants/URL';
import { RoleCode } from '../../store/Users/constants';

export const EDIT_PROFILE_REQUEST = 'EDIT_PROFILE_REQUEST';
export const EDIT_PROFILE_SUCCESS = 'EDIT_PROFILE_SUCCESS';
export const EDIT_PROFILE_FAILURE = 'EDIT_PROFILE_FAILURE';

export const SET_USER_PROFILE = 'SET_USER_PROFILE';
export const VIEW_PROFILE_REQUEST = 'EDIT_PROFILE_REQUEST';
export const VIEW_PROFILE_SUCCESS = 'EDIT_PROFILE_SUCCESS';
export const VIEW_PROFILE_FAILURE = 'EDIT_PROFILE_FAILURE';

export const viewProfileRequest = () => {
  return {
    type: VIEW_PROFILE_REQUEST
  };
};

export const viewProfileSuccess = response => {
  return {
    type: VIEW_PROFILE_SUCCESS,
    payload: response
  };
};

export const viewProfileFailure = error => {
  return {
    type: VIEW_PROFILE_FAILURE,
    payload: error
  };
};

export const setUserProfile = (profileData, permissions) => {
  return {
    type: SET_USER_PROFILE,
    payload: {
      data: profileData,
      permissions
    }
  };
};

const getUserProfileV0 = (user_id, token) => {
  const request = axios({
    method: 'get',
    url: URL.USER_PROFILE(user_id, token),
    data: token
  });

  return request.then(result => {
    if (result.data.status === 'success') {
      return result.data.data;
    }
    return Promise.reject(false);
  });
}

const getUserProfile = () => {
  return axios.get(`${process.env.REACT_APP_API_URL}/v1/me`)
  .then(response => {
    const { data } = response.data;
    /** added COMPLETED permission temporarity */
    const permissions = ['ACTIVE'];
	if (data.profile_completed) {
		permissions.push('COMPLETED');
	}
	if (data.id_verified) {
		permissions.push('VERIFIED');
	}
    get(data, 'user_type') === RoleCode.GUEST && permissions.push(RoleCode.GUEST);
    return { data, permissions };
  });
}

export const viewProfile = (user_id, token) => dispatch => {
  return getUserProfile().then((resp) => {
    const { data, permissions } = resp;
    dispatch(setUserProfile(data, permissions));
    return getUserProfileV0(data.id, token);
  }).then((resp) => {
    dispatch(viewProfileSuccess(resp));
    return true;
  }).catch((e) => {
    return Promise.reject(false);
  })
};

export const editProfileRequest = () => {
  return {
    type: EDIT_PROFILE_REQUEST
  };
};

export const editProfileSuccess = response => {
  return {
    type: EDIT_PROFILE_SUCCESS,
    payload: response
  };
};

export const editProfileFailure = error => ({
  type: EDIT_PROFILE_FAILURE,
  payload: error
});

export const editProfile = (user_id, token, profile) => {
  return dispatch => {
    dispatch(editProfileRequest());
    const request = axios({
      method: 'put',
      url: URL.EDIT_PROFILE(user_id),
      data: {
        first_name: profile.firstName,
        last_name: profile.lastName,
        email: profile.email,
        dob: profile.dob,
        phone_no: profile.phone,
        post_code: profile.postCode,
        token: token
      }
    });
    request.then(result => {
      if (result.data.status === 'success') {
        dispatch(editProfileSuccess(result.data.msg));
        dispatch(viewProfile(user_id, token));
      } else {
        dispatch(editProfileFailure(result.data.msg));
      }
    });
  };
};
