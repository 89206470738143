import React, { FC, memo, useEffect, useState } from 'react';
import axios from 'axios';
import includes from 'lodash/includes';
import TextareaAutosize from 'react-textarea-autosize';
import URL from '../../../js/constants/URL';
import { Button } from '../../../components/common';
import {ConnectionProps, ConnectionStatus} from '../types';
import {connect} from "react-redux";

const sendMessage = (connectionId: number, text: string, token: number) => {
	return axios({
		method: 'post',
		url: URL.CONNECTION_MESSAGE_CREATE_API(connectionId),
		data: { text, token }
	}).then((resp: any) => {
		return resp;
	});
};

const setPlaceholder = (connectionStatus: string, isIncoming: boolean): {isAllowed: boolean, placeholder: string} => {
       if (includes([ConnectionStatus.ACCEPT, 'EXCHANGE_ACCEPT', 'PAID'], connectionStatus)) {
		   return {
			   isAllowed: true,
			   placeholder: 'Type your message...'
		   }
       }
       if (connectionStatus === ConnectionStatus.DECLINE) {
		   if (isIncoming) {
			   return {
				   isAllowed: false,
				   placeholder: 'You have declined this connection request.'
			   }
		   }
		   return {
			   isAllowed: false,
			   placeholder: 'This connection request has been declined.'
		   }
       }
       if (includes([ConnectionStatus.PENDING, 'EXCHANGE_PENDING'], connectionStatus)) {
		   if (isIncoming) {
			   return {
				   isAllowed: false,
				   placeholder: 'Please click "accept" above first to send a message'
			   }
		   }
		   return {
			   isAllowed: false,
			   placeholder: 'Please wait for connection request to be accepted.'
		   }
       }
	   return {
		   isAllowed: false,
		   placeholder: 'An internal error has occurred. Please contact support.'
	   }
}


export const UserInput: FC<any> = ({
	connection,
	user,
	activeConnectionId,
	token,
	fetchMessages
}) => {
	const [message, handleMessage] = useState('');
	const [enableSubmit, handleEnableSubmit] = useState(true);

	const msgDetails = connection.connections.reduce((r: ConnectionProps, c: ConnectionProps) => c.connection_id === activeConnectionId ? c : r, connection.connections[0]);
	const isIncoming = connection.connections.reduce((r: boolean, c: ConnectionProps) => c.connection_id === activeConnectionId ? (c.to_user_id === user.id) || r : r, false);

	const handleSendMessage = () => {
		handleEnableSubmit(false);
		sendMessage(activeConnectionId, message, token).then(() => {
			handleMessage('');
			handleEnableSubmit(true);
			fetchMessages();
		});
	};

	useEffect(() => {
		handleMessage('');
	}, [activeConnectionId]);

	const {isAllowed, placeholder} = setPlaceholder(msgDetails.status, isIncoming)
	return (
		<div className="message-input">
			<TextareaAutosize
				cacheMeasurements
				disabled={!isAllowed}
				placeholder={placeholder}
				minRows={3}
				value={message}
				onChange={(ev) => handleMessage(ev.target.value)}
			/>
			<Button
				className="send-btn"
				disabled={message.length === 0 || !enableSubmit}
				onClick={handleSendMessage}
			>
				Send
			</Button>
		</div>
	);
};

const mapStateToProps = (store: any) => {
	return {
		connection: store.connection,
		user: store.profile.data,
	};
};

export default memo(connect(mapStateToProps)(UserInput));
