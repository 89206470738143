import React, {FC, useState} from 'react';
import UploaderContent from "./UploaderContent";
import ImageCropper from "./ImageCropper";
import {RemoteUserType} from "../../../types/editProfileTypes";

type AvatarUploadProps={
	user: RemoteUserType,
	setIsOpen: Function,
	setAvatar: Function
}
const AvatarUpload: FC<AvatarUploadProps>=({user, setIsOpen, setAvatar})=>{

	const [image, setImage]=useState('');

	return (
		<>
			{image==='' && <UploaderContent setImage={setImage} setIsOpen={setIsOpen}/>}
			{image!=='' && <ImageCropper user={user} image={image} setIsOpen={setIsOpen} setAvatar={setAvatar}/>}
		</>
	);
};


export default AvatarUpload;
