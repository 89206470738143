import React, { FC, memo } from 'react';
import { connect } from 'react-redux';
import map from 'lodash/map';
import { Link } from 'react-router-dom';
import truncate from 'lodash/truncate';
import { getRecipientInfoFromDetails } from '../api';
import { UserInfoProps } from '../types';
import { RoleCode } from '../../../store/Users/constants';

const initUser = { userId: 0, photo: '', name: '', text: '', badges: [] };

const UserInfo: FC<UserInfoProps> = ({ userId, userRole, details, hideList }) => {
	const userInfo = details
		? getRecipientInfoFromDetails(userId, details)
		: initUser;
		
	const { photo, name, text, badges} = userInfo;
	const url = userRole === RoleCode.GUEST ? `/browse/hosts/${userInfo.userId}` : `/browse/guests/${userInfo.userId}`;
	
	return (
		<div className="user-info">
			{photo && <img className="user-image" src={photo} alt="user" />}

			<div className="message-info">
				<p className="user-title">
					<span className="back-to-list" onClick={hideList}>
						<i className="fa fa-angle-left"></i>
					</span>
					<Link to={url}>{name}</Link>
					<span className="user-badges badges-wrap">
						{map(badges, ({ badge_code }, i) => (
							<span
								key={i}
								title={badge_code}
								className={badge_code.toLowerCase()}
							></span>
						))}
					</span>
				</p>
				<p className="excerpt">
					{truncate(text, {
						length: 50,
						separator: ' '
					})}
				</p>
			</div>
			<div className="clearfix"></div>
		</div>
	);
};

const mapStateToProps = (store: any) => {
	return {
		connection: store.connection
	};
};
export default memo(connect(mapStateToProps, null)(UserInfo));
