import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import URL from '../../constants/URL';
import {fetchExchangePreferences} from '../../actions/HostPreferences';
import { ProcessModal } from '../../actions/Modal';
import { HIDE_MODAL } from '../../actions/Modal'
import { Button } from '../../../components/common';

class DragAndDropModal extends Component {

  constructor(props){
    super(props);
    this.state={
      requesting:false,
    }
  }

  b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }

  saveImage =(type, e) => {

    e.preventDefault();
    this.setState({...this.state,requesting:true});
    if(this.props.dropImage!=null){
      var form = new FormData();
      var ImageURL=this.props.dropImage;
      // Split the base64 string in data and contentType
      var block = ImageURL.split(";");
      // Get the content type
      var contentType = block[0].split(":")[1];// In this case "image/gif"
      // get the real base64 content of the file
      var realData = block[1].split(",")[1];// In this case "iVBORw0KGg...."
      var blob =this.b64toBlob(realData, contentType);
      form.append("token", this.props.login.token);
      form.append("image", blob);

      form.append("type",type)
      const request =axios({
        method:"post",
        url:URL.UPLOAD_IMAGE,
        data:form,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      request.then((result) => {
        if(result.data.status=="success"){
          this.props.fetchExchangePreferences(this.props.login)
          this.close();
          this.setState({...this.state,othersrc:'',requesting:false})
        }

      });
    }
  }
  close = () => {
    const modalProps={showModal:false}
        this.props.dispatch(ProcessModal(HIDE_MODAL,null,modalProps));
    }



  render(){
    return(
      <div>
        <div className="overlay modal-backdrop modal-dialog-centered">
          <div className="col-md-6 offset-md-3">
            <div>
              </div>
              <div className="modal-content padding-com">
                {this.state.requesting && (
                  <div className="page-loading"></div>
                  )}
                  <div>
                    <div className="modal-header">
                      <button type="button" className="close" onClick={this.close} data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true"><i className="fa fa-close"></i></span>
                        </button>
                      </div>

                      <div className="modal-body">
                          <img src={this.props.dropImage}   style={{ maxHeight: '200px', maxWidth: '100%' }}/>
                      </div>
                          <div className="modal-footer">
                                <div>
                                  <Button type="submit" onClick={this.saveImage.bind(this,'profile_other')}>Save Photo</Button>
                                    <Button type="button" color="grey" onClick={this.close}>Cancel</Button>
                                    </div>

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
    )
  }
}
const mapDispatchToProps = (dispatch) => {
  return{
    fetchExchangePreferences:(user) => {dispatch(fetchExchangePreferences(user))},
    onClose:(actionType,modalType,modalProps)=>dispatch(ProcessModal(actionType,modalType,modalProps)),
    dispatch
  }
}
const mapStateToProps = (store) => {
  return {
    login: store.login.user,
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(DragAndDropModal)
