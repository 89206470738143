import React, { Component} from 'react';
import { connect } from 'react-redux';
import HostEdit from "./HostEdit";
import GuestEdit from "./GuestEdit";

class About extends Component {
  componentDidMount(){
    document.title = 'Edit Public Profile | The Room Xchange';
  }
  render(){
    return(
       <div>
         {this.props.role_code==="HOST" ? <HostEdit token={this.props.token} user_id={this.props.user_id} role_code={this.props.role_code} /> : <GuestEdit token={this.props.token} user_id={this.props.user_id} role_code={this.props.role_code}/> }
       </div>
    )
  }
}
const mapStateToProps = (store) => {
  return{
	  token: store.login.user.token,
	  user_id: store.login.user.user_id,
	  role_code: store.login.user.role_code
  }
}
export default connect(mapStateToProps)(About)
