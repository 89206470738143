import {
  fetchXchangeListSuccess,
  creatingExchange,
  creatingExchangeSuccess,
  creatingExchangeFailed
} from '../actions/Xchange';
import { handleActions } from 'redux-actions';

const defaultState = {
  xchangesData: [],
  emptyMessage: '',
  isCreatingExchange: false,
  createExchangeData: undefined,
  createExchangeError: undefined,
  isCreatedSuccess: false
};

export const exchangeReducer = handleActions(
  {
    [fetchXchangeListSuccess]: (state, { payload }) => ({
      ...state,
      xchangesData: payload,
      emptyMessage:
        payload.length == 0 ? 'You have not made any xchanges yet' : ''
    }),
    [creatingExchange]: state => ({ ...state, isCreatingExchange: true }),
    [creatingExchangeSuccess]: state => ({
      ...state,
      isCreatingExchange: false,
      isCreatedSuccess: true
    }),
    [creatingExchangeFailed]: (state, { payload }) => ({
      ...state,
      isCreatingExchange: false,
      createExchangeError:
        payload && payload.msg ? payload.msg : 'Error creating exchange'
    })
  },
  defaultState
);
