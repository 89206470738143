import React, {FC, useContext, useEffect, useState} from 'react';
import InfoBubble from "../../../components/common/InfoBubble";
import { useFormik } from 'formik';
import '../styles/profile_edit.scss';
import {foodPreferences, helpTasks, activities, smokingChoices, petTypes, petChoices} from "../utils/formData";
import HostLifeStyleSchema from "../schemas/HostLifeStyleSchema";
import {
	getAccordionDefaultConfig,
	hasError,
	manageAccordion,
	postActionSuccess,
	setAjaxLoader,
	showAjaxError, includeTestId
} from "../utils/common";
import {editProfileService} from "../utils/editProfileService";
import {UserProps} from "../types/editProfileTypes";
import FormError from "./helpers/FormError";
import RemoteUpdateError from "./helpers/RemoteUpdateError";
import FormSubmitButtons from "./helpers/FormSubmitButtons";
import EditProfileContext from "../context/EditProfileContext";

const LifeStyleHost: FC<UserProps>=({user})=>{

	const {accordionConfig, update, data}=useContext(EditProfileContext);
	const [submissionError, setSubmissionError] = useState('');
	let lifestyle=editProfileService.HOST.getLifeStyleSection({...data});
	const [buttonType, setButtonType]=useState('');


	const {values, errors, touched, handleBlur, handleChange, handleSubmit, isValid,validateForm} = useFormik({
		initialValues: lifestyle as any,
		validationSchema: HostLifeStyleSchema,
		// validateOnMount: true,
		onSubmit: (values) => {
			const params={...values};
			setAjaxLoader(true,update);
			editProfileService.HOST.updateLifestyleSection(params, user).then(result=>{
				const {data}=result;
				if(data.status==='success')
					postActionSuccess(buttonType, update, 'prioritiesCollapsed', user.user_id, user.token);
				else if(data.msg!==undefined)
					setSubmissionError(data.msg);
				else
					setSubmissionError('Some error occurred. Please try again later');

				setAjaxLoader(false,update);
			}).catch(res=>{
				showAjaxError(res,setSubmissionError,update);
			});
		}
	});
	useEffect(() => {
		(() => validateForm())();
	}, []);

	const errorMessages = () => {
		if (submissionError) {
			return submissionError
		} else if (!isValid) {
			return "There are some issues you need to resolve before saving"
		} else {
			return '';
		}
	}

	return (
		<div className="card">
			<div className="card-header" role="tab" id="lifeStyleHeading">
					<a className={accordionConfig.lifestyleCollapsed ? 'collapsed' : ''} href="#host-lifestyle" aria-expanded={!accordionConfig.lifestyleCollapsed} onClick={()=>{ manageAccordion({...getAccordionDefaultConfig(), lifestyleCollapsed: !accordionConfig.lifestyleCollapsed}, update)}}>
					<h2 className="mb-0">
						<div className="card-header__left"  {...includeTestId('lifestyle-section-heading')}>
							<i className={isValid ? 'fa fa-check icon-rounded' : 'fa fa-close icon-rounded'}/>
							Lifestyle
							<InfoBubble tooltip="This section is about how you like to live in your home and the experiences you’d like to enjoy with your housemates. If you are choosing a rent offset, it will also give you the choice to select what type of help you’d like to have to offset the rent." />
						</div>
					</h2>
				</a>
			</div>

			<div id="host-lifestyle" className={`collapse ${accordionConfig.lifestyleCollapsed?'':'show'} `} role="tabpanel" aria-labelledby="lifeStyleHeading" data-parent="#profileAccordion">
				<form className="form-horizontal form-signup" role="form" onSubmit={handleSubmit}>
					<div className="card-body">
						<div className="row form-mod" {...includeTestId('food-preferences-host')}>
							<div className="col-12">
								<label htmlFor="food_preference" className="control-label" id='food-preferences-headling-label'>
									Food preferences
								</label>
							</div>
								{foodPreferences.map((item, key) => (
									<div className="col-lg-4 col-md-4 col-sm-12 col-12"  key={`food-preference-item-${item.value}`}>
										<div className="form-check">
											<input className="form-check-input" name="food_preference" type="radio" value={item.value} id={`food-preference-${item.value}`} onChange={handleChange} onBlur={handleBlur} checked={values.food_preference===item.value}/>
											<label className="form-check-label" htmlFor={`food-preference-${item.value}`}>{item.label}</label>
										</div>

										{values.food_preference==='other' && item.value==='other' && <>
											<div className="form-group">
												<input
													className={`form-control ${hasError(errors, touched,'other_food_preference')?'error-field':''}`}
													name="other_food_preference"
													type="text"
													placeholder="Please enter other food preference"
													onChange={handleChange}
													onBlur={handleBlur}
													value={values.other_food_preference}
													{...includeTestId('other-food-preference')}
												/>
												<FormError errors={errors} fieldName={'other_food_preference'} touched={touched} containerClass={'mt-1'}/>
											</div>
										</>}
									</div>

								))}

						</div>
						{values.rent_offset&&<>

							<div className="row form-mod" {...includeTestId('help_tasks')}>
								<div className="col-12">
									<label htmlFor="food_preference" className="control-label">
										As a household what tasks do you need help with?
									</label>
								</div>
								<FormError errors={errors} fieldName={'help_tasks'} touched={null} containerClass={'mt-1 mb-1'}/>
								{helpTasks.map((item, key) => (
									<div className="col-lg-4 col-md-4 col-sm-12 col-12" key={`help-tasks-${item}`}>
										<div className="form-check" >
											<input className="form-check-input" name="help_tasks" type="checkbox" value={item} checked={values.help_tasks.includes(item)} onChange={handleChange}/>
											<label className="form-check-label" htmlFor={`food-preference-${item}`}>{item}</label>
										</div>
									</div>

								))}

								{values.help_tasks.includes('Other') &&
									<div className="form-check" key={`other-help-task-key`}>
										<input
											className={`form-control ${hasError(errors, touched, 'other_help_tasks') ? 'error-field' : ''}`}
											name="other_help_tasks"
											type="text"
											placeholder="Please enter task name"
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.other_help_tasks}
										/>
										<FormError errors={errors} fieldName={'other_help_tasks'} touched={touched} containerClass={'mt-1'}/>
									</div>
								}


							</div>
						</>}

						<div className="row form-mod" {...includeTestId('activities-we-do')}>
							<div className="col-12">
								<label htmlFor="food_preference" className="control-label">
									Activities we like to do
								</label>
								<FormError errors={errors} fieldName={'activities'} touched={null} containerClass={'mt-1 mb-1'}/>
							</div>
							{activities.map((item, key) => (
								<div className="col-lg-4 col-md-4 col-sm-12 col-12" key={`activities-${item}`}>
									<div className="form-check" >
										<input className="form-check-input" name="activities" type="checkbox" value={item} 	onChange={handleChange} onBlur={handleBlur} checked={values.activities.includes(item)}/>
										<label className="form-check-label" htmlFor={`activities-${item}`}>{item}</label>
									</div>
								</div>
							))}
						</div>



						<div className="row form-mod">


								<div className="col-lg-4 col-md-4 col-sm-12 col-12" {...includeTestId('smoking-preference')}>
									<label htmlFor="food_preference" className="control-label smoking-preference-label">
										Smoking preference
									</label>
									{smokingChoices.map((item, key) => (
										<div className="form-check" key={`smoking-preferences-${item.label}`}>
											<input name="smoking_preference" className="form-check-input" id={`smoking-preferences--${item.label}`} type="radio" checked={item.value===values.smoking_preference} onChange={handleChange} value={item.value}/>
											<label className="form-check-label" htmlFor={`smoking-preferences--${item.label}`}>
												{item.label}
											</label>
										</div>
									))}
									<FormError errors={errors} fieldName={'smoking_preference'} touched={touched} containerClass={'mt-1'}/>
								</div>

							<div className="col-lg-4 col-md-4 col-sm-12 col-12" {...includeTestId('pets-comfortable')} >
								<label htmlFor="comfortable_with_pet" className="control-label">
									Do you have pets?
								</label>
								{petChoices.map((item, key) => (
									<div className="form-check" key={`comfortable_with_pet-${item.value}`}>
										<input name="comfortable_with_pet" className="form-check-input" id={`comfortable_with_pet-${item}`} type="radio" checked={item.value===values.comfortable_with_pet} onChange={handleChange} value={item.value}/>
										<label className="form-check-label" htmlFor={`comfortable_with_pet-${item.value}`}>
											{item.label}
										</label>
									</div>
								))}
								<FormError errors={errors} fieldName={'comfortable_with_pet'} touched={touched} containerClass={'mt-1'}/>
							</div>

							{values.comfortable_with_pet==="1" &&

								<div className="col-lg-4 col-md-4 col-sm-12 col-12" {...includeTestId('pet-type')}>
									<label htmlFor="pets" className="control-label">
										What type of pets do you have?
									</label>
									<FormError errors={errors} fieldName={'pets'} touched={touched} containerClass={'mt-1 mb-1'}/>
									{petTypes.map((item, key) => (
										<div className="form-check" key={`pets-name-${item.value}`}>
											<input name="pets" className="form-check-input" id={`pets-name-${item.value}`} type="radio" checked={item.value === values.pets} onChange={handleChange} onBlur={handleBlur} value={item.value}/>
											<label className="form-check-label" htmlFor={`pets-name-${item.value}`}>
												{item.label}
											</label>
										</div>
									))}
									{values.pets === 'other' && <>
										<input className={`form-control ${hasError(errors, touched, 'other_pets') ? 'error-field' : ''}`} name="other_pets" type="text" placeholder="Please enter other pet name" onChange={handleChange} onBlur={handleBlur} value={values.other_pets}/>
										<FormError errors={errors} fieldName={'other_pets'} touched={touched} containerClass={'mt-1'}/>
									</>}
								</div>
							}

						</div>
						<RemoteUpdateError submissionError={errorMessages()}/>
					</div>
					<FormSubmitButtons setButtonType={setButtonType} isValid={isValid}/>
				</form>
			</div>
		</div>
	);
}

export default LifeStyleHost;
