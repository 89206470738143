import React, {FC, useContext, useEffect, useState} from 'react';
import InfoBubble from "../../../components/common/InfoBubble";
import { useFormik } from 'formik';
import '../styles/profile_edit.scss';
import {getPersonalityList} from "../utils/formData";
import PersonalitySchema from "../schemas/PersonalitySchema";
import {UserProps} from "../types/editProfileTypes";
import {editProfileService} from "../utils/editProfileService";
import {RoleCode} from "../../../store/Users/constants";
import FormError from "./helpers/FormError";
import {
	getAccordionDefaultConfig, getValFromObj,
	manageAccordion,
	postActionSuccess,
	setAjaxLoader,
	showAjaxError, includeTestId
} from "../utils/common";
import RemoteUpdateError from "./helpers/RemoteUpdateError";
import FormSubmitButtons from "./helpers/FormSubmitButtons";
import EditProfileContext from "../context/EditProfileContext";


const Personality: FC<UserProps>=({user})=>{
	const {accordionConfig, update, data}=useContext(EditProfileContext);
	const [buttonType, setButtonType]=useState('');
	const [submissionError, setSubmissionError] = useState('');

	let personality= user.role_code===RoleCode.HOST?getValFromObj(data,'household_personalities',[],'stringArray'):getValFromObj(data,'personality',[],'stringArray');

	const {values, errors, handleChange, handleSubmit, isValid, validateForm} = useFormik({
		initialValues: {
			personality_attributes: personality as any
		},
		validationSchema: PersonalitySchema,
		onSubmit: (values) => {
			const params={...values};
			setAjaxLoader(true,update);
			editProfileService.COMMON.updatePersonalityAttributes(params.personality_attributes,user).then(result=>{
				const {data}=result;
				setAjaxLoader(true,update);
				if(data.status==='success')
				{
					let sectionKey=user.role_code===RoleCode.HOST?'inclusionCollapsed':'interestsCollapsed';
					postActionSuccess(buttonType, update, sectionKey, user.user_id, user.token);
				}
				else if(data.msg!==undefined)
					setSubmissionError(data.msg);
				else
					setSubmissionError('Some error occurred. Please try again later');

				setAjaxLoader(false,update);
			}).catch(res=>{
				showAjaxError(res,setSubmissionError,update);
			});
		}
	});

	useEffect(() => {
		(() => validateForm())();
	}, []);

	const errorMessages = () => {
		if (submissionError) {
			return submissionError
		} else if (!isValid) {
			return "There are some issues you need to resolve before saving"
		} else {
			return '';
		}
	}

	return (
		<div className="card">
			<div className="card-header" role="tab" id="personalityHeading">
				<a className={accordionConfig.personalityCollapsed ? 'collapsed' : ''} href="#abt-personality" aria-expanded={!accordionConfig.personalityCollapsed} onClick={()=>{ manageAccordion({...getAccordionDefaultConfig(), personalityCollapsed: !accordionConfig.personalityCollapsed}, update)}}>
					<h2 className="mb-0">
						<div className="card-header__left" {...includeTestId('personality-heading')}>
							<i className={isValid ? 'fa fa-check icon-rounded' : 'fa fa-close icon-rounded'}/>
							{user.role_code===RoleCode.HOST?'Household Personality':'Personality'}
							<InfoBubble tooltip={`${user.role_code===RoleCode.HOST?'What describes the personality of your household':"What best describes your personality?"}`} />
						</div>
					</h2>
				</a>
			</div>

			<div id="abt-personality" className={`collapse ${accordionConfig.personalityCollapsed?'':'show'} `} role="tabpanel" aria-labelledby="personalityHeading" data-parent="#profileAccordion">
				<form className="form-horizontal form-signup" role="form" onSubmit={handleSubmit}>
					<div className="card-body">
						<div className="row form-mod" {...includeTestId('personality-attributes')}>
							<div className="col-12">
								<label htmlFor="personality-attributes" className="control-label">{`${user.role_code===RoleCode.HOST?'What describes the personality of your household?':"What describes the personality"}`}</label>
								<FormError errors={errors} fieldName={'personality_attributes'} touched={null} containerClass={'mt-1 mb-1'}/>
							</div>
							{getPersonalityList(user.role_code).map((item) => (
								<div className="col-lg-4 col-md-4 col-sm-12 col-12" key={`personality_attributes-${item}`}>
									<div className="form-check" >
										<input className="form-check-input" name="personality_attributes" type="checkbox" value={item} checked={values.personality_attributes.includes(item)}  onChange={handleChange}/>
										<label className="form-check-label" htmlFor={`personality_attributes-${item}`}>{item}</label>
									</div>
								</div>
							))}
						</div>
						<RemoteUpdateError submissionError={errorMessages()}/>
					</div>
					<FormSubmitButtons setButtonType={setButtonType} isValid={isValid}/>
				</form>
			</div>
		</div>
	);
}

export default Personality;
