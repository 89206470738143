import React from 'react';
import { Formik, Form } from 'formik';
import { object, string } from 'yup';
import { TextField } from '../forms';
import PropTypes from 'prop-types';

const verificationFormInitialValues = {
  verificationCode: ''
};

const verificationFormValidation = object({
  verificationCode: string().required('Please enter verification code')
});

export class VerificationForm extends React.Component {
  static propTypes = {
    isVerifying: PropTypes.bool,
    isSuccess: PropTypes.bool,
    onVerify: PropTypes.func,
    onSend: PropTypes.func,
    error: PropTypes.string
  };

  onSubmit = data => {
    const { onVerify } = this.props;

    onVerify(data);
  };

  renderForm = ({ errors, touched }) => {
    const { onSend, error } = this.props;

    return (
      <Form className="verification__form">
        <div className="card-wrapper bg-light">
          <div className="verification__wrapper">
            <TextField
              name="verificationCode"
              placeholder="Enter your verification code"
              error={
                errors.verificationCode && touched.verificationCode
                  ? errors.verificationCode
                  : null
              }
              displayLabelIfEmpty={false}
            />

            <div className="verify__controls">
              <button type="submit" className="button btn-teal">
                Verify
              </button>
              <button
                onClick={onSend}
                type="button"
                className="button btn-grey"
              >
                Resend SMS
              </button>
            </div>
          </div>
          {error && <div className="verification__errors">{error}</div>}
        </div>
      </Form>
    );
  };

  render() {
    return (
      <Formik
        initialValues={verificationFormInitialValues}
        validationSchema={verificationFormValidation}
        onSubmit={this.onSubmit}
        validateOnBlur={true}
        render={this.renderForm}
      />
    );
  }
}
