import React from 'react';
import { SignUpStep } from './SignUpStep';
import { object, string } from 'yup';
import { Form } from 'formik';
import { TextField } from '../forms';
import { Button } from 'components/common';

const Step1Fields = {
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  PHONE: 'phone',
  EMAIL: 'email'
};

export const AUPhoneRegEx = /^(\+?61|0)4\d{8}$/;

const step1Validation = object({
  [Step1Fields.FIRST_NAME]: string().required('Please enter your first name'),
  [Step1Fields.LAST_NAME]: string().required('Please enter your last name'),
  [Step1Fields.EMAIL]: string()
    .email('Please enter a valid email address')
    .required('Please enter your email address'),
  [Step1Fields.PHONE]: string()
    .matches(AUPhoneRegEx, {
      message: 'Please enter a valid phone number'
    })
    .required('Please enter your phone number')
});

export const step1InitialData = {
  firstName: '',
  lastName: '',
  email: '',
  phone: ''
};

export const SignUpStep1 = ({ onSubmit, data }) => (
  <SignUpStep
    onSubmit={onSubmit}
    data={data}
    validation={step1Validation}
    renderForm={({ errors, touched }) => (
      <Form>
          <div className="card-wrapper bg-light">
            <div className="row">
              <div className="col-md-6">
                <TextField
                  label="Name"
                  name="firstName"
                  placeholder="First Name"
                  error={
                    errors.firstName && touched.firstName ? errors.firstName : null
                  }
                />
              </div>
              <div className="col-md-6">
                <TextField
                  name="lastName"
                  placeholder="Last Name"
                  error={
                    errors.lastName && touched.lastName ? errors.lastName : null
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <TextField
                  label="Phone"
                  name="phone"
                  placeholder="eg. 0411111111"
                  error={errors.phone && touched.phone ? errors.phone : null}
                  className="phone__form-group"
                />
                <div className="phone__requirement">
                  <small className="phone__requirement__term">
                    Please make sure you enter your mobile number correctly as it
                    will be used to verify your account via SMS.
                  </small>
                </div>
              </div>
              <div className="col-md-6">
                <TextField
                  label="Email"
                  name="email"
                  placeholder="eg. hello@gmail.com"
                  error={errors.email && touched.email ? errors.email : null}
                />
              </div>
            </div>
            <div className="signup__controls signup__controls--right">
              <Button type="submit" color="teal">
                Next
              </Button>
            </div>
          </div>
      </Form>
    )}
  />
);
