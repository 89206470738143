
import InfoBubble from "../../../components/common/InfoBubble";

import React, {FC, useContext, useState} from "react";
import {

	getAccordionDefaultConfig,
	manageAccordion,
	includeTestId, getValFromObj
} from "../utils/common";
import {getUserAvatar} from "../../../components/UserProfile/utils";
import { RemoteUserType} from "../types/editProfileTypes";

import isEmpty from "lodash/isEmpty";

import EditProfileContext from "../context/EditProfileContext";
import Modal from 'react-modal';
import AvatarModal from "./helpers/avatar/AvatarModal";
import PhotoGallery from "./gallery/PhotoGallery";
import {editProfileService} from "../utils/editProfileService";
import { UserIcon } from '../../../components/common';
import { RoleCode } from '../../../store/Users/constants';


type ProfileImagesProps={
	user: RemoteUserType
	profileType: RoleCode;
};

if (process.env.NODE_ENV !== 'test') Modal.setAppElement('#root');

const Photos: FC<ProfileImagesProps>=({user, profileType})=>{
	const {accordionConfig, update, data}=useContext(EditProfileContext);
	const {profile_main,profile_other}=data;
	const userAvatar= getValFromObj(profile_main,'image_path')? profile_main.image_path: getUserAvatar();

	let [avatar, setUserAvatar]=useState(userAvatar);
	let [galleryImages, setGalleryImages]=useState(profile_other);


	const [modalOpen, setModalOpen] = useState(false);

	const setAvatar=async ()=>{

		const image=await editProfileService.COMMON.getAvatarImage(user);
		setUserAvatar(image.image_path);
	}

	const collapseMenu=()=>{
		manageAccordion(getAccordionDefaultConfig(), update);
	}

	const sectionValid = galleryImages.length>0 && avatar !== getUserAvatar();

	return (
		<div className="card">
			<AvatarModal isOpen={modalOpen} setIsOpen={setModalOpen} user={user} setAvatar={setAvatar} />
			<div className="card-header" role="tab" id="photosHeading">
				<a className={accordionConfig.photosCollapsed ? 'collapsed' : ''} href="#abt-photo" aria-expanded={!accordionConfig.aboutCollapsed} onClick={()=>{ manageAccordion({...getAccordionDefaultConfig(), photosCollapsed: !accordionConfig.photosCollapsed}, update) }}>

				<h2 className="mb-0">
						<div className="card-header__left" {...includeTestId('photo-gallery-heading')}>
							<i className={sectionValid ? 'fa fa-check icon-rounded' : 'fa fa-close icon-rounded'}/>
							Photo gallery
							<InfoBubble
								tooltip={`There are two types of images. The profile photo and your gallery images. The profile photo should be of the bedroom (if it's done up nicely) or some other nice feature of the house.<br/>
                              The gallery images are additional photos you can add that highlight areas of the house, the people in it and the surrounding areas such as beaches, shopping etc. Be mindful that these images will be visible to the public.`}
							/>
						</div>
					</h2>
				</a>
			</div>

			<div id="abt-photo" className={`collapse ${accordionConfig.photosCollapsed?'':'show'} `} role="tabpanel" aria-labelledby="photosHeading" data-parent="#profileAccordion">
			<div className="card-body">
					<div className="row">
						<div className="col-md-11">
							<div className="upload-top-des">
								You need to upload a profile image and at least one gallery image to be able to complete your profile.
							</div>
						</div>
					</div>
					<div className="col-md-6">
						<div className="pro-upload">
							<div className=" col-lg-3 col-md-4 col-sm-3 col-4 nopadding">
								<div className="img-wrapper">
									<UserIcon profile_image_url={avatar} {...includeTestId('user-avatar-gallery')} />
									<i className="fa fa-plus" onClick={()=>{setModalOpen(true)}} />
								</div>
							</div>
							<div className="upload-text">
								Upload your profile photo
							</div>
						</div>
					</div>
					<PhotoGallery galleryImages={galleryImages} setGalleryImages={setGalleryImages} user={user} profileType={profileType}/>

				</div>
				<div className="abt-btn float-right">
					<button className="button btn-orange" type="submit" onClick={collapseMenu} disabled={!sectionValid}>Save &amp; exit</button>
				</div>
			</div>
		</div>

	)
};


export default Photos;
