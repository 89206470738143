import {
  REQUEST_FETCH_CUSTOMER_CARD_DETAIL,
  FETCH_CUSTOMER_CARD_DETAIL_FAILURE,
  FETCH_CUSTOMER_CARD_DETAIL,
  FETCH_INVOICES,
  FETCH_STRIPE_INVOICES
} from '../actions/Payment';

const INITIAL_STATE = {
  requesting: false,
  invoices: null,
  stripeInvoices: null,
  cardError: '',
  cards: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REQUEST_FETCH_CUSTOMER_CARD_DETAIL:
      return { ...state, requesting: true };
    case FETCH_CUSTOMER_CARD_DETAIL:
      return { ...state, requesting: false, cards: action.payload };
    case FETCH_CUSTOMER_CARD_DETAIL_FAILURE:
      return { ...state, requesting: false, cardError: action.payload };
    case FETCH_INVOICES:
      return { ...state, invoices: action.payload };
    case FETCH_STRIPE_INVOICES:
      return { ...state, stripeInvoices: action.payload };
    default:
      return state;
  }
};
