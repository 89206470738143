import { createAction } from 'redux-actions';
import get from 'lodash/get';
import map from 'lodash/map';
import * as ApiSearchService from '../services/ApiSearchService';
import { RoleCode } from '../../store/Users/constants';

export const searchAction = createAction('SEARCH_USERS');
export const receiveSearchResultsAction = createAction(
  'RECEIVE_SEARCH_RESULTS'
);

export const searchUsers = (searchData) => async (dispatch) => {
  const { data, total, meta } = await (searchData.role === RoleCode.HOST
    ? ApiSearchService.searchHosts(searchData)
    : ApiSearchService.searchGuests(searchData));

  dispatch(receiveSearchResultsAction({ users: data, total, meta }));
  return true;
};

export const searchPublicUsers = (searchData) => async (dispatch) => {
  const { data, total, meta } = await ApiSearchService.searchPublicUsers(searchData);
  dispatch(receiveSearchResultsAction({ users: data, total, meta }));
  return true;
};
