import React, { useState, KeyboardEvent,  memo, FC, MouseEvent as ME, useEffect} from 'react';
import isNumber from 'lodash/isNumber';
import get from 'lodash/get';
import { useSelector, shallowEqual } from 'react-redux';
import { BoxedComponent } from '../common';
import FilterSelect from './FilterSelect/FilterSelect';
import LocationAutoComplete from '../LocationAutoComplete/LocationAutoComplete';
import { Location } from '../../store/Locations/constants';
// @ts-ignore
import { useHistory } from 'react-router-dom';
import { RoleCode } from '../../store/Users/constants';
import { roleLabel, optAge, optGender, optPeriod, optRentOffset, optRentBudget, paramRoles } from  './configs';
import { Button } from '../../components/common';

interface IAddress {
	suburb: string;
	post_code: number;
	state: string;
}
interface Props {
	mode: RoleCode;
	address?: IAddress;
	gender?: string;
	ageRange?: string;
	rentOffset?: string;
	rentBudget?: string;
	rentPeriod?: string;
	showHeading?: boolean;
	totalResults?: null | number;
	onSubmit?: (data:any) => void;
}

export const getSearchMode = (paramMode: null | keyof typeof paramRoles, profileRole: RoleCode) => {
	if(paramMode && paramRoles[paramMode])
		return paramRoles[paramMode];
	else if(profileRole)
		return profileRole === RoleCode.GUEST ? RoleCode.HOST : RoleCode.GUEST;
	else
		return RoleCode.HOST;
}

export const XchangeSearch: FC<Props> = ({ address, mode, gender, ageRange, rentOffset, rentPeriod, rentBudget, totalResults, showHeading, onSubmit }) => {
	const [searchMode, setSearchMode] = useState<RoleCode>(mode);
	const [periodFilter, setPeriodFilter] = useState<string>(rentPeriod || "");
	const [rentOffsetFilter, setRentOffsetFilter] = useState<string>(rentOffset || "");
	const [rentBudgetFilter, setRentBudgetFilter] = useState<string>(rentBudget || "");
	const [ageRangeFilter, setAgeRangeFilter] = useState<string>(ageRange || "");
	const [genderFilter, setGenderFilter] = useState<string>(gender || "");
	const [location, setLocation] = useState<Location|null>(null);
	const { login: loginData } = useSelector(
		({ login }: any) => ({
		  login,
		}),
		shallowEqual
	  )
	const history = useHistory();

	const toggleMode = (mode: RoleCode) => (event: ME<HTMLButtonElement>) => {
			event.preventDefault();
			setSearchMode(mode);
		}

	const getActiveClass = (roleCode: RoleCode) => searchMode === roleCode ? ' active' : ''

	const getLocationAttr = () => {
		const getData = (attr: string) => get(location, `source.${attr}`, '')
		return {
			state: getData('state'),
			suburb: getData('subtype') !== 'State' ? getData('name') : "",
			postcode: getData('postcode')
		}
	}

	const executeSearch = (event?: ME|KeyboardEvent): void => {
		if (!location) return;

		const mode = searchMode.toLowerCase() + 's';
		const { state, suburb, postcode } = getLocationAttr();
		const url = `/${loginData && loginData.user ? 'search' : 'profile_search'}?mode=${mode}&page=0&perPage=12` +
			`&rent_offset=${rentOffsetFilter}&rent_budget=${rentBudgetFilter}` +
			`&suburb=${suburb}&state=${state}&post_code=${postcode}` +
			`&gender=${genderFilter === null ? '' : genderFilter}`+
			`&age_range=${ageRangeFilter === null ? '' : ageRangeFilter}` +
			`&rent_period=${periodFilter === null ? '' : periodFilter}`;

		history.push(url);
		onSubmit && onSubmit({ role: searchMode,
			suburb,
			state,
			post_code: postcode,
			gender: genderFilter || '',
			age_range: ageRangeFilter || '',
			rent_period: periodFilter || '',
			rent_offset: rentOffsetFilter || '',
			rent_budget: rentBudgetFilter || ''
		});
	}

	useEffect(() => {
		if(location!==null && location.id){
			executeSearch();
		}
	}, [location])

	return (
		<div className="xchange-search-container">
			{showHeading && (
				<h3>Search through our verified {roleLabel[searchMode]}</h3>
			)}
			<div className="search-mode-button-group">
				<button className={`households ${getActiveClass(RoleCode.HOST)}`}
						onClick={toggleMode(RoleCode.HOST)}>Households</button>
				<button className={`housemates ${getActiveClass(RoleCode.GUEST)}`}
						onClick={toggleMode(RoleCode.GUEST)}>Housemates</button>
				{isNumber(totalResults) && totalResults > 0 && (
					<span className="total-results">
						{totalResults} result{totalResults > 1 && "s"} found
					</span>
				)}
			</div>
			<BoxedComponent className="search-bar-wrapper">
				<div className="fa fa-search"/>
				<LocationAutoComplete
					address={address}
					locationChangeCallback={setLocation}
					enterKeyCallback={executeSearch}
				/>
				<Button className="search-btn" disabled={location === null || !get(address, 'state', false)} onClick={executeSearch}>Search</Button>
			</BoxedComponent>
			<div className="filter-section">
				<div className='dropdown-lists'>
					<FilterSelect
						title="Rental period"
						defaultValue={rentPeriod}
						valueChangeCallback={(val: string) => setPeriodFilter(val)}
						items={optPeriod}
					/>
					<FilterSelect
						title="Rent offset"
						defaultValue={rentOffset}
						valueChangeCallback={(val: string) => setRentOffsetFilter(val)}
						items={optRentOffset}
					/>
					<FilterSelect
						title={searchMode === RoleCode.GUEST ? 'Budget' : 'Room value'}
						defaultValue={rentBudget}
						valueChangeCallback={(val: string) => setRentBudgetFilter(val)}
						items={optRentBudget}
					/>
					<FilterSelect
						title="Age range"
						defaultValue={ageRange}
						valueChangeCallback={(val: string) => setAgeRangeFilter(val)}
						items={optAge}
					/>
					<FilterSelect
						title="Gender"
						defaultValue={gender}
						valueChangeCallback={(val: string) => setGenderFilter(val)}
						items={optGender}
					/>
				</div>

			</div>
		</div>
	);
}

XchangeSearch.defaultProps = {
	mode: RoleCode.HOST,
	showHeading: true,
	totalResults: null,
	onSubmit: (data: any) => {}
}

export default memo(XchangeSearch);
