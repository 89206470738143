import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_RESTORE
} from '../actions/Login';

const initialState = {
  isProcessing: false,
  user: null,
  error: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LOGIN_REQUEST:
      return { ...state, isProcessing: true };
    case LOGIN_SUCCESS:
      return { ...state, user: action.payload, isProcessing: false };
    case LOGIN_FAILURE:
      return { ...state, error: action.payload, isProcessing: false };
    case LOGIN_RESTORE:
      return { ...state, user: action.payload };
    default:
      return state;
  }
}
