import React, { FC, memo } from 'react';
import truncate from 'lodash/truncate';
import toLower from 'lodash/toLower';
import { MessageCardProps } from '../types';

const MessageCard: FC<MessageCardProps> = ({
	recipient: { connection_id, userId, name, photo, text, status, seenStatus },
	activeConnectionId,
	handleActiveMessage
}) => {
	return (
		<div
			className={`message-card box ${
				activeConnectionId === connection_id && 'active-connection'
			}`}
			onClick={() => handleActiveMessage(connection_id)}
		>
			<img className="user-image" src={photo} alt="user" />
			<div className="message-info">
				<p className="user-title">{name}
					<span>
						{toLower(seenStatus) === 'unseen' && (
							<i className="fa fa-comment" title="Message Unseen"></i>
						)}
						{toLower(status) !== 'accept' && 
						(<i className="fa fa-exclamation-circle" title={toLower(status)}></i>)}
					</span>
				</p>
				<p className="excerpt">
					{truncate(text, { length: 50, separator: ' ' })}
				</p>
			</div>
			<div className="clearfix"></div>
		</div>
	);
};

export default memo(MessageCard);
