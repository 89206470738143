import React from 'react';
import { Formik, Form } from 'formik';
import { object, string } from 'yup';
import { TextField } from '../forms';
import PropTypes from 'prop-types';

const requestVerificationFormInitialValues = {
  email: '',
  phone_number: ''
};

export const AUPhoneRegEx = /^(\+?61|0)4\d{8}$/;

const requestVerificationFormValidation = object({
  email: string()
    .required('Please enter your email address')
    .email('Please enter a valid email address'),
  phone_number: string()
    .required('Please enter your phone number')
    .matches(AUPhoneRegEx, 'Please enter a valid phone number')
});

export class RequestVerificationForm extends React.Component {
  static propTypes = {
    isRequesting: PropTypes.bool,
    isSuccess: PropTypes.bool,
    onRequest: PropTypes.func,
    error: PropTypes.string
  };

  onSubmit = data => {
    const { onRequest } = this.props;

    onRequest(data);
  };

  renderForm = ({ errors, touched }) => {
    const { onSend, error } = this.props;

    return (
      <Form className="verification__form">
        <div className="row">
          <div className="col-md-12">
            <TextField
              name="email"
              placeholder="Please enter your email"
              error={errors.email && touched.email ? errors.email : null}
              displayLabelIfEmpty={false}
            />
          </div>
          <div className="col-md-12">
            <TextField
              name="phone_number"
              placeholder="Please enter your phone number"
              error={
                errors.phone_number && touched.phone_number
                  ? errors.phone_number
                  : null
              }
              displayLabelIfEmpty={false}
            />
          </div>
          <div className="col-md-12">
            <button
              onClick={onSend}
              type="submit"
              className="verify__controls__btn"
            >
              Request SMS
            </button>
          </div>
        </div>
        {error && <div className="verification__errors">{error}</div>}
      </Form>
    );
  };

  render() {
    return (
      <Formik
        initialValues={requestVerificationFormInitialValues}
        validationSchema={requestVerificationFormValidation}
        onSubmit={this.onSubmit}
        validateOnBlur={true}
        render={this.renderForm}
      />
    );
  }
}
