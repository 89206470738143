export const loginSuccess = user =>
  localStorage.setItem('trx-user-session', JSON.stringify(user));

export const isAuthenticated = () =>
  localStorage.getItem('trx-user-session')
    ? JSON.parse(localStorage.getItem('trx-user-session'))
    : null;

export const logoutSuccess = () => localStorage.removeItem('trx-user-session');

export const load = item => {
  try {
    const serializedItem = localStorage.getItem(item);

    if (serializedItem === null) {
      return undefined;
    }

    return JSON.parse(serializedItem);
  } catch (err) {
    return undefined;
  }
};

export const save = (item, itemData) => {
  try {
    const serializedItem = JSON.stringify(itemData);

    localStorage.setItem(item, serializedItem);
  } catch (err) {
    // Ignore write errors.
    console.log(err);
  }
};

export const clear = item => {
  try {
    localStorage.removeItem(item);
  } catch (err) {
    // Ignore write errors.
    console.log(err);
  }
};
