export default [
  "English",
  "Italian",
  "French",
  "Mandarin",
  "Spanish",
  "Hindi",
  "Arabic",
  "Portugese",
  "Russian",
  "Bengali",
  "Japanese",
  "Punjabi",
  "German",
  "Korean",
  "Javanese",
  "Tamil",
  "Turkish",
  "Urdu"
]
