import React, {FC, useContext, useRef, useState} from "react";
import Modal from "react-modal";
import {RemoteUserType} from "../../types/editProfileTypes";
import Cropper from "react-cropper";
import RemoteUpdateError from "../helpers/RemoteUpdateError";
import {editProfileService} from "../../utils/editProfileService";
import {ACTION_CONTINUE, postActionSuccess, setAjaxLoader, showAjaxError} from "../../utils/common";
import EditProfileContext from "../../context/EditProfileContext";


type AvatarModalProps={
	isOpen: boolean,
	setIsOpen: Function,
	user: RemoteUserType,
	refreshImages: Function
}
if (process.env.NODE_ENV !== 'test') Modal.setAppElement('#root');


const UploadModal: FC<AvatarModalProps>=({isOpen, setIsOpen, user,refreshImages})=>{

	const [image, setImage]=useState<any>('');
	const [submissionError, setSubmissionError]=useState('');
	const inputFileRef=useRef(null);
	const {update}=useContext(EditProfileContext);

	const customStyles = {
		content: {
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			transform: 'translate(-50%, -50%)',
			border: 'unset',
			width: '600px',
		},
		overlay: {
			background: '#000000bf'
		}
	};

	const onImageSelect=(e: any)=>{
		let file=e.target.files[0];
		const reader = new FileReader()
		reader.addEventListener('load', () =>
		{
			setImage(reader.result);
		})
		reader.readAsDataURL(file)

	}

	const onBtnClick=(ref: any)=>{
		ref.current.click();
	}

	const uploadGalleryImage=()=>{
		setAjaxLoader(true,update);
		editProfileService.COMMON.uploadGalleryPhoto(image, user).then(result=>{
			const {data}=result;
			if(data.status==='success')
			{
				refreshImages();
				setIsOpen(false);

			}
			else if(data.msg!==undefined)
				setSubmissionError(data.msg);
			else
				setSubmissionError('Some error occurred. Please try again later');

			setAjaxLoader(false,update);
			setImage('');
		}).catch(res=>{
			showAjaxError(res,setSubmissionError,update);
		});
	}


	return (
		<>
			<input type="file" onChange={onImageSelect}  ref={inputFileRef} className={'d-none'}  accept="image/*"/>
			<Modal isOpen={isOpen} style={customStyles}>
				{image==='' && <div>
							<div className="modal-header">
								<button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => {setIsOpen(false)}}><span aria-hidden="true"><i className="fa fa-close"></i></span></button>
							</div>
							<div className="modal-body">
								<div id="modalinnerbody" style={{cursor: 'pointer'}} onClick={() => {onBtnClick(inputFileRef)}}><h3><i className="fa fa-plus"></i>Upload Image</h3>
								</div>
							</div>
							<div className="modal-footer">
								<button className="button btn-teal mx-auto " type="button" onClick={() => {onBtnClick(inputFileRef)}}>Upload Image</button>
							</div>
					</div>
				}

				{image &&
					<div>
						<div className="modal-header">
							<button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => {setIsOpen(false)}}><span aria-hidden="true"><i className="fa fa-close"></i></span></button>
						</div>
						<div className="modal-body">
							<div id="cropperwrap">
								<div>
									<img src={image} style={{width: '400px', margin: '0 auto',display: 'block'}} alt="gallery image"/>
								</div>
							</div>
						</div>
						<div className="modal-footer">
							<RemoteUpdateError submissionError={submissionError}/>
							<div>
								<button className="button btn-orange  " type="submit" onClick={uploadGalleryImage}>Upload Gallery Photo</button>
								<button className="button btn-grey  " type="button" onClick={() => {setIsOpen(false)}}>Cancel
								</button>
							</div>
						</div>

					</div>
				}
			</Modal>
		</>
	)
}


export default UploadModal;
