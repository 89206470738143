import React, { FC, memo } from 'react';
import moment from 'moment';
import { MessageBoxProps } from '../types'

const formatDate = (date: string) => {
	const momentDate = moment(date, 'YYYY-MM-DD HH:mm:ss');
	const isSameDay = momentDate.isSame(moment(), 'day');
	return isSameDay
		? momentDate.format('hh:mm A')
		: momentDate.format('MMM DD[,] YYYY hh:mm A');
};

const MessageBox: FC<MessageBoxProps> = ({
	created_at,
	created_by,
	userId,
	text,
	userPhoto,
	nextMessageUser,
	prevMessageUser
}) => {
	return (
		<div
			className={`message-box ${
				created_by === userId
					? 'loggedin-user-message'
					: 'recipient-message'
			} ${nextMessageUser === created_by ? 'user-group' : ''}`}
		>
			{created_by !== userId && prevMessageUser !== created_by && (
				<img
					className="recipient-photo"
					alt="user-photo"
					src={userPhoto}
				/>
			)}
			<p className="message-text">
				{text}
				<span className="message-time">{formatDate(created_at)}</span>
			</p>
			<div className="clearfix"></div>
		</div>
	);
};

export default memo(MessageBox);
