import { createAction } from 'redux-actions';
import axios from 'axios';
import URL from '../constants/URL';

const VERIFYING_SMS_CODE = 'VERIFYING_SMS_CODE';
const VERIFY_SMS_CODE_FAILED = 'VERIFY_SMS_CODE_FAILED';
const VERIFY_SMS_CODE_SUCCESS = 'VERIFY_SMS_CODE_SUCCESS';
const SENDING_SMS_CODE = 'SENDING_SMS_CODE';
const SEND_SMS_SUCCESS = 'SEND_SMS_SUCCESS';
const SEND_SMS_FAILED = 'SEND_SMS_FAILED';
const RESET_SMS_VERIFICATION = 'RESET_SMS_VERIFICATION';
const REQUEST_SMS_VERIFICATION = 'REQUEST_SMS_VERIFICATION';

export const verifyingSMSCode = createAction(VERIFYING_SMS_CODE);
export const verifySMSCodeSuccess = createAction(VERIFY_SMS_CODE_SUCCESS);
export const verifySMSCodeFailed = createAction(VERIFY_SMS_CODE_FAILED);

export const sendingSMSCode = createAction(SENDING_SMS_CODE);
export const sendSMSSuccess = createAction(SEND_SMS_SUCCESS);
export const sendSMSFailed = createAction(SEND_SMS_FAILED);

export const resetSMSVerification = createAction(RESET_SMS_VERIFICATION);
export const requestSMSVerification = createAction(REQUEST_SMS_VERIFICATION);

export const verifySMS = verifyData => async dispatch => {
  dispatch(verifyingSMSCode());

  try {
    const result = await axios
      .post(URL.VERIFY_SMS_CODE, verifyData)
      .then(response => response.data);

    dispatch(verifySMSCodeSuccess(result));

    return { success: true, result };
  } catch (error) {
    dispatch(verifySMSCodeFailed(error.response.data.msg));
  }
};

export const sendVerifySMS = data => async dispatch => {
  dispatch(sendingSMSCode());

  try {
    const result = await axios
      .post(URL.SEND_VERIFY_SMS_CODE, data)
      .then(response => response.data);

    dispatch(sendSMSSuccess(data));

    return { success: true, result };
  } catch (error) {
    dispatch(sendSMSFailed(error.response.data.msg));
  }
};
