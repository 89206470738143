import React, { memo, FC, useCallback } from 'react';
import get from 'lodash/get';
import { userCardStyles } from './styles';
import { IUserCard } from './types';
import { Button } from '../../../components/common';


export const UserCard: FC<IUserCard> = ({
    user: {
      id,
      display_name,
      profile_picture = '/themes/theroomxchange/assets/images/useravatar.png',
      location,
      user_type,
      bio,
      budget,
      rent_offset
    },
    action,
    profile
  }) => {
    const {
      card,
      cardContent,
      cardContentHeading,
      cardTitle,
      cardSubtitle,
      cardDescription,
      cardImageContainer,
      cardImage,
      cardActionWrapper,
      cardBudget,
      rentOffset,
      rentBudget
    } = userCardStyles();

    const handleCardClick = useCallback(() => {
      if (!action || !action.onClick) {
        return;
      }

      action.onClick({ user_type, id });
    }, [action, id, user_type]);

    const viewProfileButton = () => {
      return (
        <Button onClick={handleCardClick}>{action.label}</Button>
      );
    }

    return (
      <div className={card} onClick={handleCardClick}>
        <div className={cardImageContainer}>
          <img className={cardImage} src={profile_picture} alt="User" />
        </div>
        <div className={cardContent}>
          <div>
            <div className={cardContentHeading}>
              <h3 className={cardTitle}>{display_name}</h3>
              <span className={cardBudget}>
                {rent_offset && <span className={rentOffset}>OFFSET</span>}
                {budget && <span className={rentBudget}>${budget}</span>}
              </span>

            </div>
            <p className={cardSubtitle}><i className="fa fa-map-marker" />{get(location, 'suburb', '')}</p>
            <div className={cardDescription}>{bio}</div>
          </div>
          <div className={cardActionWrapper}>
            {action && viewProfileButton()}
            <span className="user-badges badges-wrap">
              <span title='Digital ID' className='digital_id'></span>
            </span>
        </div>
        </div>
      </div>
    );
  }

  export default memo(UserCard);
