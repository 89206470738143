import React from 'react';

/**
 * Convenience component to render the default loading animation.
 */
export const Loader: React.FC = () => {
	return (
		<div className="data-loading-animation">
			<div className="bubble_1 bubble"/>
			<div className="bubble_2 bubble"/>
			<div className="bubble_3 bubble"/>
		</div>
	);
};
